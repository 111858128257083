import { useEffect } from "react";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";

const DownloadLink = () => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(userAgent) || /Mac/.test(userAgent)) {
      window.location.href = import.meta.env.VITE_APP_STORE_APP_LINK;
    } else {
      window.location.href = import.meta.env.VITE_PLAY_STORE_APP_LINK;
    }
  }, []);

  return (
    <div className="h-screen w-screen">
      <div className="flex items-center justify-center h-full">
        <SpinnerIcon className="text-3xl" />
      </div>
    </div>
  );
};

export default DownloadLink;
