import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ProductImage } from "~/api/models/ProductDetails";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchBundleForCode } from "~/api/storyblok/useFetchBundleForCode";
import OfferCard from "~/components/molecules/OfferCard/OfferCard";
import EditableSkinBundle from "~/components/organisms/Pricing/SkinBundleCard/EditableSkinBundle/EditableSkinBundle";
import SkinBundleCardProduct from "~/components/organisms/Pricing/SkinBundleCard/SkinBundleCardProduct";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { getImageBasedOnGender } from "~/utils";

const SkinBundleCard = () => {
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const intl = useIntl();
  const { selectedBundle, hasSkinCare } = useCheckoutStore();
  const { data } = useFetchBundleForCode(selectedBundle?.productCode);
  const customGetImageBasedOnGender = (image?: ProductImage) => {
    return getImageBasedOnGender({
      image,
      opportunityGender: opportunity?.gender,
    });
  };

  return (
    <AnimatePresence>
      {!loadingOpportunity && selectedBundle && (
        <OfferCard
          benefits={data?.hideBenefits ? [] : data?.benefits}
          treatments={
            <>
              {selectedBundle
                ?.getProducts(hasSkinCare)
                .map((bundleProduct) => (
                  <SkinBundleCardProduct
                    key={bundleProduct.productCode}
                    bundleProduct={bundleProduct}
                    customGetImageBasedOnGender={customGetImageBasedOnGender}
                  />
                ))}
            </>
          }
          title={selectedBundle?.name}
          price={intl.formatMessage(
            { id: "pricing.amount" },
            { amount: selectedBundle?.getPrice(hasSkinCare) }
          )}
          image={{
            male: data?.maleImage,
            female: data?.femaleImage,
          }}
          opportunityGender={opportunity?.gender}
        >
          <EditableSkinBundle editable={opportunity?.isValidForSales} />
        </OfferCard>
      )}
    </AnimatePresence>
  );
};

export default SkinBundleCard;
