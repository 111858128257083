import QRCode from "react-qr-code";
import Copy from "~/components/atom/Copy";
import Card from "~/components/atoms/Card/Card";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import GoogleRatingPill from "~/components/molecules/GoogleRatingPill/GoogleRatingPill";

type ReviewCardProps = {
  locationUrl?: string;
  locationName?: string;
};

const ReviewCard = ({
  locationUrl = "",
  locationName = "",
}: ReviewCardProps) => {
  return (
    <Card className="flex gap-4 items-center">
      <QRCode value={locationUrl} className="w-24 h-24 p-1" />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <Headline level={6}>
            <Copy id="summary.cards.review.title" />
          </Headline>
          <Subtitle level={2} className="text-grey-400">
            <Copy id="summary.cards.review.subtitle" />
          </Subtitle>
        </div>
        <div className="flex gap-2 items-center">
          <GoogleRatingPill />
          <Subtitle level={2} className="text-grey-400">
            {locationName}
          </Subtitle>
        </div>
      </div>
    </Card>
  );
};

export default ReviewCard;
