import { HTMLAttributes, ReactNode } from "react";
import { cn } from "~/utils";

type FormWrapperProps = {
  children: ReactNode;
  className?: HTMLAttributes<HTMLDivElement>["className"];
};

export const FormWrapper = ({ children, className }: FormWrapperProps) => (
  <div
    className={cn(
      "flex flex-col rounded-2xl border border-grey-200",
      className
    )}
  >
    {children}
  </div>
);
