import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Subtitle, { Level } from "~/components/atoms/Subtitle/Subtitle";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

export type EditableOptionProps = PropsWithClassName<{
  title?: string;
  level?: Level;
  onClick?: () => void;
  shouldHideTitle?: boolean;
  editable?: boolean;
}>;

const EditableOption = ({
  title,
  level,
  onClick,
  className,
  shouldHideTitle,
  editable = true,
}: EditableOptionProps) => {
  if (!title && !shouldHideTitle) {
    return;
  }
  return (
    <div
      className={cn(
        "flex items-center text-grey-400 justify-between",
        shouldHideTitle ? "ml-2" : "gap-2",
        className
      )}
    >
      {!shouldHideTitle && <Subtitle level={level ?? 3}>{title}</Subtitle>}
      {editable && (
        <button onClick={onClick} className="flex items-center">
          <FontAwesomeIcon
            icon={faPenToSquare}
            className="text-grey-400 cursor-pointer text-xs hover:text-grey-900 transition-colors duration-300 ease-in"
          />
        </button>
      )}
    </div>
  );
};

export default EditableOption;
