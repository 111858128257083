import { PropsWithChildren } from "react";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

export type Level = 1 | 2 | 3 | 4;

type SubtitleProps = PropsWithClassName<PropsWithChildren<{ level?: Level }>>;

const getStyles = (level: Level) => {
  switch (level) {
    case 1:
      return "text-base";

    case 2:
      return "text-sm";

    case 3:
      return "text-xs";

    case 4:
      return "text-2xs";
  }
};

const Subtitle = ({ level = 1, children, className }: SubtitleProps) => {
  return <p className={cn(getStyles(level), className)}>{children}</p>;
};

export default Subtitle;
