import { useQuery } from "@tanstack/react-query";
import {
  Example,
  ExampleCategory,
  ExampleDTO,
} from "~/modules/example/example";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchExamples = (category: ExampleCategory) => {
  const { locale } = useLocaleStore();

  const result = useQuery({
    queryKey: [QueryKeys.useFetchExamples, locale, category],
    queryFn: () =>
      storyblokServiceSingleton.get<ExampleDTO>(
        category === ExampleCategory.hair
          ? storyblokRoutes.hairExamples
          : storyblokRoutes.skinExamples,
        {
          locale,
        }
      ),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    ...result,
    data: result.data?.data?.stories.map((story) => {
      return new Example(
        story.content._uid,
        story.content.title,
        story.content.image.filename,
        story.content.slug,
        story.content.categoryKey,
        story.content.parent_id,
        story.content.treatments,
        story.content.exclusives
      );
    }),
  };
};
