import { useQuery } from "@tanstack/react-query";
import { ScanFile } from "~/api/models/ScanFile";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const REFETCH_INTERVAL_TO_UPDATE_APP_WITH_NEW_DOCS = 5000;

const PDF_FILE_EXTENSION = "pdf";

export type ScanDocumentType = "hair" | "skin";

type UseFetchRecentScansArgs = {
  opportunityId: string;
  type: ScanDocumentType;
  shouldRefetch?: boolean;
};

export const useFetchRecentScans = ({
  opportunityId,
  type,
  shouldRefetch = false,
}: UseFetchRecentScansArgs) => {
  const { data } = useFetchAccessToken();
  const { locale } = useLocaleStore();

  return useQuery({
    queryKey: [QueryKeys.useFetchRecentScans, opportunityId, type, locale],
    queryFn: () =>
      salesforceServiceSingleton.getRecentScans(
        data!.accessToken, //accessToken is not null because of the enabled flag
        opportunityId,
        locale
      ),
    enabled: !!data?.ensureAccessTokenIsValid?.(),
    select: (data) => {
      if (!data) return undefined;
      if (
        type === "hair" &&
        data.hair_scan &&
        data.hair_scan?.file_extension === PDF_FILE_EXTENSION
      ) {
        return new ScanFile(data.hair_scan);
      } else if (
        type === "skin" &&
        data.skin_scan &&
        data.skin_scan?.file_extension === PDF_FILE_EXTENSION
      ) {
        return new ScanFile(data.skin_scan);
      }
    },
    refetchInterval:
      shouldRefetch && REFETCH_INTERVAL_TO_UPDATE_APP_WITH_NEW_DOCS,
  });
};
