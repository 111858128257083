import {
  Dialog,
  DialogContent,
  DialogClose,
} from "~/components/atoms/Dialog/Dialog";
import FileUploadZone from "../FileUploadZone/FileUploadZone";

type FileUploadModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onFileDrop: (file: File | null) => void;
  loadingUpload: boolean;
  disabled?: boolean;
};

const FileUploadModal = ({
  open,
  setOpen,
  onFileDrop,
  loadingUpload,
  disabled,
}: FileUploadModalProps) => {
  const onOpenChange = (open: boolean) => {
    if (!loadingUpload) {
      setOpen(open);
    }
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-1/2 max-w-full md:w-1/2 p-0 rounded-lg">
        <div className="absolute right-4 top-4 z-50">
          <DialogClose />
        </div>
        <FileUploadZone
          onFileDrop={onFileDrop}
          loadingUpload={loadingUpload}
          disabled={disabled}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
