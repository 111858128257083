import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchLoggedInUser = () => {
  const { data } = useFetchAccessToken();
  const { locale } = useLocaleStore();

  return useQuery({
    queryKey: [QueryKeys.useFetchLoggedInUser],
    queryFn: async () => {
      const response = await salesforceServiceSingleton.getLoggedInUser(
        data!.accessToken,
        locale
      ); // accessToken is not null because of the enabled flag

      Sentry.setUser({
        email: response.email,
        id: response.id,
        username: response.display_name,
      });

      return response;
    },
    enabled: !!data?.ensureAccessTokenIsValid?.(),
  });
};
