import {
  SELECTED_RADIUS,
  UNSELECTED_RADIUS,
} from "~/components/molecules/SkinHeadConfiguration//SkinHeadConfiguration";
import { SkinSection } from "~/stores/headConfiguration/ConfigurationSection.types";
import { cn } from "~/utils";

type FemaleSkinConfigurationProps = {
  toggleSkinSectionAndUpdateProduct: (section: SkinSection) => void;
  isSelected: (section: SkinSection) => boolean;
};

const FemaleSkinConfiguration = ({
  toggleSkinSectionAndUpdateProduct,
  isSelected,
}: FemaleSkinConfigurationProps) => {
  return (
    <div>
      <svg
        className="h-full w-full"
        viewBox="0 0 480 444"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.827 378.256C45.6836 377.252 45.6836 376.392 45.5401 375.388C43.1019 347.993 48.9824 320.312 61.8908 295.929C74.9426 271.116 90.5762 246.447 90.5762 195.1C90.5762 161.108 96.7435 134 105.636 112.486L108.074 106.893C132.17 54.5419 172.329 38.3347 172.329 38.3347C186.242 30.5897 204.457 26.1434 224.824 26C229.987 26 235.007 26.4303 240.027 27.5777H240.17C245.047 26.4303 250.21 26 255.23 26C275.597 26.1434 293.812 30.5897 307.724 38.3347C307.724 38.3347 347.884 54.6854 371.98 106.893L374.418 112.486C383.31 134 389.478 161.108 389.478 195.1C389.478 246.447 405.111 270.973 418.163 295.929C431.071 320.312 436.952 347.85 434.514 375.388C434.37 376.392 434.37 377.252 434.227 378.256"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M258.672 400.345C257.094 404.074 256.807 405.365 255.086 410.528L258.672 400.345Z"
          fill="white"
        />
        <path
          d="M258.672 400.345C257.094 404.074 256.807 405.365 255.086 410.528"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M219.373 400.345C220.951 404.074 221.238 405.365 222.959 410.528L219.373 400.345Z"
          fill="white"
        />
        <path
          d="M219.373 400.345C220.951 404.074 221.238 405.365 222.959 410.528"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M336.697 208.009C336.124 213.602 335.693 219.196 335.407 224.359C334.689 237.985 332.108 251.467 328.092 264.519C318.769 294.782 298.259 320.885 270.004 337.81C269.143 338.384 268.139 338.957 267.135 339.388C259.247 343.404 250.498 345.268 241.605 345.268H237.016C228.123 345.268 219.374 343.26 211.486 339.388C210.482 338.814 209.478 338.384 208.617 337.81C180.362 320.885 159.852 294.638 150.529 264.519C146.513 251.467 144.075 237.985 143.215 224.359C143.071 221.204 142.641 217.905 142.354 214.463C142.354 214.463 126.864 143.18 163.151 105.745C190.976 76.9164 234.864 79.2113 234.864 79.2113"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M347.024 195.387C351.326 196.534 355.629 200.263 353.334 210.733C350.035 225.076 343.008 243.148 332.824 247.881"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M131.739 195.387C127.436 196.534 123.133 200.263 125.428 210.733C128.727 225.076 135.755 243.148 145.938 247.881"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M270.721 291.051C270.721 291.051 266.561 293.203 256.521 292.629C250.21 292.199 245.764 292.773 242.896 293.346C240.601 293.777 238.306 293.633 236.011 293.203C233.143 292.486 228.41 291.912 222.242 292.342C212.346 292.916 208.043 290.765 208.043 290.765"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M262.401 298.368C262.401 298.368 257.094 305.683 244.329 306.4C243.468 306.4 242.464 306.4 241.604 306.4H237.875C236.871 306.4 236.01 306.4 235.15 306.4C222.385 305.683 216.791 298.368 216.791 298.368"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M225.396 255.625C225.97 255.195 226.831 254.908 228.122 254.765C230.56 254.621 232.855 256.773 234.289 257.777C237.158 259.785 241.317 260.502 244.903 259.354C245.189 259.211 245.476 259.211 245.62 259.067L247.054 258.35C247.628 258.063 248.058 257.777 248.632 257.49C250.066 256.629 252.217 255.195 254.369 255.338C254.942 255.338 255.516 255.482 255.946 255.625"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M220.235 248.886C218.8 251.754 217.94 256.2 222.529 259.069"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M260.967 248.886C262.545 251.611 263.836 255.914 259.533 259.212"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M222.816 195.101C222.816 195.101 227.98 204.854 226.115 224.36"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M259.963 176.884V177.027C260.393 180.469 263.835 182.621 267.134 181.904L294.672 175.306C297.397 174.589 300.266 174.302 303.134 174.446C305.286 174.589 307.867 174.876 310.162 175.88C316.043 178.175 325.796 184.629 325.796 184.629L319.915 177.601C312.457 168.708 300.839 164.262 289.222 165.84L266.417 168.995C262.401 169.426 259.532 173.011 259.963 176.884Z"
          fill="#F8F8F8"
        />
        <path
          d="M219.947 176.167V176.31C219.66 179.752 216.218 182.047 212.776 181.33L185.238 175.306C182.513 174.733 179.644 174.446 176.776 174.589C174.624 174.733 172.043 175.163 169.748 176.167C163.867 178.605 154.258 185.346 154.258 185.346L160.138 178.175C167.453 169.139 178.927 164.406 190.545 165.84L213.35 168.565C217.366 168.852 220.378 172.294 219.947 176.167Z"
          fill="#F8F8F8"
        />
        <path
          d="M185.811 320.6C185.811 320.6 185.238 354.592 181.365 378.257"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M292.809 320.6C292.809 320.6 293.382 354.592 297.255 378.257"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M256.234 79.0684C257.095 79.0684 257.956 79.0684 258.673 79.0684C305.573 79.7855 333.541 115.355 336.553 164.551C336.697 167.563 336.697 170.575 336.553 173.587C336.267 182.192 335.406 203.419 335.549 204.71C336.123 208.583 337.557 209.3 339.279 207.292C339.279 207.292 349.175 194.957 351.04 184.631"
          stroke="black"
          strokeWidth="0.717134"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle
          id="botox-glabella"
          cx="240"
          cy="174"
          r={isSelected("botox-glabella") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          stroke="#D3CDBE"
          strokeWidth="0.502121"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("botox-glabella"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("botox-glabella");
          }}
        />
        <circle
          id="botox-forehead"
          cx="240"
          cy="120"
          r={isSelected("botox-forehead") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          stroke="#D3CDBE"
          strokeWidth="0.502121"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("botox-forehead"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("botox-forehead");
          }}
        />
        <circle
          id="botox-nasojugal-fold"
          cx="213"
          cy="210"
          r={
            isSelected("botox-nasojugal-fold")
              ? SELECTED_RADIUS
              : UNSELECTED_RADIUS
          }
          stroke="#D3CDBE"
          strokeWidth="0.502121"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("botox-nasojugal-fold"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("botox-nasojugal-fold");
          }}
        />
        <g
          filter="url(#filter0_b_11129_227921)"
          id="filler-jawline"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("filler-jawline"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("filler-jawline");
          }}
        >
          <circle
            cx="181"
            cy="302"
            r={
              isSelected("filler-jawline") ? SELECTED_RADIUS : UNSELECTED_RADIUS
            }
            stroke="#D3CDBE"
            strokeWidth="0.502121"
          />
        </g>
        <g
          filter="url(#filter1_b_11129_227921)"
          id="filler-chin"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("filler-chin"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("filler-chin");
          }}
        >
          <circle
            cx="238"
            cy="329"
            r={isSelected("filler-chin") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
            stroke="#D3CDBE"
            strokeWidth="0.502121"
          />
        </g>
        <g
          filter="url(#filter2_b_11129_227921)"
          id="filler-lips"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("filler-lips"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("filler-lips");
          }}
        >
          <circle
            cx="238"
            cy="294"
            r={isSelected("filler-lips") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
            stroke="#D3CDBE"
            strokeWidth="0.502121"
          />
        </g>
        <g
          filter="url(#filter3_b_11129_227921)"
          id="filler-cheeks"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("filler-cheeks"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("filler-cheeks");
          }}
        >
          <circle
            cx="301"
            cy="241"
            r={
              isSelected("filler-cheeks") ? SELECTED_RADIUS : UNSELECTED_RADIUS
            }
            stroke="#D3CDBE"
            strokeWidth="0.502121"
          />
        </g>
        <circle
          id="botox-outer-eye"
          cx="314"
          cy="194"
          r={
            isSelected("botox-outer-eye") ? SELECTED_RADIUS : UNSELECTED_RADIUS
          }
          stroke="#D3CDBE"
          strokeWidth="0.502121"
          className={cn(
            "cursor-pointer fill-white transition-all duration-500",
            {
              "fill-[#E0DACC]": isSelected("botox-outer-eye"),
            }
          )}
          onClick={() => {
            toggleSkinSectionAndUpdateProduct("botox-outer-eye");
          }}
        />
        <defs>
          <filter
            id="filter0_b_11129_227921"
            x="168.498"
            y="289.498"
            width="25.0039"
            height="25.0044"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_11129_227921"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_11129_227921"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_b_11129_227921"
            x="225.498"
            y="316.498"
            width="25.0039"
            height="25.0044"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_11129_227921"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_11129_227921"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_b_11129_227921"
            x="225.498"
            y="281.498"
            width="25.0039"
            height="25.0044"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_11129_227921"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_11129_227921"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_b_11129_227921"
            x="288.498"
            y="228.498"
            width="25.0039"
            height="25.0044"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_11129_227921"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_11129_227921"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default FemaleSkinConfiguration;
