import { Product } from "~/stores/checkout/product";
import { formatSalesForcePercent } from "~/utils/number.utils";
import { SalesforceBundle } from "./Bundle";

export type CalculatePriceDto = {
  products: string[];
  bundles: string[];
  exclude_bundle_products_ids: string[];
  payment_method: string | null;
  discount_code: string | null;
  referral_code: string | null;
  special_conditions: string[];
  other_special_conditions: string | null;
};
export type CalculatePrice = {
  hasSkinCare: boolean;
  products: Product[];
  selectedBundle: SalesforceBundle | null;
  paymentMethod: string | null;
  discountCode: string | null;
  referralCode: string | null;
  specialConditions: string[];
  otherSpecialConditions: string | null;
};

export type PricingBreakdownDto = {
  name: string;
  total_amount: number;
  total_discount: number;
  discounted_amount: number;
  discount_percent: number;
  last_modified_date_time: string;
  selected_discount?: {
    name: string;
    discount_code: string | null;
    referral_code: string | null;
    value: number;
  };
};

export class PricingBreakdown {
  name: string;
  totalAmount: number;
  totalDiscount: number;
  discountedAmount: number;
  discountPercent: string;
  lastModifiedDateTime: Date;
  discountCode: string | null;
  referralCode: string | null;

  constructor(dto: PricingBreakdownDto) {
    this.name = dto.name;
    this.totalAmount = dto.total_amount;
    this.totalDiscount = dto.total_discount;
    this.discountedAmount = dto.discounted_amount;
    this.discountPercent = formatSalesForcePercent(dto.discount_percent);
    this.lastModifiedDateTime = new Date(dto.last_modified_date_time);
    this.discountCode = dto.selected_discount?.discount_code || null;
    this.referralCode = dto.selected_discount?.referral_code || null;
  }
}
