import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { AppLocale } from "~/utils/types";

interface LocaleStore {
  locale: AppLocale;
  setLocale: (locale: AppLocale) => void;
  setIsLocaleManuallySelected: (val: boolean) => void;
  isLocaleManuallySelected: boolean;
}

const DEFAULT_LOCALE: AppLocale = "en" as const;

export const useLocaleStore = create<LocaleStore>()(
  persist(
    (set) => ({
      isLocaleManuallySelected: false,
      locale: DEFAULT_LOCALE,
      setLocale: (newLocale: AppLocale) =>
        set({
          locale: newLocale,
        }),
      setIsLocaleManuallySelected: (val: boolean) =>
        set({
          isLocaleManuallySelected: val,
        }),
    }),
    {
      storage: createJSONStorage(() => sessionStorage),
      name: "locale",
    }
  )
);
