import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import {
  ScanDocumentType,
  useFetchRecentScans,
} from "~/api/salesForce/useFetchRecentScans";
import { useUploadScan } from "~/api/salesForce/useUploadScan";
import Copy from "~/components/atom/Copy";
import LoadingDots from "~/components/atoms/LoadingDots/LoadingDots";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import FileUploadZone from "~/components/molecules/FileUploadZone/FileUploadZone";
import ScanResult from "~/components/organisms/ScanResult/ScanResult";
import { Header } from "~/components/organisms/layout/Header/Header";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { notifyOnce } from "~/utils/toast.utils";

type ScanProps = {
  type: ScanDocumentType;
};

type LatestDocumentIds = {
  id: string;
  latestPublishedVersionId: string;
};

const Scan = ({ type }: ScanProps) => {
  const intl = useIntl();
  const { opportunityId } = useParams();
  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const { mutateAsync, isLoading } = useUploadScan(opportunityId as string);
  const [disableScan, setDisableScan] = useState(false);
  const uploadFileToSalesforce = async (file: File) => {
    await mutateAsync({
      document: file,
      title: file.name,
      document_type: type,
    });
    if (isSuccess) {
      setDisableScan(true);
      setUploadModalOpen(false);
    } else if (isError) {
      notifyOnce(
        {
          description: intl.formatMessage({ id: "scan.upload.error" }),
          toastType: "error",
        },
        intl.formatMessage({ id: "scan.upload.error" })
      );
    }
  };
  //used to not refetch document blob if the recent file showed the same id and same version id
  const [pdfBlobIds, setPdfBlobIds] = useState<LatestDocumentIds | null>(null);
  const {
    data: document,
    isLoading: fetchingDocument,
    isSuccess,
    isError,
    isRefetching,
  } = useFetchRecentScans({
    opportunityId: opportunityId as string,
    type,
    shouldRefetch: true,
  });

  const { locale } = useLocaleStore();

  const { data: tokens } = useFetchAccessToken();

  useEffect(() => {
    if (
      !document ||
      isRefetching ||
      fetchingDocument ||
      (pdfBlob &&
        document.id === pdfBlobIds?.id &&
        document.latestPublishedVersionId ===
          pdfBlobIds.latestPublishedVersionId)
    ) {
      return;
    }

    const fetchBlob = async () => {
      const blob = await salesforceServiceSingleton.getFile(
        tokens?.accessToken ?? "",
        document.id ?? "",
        document.latestPublishedVersionId ?? "",
        locale
      );
      setDisableScan(false);
      if (blob && pdfBlob) {
        // for user to know when they already have a scan shown on screen that it is the old one
        // and get a notification that when the new one is there
        // only show it if the user had a previous file already and was not on the empty screen
        notifyOnce(
          {
            description: intl.formatMessage({ id: "scan.fetch.toast.info" }),
            toastType: "info",
          },
          intl.formatMessage({ id: "scan.fetch.toast.info" })
        );
      }
      setPdfBlob(blob);
      setPdfBlobIds(
        document
          ? {
              id: document.id,
              latestPublishedVersionId: document.latestPublishedVersionId,
            }
          : null
      );
    };

    fetchBlob();
  }, [
    document,
    fetchingDocument,
    pdfBlob,
    tokens,
    locale,
    pdfBlobIds?.id,
    pdfBlobIds?.latestPublishedVersionId,
    isRefetching,
    intl,
  ]);

  const onFileDrop = (file: File | null) => {
    if (file) uploadFileToSalesforce(file);
  };

  return (
    <div className="gap-header p-body flex flex-col h-full">
      <Header title={intl.formatMessage({ id: "scan.title" })} />
      <div className="flex flex-col h-full w-full gap-8 justify-center items-center">
        <AnimatePresence>
          {pdfBlob && !!document ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ScanResult
                pdfBlob={pdfBlob}
                onFileDrop={onFileDrop}
                loadingUpload={isLoading}
                uploadModalOpen={uploadModalOpen}
                setUploadModalOpen={setUploadModalOpen}
                disabled={disableScan}
              />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-full flex flex-col gap-2 justify-between items-center grow"
            >
              <div></div>
              <FileUploadZone
                className="min-w-fit w-1/2"
                onFileDrop={onFileDrop}
                loadingUpload={isLoading}
              />
              <Subtitle className="text-grey-700 flex gap-1">
                <Copy id="scan.wait.description" />
                <LoadingDots className="text-grey-700" />
              </Subtitle>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
export default Scan;
