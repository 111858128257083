import { PropsWithChildren } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import FadeInImage from "~/components/atoms/FadeInImage/FadeInImage";
import Logo from "~/components/atoms/Logo/Logo";
import { cn } from "~/utils";
import { Image, PropsWithClassName } from "~/utils/types";

type HomeCardProps = PropsWithClassName<
  PropsWithChildren<{
    image: Image;
    hasLogo?: boolean;
    contentClassName?: string;
    buttonAction?: () => void;
  }>
>;

const HomeCard = ({
  image,
  children,
  hasLogo = true,
  className,
  contentClassName,
  buttonAction,
}: HomeCardProps) => {
  return (
    <div
      className={cn(
        "grid h-screen overflow-hidden w-full grid-cols-[1fr_1fr]",
        className
      )}
    >
      <div
        className={cn(
          "relative h-full overflow-auto flex flex-col gap-8 justify-between px-20 py-16 no-scrollbar",
          contentClassName
        )}
      >
        {hasLogo && <Logo />}
        <div className="flex items-center flex-1"> {children}</div>
      </div>
      <div className="relative h-screen bg-beige-100 ">
        {buttonAction && (
          <Button
            size={ButtonSize.sm}
            variant={ButtonVariant.secondary}
            onClick={buttonAction}
            className="absolute z-50 top-10 right-10 w-10 h-10 p-1 bg-beige-500 hover:bg-beige-700"
          >
            <FontAwesomeIcon icon={faClose} className="text-md" />
          </Button>
        )}
        <FadeInImage
          className="absolute bottom-0 right-0 h-full max-w-full object-cover"
          image={image}
        />
      </div>
    </div>
  );
};

export default HomeCard;
