import { useCallback, useEffect, useRef, useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { SwiperRef } from "swiper/react";
import { useIntersectionObserver } from "usehooks-ts";
import { TreatmentPlanInformation } from "~/api/models/TreatmentPlan";
import Headline from "~/components/atoms/Headline/Headline";
import PaginationSwiper from "~/components/molecules/PaginationSwiper/PaginationSwiper";
import PlanCategoryCard from "~/components/molecules/PlanCategoryCard/PlanCategoryCard";
import PlanCategoryInformationSwiperButton from "./PlanCategoryInformationSwiperButton";

type PLanCategoryInformationProps = {
  informationSection: TreatmentPlanInformation;
};

const PLanCategoryInformation = ({
  informationSection,
}: PLanCategoryInformationProps) => {
  const refTop = useRef<HTMLDivElement | null>(null);
  const refBottom = useRef<HTMLDivElement | null>(null);
  const entryTop = useIntersectionObserver(refTop, {});
  const entryBottom = useIntersectionObserver(refBottom, {});
  const isVisible = !!entryTop?.isIntersecting || !!entryBottom?.isIntersecting;

  const swiperRef = useRef<SwiperRef>(null);
  const slideToNext = useCallback(() => {
    swiperRef?.current?.swiper.slideNext();
  }, [swiperRef]);

  const slideToPrev = useCallback(() => {
    swiperRef?.current?.swiper.slidePrev();
  }, [swiperRef]);

  const [slideConfig, setSlideConfig] = useState({
    isBeginning: true,
    isEnd: false,
  });

  useEffect(() => {
    if (isVisible && swiperRef?.current?.swiper) {
      swiperRef.current.swiper.on("slideChange", (swipe) => {
        setSlideConfig({
          isBeginning: swipe?.isBeginning,
          isEnd: swipe?.isEnd,
        });
      });
    }
  }, [swiperRef?.current?.swiper, isVisible]);

  useEffect(() => {
    const handleKeyboardArrows = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") slideToNext();

      if (event.key === "ArrowLeft") slideToPrev();
    };

    window.addEventListener("keydown", handleKeyboardArrows);

    return () => {
      window.removeEventListener("keydown", handleKeyboardArrows);
    };
  }, [slideToNext, slideToPrev]);

  return (
    <motion.div
      animate={{
        opacity: isVisible ? 1 : 0,
      }}
      transition={{ duration: 0.7 }}
      className="h-full py-10"
    >
      <div ref={refTop}></div>
      <div className="grid grid-cols-3 gap-24 h-full">
        <motion.div
          className="flex flex-col justify-between"
          animate={{
            opacity: isVisible ? 1 : 0,
            x: isVisible ? 0 : "-100%",
          }}
          transition={{ duration: 0.7 }}
        >
          <div className="flex flex-col gap-2 col-span-1">
            <Headline level={2} className="break-words">
              {informationSection.title}
            </Headline>
            <Headline level={6} className="text-grey-400">
              {informationSection.description}
            </Headline>
          </div>
          <div ref={refBottom}></div>
          <div className="flex gap-4">
            <PlanCategoryInformationSwiperButton
              onClick={slideToPrev}
              disabled={slideConfig.isBeginning}
            />
            <PlanCategoryInformationSwiperButton
              onClick={slideToNext}
              disabled={slideConfig.isEnd}
              icon={faArrowRight}
            />
          </div>
        </motion.div>

        <motion.div
          animate={{
            opacity: isVisible ? 1 : 0,
            x: isVisible ? 0 : "100%",
          }}
          transition={{ duration: 0.7 }}
          className="h-full relative col-span-2"
        >
          <div className="absolute bg-swiper-right-overlay top-0 left-0 w-full h-full z-50 pointer-events-none"></div>
          <PaginationSwiper slidesPerView={1.4} ref={swiperRef}>
            {informationSection.cards.map((card) => (
              <PlanCategoryCard {...card} key={card.title} />
            ))}
          </PaginationSwiper>
          <div className="absolute bg-swiper-left-overlay top-0 left-0 h-full z-50 pointer-events-none w-12"></div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PLanCategoryInformation;
