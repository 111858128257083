import { useMemo } from "react";
import { Product } from "~/stores/checkout/product";

const useDefaultProduct = (list: Product[]) => {
  const defaultProduct = useMemo(() => {
    const recommended = list
      .filter((product) => !product.isHiddenFromSales)
      .find((product) => product.recommended);
    if (recommended && !recommended.type.includes("addition"))
      return recommended;
    // find last in list that type does not include addition
    const lastNonAddition = list
      .reverse()
      .find((product) => !product.type.includes("addition"));

    return lastNonAddition;
  }, [list]);

  return defaultProduct;
};
export default useDefaultProduct;
