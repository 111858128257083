import { ReactNode } from "react";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/atoms/Accordion/Accordion";
import { ConfigurationAccordions } from "~/components/atoms/Accordion/Accordions.type";
import { Product, ProductRadioGroupOption } from "~/stores/checkout/product";
import { cn } from "~/utils";
import { FormContent } from "./FormContent";
import { FormHeader } from "./FormHeader";
import { FormWrapper } from "./FormWrapper";

type ConfigurationAccordionProps = {
  itemValue: ConfigurationAccordions;
  collapsible?: boolean;
  isOpen?: boolean;
  products?: ProductRadioGroupOption[];
  setSelectedOption?: (option: ProductRadioGroupOption) => void;
  title: string;
  openAccordionDescription?: string;
  closedAccordionDescription?: string;
  product?: Product;
  setProduct?: (value: ProductRadioGroupOption) => void;
  customContent?: ReactNode;
  headerButtonAction?: () => void;
  headerButtonText?: string;
  isEditable?: boolean;
};

const ConfigurationAccordion = ({
  itemValue,
  collapsible = false,
  isOpen = false,
  title,
  openAccordionDescription,
  closedAccordionDescription,
  products,
  setSelectedOption,
  product,
  setProduct,
  customContent,
  headerButtonAction,
  headerButtonText,
  isEditable = true,
}: ConfigurationAccordionProps) => {
  if (!collapsible) {
    return (
      <FormWrapper
        className={cn({
          "px-8 py-6 gap-6": !collapsible,
        })}
      >
        <FormHeader
          opened={isOpen}
          title={title}
          openAccordionDescription={openAccordionDescription}
          closedAccordionDescription={closedAccordionDescription}
          buttonAction={headerButtonAction}
          buttonText={headerButtonText}
        />
        {customContent || (
          <FormContent
            product={product}
            products={products}
            setSelectedOption={setSelectedOption}
            setProduct={setProduct}
            isEditable={isEditable}
          />
        )}
      </FormWrapper>
    );
  }

  return (
    <AccordionItem value={itemValue}>
      <FormWrapper>
        <AccordionTrigger className="px-8 py-6">
          <FormHeader
            opened={isOpen}
            title={title}
            openAccordionDescription={openAccordionDescription}
            closedAccordionDescription={closedAccordionDescription}
          />
        </AccordionTrigger>
        <AccordionContent className="px-8 pb-6">
          {customContent || (
            <FormContent
              product={product}
              products={products}
              setSelectedOption={setSelectedOption}
              setProduct={setProduct}
              isEditable={isEditable}
            />
          )}
        </AccordionContent>
      </FormWrapper>
    </AccordionItem>
  );
};

export default ConfigurationAccordion;
