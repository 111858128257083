import { motion } from "framer-motion";
import { TreatmentPlanPhaseDetail } from "~/api/models/TreatmentPlan";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { cn } from "~/utils";
import PlanCategoryRecommendationModal from "./PlanCategoryRecommendationModal";

type PlanCategoryDetailProps = {
  detail: TreatmentPlanPhaseDetail;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PlanCategoryDetail = ({
  detail,
  open,
  setOpen,
}: PlanCategoryDetailProps) => {
  const openModal = () => {
    if (detail?.recommendations && detail?.recommendations?.length > 0) {
      setOpen(true);
    }
  };

  return (
    <>
      <PlanCategoryRecommendationModal
        title={detail?.recommendationsTitle}
        recommendations={detail.recommendations}
        open={open}
        setOpen={setOpen}
      />
      <motion.div
        onClick={openModal}
        whileHover={{
          scale:
            detail?.recommendations && detail?.recommendations?.length > 0
              ? 0.98
              : 1,
        }}
        key={detail.title}
        className={cn("flex gap-4 p-4 bg-beige-100 rounded-2xl", {
          "cursor-pointer":
            detail?.recommendations && detail?.recommendations?.length > 0,
        })}
      >
        <img
          src={detail.image.filename}
          alt={detail.title}
          className="w-6 h-6 object-cover object-center"
        />
        <div className="flex flex-col gap-1">
          <Subtitle>{detail.title}</Subtitle>
          <Subtitle level={4} className="text-grey-400">
            {detail.description}
          </Subtitle>
        </div>
      </motion.div>
    </>
  );
};

export default PlanCategoryDetail;
