import SkinProductCard, {
  SkinProductCardProps,
} from "~/components/organisms/Pricing//SkinProductCard/SkinProductCard";

type BotoxProductCardProps = SkinProductCardProps;

const BotoxProductCard = (props: BotoxProductCardProps) => {
  return <SkinProductCard {...props}>{props.children}</SkinProductCard>;
};

export default BotoxProductCard;
