import { SKIN_CARE_PRODUCT_CODE } from "~/api/constants/skincare.const";
import { StoryblokAsset } from "~/services/storyblok";
import { ProductFamily } from "~/stores/checkout/product";
import { Benefit } from "./Benefit";
import { SkinProductKey } from "./SkinProductKey";

export type SalesforceBundleDTO = {
  id: string;
  name: string;
  price: number;
  product_code: string;
  bundle_products: {
    id: string;
    name: string;
    is_optional: boolean;
    product_code: string;
    original_price: number;
    sales_price: number;
  }[];
};

export type CMSBundleDTO = {
  title: string;
  description: string;
  maleImage: StoryblokAsset;
  femaleImage: StoryblokAsset;
  benefits: Benefit[];
  hideBenefits: boolean;
};

export class CMSBundle {
  title: string;
  description: string;
  maleImage: StoryblokAsset;
  femaleImage: StoryblokAsset;
  benefits: Benefit[];
  hideBenefits: boolean;

  public constructor(bundleDto: CMSBundleDTO) {
    this.title = bundleDto.title;
    this.description = bundleDto.description;
    this.maleImage = bundleDto.maleImage;
    this.femaleImage = bundleDto.femaleImage;
    this.benefits = bundleDto.benefits;
    this.hideBenefits = bundleDto.hideBenefits;
  }
}
export type BundleProduct = {
  id: string;
  name: string;
  fullName?: string;
  isOptional: boolean;
  productCode: string;
  price: number;
  productCardKey?: SkinProductKey;
  family?: ProductFamily;
};

export class SalesforceBundle {
  id: string;
  name: string;
  price: number;
  productCode: string;
  productCardKey: SkinProductKey;
  bundleProducts: BundleProduct[];

  static cardKey = SkinProductKey.skinBundle;

  static fromJson(bundle: SalesforceBundle) {
    return new SalesforceBundle(
      {
        id: bundle.id,
        name: bundle.name,
        price: bundle.price,
        product_code: bundle.productCode,
        bundle_products: [],
      },
      bundle.bundleProducts
    );
  }

  getPrice(hasSkinCare: boolean) {
    if (hasSkinCare) {
      return this.price;
    }
    const excludedProducts = this.getExcludedProducts(hasSkinCare);
    return this.price - excludedProducts.reduce((acc, p) => acc + p.price, 0);
  }

  getExcludedProducts(hasSkinCare: boolean) {
    if (hasSkinCare) {
      return [];
    }
    return this.bundleProducts.filter(
      (p) => p.productCode === SKIN_CARE_PRODUCT_CODE
    );
  }

  getProducts(hasSkinCare: boolean) {
    if (hasSkinCare) {
      return this.bundleProducts;
    }
    return this.bundleProducts.filter(
      (p) => p.productCode !== SKIN_CARE_PRODUCT_CODE
    );
  }

  isRecommended(recommendedCardKeys: SkinProductKey[]): boolean {
    return this.bundleProducts
      .filter((p) => !p.isOptional)
      .every(
        (product) =>
          product.productCardKey &&
          recommendedCardKeys.includes(product.productCardKey)
      );
  }

  public constructor(
    bundleDto: SalesforceBundleDTO,
    bundleProducts: BundleProduct[]
  ) {
    this.id = bundleDto.id;
    this.name = bundleDto.name;
    this.price = bundleDto.price;
    this.productCode = bundleDto.product_code;
    this.productCardKey = SalesforceBundle.cardKey;
    this.bundleProducts = bundleProducts;
  }
}
