import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useFetchExamples } from "~/api/storyblok/useFetchExamples";
import EmptyStateScreen from "~/components/organisms/EmptyStates/EmptyStateScreen";
import ExamplesGrid from "~/components/organisms/Examples/ExamplesGrid";
import ExamplesList from "~/components/organisms/Examples/ExamplesList";
import useSkinProducts from "~/hooks/useSkinProducts";
import { ExampleCategory } from "~/modules/example/example";
import { Routes } from "~/routes/routes";

const SkinExamples = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const { data, isLoading, isError } = useFetchExamples(ExampleCategory.skin);
  const { getProductItemCategoryKeySelected } = useSkinProducts();
  const activeExamples = data?.filter((example) =>
    getProductItemCategoryKeySelected(example.categoryKey)
  );

  const goToSkinConfiguration = () => {
    navigate(
      generatePath(Routes.SkinConfiguration, {
        opportunityId: opportunityId as string,
      })
    );
  };
  return (
    <ExamplesList isLoading={isLoading} isError={isError}>
      {activeExamples?.length ? (
        <ExamplesGrid examples={activeExamples} />
      ) : (
        <EmptyStateScreen onClick={goToSkinConfiguration} />
      )}
    </ExamplesList>
  );
};
export default SkinExamples;
