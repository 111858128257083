import { HTMLAttributes, useRef } from "react";
import "react-html5-camera-photo/build/css/index.css";
import Webcam from "react-webcam";
import { PhotoTakenState, PhotosTaken } from "~/api/models/UploadPhotos";
import overlay from "~/assets/svg/overlay.svg";
import shutter from "~/assets/svg/shutter.svg";
import { cn } from "~/utils";

type UploadTakePhotoCameraProps = {
  setWaitingApprovalPhoto: (photoToReview: PhotosTaken) => void;
  selectedPosition: string;
  waitingApprovalPhoto: PhotosTaken | null;
} & HTMLAttributes<HTMLDivElement>;

const UploadTakePhotoCamera = ({
  className,
  waitingApprovalPhoto,
  setWaitingApprovalPhoto,
  selectedPosition,
}: UploadTakePhotoCameraProps) => {
  const videoRef = useRef<Webcam>(null);

  const handleTakePicture = () => {
    const imageSrc = videoRef.current?.getScreenshot();
    if (imageSrc)
      setWaitingApprovalPhoto({
        [selectedPosition]: {
          src: imageSrc,
          state: PhotoTakenState.WAITING,
        },
      });
  };

  return (
    <div className={cn("flex items-center min-h-[28rem]", className)}>
      <div className="relative w-full h-full flex items-center">
        {!waitingApprovalPhoto ? (
          <>
            <Webcam
              ref={videoRef}
              audio={false}
              videoConstraints={{
                facingMode: "environment",
              }}
              screenshotQuality={1}
              screenshotFormat="image/png"
              forceScreenshotSourceSize
              className="w-full h-full object-cover"
            />
            <img
              src={overlay}
              className="absolute w-full h-full left-0 top-0 object-cover aspect-[375/760]"
            />
            <button
              className="absolute bottom-4 left-1/2 -translate-x-1/2 z-40"
              onClick={handleTakePicture}
            >
              <img src={shutter} />
            </button>
          </>
        ) : (
          <img
            src={waitingApprovalPhoto?.[selectedPosition]?.src}
            className="w-full h-full object-cover"
          />
        )}
      </div>
    </div>
  );
};

export default UploadTakePhotoCamera;
