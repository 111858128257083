export enum QueryKeys {
  useFetchExamples = "useFetchExamples",
  useFetchExampleBySlug = "useFetchExampleBySlug",
  useFetchAccessToken = "useFetchAccessToken",
  useFetchLoggedInUser = "useFetchLoggedInUser",
  useFetchOpportunity = "useFetchOpportunity",
  useFetchMedicalHistoryQuestions = "useFetchMedicalHistoryQuestions",
  useFetchMedicalHistory = "useFetchMedicalHistory",
  useFetchDiscounts = "useFetchDiscounts",
  useFetchPaymentMethods = "useFetchPaymentMethods",
  useFetchTreatmentPlan = "useFetchTreatmentPlan",
  useFetchHairTreatmentFocus = "useFetchHairTreatmentFocus",
  useFetchScanLegends = "useFetchScanLegends",
  useUpdateMedicalHistory = "useUpdateMedicalHistory",
  useFetchConsultationDocuments = "useFetchConsultationDocuments",
  useFetchRecentScans = "useFetchRecentScans",
  useFetchUploadToken = "useFetchUploadToken",
  useFetchIntroMainCard = "useFetchIntroMainCard",
  useFetchIntroCards = "useFetchIntroCards",
  useFetchClinics = "useFetchClinics",
  useFetchProductCategories = "useFetchProductCategories",
  useFetchUploadedPhotos = "useFetchUploadedPhotos",
  useFetchProducts = "useFetchProducts",
  useFetchBundles = "useFetchBundles",
  useFetchSkincareProducts = "useFetchSkincareProducts",
  useCalculatePrice = "useCalculatePrice",
  useFetchPhotosPositions = "useFetchPhotosPositions",
  useUploadDocument = "useUploadDocument",
  useFetchProductDetails = "useFetchProductDetails",
  useFetchPricingDetails = "useFetchPricingDetails",
  useGetRequestedContract = "useGetRequestedContract",
  useFetchContract = "useFetchContract",
  useUpdateOpportunity = "useUpdateOpportunity",
  useFetchLocations = "useFetchLocations",
  useRequestContract = "useRequestContract",
  useFetchSpecialConditions = "useFetchSpecialConditions",
  useUploadScan = "useUploadScan",
  useFetchPublicDiscounts = "useFetchPublicDiscounts",
  useFetchContractCreationLoader = "useFetchContractCreationLoader",
}
