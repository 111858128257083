import { UploadedPhoto } from "~/modules/consultation-document/documentPicture";
import Photo from "./Photo";

export function PhotoList({
  photos,
}: {
  photos: (UploadedPhoto | undefined)[];
}) {
  return (
    <section className="grid grid-cols-4 gap-6 w-full overflow-y-auto pb-10">
      {photos.map((photo) =>
        photo ? <Photo key={photo?.title} photo={photo} /> : null
      )}
    </section>
  );
}
