import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { PhotoTakenState, PhotosTaken } from "~/api/models/UploadPhotos";
import { useUploadDocument } from "~/api/salesForce/useUploadDocuments";
import logo from "~/assets/images/logo.png";
import TabsToggle from "~/components/molecules/TabsToggle/TabsToggle";
import TakePhotosModal from "~/components/molecules/TakePhotosModal/TakePhotosModal";
import UploadOverviewItem from "~/components/molecules/UploadOverviewItem/UploadOverviewItem";
import EmptyStateScreen from "~/components/organisms/EmptyStates/EmptyStateScreen";
import { PhotoPositionCategory } from "~/modules/upload-photo/upload-photo";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { AppLocale } from "~/utils/types";

export type FamiliesState = {
  counter: number;
  key: ProductItemCategoryKey;
  name: MessageDescriptor["id"];
};

type PhotosOverviewProps = {
  data?: PhotoPositionCategory[];
  isLoading?: boolean;
  families: FamiliesState[];
  setFamilies: (families: FamiliesState[]) => void;
  selectedCategories?: string | null;
};

const PhotosOverview = ({
  data,
  isLoading,
  families,
  setFamilies,
  selectedCategories,
}: PhotosOverviewProps) => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const { opportunityId } = useParams();

  const uploadToken = searchParams.get("upload_token");
  const locale = searchParams.get("locale");
  const category = searchParams.get("category");

  const [photosTaken, setPhotosTaken] = useState<PhotosTaken>();
  const { setLocale } = useLocaleStore();
  const [waitingApprovalPhoto, setWaitingApprovalPhoto] =
    useState<PhotosTaken | null>(null);

  const [isTakePhotosModalOpen, setIsTakePhotosModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");

  const activeCategories = useMemo(() => {
    return families?.filter(
      (category) =>
        selectedCategories?.includes(category.key) &&
        data?.find((photoCategory) => photoCategory.key === category.key)
    );
  }, [data, selectedCategories, families]);

  const [activeTab, setActiveTab] = useState(
    activeCategories.length > 0 ? activeCategories?.[0]?.key : undefined
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const dataPositions = useMemo(() => {
    return data?.find((category) => category.key === activeTab)?.positions;
  }, [data, activeTab]);

  useEffect(() => {
    if (locale) {
      setLocale(locale as AppLocale);
    }
  }, [locale, setLocale]);

  const { mutateAsync, isLoading: isUploadLoading } = useUploadDocument(
    uploadToken ?? ""
  );

  const handleGoToTakePhoto = (positionId: string) => {
    setSelectedPosition(positionId);
    setIsTakePhotosModalOpen(true);
  };

  const handleOpenCameraRoll = (positionId: string) => {
    setSelectedPosition(positionId);

    if (fileInputRef.current) fileInputRef.current.click();
  };

  const uploadObjectMapper = useCallback(
    (title: string, document: Blob | File) => {
      return {
        title: `${category ?? " "},${activeTab ?? " "},${title}`,
        opportunity_id: opportunityId as string,
        document: new File([document], `${title}.png`, {
          type: "image/png",
        }),
      };
    },
    [category, opportunityId, activeTab]
  );

  const handleCameraRollUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files?.[0] || !selectedPosition) return;

    const file = event.target.files[0];

    const position = dataPositions?.find(
      (position) => position.id === selectedPosition
    );

    if (!position?.title) return;

    try {
      await mutateAsync(uploadObjectMapper(position?.title, file));

      setPhotosTaken({
        ...photosTaken,
        [position.id]: {
          state: PhotoTakenState.SUCCESS,
          src: URL.createObjectURL(file),
        },
      });

      updateCategoriesCounter?.();
    } finally {
      setSelectedPosition("");
    }
  };

  const handleTakePhoto = () => {
    if (!waitingApprovalPhoto) return;

    const updatedPhotosTaken = photosTaken
      ? {
          ...photosTaken,
          ...waitingApprovalPhoto,
        }
      : { ...waitingApprovalPhoto };

    const selectedPositionIndex = dataPositions?.findIndex(
      (position) => position.id === selectedPosition
    );
    const lastPositionIndex = dataPositions ? dataPositions.length - 1 : 0;
    const nextPositionToSelect =
      selectedPositionIndex !== undefined &&
      selectedPositionIndex < lastPositionIndex
        ? dataPositions?.[selectedPositionIndex + 1].id
        : dataPositions?.[0].id;

    setPhotosTaken(updatedPhotosTaken);
    setWaitingApprovalPhoto(null);
    if (nextPositionToSelect) setSelectedPosition(nextPositionToSelect);
  };

  const clearWaitingPhotos = () => {
    if (!photosTaken) return;

    const updatedPhotosTaken = Object.entries(photosTaken)?.reduce(
      (accumulator, [key, value]) => {
        if (value.state === PhotoTakenState.WAITING) {
          return {
            ...accumulator,
            [key]: {
              ...value,
              state: PhotoTakenState.SUCCESS,
            },
          };
        }

        return {
          ...accumulator,
          [key]: value,
        };
      },
      {}
    );

    setPhotosTaken(updatedPhotosTaken);
  };

  const handleUploadPhotos = async () => {
    if (isUploadLoading) return;
    if (!photosTaken) return;

    const photos = await Promise.all(
      Object.entries(photosTaken).map(async ([key, value]) => {
        const position = dataPositions?.find((position) => position.id === key);

        const base64Response = await fetch(value.src);
        const photoBlob = await base64Response.blob();

        return {
          title: position?.title || "",
          document: photoBlob,
        };
      })
    );

    await Promise.all(
      photos.map(
        async (photo) =>
          await mutateAsync(uploadObjectMapper(photo.title, photo.document))
      )
    );

    clearWaitingPhotos();
    setIsTakePhotosModalOpen(false);
  };

  const updateCategoriesCounter = () => {
    const updatedFamilies = families.map((family) =>
      family.key === activeTab
        ? {
            ...family,
            counter: family.counter + 1,
          }
        : family
    );

    setFamilies(updatedFamilies);
  };

  useEffect(() => {
    if (activeCategories && activeCategories.length > 0) {
      setActiveTab(activeCategories[0].key);
    }
  }, [activeCategories]);

  return activeCategories && activeCategories.length > 0 ? (
    <div className="h-full overflow-hidden relative px-6 pt-6 w-full">
      <img src={logo} className="h-4 object-cover" />
      <TabsToggle
        options={activeCategories.map((family) => {
          return {
            ...family,
            value: intl.formatMessage({
              id: family.name,
            }),
          };
        })}
        activeOption={activeTab}
        setActiveOption={setActiveTab}
        className="mt-6 mx-auto w-full justify-between grid"
        style={{
          gridTemplateColumns: `repeat(${activeCategories.length}, minmax(0, 1fr))`,
        }}
        tabClassName="px-1"
      />
      <section className="w-full flex flex-col gap-4 my-4 mb-8">
        {!isLoading &&
          dataPositions?.map((position) => (
            <UploadOverviewItem
              key={position.id}
              item={position}
              handleGoToTakePhoto={() => handleGoToTakePhoto(position.id)}
              handleOpenCameraRoll={() => handleOpenCameraRoll(position.id)}
              photoTaken={photosTaken?.[position.id]}
              isLoading={isUploadLoading && position.id === selectedPosition}
            />
          ))}
      </section>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleCameraRollUpload}
        className="invisible hidden"
      />
      <TakePhotosModal
        open={isTakePhotosModalOpen}
        setOpen={setIsTakePhotosModalOpen}
        positions={dataPositions}
        selectedPosition={selectedPosition}
        setSelectedPosition={setSelectedPosition}
        photosTaken={photosTaken}
        handleTakePhoto={handleTakePhoto}
        waitingApprovalPhoto={waitingApprovalPhoto}
        setWaitingApprovalPhoto={setWaitingApprovalPhoto}
        clearWaitingPhotos={clearWaitingPhotos}
        doneButtonAction={handleUploadPhotos}
        isUploadLoading={isUploadLoading}
      />
    </div>
  ) : (
    <EmptyStateScreen hideButton />
  );
};

export default PhotosOverview;
