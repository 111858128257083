import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import "./index.css";
import { AppProvider } from "./providers/app";

if (!import.meta.env.DEV) {
  Sentry.init({
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: "https://7cc0982e54891bd8e8bbd9f9893663e8@o4505832725020672.ingest.sentry.io/4506156149702656",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AppProvider />
  </StrictMode>
);
