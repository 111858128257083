import { useEffect, useState } from "react";
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSwiper } from "swiper/react";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
} from "~/components/atoms/Button/Buttons.types";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type CustomSwiperButtonProps = PropsWithClassName<{
  type?: "next" | "prev";
  activeIndex: number;
  slidesPerView: number;
  isChevron?: boolean;
  // used when we have more than one swiper in the DOM but just one is visible and we want to rerender to update the viewsCount when the user hides or shows different swipers
  key?: number;
}>;

const CustomSwiperButton = ({
  type = "next",
  activeIndex,
  slidesPerView,
  isChevron,
  className,
  key,
}: CustomSwiperButtonProps) => {
  const swiper = useSwiper();

  const [viewsCount, setViewsCount] = useState(
    swiper.slides.length - slidesPerView + 1
  );

  const hasMoreSlides = activeIndex < viewsCount - 1;
  const hasPrevSlides = activeIndex > 0;
  const rightIcon = isChevron ? faChevronRight : faArrowRight;
  const leftIcon = isChevron ? faChevronLeft : faArrowLeft;

  useEffect(() => {
    if (swiper?.slides?.length) {
      setViewsCount(swiper.slides.length - slidesPerView + 1);
    }
  }, [swiper?.slides?.length, key]);

  return (
    <Button
      onClick={
        type === "next" ? () => swiper.slideNext() : () => swiper.slidePrev()
      }
      contentType={ButtonContentType.icon}
      size={ButtonSize.sm}
      className={cn(
        "bg-black text-white p-3  flex rounded-full transition-all duration-300",
        { hidden: type === "next" ? !hasMoreSlides : !hasPrevSlides },
        className
      )}
      icon={type === "next" ? rightIcon : leftIcon}
    />
  );
};

export default CustomSwiperButton;
