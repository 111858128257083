import { useEffect, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SalesforceBundle } from "~/api/models/Bundle";
import { ContractResponseDto } from "~/api/models/Contract";
import { useGetRequestedContract } from "~/api/salesForce/useGetRequestedContract";
import { useRequestContract } from "~/api/salesForce/useRequestContract";
import { Routes } from "~/routes/routes";
import { Product } from "~/stores/checkout/product";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { notifyOnce } from "~/utils/toast.utils";

const REFETCH_REQUEST_INTERVAL = 2000;
const ANIMATION_DELAY = 5000;

type UseRequestCreateContractArgs = {
  opportunityId: string;
  selectedProducts: Product[];
  selectedBundle: SalesforceBundle | null;
  paymentMethod: string | null;
};

const useRequestCreateContract = ({
  opportunityId,
  selectedProducts,
  selectedBundle,
  paymentMethod,
}: UseRequestCreateContractArgs) => {
  //used to cleanup refetch interval
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const navigate = useNavigate();
  const [requestId, setRequestId] = useState("");
  const [composingContract, setComposingContract] = useState(false);
  const { mutateAsync: requestContract, isLoading: requestingContract } =
    useRequestContract(opportunityId as string);
  const [
    specialConditionsValues,
    otherSpecialConditions,
    referralCode,
    discountCode,
  ] = useCheckoutStore((state) => [
    state.specialConditionsValues,
    state.otherSpecialConditions,
    state.referralCode,
    state.discountCode,
  ]);
  const { refetch: getContract } = useGetRequestedContract({
    requestId,
    disabled: requestId === "",
  });

  const navigateToContract = (contractId: string) => {
    navigate(
      generatePath(Routes.SummaryContract, {
        opportunityId: opportunityId as string,
        contractId: contractId,
      })
    );
  };

  const onCreateContract = async () => {
    setComposingContract(true);
    try {
      const contractRequest = await requestContract({
        products: selectedProducts,
        bundle: selectedBundle,
        paymentMethod: paymentMethod ?? null,
        specialConditions: specialConditionsValues ?? [],
        otherSpecialConditions: otherSpecialConditions,
        discountCode: discountCode,
        referralCode: referralCode,
      });
      if (!contractRequest) return;
      setRequestId(contractRequest.request_id);
      let contract: ContractResponseDto | undefined;
      intervalRef.current = setInterval(async () => {
        if (!contract?.contract_id) {
          contract = (await getContract()).data;
          if (contract?.contract_id) {
            // wait two seconds for animation purposes
            setTimeout(() => {
              clearInterval(intervalRef.current!);
              navigateToContract(contract?.contract_id ?? "");
              setComposingContract(false);
            }, ANIMATION_DELAY);
          } else if (contract?.error_message) {
            notifyOnce(
              {
                toastType: "info",
                descriptionId: "contract.create.info",
                autoClose: false,
              },
              "useCreateContractInfo"
            );
          }
        }
      }, REFETCH_REQUEST_INTERVAL);
    } catch (error) {
      setComposingContract(false);
      console.error(error);
    }
  };

  useEffect(() => {
    //cleanup
    return () => {
      setComposingContract(false);
      setRequestId("");
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return {
    requestingContract,
    composingContract,
    onCreateContract,
  };
};
export default useRequestCreateContract;
