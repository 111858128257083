import { ProductItemCategoryKey } from "~/api/models/ProductCategory";

export class PhotoPosition {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly imageUrl: string,
    public readonly imageAlt: string
  ) {}
}

export type PhotoPositionCategoryDTO = {
  key: ProductItemCategoryKey;
  positions: PhotoPositionDTO[];
};

export type PhotoPositionCategory = {
  key: ProductItemCategoryKey;
  positions: PhotoPosition[];
};

export type PhotoPositionDTO = {
  _uid: string;
  title: string;
  placeholderImage: {
    filename: string;
    alt: string;
  };
  order: number;
};
