import { useCallback, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useFetchIntroCards } from "~/api/storyblok/useFetchIntroCards";
import { useFetchIntroMainCard } from "~/api/storyblok/useFetchIntroMainCard";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import GoogleRatingPill from "~/components/molecules/GoogleRatingPill/GoogleRatingPill";
import { separateLettersAndNonLetters } from "~/utils/string.utils";

const MILLISECONDS_PER_CARD = 5000;

const WhyHSSlideshow = () => {
  const { data: introCards, isLoading: loadingIntroCards } =
    useFetchIntroCards();
  const { data: mainIntroCard, isLoading: loadingMainCard } =
    useFetchIntroMainCard();

  const cards = useMemo(() => {
    return [...(introCards ?? []), mainIntroCard];
  }, [introCards, mainIntroCard]);

  const [activeCard, setActiveCard] = useState(0);

  const showNextCard = useCallback(() => {
    setActiveCard((prev) => (prev + 1) % cards.length);
  }, [cards]);

  useEffect(() => {
    const interval = setInterval(() => {
      showNextCard();
    }, MILLISECONDS_PER_CARD);
    return () => clearInterval(interval);
  }, [showNextCard]);

  const titleParts = useMemo(
    () => separateLettersAndNonLetters(cards[activeCard]?.title ?? ""),
    [cards, activeCard]
  );

  return (
    <AnimatePresence>
      <div
        className="overflow-hidden relative rounded-2xl py-5 px-6 cursor-pointer flex items-center justify-center flex-1"
        onClick={showNextCard}
      >
        {loadingIntroCards || loadingMainCard ? (
          <SpinnerIcon />
        ) : (
          <>
            <motion.div
              key={`image${activeCard}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "linear" }}
              className="bg-beige-100 absolute top-0 left-0 w-full h-full rounded-2xl"
            >
              <StoryblokOptimizedImage
                src={cards[activeCard]?.backgroundImage?.filename}
                alt={
                  cards[activeCard]?.backgroundImage?.alt ??
                  cards[activeCard]?.title
                }
                className="w-full h-full object-cover object-center"
              />
            </motion.div>
            <div className="absolute bg-why-hs-slideshow-card top-0 left-0 w-full h-full rounded-2xl"></div>
            <motion.div
              className="relative h-full w-full z-20 flex flex-col justify-end gap-3"
              key={`content${activeCard}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, ease: "linear" }}
            >
              {cards[activeCard]?.title === mainIntroCard?.title && (
                <GoogleRatingPill className="py-1" />
              )}
              <div className="flex items-center gap-1">
                {titleParts.map((part) =>
                  part.isLetter ? (
                    <Subtitle key={part.value} className="text-white">
                      {part.value}
                    </Subtitle>
                  ) : (
                    <Subtitle key={part.value} className="text-white font-bold">
                      {part.value}
                    </Subtitle>
                  )
                )}
              </div>
            </motion.div>
          </>
        )}
      </div>
    </AnimatePresence>
  );
};

export default WhyHSSlideshow;
