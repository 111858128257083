import { motion } from "framer-motion";
import { useFetchContractCreationLoader } from "~/api/storyblok/useFetchContractCreationLoader";
import Copy from "~/components/atom/Copy";
import Headline from "~/components/atoms/Headline/Headline";
import LoadingDots from "~/components/atoms/LoadingDots/LoadingDots";
import Logo from "~/components/atoms/Logo/Logo";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import ContentLoader from "~/components/molecules/ContentLoader/ContentLoader";

type ContractLoaderProps = {
  showLoader: boolean;
};

const ContractLoader = ({ showLoader }: ContractLoaderProps) => {
  const { data, isLoading } = useFetchContractCreationLoader();

  return (
    showLoader && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="grid grid-cols-2 h-screen fixed top-0 left-0 bg-white z-50"
      >
        <div className="relative flex flex-col items-center justify-center gap-2 w-full">
          <div className="absolute top-8 left-6">
            <Logo />
          </div>
          <div className="flex gap-2 items-center">
            <Headline level={4}>
              <Copy id="pricing.contract.loader.title" />
            </Headline>
            <LoadingDots />
          </div>
          <Subtitle className="text-grey-400">
            <Copy id="pricing.contract.loader.subtitle" />
          </Subtitle>
        </div>
        <div className="pr-20">
          {!isLoading && <ContentLoader blocks={data} />}
        </div>
      </motion.div>
    )
  );
};

export default ContractLoader;
