import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import { ConfigurationAccordions } from "~/components/atoms/Accordion/Accordions.type";
import ConfigurationAccordion from "~/components/molecules/ConfigurationAccordion/ConfigurationAccordion";
import useHairProducts from "~/hooks/useHairProducts";
import { ProductRadioGroupOption } from "~/stores/checkout/product";

type BeardGraftsProps = {
  openedAccordion?: ConfigurationAccordions;
  collapsible?: boolean;
  isEditable?: boolean;
};

const BeardGrafts = ({
  openedAccordion,
  collapsible,
  isEditable = true,
}: BeardGraftsProps) => {
  const intl = useIntl();
  const { data: allProducts } = useFetchProducts();
  const { beardProduct, setBeardProduct } = useHairProducts();

  const products: ProductRadioGroupOption[] = useMemo(
    () =>
      allProducts.hair.beard.transplantation?.map((option) => ({
        ...option,
        name: option.name,
        text: intl.formatMessage(
          { id: "configuration.grafts.value" },
          { from: option.grafts.from, to: option.grafts.to }
        ),
      })) ?? [],
    [allProducts.hair.beard.transplantation]
  );
  const [selectedOption, setSelectedOption] = useState<
    ProductRadioGroupOption | undefined
  >(products.find((product) => product.id === beardProduct?.id) || products[0]);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(
        products.find((product) => product.id === beardProduct?.id) ||
          products[0]
      );
    }
  }, [products, beardProduct]);

  const isOpen = useMemo(
    () => openedAccordion == ConfigurationAccordions.BEARD_GRAFTS,
    [openedAccordion]
  );

  if (!selectedOption) return null;

  return (
    <ConfigurationAccordion
      itemValue={ConfigurationAccordions.BEARD_GRAFTS}
      collapsible={collapsible}
      isOpen={isOpen}
      products={products}
      setSelectedOption={setSelectedOption}
      title={intl.formatMessage({
        id: "configuration.beard.grafts.title",
      })}
      closedAccordionDescription={intl.formatMessage(
        { id: "configuration.grafts.value" },
        {
          from: selectedOption.grafts.from,
          to: selectedOption.grafts.to,
        }
      )}
      product={beardProduct}
      setProduct={setBeardProduct}
      isEditable={isEditable}
    />
  );
};

export default BeardGrafts;
