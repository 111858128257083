import { useIntl } from "react-intl";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import FillerProductCard from "~/components/organisms/Pricing/FillerProductCard/FillerProductCard";
import useDefaultProduct from "~/hooks/useDefaultProduct";

const LipsFillerCard = () => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();
  const defaultProduct = useDefaultProduct(allProducts.skin.filler.lips);

  return (
    <FillerProductCard
      skinProductFamilyKey={SkinProductKey.lipsFiller}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.lipsFiller",
      })}
      isLoading={isLoading}
      options={allProducts.skin.filler.lips}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.lipsFiller.none",
      })}
      modalTitle={intl.formatMessage({
        id: "pricing.lipsFiller",
      })}
    />
  );
};

export default LipsFillerCard;
