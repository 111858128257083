import { useMemo } from "react";
import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import useTreatmentType from "~/hooks/useTreatmentType";
import { Product, ProductFamily } from "~/stores/checkout/product";
import { SKIN_FAMILIES } from "~/stores/checkout/product.constants";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { insertArrayAtIndex } from "~/utils/array.utils";
import useSkinBundles from "./useSkinBundles";

const useSkinProducts = () => {
  const [selectedProducts, setSelectedProducts, selectedBundle] =
    useCheckoutStore((state) => [
      state.selectedProducts,
      state.setSelectedProducts,
      state.selectedBundle,
    ]);
  const {
    setSkinBundleAndHandleSkinCategory,
    bundles,
    isLoading: isSkinBundlesLoading,
  } = useSkinBundles();

  const { skinCategory, skinSelected, toggleProductCategory } =
    useTreatmentType();

  const getProductByProductCardKey = (productCardKey: SkinProductKey) => {
    return selectedProducts.find(
      (product) => product.productCardKey === productCardKey
    );
  };

  const setSkinProductByProductCardKey = (
    productCardKey: SkinProductKey,
    product?: Product
  ) => {
    const list = selectedProducts;
    const index = list.findIndex(
      (selected) => selected.productCardKey === productCardKey
    );
    if (!product && index > -1) {
      list.splice(index, 1);
    } else if (product && index > -1) {
      list[index] = product;
    } else if (product && index < 0) {
      list.push(product);
    }

    if (product && !skinSelected && skinCategory) {
      toggleProductCategory(skinCategory);
    }
    setSelectedProducts(list);
  };

  const setFillerProductByProductCardKey = (
    productCardKey?: SkinProductKey,
    products?: Product[]
  ) => {
    const extras: Product[] = [];
    let mainOption: Product | undefined = undefined;
    products?.map((product) => {
      if (product.type.includes("addition")) {
        extras.push(product);
      } else {
        mainOption = product;
      }
    });
    const firstFillerProductIndex = selectedProducts.findIndex(
      (product) => product.productCardKey === productCardKey
    );
    let filteredList = selectedProducts.filter(
      (product) => product.productCardKey !== productCardKey
    );
    if (mainOption) {
      filteredList = insertArrayAtIndex(
        filteredList,
        [mainOption, ...extras],
        firstFillerProductIndex
      );
    }

    if (mainOption && !skinSelected && skinCategory) {
      toggleProductCategory(skinCategory);
    }
    setSelectedProducts(filteredList);
  };

  const getFillerProductByProductCardKey = (
    productCardKey?: SkinProductKey
  ) => {
    return selectedProducts.filter(
      (product) => product.productCardKey === productCardKey
    );
  };

  const hasSkinProducts = useMemo(
    () =>
      selectedProducts.find((product) =>
        SKIN_FAMILIES.includes(product.family)
      ) || selectedBundle,
    [selectedProducts, selectedBundle]
  );

  const removeSkinProducts = () => {
    setSelectedProducts(
      selectedProducts.filter(
        (product) => !SKIN_FAMILIES.includes(product.family)
      )
    );
    setSkinBundleAndHandleSkinCategory(null);
  };

  const getProductFamilySelected = (
    families: ProductFamily[] | ProductFamily
  ) => {
    const selectedByIndividualProduct = selectedProducts.find((product) =>
      Array.isArray(families)
        ? families.includes(product.family)
        : product.family === families
    );
    const selectedByBundle = selectedBundle?.bundleProducts.find(
      (bundleProduct) =>
        bundleProduct.family &&
        (Array.isArray(families)
          ? families.includes(bundleProduct.family)
          : bundleProduct.family === families)
    );
    return selectedByIndividualProduct || selectedByBundle;
  };

  const getProductItemCategoryKeySelected = (
    categoryKey: ProductItemCategoryKey
  ) => {
    switch (categoryKey) {
      case "botox":
        return getProductFamilySelected("botox");
      case "filler":
        return getProductFamilySelected("filler");
      case "needling":
        return getProductFamilySelected("needling");
      case "skin booster":
        return getProductFamilySelected("skin booster");
      default:
        return undefined;
    }
  };

  return {
    getProductByProductCardKey,
    setSkinProductByProductCardKey,
    setFillerProductByProductCardKey,
    getFillerProductByProductCardKey,
    removeSkinProducts,
    hasSkinProducts,
    getProductFamilySelected,
    getProductItemCategoryKeySelected,
    setSkinBundleAndHandleSkinCategory,
    bundles,
    isSkinBundlesLoading,
  };
};

export default useSkinProducts;
