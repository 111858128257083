import { clsx, ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { ProductImage } from "~/api/models/ProductDetails";

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes));

type GetImageBasedOnGenderArgs = {
  image?: ProductImage;
  opportunityGender?: "male" | "female";
  defaultToFemale?: boolean;
};

export const getImageBasedOnGender = ({
  image,
  opportunityGender,
  defaultToFemale,
}: GetImageBasedOnGenderArgs) => {
  if (!defaultToFemale) {
    return opportunityGender === "female" ? image?.female : image?.male;
  } else {
    return opportunityGender === "male" ? image?.male : image?.female;
  }
};
