import { useQuery } from "@tanstack/react-query";
import { Contract } from "~/api/models/Contract";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

const REFETCH_INTERVAL = 1000 * 3;

type UseFetchContractArgs = {
  contractId: string;
  hasRefetch?: boolean;
  disabled?: boolean;
};

export const useFetchContract = ({
  contractId,
  hasRefetch,
  disabled,
}: UseFetchContractArgs) => {
  const { data } = useFetchAccessToken();
  const { locale } = useLocaleStore();

  return useQuery({
    queryKey: [QueryKeys.useFetchContract, locale, contractId],
    queryFn: () =>
      salesforceServiceSingleton.getContract(
        data?.accessToken ?? "",
        contractId,
        locale
      ),
    enabled: !!data?.ensureAccessTokenIsValid?.() && !disabled,
    select: (data) => new Contract(data),
    refetchInterval: (data) =>
      data?.signed || !hasRefetch ? false : REFETCH_INTERVAL,
  });
};
