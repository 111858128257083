import { useMemo } from "react";
import { useIntl } from "react-intl";
import { PublicDiscount } from "~/api/models/DiscountCode";
import { useFetchPublicDiscounts } from "~/api/salesForce/useFetchPublicDiscounts";
import SearchDropdown from "~/components/molecules/SearchDropdown/SearchDropdown";

type DiscountCodeDropdownProps = {
  selectedDiscountCode: string | null;
  setSelectedDiscountCode: (selectedDiscountCode: string | null) => void;
};

const DiscountCodeDropdown = ({
  selectedDiscountCode,
  setSelectedDiscountCode,
}: DiscountCodeDropdownProps) => {
  const intl = useIntl();
  const { data, isLoading } = useFetchPublicDiscounts();
  const setSelectedOption = (option?: PublicDiscount) => {
    if (!option) {
      setSelectedDiscountCode(null);
    }
    setSelectedDiscountCode(option?.value ?? null);
  };
  const selectedOption = useMemo(
    () => data?.find((option) => option.value === selectedDiscountCode),
    [data, selectedDiscountCode]
  );

  return (
    <SearchDropdown
      options={data ?? []}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      placeholder={intl.formatMessage({
        id: "pricing.modal.discount.discountCode.placeholder",
      })}
      loadingOptions={isLoading}
      className="py-2 pl-6 pr-4 rounded-full bg-beige-500 border border-beige-500"
    />
  );
};

export default DiscountCodeDropdown;
