import { useMemo } from "react";
import { useIntl } from "react-intl";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Dropdown from "~/components/atoms/Dropdown/Dropdown";
import {
  LANGUAGE_OPTIONS,
  LanguageDropdownOption,
} from "~/components/organisms/layout/LanguageDropdown//LanguageDropdown.constants";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

const LanguageDropdown = () => {
  const intl = useIntl();
  const [locale, setLocale, setIsLocaleManuallySelected] = useLocaleStore(
    (state) => [
      state.locale,
      state.setLocale,
      state.setIsLocaleManuallySelected,
    ]
  );
  const changeLocale = (option: LanguageDropdownOption) => {
    setIsLocaleManuallySelected(true);
    setLocale(option.id);
  };
  const translatedOptions = useMemo(
    () =>
      LANGUAGE_OPTIONS.map((option) => ({
        ...option,
        title: intl.formatMessage({ id: option.title }),
      })),
    [intl]
  );
  const selectedOption = useMemo(
    () => translatedOptions.find((option) => option.id === locale),
    [locale]
  );

  return (
    <Dropdown
      hasArrow={false}
      align="end"
      value={selectedOption}
      onChange={changeLocale}
      customTrigger={
        <Button
          variant={ButtonVariant.secondaryOutline}
          customIcon={selectedOption?.icon}
          contentType={ButtonContentType.icon}
          size={ButtonSize.sm}
          className="p-2"
        />
      }
      options={translatedOptions}
    />
  );
};

export default LanguageDropdown;
