import { PropsWithChildren } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { RadioGroupOption } from "~/components/molecules/RadioGroup/RadioGroup.types";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type RadioProps<T> = PropsWithChildren<
  PropsWithClassName<{
    defaultChecked?: boolean;
    checked?: boolean;
    option?: T;
    onChange?: (value: T) => void;
    withCheck?: boolean;
    disabled?: boolean;
  }>
>;

const Radio = <T extends RadioGroupOption>({
  checked,
  defaultChecked,
  option,
  onChange,
  withCheck,
  className,
  children,
  disabled,
}: RadioProps<T>) => {
  const handleClick = () => {
    if (option && !option?.disabled && !disabled) {
      onChange?.(option);
    }
  };

  return (
    <div
      className={cn(
        "flex gap-3 items-center cursor-pointer transition-all duration-300",
        className
      )}
      onClick={handleClick}
    >
      <div className="relative flex">
        <input
          value={option?.name}
          checked={checked}
          defaultChecked={defaultChecked}
          type="radio"
          className={cn("cursor-pointer accent-black w-5 h-5 bg-green-100", {
            "text-grey-200": option?.disabled || disabled,
          })}
          readOnly
        />
        {withCheck && (
          <div
            className={cn(
              "w-5 h-5 absolute flex items-center justify-center rounded-md top-0 bg-white border border-grey-50 transition-all duration-300",
              {
                "bg-black border-black": checked,
              }
            )}
          >
            {checked && (
              <FontAwesomeIcon icon={faCheck} className="text-xs text-white" />
            )}
          </div>
        )}
      </div>
      <div className="w-full">
        {children ??
          (option && (
            <Subtitle
              className={cn({
                "text-grey-300": option?.disabled || disabled,
              })}
            >
              {option?.text ?? option?.name}
            </Subtitle>
          ))}
      </div>
    </div>
  );
};

export default Radio;
