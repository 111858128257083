import { motion } from "framer-motion";
import { TreatmentPlanPhase } from "~/api/models/TreatmentPlan";
import Copy from "~/components/atom/Copy";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import Stepper from "~/components/molecules/Stepper/Stepper";

type PlanPhaseDetailsProps = {
  currentPhase?: TreatmentPlanPhase;
  currentPhaseIndex: number;
  stepCount: number;
  setCurrentPhaseIndex: (phaseIndex: number) => void;
  stopTimer?: boolean;
};

const PlanPhaseDetails = ({
  currentPhaseIndex,
  setCurrentPhaseIndex,
  stepCount,
  currentPhase,
  stopTimer,
}: PlanPhaseDetailsProps) => {
  return (
    <div className="flex flex-col gap-10">
      <Headline level={2}>
        <Copy id="treatment.plan.phases.title" />
      </Headline>
      <div className="flex flex-col gap-6">
        <Stepper
          stepCount={stepCount}
          currentStep={currentPhaseIndex ?? 0}
          setCurrentStep={setCurrentPhaseIndex}
          stopTimer={stopTimer}
        />
        <div className="flex flex-col gap-4">
          <motion.div
            key={currentPhase?.title}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7 }}
          >
            <Headline level={3} className="text-grey-700">
              {currentPhase?.title}
            </Headline>
          </motion.div>
          <motion.div
            key={currentPhase?.description}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Subtitle className="text-grey-400">
              {currentPhase?.description}
            </Subtitle>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PlanPhaseDetails;
