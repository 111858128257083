import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { FamiliesState } from "./PhotosOverview";

export const DEFAULT_HAIR_FAMILIES: FamiliesState[] = [
  {
    counter: 0,
    key: ProductItemCategoryKey.ht,
    name: "photos.upload.hair.tabs.ht",
  },
  {
    counter: 0,
    key: ProductItemCategoryKey.beard,
    name: "photos.upload.hair.tabs.beard",
  },
  {
    counter: 0,
    key: ProductItemCategoryKey.eyebrow,
    name: "photos.upload.hair.tabs.eyebrows",
  },
];

export const DEFAULT_SKIN_FAMILIES: FamiliesState[] = [
  {
    counter: 0,
    key: ProductItemCategoryKey.needling,
    name: "photos.upload.skin.tabs.needling",
  },
  {
    counter: 0,
    key: ProductItemCategoryKey.botox,
    name: "photos.upload.skin.tabs.botox",
  },
  {
    counter: 0,
    key: ProductItemCategoryKey.filler,
    name: "photos.upload.skin.tabs.filler",
  },
  {
    counter: 0,
    key: ProductItemCategoryKey.skinBooster,
    name: "photos.upload.skin.tabs.skinBooster",
  },
];
