import type { ISbStoriesParams } from "storyblok-js-client";
import StoryblokClient from "storyblok-js-client";
import type { ISbResult } from "storyblok-js-client";
import { AppLocale } from "~/utils/types";

export interface StoryblokStory<T> {
  uuid: string;
  slug: string;
  full_slug: string;
  content: T;
  parent_id: number;
}

export interface StoryblokMultiStoryData<T> {
  stories: StoryblokStory<T>[];
}

export interface StoryblokMultiStoryResult<T> extends ISbResult {
  data: StoryblokMultiStoryData<T>;
}

export interface StoryblokAsset {
  filename: string;
  alt: string;
}

export type StoryblokVersion = "published" | "draft";
export type StoryblokLocale = "default" | "de" | "fr" | "it";

export const storyblokLocaleForAppLocale: Record<AppLocale, StoryblokLocale> = {
  en: "default",
  "de-CH": "de",
  "fr-CH": "fr",
  "it-CH": "it",
};

const storyblokAccessToken = import.meta.env.VITE_STORYBLOK_ACCESS_TOKEN;
const storyblokBaseSlug = "cdn/stories" as const;

export const storyblokService = () => {
  const storyblok = new StoryblokClient({
    accessToken: storyblokAccessToken,
  });

  const defaultParams: ISbStoriesParams = {
    version: import.meta.env.VITE_STORYBLOK_VERSION,
  };

  const get = <T>(
    key: string,
    params: { locale: AppLocale },
    storyblokParams?: ISbStoriesParams
  ): Promise<StoryblokMultiStoryResult<T>> => {
    return storyblok.get(storyblokBaseSlug, {
      starts_with: key,
      language: storyblokLocaleForAppLocale[params.locale],
      ...defaultParams,
      ...storyblokParams,
    });
  };
  const getAll = <T>(
    key: string,
    params: { locale: AppLocale },
    storyblokParams?: ISbStoriesParams
  ): Promise<StoryblokStory<T>[]> => {
    return storyblok.getAll(storyblokBaseSlug, {
      starts_with: key,
      language: storyblokLocaleForAppLocale[params.locale],
      ...defaultParams,
      ...storyblokParams,
    }) as unknown as Promise<StoryblokStory<T>[]>;
  };

  return {
    ...storyblok,
    getAll,
    get,
  };
};

export const storyblokRoutes = {
  bundles: "products/skin/bundles",
  skincareProducts: "products/skin/skincare-products",
  hairExamples: "examples-test/hair",
  skinExamples: "examples-test/skin",
  medicalHistoryQuestions: "medical-history-questions",
  discounts: "discounts",
  paymentMethods: "payment-methods",
  skinPlan: "treatment-plans-test/skin-plan",
  hairPlan: "treatment-plans-test/hair-plan",
  htPlan: "treatment-plans/hair-transplantation-plan",
  prpPlan: "treatment-plans/prp-plan",
  beardPlan: "treatment-plans/beard-plan",
  eyebrowsPlan: "treatment-plans/eyebrows-plan",
  medicalNeedlingPlan: "treatment-plans/medical-needling-plan",
  botoxPlan: "treatment-plans/botox-plan",
  fillerPlan: "treatment-plans/filler-plan",
  hairTreatmentFocus: "assessment-configuration/treatment-focus",
  skinTreatmentFocus: "assessment-configuration/skin-treatment-focus",
  scanLegends: "scan-legends",
  whyMainCard: "why-hair-and-skin-test/main-card",
  whyCards: "why-hair-and-skin-test/why-hair-and-skin-cards",
  clinicsPill: "clinics-pill",
  productCategories: "product-categories",
  hairPhotosPositions: "upload-photos-positions-test/hair",
  skinPhotosPositions: "upload-photos-positions-test/skin",
  productDetailsHt:
    "products/hair/product-details/hair-transplantation-without-prp",
  productDetailsHairBundle:
    "products/hair/product-details/hair-transplantation",
  productDetailsLongHair: "products/hair/product-details/long-hair",
  productDetailsPrp: "products/hair/product-details/prp",
  productDetailsEyebrow: "products/hair/product-details/eyebrow",
  productDetailsBeard: "products/hair/product-details/beard",
  productDetailsNeedling: "products/skin/product-details/needling",
  productDetailsBotoxWrinkles: "products/skin/product-details/botox-wrinkles",
  productDetailsBotoxSweat: "products/skin/product-details/botox-sweat",
  productDetailsBotoxAfterTreatment:
    "products/skin/product-details/botox-after-treatment",
  productDetailsLipsFiller: "products/skin/product-details/lips-filler",
  productDetailsCheeksFiller: "products/skin/product-details/cheeks-filler",
  productDetailsJawlineFiller: "products/skin/product-details/jawline-filler",
  productDetailsChinFiller: "products/skin/product-details/chin-filler",
  productDetailsSkinBooster: "products/skin/product-details/skin-booster",
  productDetailsHyaluronidase: "products/skin/product-details/hyaluronidase",
  productDetailsSkinCare: "products/skin/product-details/skin-care",
  pricingDetails: "pricing-details",
  contractCreationLoader: "contract-creation-loader",
};

export const storyblokServiceSingleton = storyblokService();
