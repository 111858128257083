export enum ButtonContentType {
  text = "text",
  icon = "icon",
  textIcon = "text-icon",
  iconText = "icon-text",
}

export class ButtonSize {
  public static lg = new ButtonSize("lg", "h-14 text-sm leading-6");
  public static md = new ButtonSize("md", "h-12 text-sm leading-6");
  public static sm = new ButtonSize("sm", "h-10 text-xs");

  name: string;
  style: string;

  private constructor(name: string, style: string) {
    this.name = name;
    this.style = style;
  }
}

export class ButtonVariant {
  public static primary = new ButtonVariant(
    "primary",
    "bg-black hover:bg-green-900 text-white"
  );
  public static secondary = new ButtonVariant(
    "secondary",
    "bg-beige-100 hover:bg-beige-500 text-black"
  );
  public static secondaryOutline = new ButtonVariant(
    "secondary-outline",
    "bg-transparent border border-grey-200 hover:border-black text-black"
  );

  public static darkSecondaryOutline = new ButtonVariant(
    "dark-secondary-outline",
    "bg-transparent border border-black hover:border-green-900 text-black hover:text-green-900"
  );

  public static text = new ButtonVariant(
    "text",
    "bg-transparent hover:text-black"
  );

  name: string;
  style: string;

  private constructor(name: string, style: string) {
    this.name = name;
    this.style = style;
  }
}
