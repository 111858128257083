import { useMemo } from "react";
import { splitArrayHalf } from "~/utils/array.utils";
import LoaderList, { LoaderListProps } from "./LoaderList";

type ContentLoaderProps = LoaderListProps;

const ContentLoader = ({ blocks }: ContentLoaderProps) => {
  const { firstHalf, secondHalf } = useMemo(
    () => splitArrayHalf(blocks || []),
    [blocks]
  );

  return (
    <div className="grid grid-cols-2 gap-6">
      <LoaderList blocks={firstHalf} />
      <LoaderList blocks={secondHalf} />
    </div>
  );
};

export default ContentLoader;
