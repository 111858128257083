import { Navigate, Outlet, generatePath, useParams } from "react-router-dom";
import {
  MOBILE_FALLBACK_MAX_WIDTH,
  MobileFallBackScreen,
} from "~/components/organisms/MobileFallbackScreen/MobileFallbackScreen";
import useKeyboardArrows from "~/hooks/useKeyboardArrows";
import { useWindowDimensions } from "~/hooks/useWindowDimensions";
import { useAuthStore } from "~/stores/auth/useAuthStore";
import Initialized from "./Initialized";
import { Routes } from "./routes";

const LoggedInOnly = () => {
  useKeyboardArrows();
  const { isLoggedIn } = useAuthStore();
  const { opportunityId } = useParams();

  const { width } = useWindowDimensions();

  if (width < MOBILE_FALLBACK_MAX_WIDTH) {
    return <MobileFallBackScreen />;
  }

  if (!isLoggedIn()) {
    return (
      <Navigate
        to={generatePath(Routes.Login, {
          opportunityId: opportunityId as string,
        })}
        replace
      />
    );
  }

  return (
    <Initialized>
      <Outlet />
    </Initialized>
  );
};

export default LoggedInOnly;
