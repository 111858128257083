import React, { PropsWithChildren, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import { ButtonSize } from "~/components/atoms/Button/Buttons.types";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import Drop from "~/components/icons/Drop";
import { cn } from "~/utils";
import { notifyFileUploadError, verifyUploadOnePdf } from "~/utils/files.utils";
import { PropsWithClassName } from "~/utils/types";

type FileUploadZoneProps = PropsWithChildren<
  PropsWithClassName<{
    onFileDrop: (file: File | null) => void;
    loadingUpload?: boolean;
    disabled?: boolean;
  }>
>;

const FileUploadZone = ({
  className,
  onFileDrop,
  loadingUpload,
  disabled,
}: FileUploadZoneProps) => {
  const intl = useIntl();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    checkFileAndUpload(droppedFiles);
  };
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      checkFileAndUpload(event.target.files);
    }
  };

  const checkFileAndUpload = (files: File[] | FileList) => {
    if (loadingUpload || disabled) return;
    if (!verifyUploadOnePdf(files)) {
      return notifyFileUploadError();
    }
    onFileDrop(files[0]);
  };

  return (
    <div
      className={cn(
        "relative border-2 border-green-500 border-dashed rounded-lg px-4 py-14 flex flex-col gap-8 items-center justify-center transition-all duration-300",
        className,
        {
          "border-black": isDraggingOver,
        }
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Drop className="w-16 h-16" />
      <div className="flex flex-col gap-2 items-center text-center">
        <Headline level={4}>
          <Copy id="file.drop" />
        </Headline>
        <Subtitle className="text-grey-700">
          <Copy id="or" />
        </Subtitle>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
          disabled={loadingUpload || disabled}
        />
        <Button
          size={ButtonSize.sm}
          text={intl.formatMessage({
            id: "file.browse",
          })}
          className="px-16"
          onClick={handleClick}
          loading={loadingUpload}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default FileUploadZone;
