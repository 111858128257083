import { useMemo } from "react";
import { useIntl } from "react-intl";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import TabsToggle from "~/components/molecules/TabsToggle/TabsToggle";
import EmptyStateScreen from "~/components/organisms/EmptyStates/EmptyStateScreen";
import { TreatmentPreviewCard } from "~/components/organisms/TreatmentPreviewCard/TreatmentPreviewCard";
import { Header } from "~/components/organisms/layout/Header/Header";
import { ExampleGender, Example } from "~/modules/example/example";

type GridProps = {
  example?: Example;
  isLoading: boolean;
  activeTab: ExampleGender;
  setActiveTab: (activeTab: ExampleGender) => void;
};

const Grid = ({ example, isLoading, activeTab, setActiveTab }: GridProps) => {
  const intl = useIntl();
  const activeExamples = useMemo(
    () =>
      example?.treatments?.filter((treatment) => {
        return activeTab === ExampleGender.all
          ? true
          : treatment.gender.toLowerCase() === activeTab;
      }),
    [example, activeTab]
  );

  if (!example) {
    // TODO redirect to 404 when built
    return null;
  }

  return (
    <div className="flex flex-col gap-header p-body">
      <Header title={example.title} hasBackButton />
      <div className="flex flex-col gap-6">
        <TabsToggle
          options={[
            {
              key: ExampleGender.all,
              value: intl.formatMessage({ id: "examples.all" }),
            },
            {
              key: ExampleGender.men,
              value: intl.formatMessage({ id: "examples.men" }),
            },
            {
              key: ExampleGender.women,
              value: intl.formatMessage({ id: "examples.women" }),
            },
          ]}
          activeOption={activeTab}
          setActiveOption={setActiveTab}
        />
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <SpinnerIcon className="text-3xl" />
          </div>
        ) : activeExamples && activeExamples.length > 0 ? (
          <div className="grid grid-cols-4 w-full cursor-pointer gap-6">
            {activeExamples?.map((treatment) => (
              <TreatmentPreviewCard key={treatment.id} treatment={treatment} />
            ))}
          </div>
        ) : (
          <EmptyStateScreen
            hideButton
            customTitleId="examples.noPhotos.title"
            customDescriptionId="examples.noPhotos.description"
          />
        )}
      </div>
    </div>
  );
};

export default Grid;
