import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Checkbox from "~/components/atoms/Checkbox/Checkbox";
import { DropdownOption } from "~/components/atoms/Dropdown/Dropdown.types";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type DropdownItemProps<T extends DropdownOption> = PropsWithClassName<{
  option: T;
  onChange?: (option: T) => void;
  selected?: boolean;
  multiSelect?: boolean;
  title?: string;
}>;

const DropdownItem = <T extends DropdownOption>({
  option,
  onChange,
  selected,
  multiSelect,
  title,
  className,
}: DropdownItemProps<T>) => {
  return (
    <DropdownMenu.CheckboxItem
      onClick={() => {
        if (!option.disabled) onChange?.(option);
      }}
      className={cn(
        "DropdownMenuItem",
        {
          "pointer-events-none bg-grey-200 text-grey-300 ": option.disabled,
        },
        className
      )}
    >
      {option.icon}
      {title ?? option.title}

      {multiSelect ? (
        <Checkbox checked={selected} />
      ) : (
        <FontAwesomeIcon
          className={cn(
            "h-3 text-grey-400 opacity-0 transition duration-300 ease-in-out",
            { "opacity-100": selected }
          )}
          icon={faCheck}
        />
      )}
    </DropdownMenu.CheckboxItem>
  );
};

export default DropdownItem;
