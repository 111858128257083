import { useQuery } from "@tanstack/react-query";
import {
  Example,
  ExampleCategory,
  ExampleDTO,
} from "~/modules/example/example";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchExampleBySlug = (
  category: ExampleCategory,
  slug: string
) => {
  const { locale } = useLocaleStore();

  const result = useQuery({
    queryKey: [QueryKeys.useFetchExampleBySlug, slug, locale, category],
    queryFn: () =>
      storyblokServiceSingleton.get<ExampleDTO>(
        `${category === ExampleCategory.hair ? storyblokRoutes.hairExamples : storyblokRoutes.skinExamples}/${slug}`,
        {
          locale,
        }
      ),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  const exampleDTO = result.data?.data?.stories.length
    ? result.data?.data?.stories[0]?.content
    : undefined;

  if (!exampleDTO)
    return {
      ...result,
      data: null,
    };

  return {
    ...result,
    data: new Example(
      exampleDTO._uid,
      exampleDTO.title,
      exampleDTO.image.filename,
      exampleDTO.slug,
      exampleDTO.categoryKey,
      exampleDTO.parent_id,
      exampleDTO.treatments
    ),
  };
};
