import InfoButton from "~/components/atoms/InfoButton/InfoButton";
import Switch from "~/components/atoms/Switch/Switch";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type SkinCareSwitchProps = {
  withInfo?: boolean;
  openSkinCareProductModal?: () => void;
  disabled?: boolean;
};

const SkinCareSwitch = ({
  withInfo = false,
  openSkinCareProductModal,
  disabled,
}: SkinCareSwitchProps) => {
  const [hasSkinCare, toggleSkinCare] = useCheckoutStore((state) => [
    state.hasSkinCare,
    state.toggleSkinCare,
  ]);

  return (
    <div className="flex gap-2 items-center">
      <Switch
        disabled={disabled}
        value={hasSkinCare}
        onChanged={toggleSkinCare}
        label="Skincare Products"
        labelStyle="text-grey-700 text-sm"
      />
      {withInfo && (
        <InfoButton
          onClick={openSkinCareProductModal}
          className="h-2 w-2 p-1.5"
        />
      )}
    </div>
  );
};

export default SkinCareSwitch;
