import { useQuery } from "@tanstack/react-query";
import {
  TreatmentPlanProductCategory,
  TreatmentPlanCategory,
} from "~/api/models/TreatmentPlan";
import { QueryKeys } from "~/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { AppLocale } from "~/utils/types";

export const useFetchTreatmentPlan = (
  category: TreatmentPlanProductCategory
) => {
  const { locale } = useLocaleStore();
  return useQuery<
    StoryblokMultiStoryResult<TreatmentPlanCategory>,
    unknown,
    TreatmentPlanCategory[],
    (QueryKeys | AppLocale | TreatmentPlanProductCategory)[]
  >({
    queryKey: [QueryKeys.useFetchTreatmentPlan, locale, category],
    queryFn: () =>
      storyblokServiceSingleton.get(
        category === TreatmentPlanProductCategory.hair
          ? storyblokRoutes.hairPlan
          : storyblokRoutes.skinPlan,
        { locale }
      ),
    select: (data) => {
      return data.data.stories?.map((story) => ({
        title: story.content.title,
        image: story.content.image,
        phases: story.content.phases,
        recommendations: story.content.recommendations,
        benefits: story.content.benefits,
        information: story.content.information,
        key: story.content.key,
      }));
    },
  });
};
