import React, { useRef, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  DropdownOption,
  DropdownOptionGroup,
  DropdownOptionGroupItem,
} from "~/components/atoms/Dropdown/Dropdown.types";
import DropdownItem from "~/components/atoms/Dropdown/DropdownItem";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import useClickOutside from "~/hooks/useClickOutside";
import useEsc from "~/hooks/useEsc";
import { cn } from "~/utils";

type DropdownProps<T extends DropdownOption> = {
  customTrigger?: React.ReactNode;
  onChange?: (option: T) => void;
  options?: T[];
  hasArrow?: boolean;
  value?: T | T[];
  align?: "start" | "end" | "center";
  optionGroups?: DropdownOptionGroup<T>;
  titleConstructor?: (option?: T | DropdownOptionGroupItem<T>) => string;
  onClick?: () => void;
};

const Dropdown = <T extends DropdownOption>({
  customTrigger,
  options,
  onChange,
  hasArrow = true,
  value,
  align = "center",
  optionGroups,
  titleConstructor,
  onClick,
}: DropdownProps<T>) => {
  const triggerRef = useRef(null);
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const multiSelect = Array.isArray(value);
  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  useEsc(() => {
    setOpen(false);
  });

  useClickOutside([triggerRef, contentRef], () => {
    setOpen(false);
  });

  return (
    <div ref={triggerRef}>
      <DropdownMenu.Root open={open}>
        <DropdownMenu.Trigger
          asChild
          onClick={() => {
            toggleOpen();
            if (onClick) onClick();
          }}
        >
          {customTrigger || multiSelect ? (
            <div className="w-fit h-fit flex items-center justify-between gap-3">
              {customTrigger}
              {hasArrow && (
                <FontAwesomeIcon
                  className={cn(
                    "h-3 text-grey-400 transition duration-300 ease-in-out",
                    open && "rotate-180 transform"
                  )}
                  icon={faChevronDown}
                />
              )}
            </div>
          ) : (
            <button className="flex min-w-dropdown items-center justify-between gap-3 rounded-full bg-beige-100 px-6 py-3 outline-none">
              {value?.icon && <div className="w-6 h-6">{value?.icon}</div>}
              <p className="text-sm">{value?.title}</p>
              {hasArrow && (
                <FontAwesomeIcon
                  className={cn(
                    "h-3 text-grey-400 transition duration-300 ease-in-out",
                    open && "rotate-180 transform"
                  )}
                  icon={faChevronDown}
                />
              )}
            </button>
          )}
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            ref={contentRef}
            align={align}
            className="DropdownMenuContent"
            sideOffset={5}
          >
            {options?.map((option, index) => {
              const isSelected = multiSelect
                ? value.findIndex((v) => v.id === option.id) !== -1
                : value?.id === option.id;
              return (
                <DropdownItem
                  title={titleConstructor?.(option)}
                  className={cn("first:rounded-t-2xl", {
                    "last:rounded-b-2xl": !optionGroups?.length,
                  })}
                  key={index}
                  option={option}
                  selected={isSelected}
                  onChange={onChange}
                />
              );
            })}
            {optionGroups?.map((group, index) => (
              <div
                key={index}
                className="border-grey-50 border-0 border-b-2 last:border-b-0"
              >
                {group.options.length > 0 && (
                  <Subtitle className="uppercase text-grey-400 p-4 pb-2 font-bold">
                    {group.title}
                  </Subtitle>
                )}
                <div>
                  {group.options.length > 0 ? (
                    group.options?.map((groupOption, groupIndex) => {
                      const isSelected = multiSelect
                        ? value.findIndex((v) => v.id === groupOption.id) !== -1
                        : value?.id === groupOption.id;
                      return (
                        <DropdownItem
                          title={titleConstructor?.(groupOption)}
                          className={cn({
                            "last:rounded-b-2xl":
                              index === optionGroups.length - 1,
                          })}
                          key={groupIndex}
                          option={groupOption}
                          multiSelect={multiSelect}
                          selected={isSelected}
                          onChange={onChange}
                        />
                      );
                    })
                  ) : (
                    <DropdownItem
                      title={titleConstructor?.(group)}
                      className={
                        "uppercase text-grey-400 p-4 pb-2 font-bold last:rounded-b-2xl"
                      }
                      option={group}
                      multiSelect={multiSelect}
                      selected={
                        multiSelect
                          ? value.findIndex((v) => v.id === group.id) !== -1
                          : value?.id === group.id
                      }
                      onChange={onChange}
                    />
                  )}
                </div>
              </div>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default Dropdown;
