import { useQuery } from "@tanstack/react-query";
import { ContentBlock, LoadingContentDto } from "~/api/models/LoadingContent";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchContractCreationLoader = () => {
  const { locale } = useLocaleStore();
  return useQuery({
    queryKey: [QueryKeys.useFetchContractCreationLoader, locale],
    queryFn: () =>
      storyblokServiceSingleton.get<LoadingContentDto>(
        storyblokRoutes.contractCreationLoader,
        {
          locale,
        }
      ),
    select: (data) =>
      data.data.stories.length > 0
        ? data.data.stories[0].content.blocks.map(
            (block) => new ContentBlock(block)
          )
        : [],
  });
};
