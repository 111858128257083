import { useIntl } from "react-intl";
import { ToastContainer } from "react-toastify";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { CopyKeys } from "~/utils/types";

type CustomToastProps = {
  titleId?: CopyKeys;
  title?: string;
  description?: string;
  descriptionId?: CopyKeys;
};

const CustomToast = ({
  titleId,
  description,
  title,
  descriptionId,
}: CustomToastProps) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col gap-1">
      <Headline className="text-black">
        {titleId ? intl.formatMessage({ id: titleId }) : title}
      </Headline>
      <Subtitle className="text-grey-400">
        {descriptionId
          ? intl.formatMessage({ id: descriptionId })
          : description}
      </Subtitle>
    </div>
  );
};

export default CustomToast;

export const AUTO_CLOSE = 3000;
const LIMIT = 3;

export const CustomToastContainer = () => {
  return (
    <ToastContainer
      className="w-[28rem] flex justify-end flex-col"
      toastClassName="w-[26.5rem] rounded-2xl p-4"
      bodyClassName="flex items-start justify-center"
      autoClose={AUTO_CLOSE}
      pauseOnHover
      limit={LIMIT}
    />
  );
};
