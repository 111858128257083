import { useIntl } from "react-intl";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import BotoxProductCard from "~/components/organisms/Pricing/BotoxProductCard/BotoxProductCard";
import useDefaultProduct from "~/hooks/useDefaultProduct";

const BotoxSweatCard = () => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();
  const defaultProduct = useDefaultProduct([
    ...allProducts.skin.botox.sweat.x1,
    ...allProducts.skin.botox.sweat.x2,
  ]);

  return (
    <BotoxProductCard
      skinProductFamilyKey={SkinProductKey.botoxSweat}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.botoxSweat",
      })}
      isLoading={isLoading}
      options={[
        ...allProducts.skin.botox.sweat.x1,
        ...allProducts.skin.botox.sweat.x2,
      ]}
      hasSubtitle={false}
      modalTitle={intl.formatMessage({ id: "pricing.botoxSweat" })}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.botoxSweat.none",
      })}
    />
  );
};

export default BotoxSweatCard;
