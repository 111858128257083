import QRCode from "react-qr-code";
import Copy from "~/components/atom/Copy";
import { cn } from "~/utils";
import { CopyKeys } from "~/utils/types";

type Props = {
  uploadUrl: string;
  descriptionCopyId: CopyKeys;
  titleCopyId?: CopyKeys;
};

export function ScanQRCodeScreen({
  uploadUrl,
  descriptionCopyId,
  titleCopyId,
}: Props) {
  return (
    <div className="flex flex-col gap-6 h-full w-full items-center justify-center">
      <QRCode value={uploadUrl} />
      <div className="max-w-xs">
        {titleCopyId ? (
          <h1 className="mb-2 text-center text-2xl font-normal">
            <Copy id={titleCopyId} />
          </h1>
        ) : null}
        <p
          className={cn("text-center text-base text-grey-400", {
            "mt-4": !titleCopyId,
          })}
        >
          <Copy id={descriptionCopyId} />
        </p>
      </div>
    </div>
  );
}
