import { useEffect, RefObject } from "react";

const useClickOutside = (
  refs: RefObject<HTMLElement>[],
  callback: () => void
) => {
  const handleClick = (e: MouseEvent) => {
    if (
      refs.every(
        (ref) => ref.current && !ref.current.contains(e.target as Node)
      )
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
};

export default useClickOutside;
