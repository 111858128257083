import { AnimatePresence, motion } from "framer-motion";
import { TreatmentPlanRecommendation } from "~/api/models/TreatmentPlan";
import Headline from "~/components/atoms/Headline/Headline";
import Pill from "~/components/atoms/Pill/Pill";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import ZoomCard from "~/components/molecules/ZoomCard/ZoomCard";
import { toDoubleDigit } from "~/utils/number.utils";
import { PropsWithClassName } from "~/utils/types";

type TreatmentPlanRecommendationCardProps = PropsWithClassName<{
  recommendation: TreatmentPlanRecommendation;
  order?: number;
  disableHover?: boolean;
  hasZoom?: boolean;
  onHover?: () => void;
}>;

const TreatmentPlanRecommendationCard = ({
  recommendation,
  order,
  disableHover,
  className,
  hasZoom,
  onHover,
}: TreatmentPlanRecommendationCardProps) => {
  return (
    <ZoomCard
      className={className}
      disableHover={disableHover}
      reverseBackdrop
      backgroundImage={{
        src: recommendation.image.filename,
        alt: recommendation.image.alt,
      }}
      hasZoom={hasZoom}
      onHover={onHover}
    >
      {(isHovered) => (
        <div className="flex flex-col gap-3">
          <AnimatePresence>
            {(isHovered || hasZoom) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7, delay: 0.5 }}
              >
                <Pill className="bg-white text-grey-700">
                  <Subtitle level={2}>{recommendation.subtitle}</Subtitle>
                </Pill>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.7,
              delay: isHovered || hasZoom ? 0.5 : 0,
            }}
            key={isHovered || hasZoom ? "title" : "order"}
          >
            <Headline level={4} className="text-white">
              {isHovered || !order || hasZoom
                ? recommendation.title
                : toDoubleDigit(order)}
            </Headline>
          </motion.div>
        </div>
      )}
    </ZoomCard>
  );
};

export default TreatmentPlanRecommendationCard;
