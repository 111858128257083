import { Suspense } from "react";
import { IntlProvider } from "react-intl";
import { ErrorBoundary } from "@sentry/react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import developerCopy from "~/assets/translations/_developer.json";
import deCopy from "~/assets/translations/de-CH.json";
import enCopy from "~/assets/translations/en.json";
import frCopy from "~/assets/translations/fr-CH.json";
import itCopy from "~/assets/translations/it-CH.json";
import { CustomToastContainer } from "~/components/atoms/CustomToast/CustomToast";
import ScreenLoader from "~/components/organisms/ScreenLoader/ScreenLoader";
import { QueryKeys } from "~/queryKeys";
import { AppRoutes } from "~/routes";
import { CustomError } from "~/services/CustomError";
import { NOT_AUTHORIZED_ERROR_STATUS_CODE } from "~/services/errors.const";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { AppLocale } from "~/utils/types";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      useErrorBoundary: (error) => {
        if (error instanceof CustomError && !error.hasErrorBoundary) {
          return false;
        }
        return true;
      },
      retry: (failureCount, error) => {
        if (
          error instanceof CustomError &&
          error.cause === NOT_AUTHORIZED_ERROR_STATUS_CODE
        ) {
          return false;
        }

        return failureCount <= 3;
      },
    },
    mutations: {
      useErrorBoundary: (error) => {
        if (error instanceof CustomError && !error.hasErrorBoundary) {
          return false;
        }
        return true;
      },
      retry: (failureCount, error) => {
        if (
          error instanceof CustomError &&
          error.cause === NOT_AUTHORIZED_ERROR_STATUS_CODE
        ) {
          return false;
        }

        // Retry others just once
        return failureCount <= 3;
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (
        error instanceof CustomError &&
        error.cause === NOT_AUTHORIZED_ERROR_STATUS_CODE &&
        query.queryKey?.[0] !== QueryKeys.useFetchAccessToken
      ) {
        queryClient.refetchQueries({
          queryKey: [QueryKeys.useFetchAccessToken],
        });
      }
      console.log(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (
        error instanceof CustomError &&
        error.cause === NOT_AUTHORIZED_ERROR_STATUS_CODE
      ) {
        queryClient.refetchQueries({
          queryKey: [QueryKeys.useFetchAccessToken],
        });
      }
      console.log(error);
    },
  }),
});

const copy: Record<AppLocale, keyof typeof developerCopy> = {
  "it-CH": itCopy as never,
  en: enCopy as never,
  "de-CH": deCopy as never,
  "fr-CH": frCopy as never,
};

export function AppProvider() {
  const { locale } = useLocaleStore();

  return (
    <Suspense fallback={<ScreenLoader />}>
      <IntlProvider
        locale={locale}
        messages={import.meta.env.DEV ? developerCopy : (copy[locale] as never)}
      >
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <CustomToastContainer />
          </QueryClientProvider>
        </ErrorBoundary>
      </IntlProvider>
    </Suspense>
  );
}
