export enum PhotoTakenState {
  WAITING = "waiting",
  SUCCESS = "success",
  ERROR = "error",
}

export type PhotoTaken = {
  src: string;
  state?: PhotoTakenState;
};

export type PhotosTaken = Record<string, PhotoTaken>;

export type PhotosTakenDto = {
  title: string;
  document: Blob;
};
