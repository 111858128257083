import { useEffect } from "react";
import { generatePath, useParams } from "react-router-dom";
import { Routes } from "~/routes/routes";
import { salesforceEndpoints } from "~/services/salesForce/salesForce";

export const Login = () => {
  const { opportunityId } = useParams();

  useEffect(() => {
    window.location.href = salesforceEndpoints.auth.auth(
      `${window.location.origin}${generatePath(Routes.OAuth, {
        opportunityId: opportunityId as string,
      })}`
    );
  }, []);
  return null;
};
