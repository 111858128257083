import { Tab } from "~/components/atoms/Tab/Tab";
import { cn } from "~/utils";

type TabOption<T> = {
  key: T;
  value: string;
  counter?: number;
};

type TabsToggleProps<T> = {
  options: TabOption<T>[];
  activeOption: T;
  setActiveOption: (option: T) => void;
  className?: string;
  tabClassName?: string;
  style?: React.CSSProperties;
};

const TabsToggle = <T,>({
  options,
  activeOption,
  setActiveOption,
  className,
  tabClassName,
  style,
}: TabsToggleProps<T>) => {
  return (
    <div
      className={cn("flex w-fit gap-2 rounded-full bg-beige-100", className)}
      style={style}
    >
      {options.map((option) => (
        <Tab
          key={option.value}
          text={option.value}
          isActive={activeOption === option.key}
          onClick={() => setActiveOption(option.key)}
          counter={option.counter}
          className={tabClassName}
        />
      ))}
    </div>
  );
};

export default TabsToggle;
