import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { cn } from "~/utils";

type CheckboxProps = {
  checked?: boolean;
};

const Checkbox = ({ checked }: CheckboxProps) => {
  return (
    <RadixCheckbox.Root
      checked={checked}
      className={cn(
        "bg-white w-5 h-5 border border-grey-50 rounded-md flex items-center justify-center transition-all duration-300",
        { "bg-black": checked }
      )}
    >
      <RadixCheckbox.Indicator className="flex items-center justify-center">
        <FontAwesomeIcon icon={faCheck} className="text-white text-xs" />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};

export default Checkbox;
