import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { notifyOnce } from "~/utils/toast.utils";

export const useUploadDocument = (uploadToken: string) => {
  const queryClient = useQueryClient();
  const { locale } = useLocaleStore();

  return useMutation({
    mutationKey: [QueryKeys.useUploadDocument],
    mutationFn: (body: {
      title: string;
      document: File;
      opportunity_id: string;
    }) => salesforceServiceSingleton.uploadDocument(uploadToken, body, locale),
    onError: () => {
      notifyOnce(
        {
          toastType: "error",
          descriptionId: "photos.upload.take-photo-modal.error",
        },
        "useUploadDocumentError"
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.useUploadDocument]);
    },
  });
};
