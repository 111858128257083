import { useQuery } from "@tanstack/react-query";
import { SalesforceBundle } from "~/api/models/Bundle";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import useFlattenedProducts from "~/hooks/useFlattenedProducts";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchBundles = () => {
  const { data } = useFetchAccessToken();
  const { locale } = useLocaleStore();
  const { data: flattenedProducts } = useFlattenedProducts();

  const result = useQuery({
    queryKey: [QueryKeys.useFetchBundles, locale],
    queryFn: () =>
      salesforceServiceSingleton.getBundles(data!.accessToken, locale), // accessToken is not null because of the enabled flag
    enabled: !!data?.ensureAccessTokenIsValid?.(),
  });

  return {
    ...result,
    data: result.data
      ?.map(
        (bundle) =>
          new SalesforceBundle(
            bundle,
            bundle.bundle_products.map((bundleProduct) => {
              const product = flattenedProducts?.find(
                (p) => p.productCode === bundleProduct.product_code
              );
              return {
                id: bundleProduct.id,
                name: bundleProduct.name,
                fullName: product?.name ?? bundleProduct.name,
                isOptional: bundleProduct.is_optional,
                productCode: bundleProduct.product_code,
                price: bundleProduct.sales_price,
                productCardKey: product?.productCardKey as SkinProductKey,
                family: product?.family,
              };
            })
          )
      )
      .sort((a, b) => a.price - b.price),
  };
};
