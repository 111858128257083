import { useIntl } from "react-intl";
import { HairProductKey } from "~/api/models/HairProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import ProductCard from "~/components/organisms/Pricing/ProductCard/ProductCard";
import useHairProducts from "~/hooks/useHairProducts";
import { Product } from "~/stores/checkout/product";

const EyebrowsCard = () => {
  const { data: allProducts, isLoading } = useFetchProducts();
  const intl = useIntl();
  const { eyebrowProduct, setEyebrowProduct } = useHairProducts();

  return (
    <ProductCard
      hairProductFamilyKey={HairProductKey.eyebrows}
      cardTitle={intl.formatMessage({
        id: "pricing.eyebrows",
      })}
      product={eyebrowProduct}
      setProduct={
        setEyebrowProduct as (product?: Product | Product[] | undefined) => void
      }
      modalTitle={intl.formatMessage({
        id: "pricing.modal.eyebrows.title",
      })}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.eyebrows.none",
      })}
      isLoading={isLoading}
      options={allProducts.hair.eyebrow.transplantation}
      optionSubtitleFormatter={(option) =>
        intl.formatMessage(
          {
            id: "graftsCount",
          },
          {
            from: option?.grafts.from,
            to: option?.grafts.to,
          }
        )
      }
      optionTitleFormatter={(option) => option.name.split("(")[0]}
    />
  );
};

export default EyebrowsCard;
