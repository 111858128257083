import { useMemo } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { HairProductKey } from "~/api/models/HairProductKey";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import Copy from "~/components/atom/Copy";
import Headline from "~/components/atoms/Headline/Headline";
import EmptyStateScreen from "~/components/organisms/EmptyStates/EmptyStateScreen";
import PricingCard from "~/components/organisms/Pricing/PricingCard/PricingCard";
import PricingHeader from "~/components/organisms/Pricing/PricingHeader/PricingHeader";
import WhyHSSlideshow from "~/components/organisms/Pricing/WhyHSSlideshow/WhyHSSlideshow";
import { productCardBuilder } from "~/components/organisms/ProductCards/ProductCards";
import { ProductCardContext } from "~/hooks/useProductCardContext";
import useTreatmentRelatedSkinProductKeys from "~/hooks/useTreatmentRelatedSkinProductKeys";
import useTreatmentType from "~/hooks/useTreatmentType";
import { Routes } from "~/routes/routes";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { cn } from "~/utils";

const MAX_RECOMMENDED_PRODUCTS = 3;

const Pricing = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const treatmentRelatedSkinProductKeys = useTreatmentRelatedSkinProductKeys();
  const selectedProductsCardKeys: (SkinProductKey | HairProductKey)[] =
    useCheckoutStore((state) => {
      return state.selectedProducts
        .filter((p) => !!p.productCardKey)
        .map((p) => p.productCardKey)
        .concat(state.selectedBundle?.productCardKey || []);
    });
  const { planType } = useTreatmentType();

  const productCards = useMemo(() => {
    const cards: (SkinProductKey | HairProductKey)[] = [];
    selectedProductsCardKeys.map((productCardKey) => {
      if (productCardKey && !cards.includes(productCardKey)) {
        cards.push(productCardKey);
      }
    });
    return cards;
  }, [selectedProductsCardKeys]);

  const recommendedSkinProducts = useMemo(() => {
    const allRecommended = treatmentRelatedSkinProductKeys.filter(
      (product) => !productCards.includes(product)
    );

    return allRecommended.slice(0, MAX_RECOMMENDED_PRODUCTS);
  }, [treatmentRelatedSkinProductKeys, productCards]);

  const gotToConfiguration = () => {
    if (planType === "skin") {
      navigate(
        generatePath(Routes.SkinConfiguration, {
          opportunityId: opportunityId as string,
        })
      );
    } else {
      navigate(
        generatePath(Routes.HairConfiguration, {
          opportunityId: opportunityId as string,
        })
      );
    }
  };

  return (
    <div className="relative h-full grid grid-cols-3 gap-10 h-screen p-body pb-0">
      <div className="flex flex-col col-span-2 gap-header overflow-scroll no-scrollbar">
        <PricingHeader />
        <div className="flex flex-col h-full">
          <div
            className={cn("flex flex-col gap-4 pb-body", {
              "h-full": productCards.length === 0,
            })}
          >
            <ProductCardContext.Provider
              value={{ isSuggested: false, isSummaryCard: false }}
            >
              {productCards.length > 0 ? (
                productCards.map((productKey) => {
                  return productCardBuilder({ productKey });
                })
              ) : (
                <EmptyStateScreen onClick={gotToConfiguration} />
              )}
            </ProductCardContext.Provider>
          </div>
          {recommendedSkinProducts.length > 0 && (
            <div className="flex flex-col gap-4 pb-body">
              <Headline level={6}>
                <Copy id="pricing.recommended" />
              </Headline>
              <ProductCardContext.Provider
                value={{ isSuggested: true, isSummaryCard: false }}
              >
                {recommendedSkinProducts.map((productKey) => {
                  return (
                    <div key={productKey}>
                      {productCardBuilder({ productKey })}
                    </div>
                  );
                })}
              </ProductCardContext.Provider>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex flex-col h-full gap-6 pb-body">
          <PricingCard />
          <WhyHSSlideshow />
        </div>
      </div>
    </div>
  );
};

export default Pricing;
