import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { generatePaths } from "./paths";

export function AppRoutes() {
  return (
    <RouterProvider
      // key added to re-render the provider and
      // make the navigation work on the pricing page
      key={window.location.pathname}
      router={createBrowserRouter(generatePaths())}
    />
  );
}
