import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Headline from "~/components/atoms/Headline/Headline";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import { Example } from "~/modules/example/example";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { hasCommonElements } from "~/utils/array.utils";

type ExamplesGridProps = {
  examples?: Example[];
};

const ExamplesGrid = ({ examples = [] }: ExamplesGridProps) => {
  const [selectedProducts, selectedBundle] = useCheckoutStore((state) => [
    state.selectedProducts,
    state.selectedBundle,
  ]);
  return (
    <div className="grid w-full grid-cols-4 gap-6 pb-10">
      {examples?.map((example, index) =>
        !example.exclusives.length ||
        hasCommonElements(
          example.exclusives,
          selectedProducts.map((product) => product.productCode)
        ) ||
        (selectedBundle &&
          example.exclusives.includes(selectedBundle.productCode)) ? (
          <Link
            key={index}
            to={example.slug}
            className="w-full aspect-[228/272]"
          >
            <motion.div
              whileTap={{ scale: 0.95 }}
              className="h-full flex cursor-pointer flex-col rounded-2xl bg-beige-100 py-4 px-5 bg-cover bg-center justify-end text-white relative"
            >
              <StoryblokOptimizedImage
                src={example.imageUrl}
                className="absolute top-0 left-0 w-full h-full object-cover object-center z-10 rounded-2xl"
              />
              <div className="absolute top-0 left-0 w-full h-full z-20 bg-example-card-normal-overlay rounded-2xl"></div>
              <Headline level={5} className="relative z-20">
                {example.title}
              </Headline>
            </motion.div>
          </Link>
        ) : null
      )}
    </div>
  );
};

export default ExamplesGrid;
