import { useState } from "react";
import PackageOfferCard from "~/components/organisms/PackageOfferCard/PackageOfferCard";
import SkincareProductsModal from "~/components/organisms/SkincareProductsModal/SkincareProductsModal";
import useSkinBundles from "~/hooks/useSkinBundles";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type TreatmentPackagesProductsProps = {
  hasSkinCare?: boolean;
  isEditable?: boolean;
};

const TreatmentPackageProducts = ({
  hasSkinCare,
  isEditable = true,
}: TreatmentPackagesProductsProps) => {
  const { selectedBundle } = useCheckoutStore();
  const {
    toggleSkinBundleAndHandleSkinCategory,
    bundles,
    highestPriceRecommendedBundle,
  } = useSkinBundles();

  const [isSkincareProductsModalOpen, setIsSkincareProductsModalOpen] =
    useState(false);

  return (
    <div className="grid grid-cols-3 gap-6 w-full overflow-y-auto pb-10">
      {bundles?.map((bundle) => (
        <PackageOfferCard
          onSelect={() => toggleSkinBundleAndHandleSkinCategory(bundle)}
          isSelected={selectedBundle?.productCode === bundle.productCode}
          isRecommended={highestPriceRecommendedBundle?.id === bundle.id}
          key={bundle.id}
          bundle={bundle}
          isSkinCareSelected={hasSkinCare}
          openSkinCareProducts={() => setIsSkincareProductsModalOpen(true)}
          disabled={!isEditable}
        />
      ))}
      <SkincareProductsModal
        open={isSkincareProductsModalOpen}
        setOpen={setIsSkincareProductsModalOpen}
      />
    </div>
  );
};

export default TreatmentPackageProducts;
