import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { SalesforceBundle } from "~/api/models/Bundle";
import { Product } from "~/stores/checkout/product";

export interface CheckoutStoreState {
  setSelectedProducts: (selectedProducts: Product[]) => void;
  setPaymentMethod: (paymentMethod: string) => void;
  paymentMethod: string | null;
  selectedProducts: Product[];
  selectedBundle: SalesforceBundle | null;
  hasSkinCare: boolean;
  referralCode: string | null;
  setReferralCode: (arg: string | null) => void;
  discountCode: string | null;
  setDiscountCode: (arg: string | null) => void;
  specialConditionsValues: string[] | null;
  otherSpecialConditions: string | null;
  toggleSkinCare: () => void;
  setSelectedBundle: (arg: SalesforceBundle | null) => void;
  setHasSkinCare: (arg: boolean) => void;
  setSpecialConditionsValues: (arg: string[]) => void;
  setOtherSpecialConditions: (arg: string) => void;
}

export const useCheckoutStore = create<CheckoutStoreState>()(
  persist(
    (set, get) => ({
      selectedBundle: null,
      setSelectedBundle: (bundle: SalesforceBundle | null) => {
        set({ selectedBundle: bundle });
      },
      setHasSkinCare: (arg: boolean) => {
        set({ hasSkinCare: arg });
      },
      setSelectedProducts: (selectedProducts: Product[]) => {
        set({ selectedProducts });
      },
      setPaymentMethod: (paymentMethod: string) => set({ paymentMethod }),
      toggleSkinCare: () => {
        const prev = get().hasSkinCare;
        set({
          hasSkinCare: !prev,
        });
      },
      setSpecialConditionsValues: (specialConditionsValues: string[]) => {
        set({ specialConditionsValues });
      },
      setOtherSpecialConditions: (otherSpecialConditions: string) => {
        set({ otherSpecialConditions });
      },
      setReferralCode: (referralCode: string | null) => {
        set({ referralCode: referralCode ?? null });
      },
      setDiscountCode: (discountCode: string | null) => {
        set({ discountCode: discountCode ?? null });
      },
      specialConditions: [],
      paymentMethod: null,
      selectedProducts: [],
      hasSkinCare: true,
      referralCode: null,
      discountCode: null,
      specialConditionsValues: null,
      otherSpecialConditions: null,
    }),
    {
      merge: (state, currentState) => {
        // This is needed as in the persisted state, the selectedBundle is a plain object and not an instance of SalesforceBundle
        const persistedState = state as CheckoutStoreState;
        const selectedBundle = persistedState.selectedBundle;

        currentState = { ...currentState, ...persistedState };

        currentState.selectedBundle = selectedBundle
          ? SalesforceBundle.fromJson(selectedBundle)
          : null;

        return currentState;
      },
      storage: createJSONStorage(() => sessionStorage),
      name: "checkout",
    }
  )
);
