import Copy from "~/components/atom/Copy";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { NumberFormatterService } from "~/services/numberFormatter";
import { PricingTextKeys } from "~/stores/checkout/paymentMethod";

type PricingTextProps = {
  amount: number;
  instalmentMonths: number;
  pricingText: PricingTextKeys;
  minimum: number;
};

export const PricingText = ({
  amount,
  instalmentMonths,
  pricingText,
  minimum,
}: PricingTextProps) => {
  const calculatedAmount = amount / instalmentMonths;

  return (
    <Subtitle level={2}>
      <Copy
        id={pricingText}
        values={{
          amount: NumberFormatterService.formatToCH(
            calculatedAmount < minimum ? minimum : calculatedAmount
          ),
        }}
      />
    </Subtitle>
  );
};
