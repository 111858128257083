import { useIntl } from "react-intl";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import FillerProductCard from "~/components/organisms/Pricing/FillerProductCard/FillerProductCard";
import useDefaultProduct from "~/hooks/useDefaultProduct";

const ChinFillerCard = () => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();
  const defaultProduct = useDefaultProduct(allProducts.skin.filler.chin);

  return (
    <FillerProductCard
      skinProductFamilyKey={SkinProductKey.chinFiller}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.chinFiller",
      })}
      options={allProducts.skin.filler.chin}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.chinFiller.none",
      })}
      modalTitle={intl.formatMessage({
        id: "pricing.chinFiller",
      })}
      isLoading={isLoading}
    />
  );
};

export default ChinFillerCard;
