import { FormattedMessage } from "react-intl";
import mobileFallback from "~/assets/mobileFallback.png";
import Headline from "~/components/atoms/Headline/Headline";

export const MOBILE_FALLBACK_MAX_WIDTH = 1000;

export const MobileFallBackScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-8">
      <div>
        <img
          className="object-contain w-full"
          src={mobileFallback}
          alt="Mobile fallback image"
        />
      </div>
      <div className="flex flex-col gap-2">
        <Headline className="text-center" level={1} visualLevel={4}>
          <FormattedMessage id="mobile-fallback.title" />
        </Headline>
        <p className="text-center text-grey-400">
          <FormattedMessage id="mobile-fallback.description" />
        </p>
      </div>
    </div>
  );
};
