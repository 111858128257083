import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { ProductCategoryDropdownOption } from "~/api/models/ProductCategory";

export interface SelectedProductCategoriesState {
  setSelectedProductCategories: (
    productCategories: ProductCategoryDropdownOption[]
  ) => void;
  toggleProductCategory: (
    productCategory: ProductCategoryDropdownOption
  ) => void;
  selectedProductCategories: ProductCategoryDropdownOption[];
}

export const useSelectedProductCategoriesStore =
  create<SelectedProductCategoriesState>()(
    persist(
      (set, get) => ({
        setSelectedProductCategories: (
          productCategories: ProductCategoryDropdownOption[]
        ) => set({ selectedProductCategories: productCategories }),
        toggleProductCategory: (
          productCategory: ProductCategoryDropdownOption
        ) => {
          const selected = get().selectedProductCategories;
          if (selected.find((p) => p.id === productCategory.id)) {
            set({
              selectedProductCategories: selected.filter(
                (selectedProduct) => selectedProduct.id !== productCategory.id
              ),
            });
          } else {
            set({
              selectedProductCategories: [...selected, productCategory],
            });
          }
        },
        selectedProductCategories: [],
      }),
      {
        storage: createJSONStorage(() => sessionStorage),
        name: "selectedProductCategories",
      }
    )
  );

export default useSelectedProductCategoriesStore;
