import { useState, useEffect, RefObject } from "react";
import { throttle } from "lodash";

interface Position {
  top: number;
  left: number;
  right: number;
  bottom: number;
  toRight: number;
}

const useElementPosition = (
  elementRef: RefObject<HTMLElement>,
  throttleTime = 100
): Position => {
  const [position, setPosition] = useState<Position>({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    toRight: 0,
  });

  useEffect(() => {
    const updatePosition = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left,
          right: rect.right,
          bottom: rect.bottom,
          toRight: window.innerWidth - rect.left - rect.width,
        });
      }
    };

    const throttledUpdate = throttle(updatePosition, throttleTime);

    window.addEventListener("resize", throttledUpdate);
    throttledUpdate();

    return () => {
      window.removeEventListener("resize", throttledUpdate);
      throttledUpdate.cancel();
    };
  }, [elementRef, throttleTime]);

  return position;
};

export default useElementPosition;
