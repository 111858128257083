import { useMemo } from "react";
import { useIntl } from "react-intl";
import { HairProductKey } from "~/api/models/HairProductKey";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import Copy from "~/components/atom/Copy";
import Card from "~/components/atoms/Card/Card";
import Headline from "~/components/atoms/Headline/Headline";
import Pill from "~/components/atoms/Pill/Pill";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { productCardBuilder } from "~/components/organisms/ProductCards/ProductCards";
import { ProductCardContext } from "~/hooks/useProductCardContext";
import { cn } from "~/utils";

type SummaryTreatmentsProps = {
  discountedAmount?: number;
  totalAmount?: number;
  discountTotal?: number;
  selectedProductsKeys?: (SkinProductKey | HairProductKey)[];
};

const SummaryTreatments = ({
  discountedAmount,
  totalAmount,
  discountTotal,
  selectedProductsKeys,
}: SummaryTreatmentsProps) => {
  const intl = useIntl();
  const hasDiscountedAmount = useMemo(() => {
    return discountedAmount !== totalAmount;
  }, [discountedAmount, totalAmount]);

  return (
    <Card className="flex flex-col gap-4">
      <Subtitle level={1} className="text-grey-400 capitalize">
        <Copy id="summary.cards.treatments.title" />
      </Subtitle>
      <ProductCardContext.Provider
        value={{ isSuggested: false, isSummaryCard: true }}
      >
        {selectedProductsKeys?.map((productKey) => {
          return (
            <div key={productKey}>{productCardBuilder({ productKey })}</div>
          );
        })}
      </ProductCardContext.Provider>
      <div className="flex justify-between pt-5 border-t border-grey-50">
        <Headline level={6} className="font-normal">
          <Copy id="pricing.card.total" />
        </Headline>
        <div className="flex gap-2 items-center">
          <Subtitle
            className={cn({
              "text-grey-400 line-through": hasDiscountedAmount,
            })}
          >
            {intl.formatMessage(
              { id: "pricing.amount" },
              { amount: totalAmount ?? 0 }
            )}
          </Subtitle>
          {hasDiscountedAmount && (
            <Subtitle className="text-grey-700">
              {intl.formatMessage(
                { id: "pricing.amount" },
                { amount: discountedAmount }
              )}
            </Subtitle>
          )}
          {hasDiscountedAmount && (
            <Pill className="bg-green-100 py-1 px-2">
              <Subtitle level={4} className="font-bold">
                {intl.formatMessage(
                  { id: "pricing.amount.discount" },
                  { amount: discountTotal ?? 0 }
                )}
              </Subtitle>
            </Pill>
          )}
        </div>
      </div>
    </Card>
  );
};

export default SummaryTreatments;
