import Step from "./Step";

type StepperProps = {
  stepCount: number;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  secondsPerStep?: number;
  stopTimer?: boolean;
};

const SECONDS_PER_STEP = 10;

const Stepper = ({
  stepCount,
  currentStep,
  setCurrentStep,
  secondsPerStep = SECONDS_PER_STEP,
  stopTimer,
}: StepperProps) => {
  return (
    <div className="flex w-full">
      {Array.from({ length: stepCount }, (_, index) => index + 1).map(
        (stepNumber) => (
          <Step
            secondsPerStep={secondsPerStep}
            key={stepNumber}
            stepNumber={stepNumber}
            isActivated={stepNumber - 1 === currentStep}
            onClick={() => setCurrentStep(stepNumber)}
            stepCount={stepCount}
            setCurrentStep={setCurrentStep}
            stopTimer={stopTimer}
          />
        )
      )}
    </div>
  );
};

export default Stepper;
