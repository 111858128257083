export type PublicDiscountDto = {
  id: string;
  code: string;
  name: string;
  is_active: boolean;
};
export class PublicDiscount {
  id: string;
  value: string;
  title: string;
  disabled?: boolean;

  constructor(dto: PublicDiscountDto) {
    this.id = dto.id;
    this.value = dto.code;
    this.title = dto.name;
    this.disabled = !dto.is_active;
  }
}
