import { useEffect } from "react";
import { UseInitializeProductsArgs } from "~/hooks/useInitializeProducts";
import useOpportunityProducts from "~/hooks/useOpportunityProducts";
import useSkinProducts from "~/hooks/useSkinProducts";
import useTreatmentType from "~/hooks/useTreatmentType";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import useConfigurationSectionStore from "~/stores/headConfiguration";
import { SkinSection } from "~/stores/headConfiguration/ConfigurationSection.types";

type UseInitializeSkinProductsArgs = UseInitializeProductsArgs;

const useInitializeSkinProducts = ({
  opportunity,
  loadingOpportunity,
}: UseInitializeSkinProductsArgs) => {
  const { skinSelected } = useTreatmentType();
  const [setSkinSections, skinSections] = useConfigurationSectionStore(
    (state) => [state.setSkinSections, state.skinSections]
  );
  const [selectedProducts, setSelectedProducts, setHasSkinCare] =
    useCheckoutStore((state) => [
      state.selectedProducts,
      state.setSelectedProducts,
      state.setHasSkinCare,
    ]);
  const { skinOpportunityProducts, isLoading: isOpportunityProductsLoading } =
    useOpportunityProducts(opportunity);
  const {
    hasSkinProducts,
    removeSkinProducts,
    setSkinBundleAndHandleSkinCategory,
    bundles,
    isSkinBundlesLoading,
  } = useSkinProducts();

  useEffect(() => {
    if (!loadingOpportunity && !skinSelected && hasSkinProducts) {
      removeSkinProducts();
    }
  }, [loadingOpportunity, hasSkinProducts, removeSkinProducts, skinSelected]);

  // only run when page is first loaded and no skin products are selected
  // => for a new tab
  useEffect(() => {
    if (!loadingOpportunity) {
      if (
        opportunity &&
        opportunity.skinConfiguration.length > 0 &&
        skinSections.length === 0
      ) {
        setSkinSections(opportunity.skinConfiguration as SkinSection[]);
      }
      if (
        !hasSkinProducts &&
        !isSkinBundlesLoading &&
        !isOpportunityProductsLoading
      ) {
        if (opportunity && opportunity?.getBundleId()) {
          setSkinBundleAndHandleSkinCategory(
            bundles?.find((b) => b.id === opportunity?.getBundleId()) ?? null
          );
        }

        if (
          skinSelected &&
          !hasSkinProducts &&
          !isSkinBundlesLoading &&
          !isOpportunityProductsLoading
        ) {
          if (opportunity && opportunity?.getBundleId()) {
            setSkinBundleAndHandleSkinCategory(
              bundles?.find((b) => b.id === opportunity?.getBundleId()) ?? null
            );
          }

          const opportunitySkinCare = opportunity?.hasSkinCare();
          const hasSkinCare =
            opportunitySkinCare !== undefined ? opportunitySkinCare : true;
          setHasSkinCare(hasSkinCare);

          if (skinOpportunityProducts.length > 0) {
            setSelectedProducts([
              ...selectedProducts,
              ...skinOpportunityProducts,
            ]);
          }
        }
      }
    }
  }, [isOpportunityProductsLoading, isSkinBundlesLoading, loadingOpportunity]);
};

export default useInitializeSkinProducts;
