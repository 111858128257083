import * as Sentry from "@sentry/react";
import { SalesforceBundleDTO } from "~/api/models/Bundle";
import { ClinicLocationDTO } from "~/api/models/ClinicLocation";
import {
  ContractDto,
  ContractRequestBody,
  ContractRequestBodyDto,
  ContractRequestDto,
  ContractResponseDto,
} from "~/api/models/Contract";
import { PublicDiscountDto } from "~/api/models/DiscountCode";
import { MedicalHistoryDTO } from "~/api/models/MedicalHistory";
import { OpportunityDTO, UpdateOpportunityDTO } from "~/api/models/Opportunity";
import {
  CalculatePrice,
  CalculatePriceDto,
  PricingBreakdownDto,
} from "~/api/models/PriceCalculator";
import { RecentScansDto } from "~/api/models/ScanFile";
import { UploadScanBodyDto } from "~/api/models/ScanFile";
import { SpecialCondition } from "~/api/models/SpecialCondition";
import { ProductsDTO } from "~/api/salesForce/useFetchProducts";
import { UpdateMedicalHistoryDto } from "~/api/salesForce/useUpdateMedicalHistory";
import { ConsultationDocumentDTO } from "~/modules/consultation-document/consultation-document";
import { CustomError } from "~/services/CustomError";
import {
  INVALID_PUBLIC_DISCOUNT_CODE_ERROR_NAME,
  INVALID_REFERRAL_CODE_ERROR_NAME,
} from "~/services/errors.const";
import {
  FetchApiParams,
  OAuthGrantType,
  OptionalReturn,
} from "~/services/salesForce/salesForce.types";
import { PaymentMethodDTO } from "~/stores/checkout/paymentMethod";
import { AppLocale } from "~/utils/types";

const SALESFORCE_API_VERSION = "v1";
const SALESFORCE_PRICE_CALCULATOR_API_VERSION = "v2";

const fetchApi = async <T = undefined>({
  method,
  url,
  headers = {},
  data,
  isFormData,
  accessToken,
  language,
  handleErrorBoundary,
}: FetchApiParams): Promise<OptionalReturn<T>> => {
  const defaultHeaders: Record<string, string> = isFormData
    ? {}
    : {
        "Content-Type": "application/json",
      };

  if (accessToken) {
    defaultHeaders["Authorization"] = `Bearer ${accessToken ?? ""}`;
  }

  if (language) {
    defaultHeaders["Accept-Language"] = language;
  }

  const formattedData = () => {
    if (!data) return;

    if (isFormData) return data as FormData;

    return JSON.stringify(data);
  };

  const response = await fetch(url, {
    method: method,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    body: formattedData(),
  });

  if (response.status === 404) {
    return null as OptionalReturn<T>;
  }

  if (!response.ok) {
    const responseText = await response.text();

    const error = new CustomError({
      message: `salesForceApi Error: ${response.status} ${responseText ? responseText : ""} ${response.url}`,
      options: {
        cause: response.status,
      },
      hasErrorBoundary: handleErrorBoundary
        ? handleErrorBoundary(response, responseText)
        : true,
    });
    Sentry.captureException(error);
    throw error;
  }

  const isFileResponse =
    response.headers.get("Content-Type")?.startsWith("image") ||
    response.headers.get("Content-Type")?.startsWith("application/pdf");

  if (isFileResponse) {
    return (await response.blob()) as OptionalReturn<T>;
  }

  const responseText = await response.text();
  if (responseText) {
    const res = JSON.parse(responseText);
    if (res?.error) {
      const error = new CustomError({
        message: `salesForceApi Error: ${response.status} ${responseText ? responseText : ""} ${response.url}`,
        options: {
          cause: response.status,
        },
        hasErrorBoundary: handleErrorBoundary
          ? handleErrorBoundary(response, responseText)
          : true,
      });
      Sentry.captureException(error);
      console.error(error);
      throw error;
    }
    return res;
  }
  return null as OptionalReturn<T>;
};

export const salesforceEndpoints = {
  loggedInUser: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/user/account`,
  paymentMethods: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/payment-methods`,
  discounts: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/discounts`,
  locations: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }${SALESFORCE_API_VERSION}/locations`,
  opportunity: (id: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${id}`,
  products: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/products`,
  bundles: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/bundles`,
  opportunityMedicalHistory: (id: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${id}/medical-history`,
  consultationDocuments: (id: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${id}/consultation-documents`,
  uploadPhoto: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/document`,
  file: (id: string, versionId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/document/${id}/version/${versionId}`,
  calculatePrice: (id: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_PRICE_CALCULATOR_API_VERSION}/opportunity/${id}/price-calculator`,
  requestContract: (opportunityId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${opportunityId}/contract`,
  contractByRequest: (requestId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/contract/request/${requestId}`,
  contract: (contractId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/contract/${contractId}`,
  updateOpportunity: (opportunityId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${opportunityId}`,
  recentScans: (opportunityId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${opportunityId}/scans/recent`,
  specialConditions: ` 
    ${import.meta.env.VITE_SALESFORCE_API_URL}sales/${SALESFORCE_API_VERSION}/contract/special-conditions`,
  scanUpload: (opportunityId: string) =>
    `${
      import.meta.env.VITE_SALESFORCE_API_URL
    }sales/${SALESFORCE_API_VERSION}/opportunity/${opportunityId}/scans/upload`,
  publicDiscounts: `${
    import.meta.env.VITE_SALESFORCE_API_URL
  }sales/${SALESFORCE_API_VERSION}/public-discounts`,
  auth: {
    auth: (redirectUri: string) =>
      `${import.meta.env.VITE_SALESFORCE_OAUTH_URL}?origin=${redirectUri}`,
    token: (grantType: OAuthGrantType, code: string) =>
      `${
        import.meta.env.VITE_SALESFORCE_API_URL
      }auth/token?grant_type=${grantType}&${
        grantType === "authorization_code" ? `code=${code}` : `token=${code}`
      }`,
    uploadToken: (treatmentEventId: string, opportunityId: string) =>
      `${
        import.meta.env.VITE_SALESFORCE_API_URL
      }auth/upload-token?treatment_event_id=${treatmentEventId}&opportunity_id=${opportunityId}`,
  },
};

export const salesforceService = () => {
  return {
    getAccessToken: async (
      code: string,
      grantType: OAuthGrantType,
      locale: AppLocale
    ) => {
      return fetchApi<{
        access_token: string;
        refresh_token: string;
        expires_at: number;
      }>({
        method: "GET",
        url: salesforceEndpoints.auth.token(grantType, code),
        language: locale,
      });
    },
    getUploadToken: async (
      accessToken: string,
      treatmentEventId: string,
      opportunityId: string,
      locale: AppLocale
    ) => {
      return fetchApi<{
        token: string;
        expires_at: number;
      }>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.auth.uploadToken(
          treatmentEventId,
          opportunityId
        ),
        language: locale,
      });
    },
    getLoggedInUser: async (accessToken: string, locale: AppLocale) => {
      return fetchApi<{ id: string; display_name: string; email: string }>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.loggedInUser,
        language: locale,
      });
    },
    getOpportunity: async (
      accessToken: string,
      id: string,
      locale: AppLocale
    ) => {
      return fetchApi<OpportunityDTO>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.opportunity(id),
        language: locale,
        handleErrorBoundary: () => false,
      });
    },
    getOpportunityMedicalHistory: async (
      accessToken: string,
      id: string,
      locale: AppLocale
    ) => {
      return fetchApi<MedicalHistoryDTO>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.opportunityMedicalHistory(id),
        language: locale,
      });
    },
    getPaymentMethods: async (accessToken: string, locale: AppLocale) => {
      return fetchApi<PaymentMethodDTO[]>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.paymentMethods,
        language: locale,
      });
    },
    updateOpportunityMedicalHistory: async (
      accessToken: string,
      id: string,
      data: UpdateMedicalHistoryDto,
      locale: AppLocale
    ) => {
      return fetchApi({
        accessToken,
        method: "PUT",
        url: salesforceEndpoints.opportunityMedicalHistory(id),
        data: data,
        language: locale,
      });
    },
    getConsultationDocuments: async (
      accessToken: string,
      id: string,
      locale: AppLocale
    ) => {
      return fetchApi<{
        treatment_event_id?: string;
        consultation_documents?: ConsultationDocumentDTO[];
      }>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.consultationDocuments(id),
        language: locale,
      });
    },
    getFile: async (
      accessToken: string,
      id: string,
      versionId: string,
      locale: AppLocale
    ) => {
      return fetchApi<Blob>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.file(id, versionId),
        language: locale,
      });
    },
    uploadDocument: async (
      uploadToken: string,
      data: {
        title: string;
        document: File;
        opportunity_id: string;
      },
      locale: AppLocale
    ) => {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("document", data.document);

      return fetchApi({
        method: "POST",
        accessToken: uploadToken,
        data: formData,
        isFormData: true,
        url: salesforceEndpoints.uploadPhoto,
        language: locale,
      });
    },
    getProducts: async (accessToken: string, locale: AppLocale) => {
      return fetchApi<ProductsDTO[]>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.products,
        language: locale,
      });
    },
    getBundles: async (
      accessToken: string,
      locale: AppLocale
    ): Promise<SalesforceBundleDTO[]> => {
      return fetchApi<SalesforceBundleDTO[]>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.bundles,
        language: locale,
      });
    },
    calculatePricing: async (
      accessToken: string,
      id: string,
      data: CalculatePrice,
      locale: AppLocale
    ) => {
      const dto: CalculatePriceDto = {
        products: data.products.map((product) => product.id),
        bundles: data.selectedBundle ? [data.selectedBundle.id] : [],
        exclude_bundle_products_ids: data.selectedBundle
          ? data.selectedBundle
              .getExcludedProducts(data.hasSkinCare)
              .map((product) => product.id)
          : [],
        payment_method: data.paymentMethod,
        discount_code: data.discountCode,
        referral_code: data.referralCode,
        special_conditions: data.specialConditions,
        other_special_conditions: data.otherSpecialConditions,
      };
      return fetchApi<PricingBreakdownDto>({
        accessToken,
        method: "POST",
        url: salesforceEndpoints.calculatePrice(id),
        data: dto,
        language: locale,
        handleErrorBoundary: (response, responseText) => {
          if (
            responseText.includes(INVALID_PUBLIC_DISCOUNT_CODE_ERROR_NAME) ||
            responseText.includes(INVALID_REFERRAL_CODE_ERROR_NAME)
          ) {
            return false;
          }
          return true;
        },
      });
    },
    requestContract: async (
      accessToken: string,
      opportunityId: string,
      data: ContractRequestBody,
      locale: AppLocale
    ) => {
      const dto: ContractRequestBodyDto = {
        products: data.products.map((product) => product.id),
        payment_method: data.paymentMethod,
        bundles: data.bundle ? [data.bundle.id] : [],
        special_conditions: data.specialConditions,
        other_special_conditions: data.otherSpecialConditions,
        discount_code: data.discountCode,
        referral_code: data.referralCode,
      };
      return fetchApi<ContractRequestDto>({
        accessToken,
        method: "POST",
        url: salesforceEndpoints.requestContract(opportunityId),
        data: dto,
        language: locale,
      });
    },
    getContractByRequest: async (
      accessToken: string,
      requestId: string,
      locale: AppLocale
    ) => {
      return fetchApi<ContractResponseDto>({
        accessToken,
        method: "GET",
        url: salesforceEndpoints.contractByRequest(requestId),
        language: locale,
      });
    },
    getContract: async (
      accessToken: string,
      contractId: string,
      locale: AppLocale
    ) => {
      return fetchApi<ContractDto>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.contract(contractId),
        language: locale,
      });
    },
    updateOpportunity: async (
      accessToken: string,
      opportunityId: string,
      data: UpdateOpportunityDTO,
      locale: AppLocale
    ) => {
      return fetchApi({
        method: "PATCH",
        accessToken: accessToken,
        data: data,
        url: salesforceEndpoints.updateOpportunity(opportunityId),
        language: locale,
      });
    },
    getLocations: async (accessToken: string, locale: AppLocale) => {
      return fetchApi<ClinicLocationDTO[]>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.locations,
        language: locale,
      });
    },
    getRecentScans: async (
      accessToken: string,
      opportunityId: string,
      locale: AppLocale
    ) => {
      try {
        return fetchApi<RecentScansDto>({
          method: "GET",
          accessToken,
          url: salesforceEndpoints.recentScans(opportunityId),
          language: locale,
          handleErrorBoundary: (response) => {
            if (response.status === 400) {
              return false;
            }
            return true;
          },
        });
      } catch (error) {
        Sentry.captureException(error);

        return null;
      }
    },
    getSpecialConditions: async (accessToken: string, locale: AppLocale) => {
      return fetchApi<SpecialCondition[]>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.specialConditions,
        language: locale,
      });
    },
    uploadScan: async (
      accessToken: string,
      opportunityId: string,
      data: UploadScanBodyDto,
      locale: AppLocale
    ) => {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("document", data.document);
      formData.append("document_type", data.document_type);

      return fetchApi({
        method: "POST",
        accessToken,
        data: formData,
        isFormData: true,
        url: salesforceEndpoints.scanUpload(opportunityId),
        language: locale,
        handleErrorBoundary: () => {
          return false;
        },
      });
    },
    getPublicDiscounts: async (accessToken: string, locale: AppLocale) => {
      return fetchApi<PublicDiscountDto[]>({
        method: "GET",
        accessToken,
        url: salesforceEndpoints.publicDiscounts,
        language: locale,
      });
    },
  };
};

export const salesforceServiceSingleton = salesforceService();
