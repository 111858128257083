import { useSearchParams } from "react-router-dom";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import ScreenLoader from "~/components/organisms/ScreenLoader/ScreenLoader";

export const OAuth = () => {
  const [searchParams] = useSearchParams();

  const { isLoading } = useFetchAccessToken(searchParams.get("code"));

  if (isLoading) return <ScreenLoader />;

  return null;
};
