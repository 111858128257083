import { PropsWithChildren } from "react";
import Pill from "~/components/atoms/Pill/Pill";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type BadgeProps = PropsWithChildren<PropsWithClassName>;

const Badge = ({ className, children }: BadgeProps) => {
  return (
    <Pill className={cn("py-1 px-3 text-2xs text-white", className)}>
      {children}
    </Pill>
  );
};

export default Badge;
