import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router-dom";
import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/atoms/Dialog/Dialog";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import { Edit } from "~/components/icons/Edit";
import TabsToggle from "~/components/molecules/TabsToggle/TabsToggle";
import { Header } from "~/components/organisms/layout/Header/Header";
import { UploadedPhoto } from "~/modules/consultation-document/documentPicture";
import { Routes } from "~/routes/routes";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { PhotoList } from "./PhotoList/PhotoList";
import { ScanQRCodeScreen } from "./ScanQRCodeScreen";
import { DEFAULT_HAIR_FAMILIES, DEFAULT_SKIN_FAMILIES } from "./photos.const";

type PhotosScreen = {
  isLoading: boolean;
  photos: UploadedPhoto[];
  productCategory: "hair" | "skin";
  isProductItemCategoryKeySelected: (
    productItemCategoryKey: ProductItemCategoryKey
  ) => boolean;
  selectedCategories: ProductItemCategoryKey[];
  uploadToken?: string;
};

const PhotosScreen = ({
  photos,
  isLoading,
  productCategory,
  isProductItemCategoryKeySelected,
  selectedCategories,
  uploadToken,
}: PhotosScreen) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const origin = window.location.origin;
  const { locale } = useLocaleStore();

  const showEditModal = () => setOpen(true);
  const activeCategories = useMemo(() => {
    return (
      productCategory === "hair" ? DEFAULT_HAIR_FAMILIES : DEFAULT_SKIN_FAMILIES
    )?.filter((category) => isProductItemCategoryKeySelected(category.key));
  }, [isProductItemCategoryKeySelected, productCategory]);

  const [activeTab, setActiveTab] = useState(
    activeCategories.length > 0 ? activeCategories?.[0]?.key : undefined
  );
  const activePhotos = useMemo(
    () => photos.filter((photo) => photo.subCategory === activeTab),

    [photos, activeTab]
  );
  const uploadUrl = useMemo(() => {
    return `${origin}${generatePath(Routes.UploadOverview, {
      opportunityId: opportunityId as string,
    })}?upload_token=${uploadToken}&locale=${locale}&category=${productCategory}&selected_categories=${selectedCategories.join(",")}`;
  }, [
    opportunityId,
    uploadToken,
    locale,
    selectedCategories,
    origin,
    productCategory,
  ]);

  return (
    <>
      <div className="flex flex-col gap-header p-body h-full relative">
        <Header title={intl.formatMessage({ id: "photos.title" })} />
        {(isLoading || loadingOpportunity) && photos.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <SpinnerIcon className="text-3xl" />
          </div>
        ) : photos.length > 0 ? (
          <>
            <div className="flex items-center justify-between">
              <TabsToggle
                options={activeCategories.map((family) => {
                  return {
                    ...family,
                    value: intl.formatMessage({
                      id: family.name,
                    }),
                    counter: photos.filter(
                      (photo) => photo.subCategory === family.key
                    ).length,
                  };
                })}
                activeOption={activeTab}
                setActiveOption={setActiveTab}
                className="w-full justify-between grid max-w-fit"
                style={{
                  gridTemplateColumns: `repeat(${activeCategories.length}, minmax(0, 1fr))`,
                }}
                tabClassName="px-4"
              />
              {opportunity?.isValidForSales && (
                <div className="flex justify-end backdrop-blur-sm bg-white/80">
                  <Button
                    variant={ButtonVariant.secondaryOutline}
                    size={ButtonSize.sm}
                    onClick={showEditModal}
                  >
                    <Edit />
                    <Copy id="photos.edit.button" />
                  </Button>
                </div>
              )}
            </div>
            <PhotoList key={activePhotos.length} photos={activePhotos} />
          </>
        ) : (
          uploadUrl &&
          opportunity?.isValidForSales && (
            <ScanQRCodeScreen
              uploadUrl={uploadUrl}
              titleCopyId="photos.wait.copy-title"
              descriptionCopyId="photos.wait.copy-description"
            />
          )
        )}
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="min-w-pricing-modal min-h-pricing-modal flex flex-col justify-between">
          <div className="flex flex-col gap-6 ">
            <DialogHeader>
              <DialogTitle className="font-normal">
                <Copy id="photos.edit.modal.title" />
              </DialogTitle>
              <DialogClose />
            </DialogHeader>
            {uploadUrl && (
              <ScanQRCodeScreen
                uploadUrl={uploadUrl}
                descriptionCopyId="photos.edit.modal.description"
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default PhotosScreen;
