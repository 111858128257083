import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Headline from "~/components/atoms/Headline/Headline";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";
import { ITEM_HEIGHT_REM } from "./SearchDropdown";

type SearchDropdownOptionProps = PropsWithClassName<{
  isSelected: boolean;
  disabled?: boolean;
  onClick?: () => void;
  customOption?: React.ReactNode;
  title?: string;
  id?: string;
}>;

const SearchDropdownOption = ({
  isSelected,
  disabled,
  onClick,
  customOption,
  title,
  id,
  className,
}: SearchDropdownOptionProps) => {
  return (
    <button
      id={isSelected ? "selected" : id}
      className={cn(
        "py-3 box-border px-4 flex gap-3 items-center text-left outline-black hover:bg-grey-100",
        {
          "cursor-auto text-grey-300": disabled,
        },
        className
      )}
      style={{ height: `${ITEM_HEIGHT_REM}rem` }}
      onClick={onClick}
    >
      <div className="w-full">
        {customOption ? customOption : <Headline level={6}>{title}</Headline>}
      </div>
      {isSelected && <FontAwesomeIcon icon={faCheck} className="h-4 w-4 p-1" />}
    </button>
  );
};

export default SearchDropdownOption;
