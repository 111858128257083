import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { StoryblokAsset } from "~/services/storyblok";

type PlanCategoryCardProps = {
  title: string;
  description: string;
  image: StoryblokAsset;
};

const PlanCategoryCard = ({
  title,
  description,
  image,
}: PlanCategoryCardProps) => {
  return (
    <div className="bg-grey-100 rounded-2xl flex flex-col h-full">
      <img
        src={image.filename}
        alt={image.alt}
        className="h-[25rem] min-h-[70%] w-full object-cover object-center rounded-t-2xl shrink-1"
      />
      <div className="px-6 py-5 flex flex-col gap-3 grow shrink-0 ">
        <Subtitle className="text-grey-400">{title}</Subtitle>
        <Headline level={5}>{description}</Headline>
      </div>
    </div>
  );
};

export default PlanCategoryCard;
