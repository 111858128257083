import { useEffect } from "react";
import { ProductCategoryDropdownOption } from "~/api/models/ProductCategory";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import useDefaultProduct from "~/hooks/useDefaultProduct";
import useHairProducts from "~/hooks/useHairProducts";
import { UseInitializeProductsArgs } from "~/hooks/useInitializeProducts";
import useOpportunityProducts from "~/hooks/useOpportunityProducts";
import useTreatmentType from "~/hooks/useTreatmentType";
import { Product } from "~/stores/checkout/product";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

const getNewSelectedProducts = (params: {
  currentSelectedProducts: Product[];
  htSelected: ProductCategoryDropdownOption | undefined;
  allProducts: ReturnType<typeof useFetchProducts>["data"];
  transplantationProduct: Product | undefined;
  prpSelected: ProductCategoryDropdownOption | undefined;
  prpProduct: Product | undefined;
  freePrpProduct: Product | undefined;
  transplantOpportunityProduct: Product | undefined;
  prpOpportunityProduct: Product | undefined;
  freePrpOpportunityProduct: Product | undefined;
  transplantationDefaultProduct: Product | undefined;
  prpDefaultProduct: Product | undefined;
  bundleDefaultProduct: Product | undefined;
  eyebrowSelected: ProductCategoryDropdownOption | undefined;
  eyebrowProduct: Product | undefined;
  eyebrowOpportunityProduct: Product | undefined;
  eyebrowDefaultProduct: Product | undefined;
  beardSelected: ProductCategoryDropdownOption | undefined;
  beardProduct: Product | undefined;
  beardOpportunityProduct: Product | undefined;
  beardDefaultProduct: Product | undefined;
}) => {
  let products = params.currentSelectedProducts;
  const transplantationProduct = params.transplantationProduct;
  const transplantOpportunityProduct = params.transplantOpportunityProduct;
  const transplantationDefaultProduct = params.transplantationDefaultProduct;
  const prpProduct = params.prpProduct;
  const freePrpProduct = params.freePrpProduct;
  const eyebrowProduct = params.eyebrowProduct;
  const beardProduct = params.beardProduct;
  const freePrpOpportunityProduct = params.freePrpOpportunityProduct;

  if (
    params.htSelected &&
    params.allProducts?.hair.head.transplantation.short &&
    !transplantationProduct
  ) {
    if (transplantOpportunityProduct || transplantationDefaultProduct) {
      products.push(
        transplantOpportunityProduct ?? transplantationDefaultProduct!
      );
    }
  } else if (!params.htSelected && transplantationProduct) {
    // remove product from products list
    products = products.filter((v) => v.id !== transplantationProduct.id);
  }

  if (params.prpSelected && !prpProduct) {
    if (params.prpOpportunityProduct) {
      products.push(params.prpOpportunityProduct);
    } else {
      if (!params.htSelected && params.prpDefaultProduct) {
        products.push(params.prpDefaultProduct);
      } else if (params.htSelected && params.bundleDefaultProduct) {
        products.push(params.bundleDefaultProduct);
      }
    }
  } else if (!params.prpSelected && prpProduct) {
    products = products.filter((v) => v.id !== prpProduct.id);
  } else if (
    params.htSelected &&
    params.prpSelected &&
    // check it's selected because of prp not a free prp
    prpProduct &&
    prpProduct?.type !== "bundle" &&
    params.bundleDefaultProduct
  ) {
    //remove old prp product
    products = products.filter((v) => v.id !== prpProduct?.id);
    //push new
    products.push(
      params.allProducts.hair.head.bundle?.find(
        (v) => v.sessions === prpProduct?.sessions
      ) ?? params.bundleDefaultProduct
    );
  } else if (
    params.prpSelected &&
    !params.htSelected &&
    // check it's selected because of prp not a free prp
    prpProduct &&
    prpProduct?.type === "bundle" &&
    params.prpDefaultProduct
  ) {
    //remove old prp product
    products = products.filter((v) => v.id !== prpProduct?.id);
    // push new
    products.push(
      params.allProducts.hair.head.prp?.find(
        (v) => v.sessions === prpProduct?.sessions
      ) ?? params.prpDefaultProduct
    );
  }

  if (params.prpSelected && freePrpOpportunityProduct) {
    products.push(freePrpOpportunityProduct);
  } else if (!params.prpSelected && freePrpProduct) {
    products = products.filter((v) => v.id !== freePrpProduct.id);
  }

  if (
    params.eyebrowSelected &&
    params.eyebrowDefaultProduct &&
    !eyebrowProduct
  ) {
    products.push(
      params.eyebrowOpportunityProduct ?? params.eyebrowDefaultProduct
    );
  } else if (!params.eyebrowSelected && eyebrowProduct) {
    products = products.filter((v) => v.id !== eyebrowProduct.id);
  }

  if (params.beardSelected && params.beardDefaultProduct && !beardProduct) {
    products.push(params.beardOpportunityProduct ?? params.beardDefaultProduct);
  } else if (!params.beardSelected && beardProduct) {
    products = products.filter((v) => v.id !== beardProduct.id);
  }

  return products;
};

type UseInitializeHairProductsArgs = UseInitializeProductsArgs;

const useInitializeHairProducts = ({
  loadingOpportunity,
  opportunity,
}: UseInitializeHairProductsArgs) => {
  const [selectedProducts, setSelectedProducts] = useCheckoutStore((state) => [
    state.selectedProducts,
    state.setSelectedProducts,
  ]);
  const { htSelected, prpSelected, eyebrowSelected, beardSelected } =
    useTreatmentType();
  const { data: allProducts, isLoading } = useFetchProducts();
  const {
    prpOpportunityProduct,
    beardOpportunityProduct,
    eyebrowOpportunityProduct,
    transplantOpportunityProduct,
    freePrpOpportunityProduct,
  } = useOpportunityProducts(opportunity);
  const {
    transplantationProduct,
    prpProduct,
    eyebrowProduct,
    beardProduct,
    freePrpProduct,
  } = useHairProducts();

  const [
    prpDefaultProduct,
    bundleDefaultProduct,
    transplantationDefaultProduct,
    eyebrowDefaultProduct,
    beardDefaultProduct,
  ] = [
    useDefaultProduct(allProducts?.hair.head.prp),
    useDefaultProduct(allProducts?.hair.head.bundle),
    useDefaultProduct(allProducts?.hair.head.transplantation.short),
    useDefaultProduct(allProducts?.hair.eyebrow.transplantation),
    useDefaultProduct(allProducts?.hair.beard.transplantation),
  ];

  useEffect(() => {
    if (!isLoading && !loadingOpportunity) {
      const products = getNewSelectedProducts({
        currentSelectedProducts: selectedProducts,
        htSelected,
        allProducts,
        transplantationProduct,
        prpSelected,
        prpProduct,
        freePrpProduct,
        transplantOpportunityProduct,
        prpOpportunityProduct,
        freePrpOpportunityProduct,
        transplantationDefaultProduct,
        prpDefaultProduct,
        bundleDefaultProduct,
        eyebrowSelected,
        eyebrowProduct,
        eyebrowOpportunityProduct,
        eyebrowDefaultProduct,
        beardSelected,
        beardProduct,
        beardOpportunityProduct,
        beardDefaultProduct,
      });
      setSelectedProducts(products);
    }
  }, [
    isLoading,
    loadingOpportunity,
    htSelected,
    prpSelected,
    eyebrowSelected,
    beardSelected,
  ]);
};

export default useInitializeHairProducts;
