import { UploadedPhoto } from "~/modules/consultation-document/documentPicture";

const Photo = ({ photo }: { photo: UploadedPhoto }) => {
  return (
    <div className="h-80 w-full bg-beige-100 rounded-2xl border border-grey-50 flex-col overflow-hidden flex items-center justify-center relative">
      {photo?.title ? (
        <span className="absolute top-0 left-0 bg-white rounded-full border  border-grey-50 px-3 py-1 text-sm m-4">
          {photo.title}
        </span>
      ) : null}
      {photo?.src ? (
        <img
          src={photo.src}
          className="w-full h-full aspect-[228/320] object-cover"
        />
      ) : null}
    </div>
  );
};

export default Photo;
