import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { SalesforceBundle } from "~/api/models/Bundle";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchBundleForCode } from "~/api/storyblok/useFetchBundleForCode";
import Copy from "~/components/atom/Copy";
import Badge from "~/components/atoms/Badge/Badge";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Headline from "~/components/atoms/Headline/Headline";
import InfoButton from "~/components/atoms/InfoButton/InfoButton";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { cn } from "~/utils";

type PackageOfferCard = {
  bundle: SalesforceBundle;
  onSelect: () => void;
  isSelected: boolean;
  isRecommended: boolean;
  isSkinCareSelected?: boolean;
  openSkinCareProducts?: () => void;
  disabled?: boolean;
};

const SKINCARE_PRODUCT_CODE = "SCPR";

const PackageOfferCard = ({
  onSelect,
  isSelected,
  isRecommended,
  bundle,
  isSkinCareSelected = false,
  openSkinCareProducts = () => null,
  disabled = false,
}: PackageOfferCard) => {
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const { data } = useFetchBundleForCode(bundle.productCode);

  const { formatMessage } = useIntl();

  const image = useMemo(
    () =>
      opportunity?.gender !== "female" ? data?.maleImage : data?.femaleImage,
    [opportunity?.gender, data]
  );

  if (!data) return null;

  return (
    <AnimatePresence>
      {!loadingOpportunity && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut", stiffness: 10 }}
          className="w-full h-full"
        >
          <div
            className={cn(
              "p-1 h-full rounded-[1.25rem] hover:border-black hover:border-2 flex flex-col gap-4 w-full transition-all duration-300",
              isSelected ? "border-black border-2" : "border-grey-200 border-2"
            )}
          >
            <div className={cn("bg-pink-500 rounded-2xl h-64 w-full relative")}>
              <StoryblokOptimizedImage
                src={image?.filename}
                alt={image?.alt}
                className="object-cover aspect-square h-full w-full rounded-[1.25rem]"
              />
              {isRecommended && (
                <div className="absolute right-2 top-2">
                  <Badge className="bg-white text-black uppercase font-bold px-[0.625rem] py-[0.375rem]">
                    <Copy id="configuration.skin.treatments.recommended" />
                  </Badge>
                </div>
              )}
            </div>
            <div className="h-full justify-center gap-4 px-5 pb-5">
              <div className="flex gap-1 flex-col w-full h-28 text-ellipsis overflow-hidden mb-4">
                <Headline level={6}>{data.title}</Headline>
                <Subtitle level={2} className="text-grey-400">
                  {data.description}
                </Subtitle>
              </div>
              <div className="flex flex-col gap-4">
                <Button
                  size={ButtonSize.sm}
                  onClick={disabled ? undefined : onSelect}
                  variant={
                    isSelected
                      ? ButtonVariant.secondaryOutline
                      : ButtonVariant.primary
                  }
                  className="w-full max-w-none"
                  disabled={disabled}
                >
                  <span className="flex items-center gap-1">
                    {formatMessage({
                      id: isSelected
                        ? "configuration.skin.treatments.selected"
                        : "configuration.skin.treatments.select",
                    })}
                    {isSelected && (
                      <FontAwesomeIcon className="h-4 w-4" icon={faCheck} />
                    )}
                  </span>
                </Button>
                <div className="flex flex-col gap-3">
                  {bundle
                    .getProducts(isSkinCareSelected)
                    .map((productIncluded) => (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 0.5,
                          ease: "easeInOut",
                          stiffness: 10,
                        }}
                        key={productIncluded.id}
                        className="flex gap-2 justify-between items-baseline"
                      >
                        <div className="flex gap-2 items-baseline text-xs">
                          <FontAwesomeIcon icon={faCheck} />
                          <Subtitle level={2} className="text-grey-700">
                            {productIncluded.fullName}
                          </Subtitle>
                        </div>
                        {productIncluded.productCode ===
                          SKINCARE_PRODUCT_CODE && (
                          <InfoButton onClick={openSkinCareProducts} />
                        )}
                      </motion.div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PackageOfferCard;
