import { cn } from "~/utils";

type InputProps = {
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  size?: "sm" | "bg";
};

const Input = ({
  type = "text",
  size = "bg",
  placeholder,
  value,
  onChange,
  disabled,
}: InputProps) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      disabled={disabled}
      className={cn(
        "bg-beige-500 rounded-full outline-none pl-6 pr-4 border border-beige-500 active:border-black focus:border-black",
        {
          "py-2": size === "bg",
          "py-4": size === "sm",
          "bg-grey-100 border-grey-100": disabled,
        }
      )}
    />
  );
};

export default Input;
