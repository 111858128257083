import { useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import summaryImage from "~/assets/images/summary.png";
import Copy from "~/components/atom/Copy";
import Headline from "~/components/atoms/Headline/Headline";
import ContractCard from "~/components/molecules/ContractCard/ContractCard";
import DownloadAppCard from "~/components/molecules/DownloadAppCard/DownloadAppCard";
import HomeCard from "~/components/molecules/HomeCard/HomeCard";
import ReviewCard from "~/components/molecules/ReviewCard/ReviewCard";
import SummaryTreatments from "~/components/molecules/SummaryTreatments/SummaryTreatments";
import { Routes } from "~/routes/routes";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { removeDuplicates } from "~/utils/array.utils";

const Summary = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const selectedProductsKeys = useCheckoutStore((state) =>
    removeDuplicates(
      state.selectedProducts.map((p) => p.productCardKey)
    ).concat(state.selectedBundle?.productCardKey || [])
  );
  const navigateToIntro = () => {
    navigate(
      generatePath(Routes.Introduction, {
        opportunityId: opportunityId as string,
      })
    );
  };
  if (!selectedProductsKeys.length) {
    navigateToIntro();
    return null;
  }
  const navigateBack = () => navigate(-1);

  return (
    <HomeCard
      image={{
        src: summaryImage,
        alt: intl.formatMessage({ id: "summary.alt" }),
      }}
      contentClassName="py-10"
      buttonAction={navigateBack}
    >
      <motion.div
        className="flex flex-col justify-center gap-8 w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: loadingOpportunity ? 0 : 1 }}
        exit={{ opacity: 0 }}
      >
        <div>
          <Headline level={3}>
            <Copy id="summary.title.congratulations" />
          </Headline>
          <Headline level={3}>
            <Copy
              id="summary.title.name"
              values={{ name: opportunity?.accountFirstName }}
            />
          </Headline>
        </div>
        <div className="mx-w-full flex flex-col gap-4">
          <SummaryTreatments
            discountedAmount={opportunity?.discountedAmount}
            totalAmount={opportunity?.amount}
            discountTotal={opportunity?.discountTotal}
            selectedProductsKeys={selectedProductsKeys}
          />
          <ContractCard
            contractExpirationDate={opportunity?.contractExpirationDate}
            contractId={opportunity?.lastContractId}
          />
          <DownloadAppCard />
          <ReviewCard
            locationUrl={opportunity?.location.googleReviewUrl}
            locationName={opportunity?.location.name}
          />
        </div>
      </motion.div>
    </HomeCard>
  );
};

export default Summary;
