import ProductCard, {
  ProductCardProps,
} from "~/components/organisms/Pricing/ProductCard/ProductCard";

export type SkinProductCardProps = ProductCardProps;

const SkinProductCard = (props: SkinProductCardProps) => {
  return <ProductCard {...props}>{props.children}</ProductCard>;
};

export default SkinProductCard;
