import { ReactElement, useState } from "react";
import { motion } from "framer-motion";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import { cn } from "~/utils";
import { Image, PropsWithClassName } from "~/utils/types";

type ZoomCardProps = PropsWithClassName<{
  backgroundImage?: Image;
  onClick?: () => void;
  children?: (isHovered?: boolean) => ReactElement;
  reverseBackdrop?: boolean;
  isBlank?: boolean;
  disableHover?: boolean;
  hasZoom?: boolean;
  onHover?: () => void;
}>;

const ZoomCard = ({
  className,
  children,
  backgroundImage,
  onClick,
  reverseBackdrop,
  isBlank,
  disableHover,
  hasZoom,
  onHover,
}: ZoomCardProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <motion.div
      initial={{ flex: hasZoom ? 2 : 1, opacity: 0 }}
      whileHover={{ flex: disableHover || isBlank ? 1 : 2 }}
      animate={{ flex: hasZoom ? 2 : 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={cn("relative rounded-2xl bg-cover bg-center", className, {
        "cursor-pointer": !(isBlank || disableHover),
      })}
      onMouseEnter={() => {
        if (isBlank || disableHover) {
          return;
        }
        onHover?.();
        setHovered(true);
      }}
      onMouseLeave={() => {
        if (isBlank || disableHover) {
          return;
        }
        setHovered(false);
      }}
      onClick={onClick}
    >
      {!isBlank && (
        <div className="p-6 h-full rounded-2xl  relative">
          <StoryblokOptimizedImage
            src={backgroundImage?.src}
            alt={backgroundImage?.alt}
            className="absolute top-0 left-0 w-full h-full object-cover object-center rounded-2xl z-10"
          />
          <div className="absolute top-0 left-0 w-full h-full z-20 rounded-2xl bg-zoom-card-normal-overlay"></div>
          <div className="flex relative z-40 h-full items-end">
            {children?.(isHovered)}
          </div>
          <div
            className={cn(
              "rounded-2xl absolute top-0 left-0 bottom-0 right-0 bg-black opacity-35 transition-opacity duration-700 ease-in-out z-30",
              {
                "opacity-0": reverseBackdrop
                  ? !(hasZoom || isHovered)
                  : hasZoom || isHovered,
              }
            )}
          ></div>
        </div>
      )}
    </motion.div>
  );
};

export default ZoomCard;
