import Headline from "~/components/atoms/Headline/Headline";
import { cn } from "~/utils";
import StepProgress from "./StepProgress";

type StepProps = {
  secondsPerStep: number;
  stepNumber: number;
  isActivated: boolean;
  onClick: () => void;
  stepCount: number;
  setCurrentStep: (step: number) => void;
  stopTimer?: boolean;
};
const Step = ({
  stepNumber,
  secondsPerStep,
  stepCount,
  isActivated,
  setCurrentStep,
  stopTimer,
}: StepProps) => {
  const nextStep = stepNumber === stepCount ? 1 : stepNumber + 1;
  return (
    <div
      className={cn("flex items-center", {
        "w-full": stepNumber !== 1,
      })}
    >
      {stepNumber !== 1 && <div className="bg-grey-50 w-full h-px"></div>}
      <StepProgress
        onClick={() => {
          setCurrentStep(stepNumber - 1);
        }}
        progress={isActivated ? undefined : 0}
        counterInSeconds={isActivated ? secondsPerStep : undefined}
        sizeInRem={2.5}
        onTimeout={() => {
          setCurrentStep(nextStep - 1);
        }}
        stopTimer={stopTimer}
      >
        <Headline
          level={6}
          className={cn(
            "text-grey-400 flex rounded-full items-center justify-center w-full h-full transition-colors duration-500",
            {
              "text-white bg-black ": isActivated,
            }
          )}
        >
          {stepNumber}
        </Headline>
      </StepProgress>
    </div>
  );
};

export default Step;
