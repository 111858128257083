import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { TreatmentPlanProductCategory } from "~/api/models/TreatmentPlan";
import { useFetchTreatmentPlan } from "~/api/storyblok/useFetchTreatmentPlan";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import TreatmentPlanCategoryCard from "~/components/molecules/TreatmentPlanCategoryCard/TreatmentPlanCategoryCard";
import ZoomCard from "~/components/molecules/ZoomCard/ZoomCard";
import EmptyStateScreen from "~/components/organisms/EmptyStates/EmptyStateScreen";
import { Header } from "~/components/organisms/layout/Header/Header";
import { cn } from "~/utils";

type PlanProps = {
  planCategory: TreatmentPlanProductCategory;
  getProductItemCategoryKeySelected: (key: ProductItemCategoryKey) => unknown;
  onNoProductsCta?: () => void;
};

const Plan = ({
  planCategory,
  getProductItemCategoryKeySelected,
  onNoProductsCta,
}: PlanProps) => {
  const intl = useIntl();
  const { data: planCategories, isLoading } =
    useFetchTreatmentPlan(planCategory);
  const activePlanCategories = useMemo(() => {
    return planCategories?.filter((category) =>
      getProductItemCategoryKeySelected(category.key)
    );
  }, [planCategories, getProductItemCategoryKeySelected]);
  const [zoomedCard, setZoomedCard] = useState(0);

  return (
    <div className="flex flex-col h-full gap-header p-body">
      <Header
        title={intl.formatMessage({
          id: "treatment.plan.title",
        })}
      />
      <div className="flex h-full">
        {isLoading ? (
          <div className="w-full flex items-center justify-center">
            <SpinnerIcon />
          </div>
        ) : (
          <div
            className={cn("flex w-full", {
              "h-2/3": activePlanCategories?.length,
            })}
          >
            <div className="flex w-full gap-6">
              {activePlanCategories?.length ? (
                <>
                  {activePlanCategories?.map((planCategory) => {
                    const planCategoryIndex = activePlanCategories.findIndex(
                      (c) => c.title === planCategory.title
                    );
                    return (
                      <TreatmentPlanCategoryCard
                        hasZoom={zoomedCard === planCategoryIndex}
                        key={planCategory.title}
                        treatmentPlanCategory={planCategory}
                        onHover={() => setZoomedCard(planCategoryIndex)}
                      />
                    );
                  })}
                  {activePlanCategories.length < 2 && (
                    <ZoomCard
                      isBlank
                      className="bg-transparent hover:bg-transparent"
                    />
                  )}
                </>
              ) : (
                <div className="w-full">
                  <EmptyStateScreen onClick={onNoProductsCta} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Plan;
