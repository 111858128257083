import Copy from "~/components/atom/Copy";
import { cn } from "~/utils";
import { CopyKeys } from "~/utils/types";

export type TagVariants = "positive" | "neutral" | "border";

interface Props {
  size?: "s" | "m";
  variant?: TagVariants;
  disabled?: boolean;
  icon?: React.ReactNode;
  copy:
    | {
        id: CopyKeys;
        values?: Record<string, string | React.ReactNode>;
      }
    | string;
}

const Tag = ({
  size = "s",
  variant = "positive",
  disabled,
  icon,
  copy,
}: Props) => {
  return (
    <>
      <div
        className={cn(
          "flex gap-1 items-center rounded-full text-2xs hover:transition-colors hover:transition-border relative",
          {
            "px-2 py-1": size === "s",
            "px-2.5 py-1.5": size === "m",
            "bg-green-900 hover:bg-green-500 text-white":
              variant === "positive",
            "bg-beige-100 hover:bg-beige-500": variant === "neutral",
            "bg-white rounded-full border border-grey-50 hover:border-black text-grey-700":
              variant === "border",
            "cursor-not-allowed": disabled,
            "bg-grey-200 text-grey-400 border-none hover:bg-grey-200 hover:text-grey-400":
              disabled && variant !== "border",
            "bg-white hover:border-grey-50 text-grey-300":
              disabled && variant === "border",
          }
        )}
      >
        {typeof copy === "string" ? (
          <span>{copy}</span>
        ) : (
          <Copy id={copy.id} values={copy.values || {}} />
        )}
        {icon && icon}
      </div>
    </>
  );
};

export default Tag;
