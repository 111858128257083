import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HairProductKey } from "~/api/models/HairProductKey";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchProductDetails } from "~/api/storyblok/useFetchProductDetails";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import EditablePrp from "~/components/molecules/EditablePrp/EditablePrp";
import EditableTransplantation from "~/components/molecules/EditableTransplantation/EditableTransplantation";
import OfferCard from "~/components/molecules/OfferCard/OfferCard";
import ProductDetailCard from "~/components/molecules/ProductDetailCard/ProductDetailCard";
import useHairProducts from "~/hooks/useHairProducts";
import useTreatmentType from "~/hooks/useTreatmentType";
import { getImageBasedOnGender } from "~/utils";

const TransplantBundleOfferCard = () => {
  const intl = useIntl();
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const { hairPlanType } = useTreatmentType();
  const { hairTotal, transplantationProduct, prpProduct } = useHairProducts();

  const { data: prpDetails, isLoading: loadingPrp } = useFetchProductDetails(
    HairProductKey.prp
  );
  const { data: htDetails, isLoading: loadingHt } = useFetchProductDetails(
    HairProductKey.ht
  );

  const { data: bundleDetails, isLoading: loadingBundle } =
    useFetchProductDetails(HairProductKey.bundle);

  const [productImage, loading] = useMemo(() => {
    let loading;
    let image;
    if (hairPlanType === "bundle") {
      image = bundleDetails?.image;
      loading = loadingBundle;
    } else if (hairPlanType === "prp") {
      image = prpDetails?.image;
      loading = loadingPrp;
    } else {
      image = htDetails?.image;
      loading = loadingHt;
    }
    return [image, loading];
  }, [
    bundleDetails?.image,
    prpDetails?.image,
    htDetails?.image,
    loadingBundle,
    loadingPrp,
    loadingHt,
    hairPlanType,
  ]);

  const transplantBundleId = useMemo(() => {
    if (hairPlanType == "bundle") {
      return "pricing.bundle";
    }

    if (hairPlanType === "prp") return "pricing.prp";

    return "pricing.ht";
  }, [hairPlanType]);

  return (
    <AnimatePresence>
      {hairPlanType !== "none" && !loadingOpportunity && (
        <OfferCard
          opportunityGender={opportunity?.gender}
          benefits={
            hairPlanType === "bundle"
              ? bundleDetails?.hideBenefits
                ? []
                : bundleDetails?.benefits
              : hairPlanType === "prp"
                ? prpDetails?.hideBenefits
                  ? []
                  : prpDetails?.benefits
                : htDetails?.hideBenefits
                  ? []
                  : htDetails?.benefits
          }
          image={productImage}
          loadingDetails={loading}
          title={intl.formatMessage({
            id: transplantBundleId,
          })}
          price={intl.formatMessage(
            { id: "pricing.amount" },
            { amount: hairTotal }
          )}
          treatments={
            <>
              <ProductDetailCard
                image={getImageBasedOnGender({
                  image: htDetails?.image,
                  opportunityGender: opportunity?.gender,
                })}
              >
                <EditableTransplantation
                  editable={opportunity?.isValidForSales}
                />
              </ProductDetailCard>
              <ProductDetailCard
                image={getImageBasedOnGender({
                  image: prpDetails?.image,
                  opportunityGender: opportunity?.gender,
                })}
              >
                <EditablePrp editable={opportunity?.isValidForSales} />
              </ProductDetailCard>
            </>
          }
        >
          <div className="text-grey-400">
            <Subtitle level={3}>{transplantationProduct?.name}</Subtitle>
            <Subtitle level={3}>{prpProduct?.name}</Subtitle>
          </div>
        </OfferCard>
      )}
    </AnimatePresence>
  );
};

export default TransplantBundleOfferCard;
