import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useAuthStore } from "~/stores/auth/useAuthStore";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchAccessToken = (code?: string | null) => {
  const { setRefreshToken, refreshToken, setExpiresAt, expiresAt } =
    useAuthStore();
  const { locale } = useLocaleStore();

  return useQuery({
    queryKey: [QueryKeys.useFetchAccessToken],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    queryFn: async () => {
      try {
        if (code) {
          const tokens = await salesforceServiceSingleton.getAccessToken(
            code,
            "authorization_code",
            locale
          );

          const expiresAtInSeconds = tokens.expires_at * 1000;
          setRefreshToken(tokens.refresh_token);
          setExpiresAt(expiresAtInSeconds);

          return {
            accessToken: tokens.access_token,
            ensureAccessTokenIsValid: () =>
              !!tokens.access_token && expiresAtInSeconds > Date.now(),
          };
        } else if (refreshToken) {
          const tokens = await salesforceServiceSingleton.getAccessToken(
            refreshToken,
            "refresh_token",
            locale
          );
          const expiresAtInSeconds = tokens.expires_at * 1000;
          setExpiresAt(expiresAtInSeconds);

          return {
            accessToken: tokens.access_token,
            ensureAccessTokenIsValid: () =>
              !!tokens.access_token && expiresAtInSeconds > Date.now(),
          };
        }
      } catch {
        setRefreshToken(null);
      }
    },
    staleTime: !expiresAt || expiresAt < Date.now() ? undefined : expiresAt,
  });
};
