import { useCallback, useMemo } from "react";
import { Opportunity } from "~/api/models/Opportunity";
import {
  ProductCategoryDropdownOption,
  ProductCategoryGroupKey,
  ProductItemCategoryKey,
} from "~/api/models/ProductCategory";
import { ProductFamily, ProductType } from "~/stores/checkout/product";
import useFlattenedProducts from "./useFlattenedProducts";

const useOpportunityProducts = (opportunity?: Opportunity) => {
  const { data: flattenedProducts, isLoading } = useFlattenedProducts();

  const getOpportunityHairProduct = useCallback(
    (family: ProductFamily, types?: ProductType[]) => {
      return flattenedProducts.find((product) => {
        return (
          opportunity?.products.includes(product.id) &&
          product.family === family &&
          (!types || types.includes(product.type))
        );
      });
    },
    [flattenedProducts, opportunity?.products]
  );
  const getOpportunitySkinProduct = useCallback(() => {
    return flattenedProducts.filter(
      (product) =>
        opportunity?.products.includes(product.id) &&
        (product.family === "botox" ||
          product.family === "filler" ||
          product.family === "needling" ||
          product.family === "skin booster")
    );
  }, [flattenedProducts, opportunity?.products]);

  const freePrpOpportunityProduct = useMemo(
    () =>
      isLoading
        ? undefined
        : getOpportunityHairProduct("prp hair", ["prp hair free"]),
    [getOpportunityHairProduct, isLoading]
  );

  const prpOpportunityProduct = useMemo(
    () =>
      isLoading
        ? undefined
        : getOpportunityHairProduct("prp hair", ["prp hair", "bundle"]),
    [getOpportunityHairProduct, isLoading]
  );
  const transplantOpportunityProduct = useMemo(
    () =>
      isLoading ? undefined : getOpportunityHairProduct("hair transplant"),
    [getOpportunityHairProduct, isLoading]
  );
  const beardOpportunityProduct = useMemo(
    () =>
      isLoading ? undefined : getOpportunityHairProduct("beard transplant"),
    [getOpportunityHairProduct, isLoading]
  );
  const eyebrowOpportunityProduct = useMemo(
    () =>
      isLoading ? undefined : getOpportunityHairProduct("eyebrow transplant"),
    [getOpportunityHairProduct, isLoading]
  );

  const skinOpportunityProducts = useMemo(
    () => (isLoading ? [] : getOpportunitySkinProduct()),
    [getOpportunitySkinProduct, isLoading]
  );

  const getCategoriesByOpportunityProducts = (
    data: ProductCategoryDropdownOption[]
  ) => {
    if (opportunity?.products.length) {
      const selectedCategories: ProductCategoryDropdownOption[] = [];
      const prpCategory = data.find(
        (v) => v.key === ProductItemCategoryKey.prp
      );
      const htCategory = data.find((v) => v.key === ProductItemCategoryKey.ht);
      const beardCategory = data.find(
        (v) => v.key === ProductItemCategoryKey.beard
      );
      const eyebrowCategory = data.find(
        (v) => v.key === ProductItemCategoryKey.eyebrow
      );

      const skinCategory = data.find(
        (v) => v.key === ProductCategoryGroupKey.skin
      );
      if ((prpOpportunityProduct || freePrpOpportunityProduct) && prpCategory) {
        selectedCategories.push(prpCategory);
      }
      if (transplantOpportunityProduct && htCategory) {
        selectedCategories.push(htCategory);
      }
      if (beardOpportunityProduct && beardCategory) {
        selectedCategories.push(beardCategory);
      }
      if (eyebrowOpportunityProduct && eyebrowCategory) {
        selectedCategories.push(eyebrowCategory);
      }

      // has one of the skin products
      if (skinOpportunityProducts.length > 0 && skinCategory) {
        // select the skin category
        selectedCategories.push(skinCategory);
      }

      return selectedCategories.length ? selectedCategories : undefined;
    } else if (opportunity?.interestedIn) {
      const categories = opportunity.interestedIn.categories
        .map((key) => data.find((v) => v.key === key))
        .filter(Boolean) as ProductCategoryDropdownOption[];
      return categories.length ? categories : undefined;
    }
    return undefined;
  };

  return {
    getCategoriesByOpportunityProducts,
    transplantOpportunityProduct,
    prpOpportunityProduct,
    beardOpportunityProduct,
    eyebrowOpportunityProduct,
    skinOpportunityProducts,
    freePrpOpportunityProduct,
    isLoading,
  };
};

export default useOpportunityProducts;
