import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { StoryblokAsset } from "~/services/storyblok";
import { Benefit } from "./Benefit";

export type TreatmentPlanPhaseDetail = {
  title: string;
  description: string;
  image: StoryblokAsset;
  recommendations?: TreatmentPlanRecommendation[];
  recommendationsTitle?: string;
};

export type TreatmentPlanPhase = {
  title: string;
  description: string;
  image: StoryblokAsset;
  details?: TreatmentPlanPhaseDetail[];
};

export type TreatmentPlanRecommendation = {
  title: string;
  subtitle: string;
  image: StoryblokAsset;
};

export type TreatmentPlanInformationCard = {
  title: string;
  description: string;
  image: StoryblokAsset;
};

export type TreatmentPlanInformation = {
  title: string;
  description: string;
  cards: TreatmentPlanInformationCard[];
};

export type TreatmentPlanCategory = {
  key: ProductItemCategoryKey;
  title: string;
  image: StoryblokAsset;
  phases: TreatmentPlanPhase[];
  recommendations?: TreatmentPlanRecommendation[];
  benefits?: Benefit[];
  information?: TreatmentPlanInformation[];
};

export enum TreatmentPlanProductCategory {
  hair = "hair",
  skin = "skin",
}
