import { useMemo } from "react";
import { SalesforceBundle } from "~/api/models/Bundle";
import { useFetchBundles } from "~/api/salesForce/useFetchBundles";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import useTreatmentRelatedSkinProductKeys from "./useTreatmentRelatedSkinProductKeys";
import useTreatmentType from "./useTreatmentType";

const useSkinBundles = () => {
  const { data: bundles, isLoading } = useFetchBundles();
  const { setSelectedBundle, selectedBundle } = useCheckoutStore();
  const { skinCategory, skinSelected, toggleProductCategory } =
    useTreatmentType();
  const treatmentRelatedSkinProductKeys = useTreatmentRelatedSkinProductKeys();

  const highestPriceRecommendedBundle = useMemo(
    () =>
      bundles
        ?.filter((bundle) =>
          bundle.isRecommended(treatmentRelatedSkinProductKeys)
        )
        ?.reduce(
          (prev: SalesforceBundle | null, current) =>
            prev && prev.price > current.price ? prev : current,
          null
        ),
    [bundles, treatmentRelatedSkinProductKeys]
  );

  const setSkinBundleAndHandleSkinCategory = (
    bundle: SalesforceBundle | null
  ) => {
    if (bundle && !skinSelected && skinCategory) {
      toggleProductCategory(skinCategory);
    }
    setSelectedBundle(bundle);
  };

  const toggleSkinBundleAndHandleSkinCategory = (
    bundle: SalesforceBundle | null
  ) => {
    setSkinBundleAndHandleSkinCategory(
      selectedBundle?.productCode === bundle?.productCode ? null : bundle
    );
  };

  return {
    bundles,
    highestPriceRecommendedBundle,
    setSkinBundleAndHandleSkinCategory,
    toggleSkinBundleAndHandleSkinCategory,
    isLoading,
  };
};
export default useSkinBundles;
