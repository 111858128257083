import { PropsWithChildren } from "react";
import {
  IconDefinition,
  faArrowLeft,
  faArrowRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";
import { ButtonContentType, ButtonSize, ButtonVariant } from "./Buttons.types";

type ButtonProps = PropsWithChildren<
  PropsWithClassName<{
    contentType?: ButtonContentType;
    variant?: ButtonVariant;
    size?: ButtonSize;
    disabled?: boolean;
    icon?: IconDefinition;
    customIcon?: React.ReactNode;
    text?: string;
    iconSize?: number;
    loading?: boolean;
  }>
> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const ICON_SIZE = 16;

const Button = ({
  contentType = ButtonContentType.text,
  variant = ButtonVariant.primary,
  size = ButtonSize.lg,
  text,
  iconSize = ICON_SIZE,
  icon = contentType == ButtonContentType.textIcon ? faArrowRight : faArrowLeft,
  disabled,
  className,
  children,
  loading,
  customIcon,
  ...rest
}: ButtonProps) => {
  const buttonIcon = customIcon || icon;

  return (
    <button
      disabled={disabled}
      {...rest}
      className={cn(
        "flex max-w-button items-center justify-center gap-4 rounded-full px-5 py-4 font-bold uppercase transition-all duration-300",
        !disabled ? variant.style : "bg-grey-200 text-grey-300",
        size.style,
        {
          "flex-row-reverse justify-between":
            contentType === ButtonContentType.textIcon,
          "justify-between": contentType === ButtonContentType.iconText,
        },
        className
      )}
    >
      {children}
      {buttonIcon &&
        contentType !== ButtonContentType.text &&
        (customIcon || (
          <FontAwesomeIcon width={iconSize} height={iconSize} icon={icon} />
        ))}
      {text && contentType !== ButtonContentType.icon && <p>{text}</p>}
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <FontAwesomeIcon icon={faSpinner} spin={loading} />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
};

export default Button;
