import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import qrProduction from "~/assets/images/qrProduction.png";
import Copy from "~/components/atom/Copy";
import AppDownloadBadge from "~/components/atoms/AppDownloadBadge/AppDownloadBadge";
import { AppDownloadBadgeType } from "~/components/atoms/AppDownloadBadge/AppDownloadBadge.types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
} from "~/components/atoms/Dialog/Dialog";
import FadeInSection from "~/components/atoms/FadeInSection/FadeInSection";
import Headline from "~/components/atoms/Headline/Headline";

type DownloadModalProps = {
  open: boolean;
  toggleOpen: () => void;
};
const ANIMATION_DURATION = 0.7;

const DownloadModal = ({ open, toggleOpen }: DownloadModalProps) => {
  const intl = useIntl();
  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent
        slideUpAnimation
        className="overflow-scroll w-full max-w-none h-slide-up-modal flex flex-col translate-x-0 translate-y-0 top-auto bottom-0 left-0 right-0 gap-0 p-0"
      >
        <div className="h-full w-full bg-download bg-center bg-no-repeat bg-cover">
          <DialogHeader className="flex justify-end px-20 py-8">
            <DialogClose className="h-8 text-white opacity-100 hover:opacity-70" />
          </DialogHeader>
          <FadeInSection duration={ANIMATION_DURATION} className="py-8 px-24">
            <img
              src={qrProduction}
              alt="qr"
              className="z-10 w-qr max-w-full object-contain"
            />
            <div className="flex flex-col gap-2 mt-6">
              <Headline level={1} className="text-white">
                <Copy id="download.title" />
              </Headline>
              <Headline
                className="text-beige-100 max-w-download-info"
                level={6}
              >
                <Copy id="download.description" />
              </Headline>
            </div>
            <div className="flex gap-4 mt-8">
              <Link
                target="_blank"
                to={intl.formatMessage({ id: "download.ios-url" })}
              >
                <AppDownloadBadge type={AppDownloadBadgeType.ios} />
              </Link>
              <Link
                target="_blank"
                to={intl.formatMessage({ id: "download.android-url" })}
              >
                <AppDownloadBadge type={AppDownloadBadgeType.android} />
              </Link>
            </div>
          </FadeInSection>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadModal;
