import { useQuery } from "@tanstack/react-query";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { PDFDoc } from "~/modules/consultation-document/PDFDoc";
import {
  PhotosCategory,
  consultationDocumentMapper,
} from "~/modules/consultation-document/consultation-document";
import { DocumentPicture } from "~/modules/consultation-document/documentPicture";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const REFETCH_INTERVAL_TO_UPDATE_APP_WITH_NEW_DOCS = 5000;

export const useFetchConsultationDocuments = (
  id: string,
  shouldRefetch: boolean = false,
  category: PhotosCategory
) => {
  const { data } = useFetchAccessToken();
  const { locale } = useLocaleStore();

  return useQuery({
    queryKey: [QueryKeys.useFetchConsultationDocuments],
    queryFn: async () => {
      const response =
        await salesforceServiceSingleton.getConsultationDocuments(
          data!.accessToken, //accessToken is not null because of the enabled flag
          id,
          locale
        );

      return {
        treatmentEventId: response?.treatment_event_id,
        consultationDocuments: consultationDocumentMapper(
          response?.consultation_documents ?? []
        ),
      };
    },
    enabled: !!data?.ensureAccessTokenIsValid?.(),
    select: (data) => {
      const [pictures, pdfDocs] = data?.consultationDocuments?.reduce(
        ([accPics, accPdfDocs]: [DocumentPicture[], PDFDoc[]], dto) => {
          if (dto.category !== category) return [accPics, accPdfDocs];

          if (
            dto.fileExtension === "png" ||
            dto.fileExtension === "jpg" ||
            dto.fileExtension === "jpeg"
          ) {
            accPics = [
              ...accPics,
              new DocumentPicture(
                dto.id,
                dto.fileExtension,
                dto.title,
                dto.url,
                dto.subCategory,
                dto.latestPublishedVersionId
              ),
            ];
          }
          if (dto.fileExtension === "pdf") {
            accPdfDocs = [
              ...accPdfDocs,
              new PDFDoc(
                dto.id,
                dto.fileExtension,
                dto.title,
                dto.url,
                dto.latestPublishedVersionId
              ),
            ];
          }
          return [accPics, accPdfDocs];
        },
        [[], []]
      ) ?? [[], []];

      return {
        treatmentEventId: data.treatmentEventId,
        pictures,
        pdfDocs,
      };
    },
    refetchInterval:
      shouldRefetch && REFETCH_INTERVAL_TO_UPDATE_APP_WITH_NEW_DOCS,
  });
};
