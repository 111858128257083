import { useState } from "react";
import EditableOption from "~/components/molecules/EditableOption/EditableOption";
import SkinBundleModal from "~/components/organisms/Pricing/SkinBundleCard/SkinBundleModal/SkinBundleModal";
import useProductCardContext from "~/hooks/useProductCardContext";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type EditableSkinBundleProps = { editable?: boolean };

const EditableSkinBundle = ({ editable }: EditableSkinBundleProps) => {
  const { isSummaryCard } = useProductCardContext();
  const [open, setOpen] = useState(false);
  const { selectedBundle, hasSkinCare } = useCheckoutStore();
  const showModal = () => setOpen(true);
  return (
    <>
      <SkinBundleModal open={open} setOpen={setOpen} editable={editable} />
      <EditableOption
        className="text-grey-400 text-sm"
        onClick={showModal}
        title={selectedBundle
          ?.getProducts(hasSkinCare)
          .map((product) => product.name)
          .join(", ")}
        editable={editable && !isSummaryCard}
      />
    </>
  );
};

export default EditableSkinBundle;
