import { PropsWithChildren, useMemo, useState } from "react";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import EditableOption from "~/components/molecules/EditableOption/EditableOption";
import ProductModal, {
  ProductModalProps,
} from "~/components/molecules/ProductModal/ProductModal";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

export type EditableProductProps = PropsWithClassName<
  PropsWithChildren<Omit<ProductModalProps, "open" | "setOpen">>
> & {
  editable?: boolean;
  forceShowModal?: boolean;
  stopForceShowModal?: () => void;
};

const EditableProduct = ({
  product,
  optionSubtitleFormatter,
  blankOptionTitle,
  editable = true,
  className,
  forceShowModal,
  stopForceShowModal,
  ...rest
}: EditableProductProps) => {
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen(true);
  const title = useMemo(() => {
    if (!product) {
      return blankOptionTitle;
    }
    const products = !Array.isArray(product) ? [product] : product;

    return products
      .map((product) => {
        return optionSubtitleFormatter && product
          ? optionSubtitleFormatter(product)
          : product.name;
      })
      .join(", ");
  }, [optionSubtitleFormatter, product, blankOptionTitle]);

  const setOpenAndStopForceShowModal = (open: boolean) => {
    setOpen(open);
    if (!open) {
      stopForceShowModal?.();
    }
  };
  return (
    <>
      <ProductModal
        blankOptionTitle={blankOptionTitle}
        optionSubtitleFormatter={optionSubtitleFormatter}
        product={product}
        open={open || !!forceShowModal}
        setOpen={setOpenAndStopForceShowModal}
        {...rest}
      />
      {editable ? (
        !forceShowModal && (
          <EditableOption
            onClick={showModal}
            title={title}
            className={className}
            editable={editable}
          />
        )
      ) : (
        <Subtitle level={2} className={cn("text-grey-400", className)}>
          {title}
        </Subtitle>
      )}
    </>
  );
};

export default EditableProduct;
