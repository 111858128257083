import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { useFetchAccessToken } from "./useFetchAccessToken";

export const useFetchUploadToken = (
  treatmentEventId: string,
  opportunityId: string
) => {
  const { data } = useFetchAccessToken();
  const uploadTokenExpiresAtRef = useRef<number | null>(null);
  const { locale } = useLocaleStore();

  const result = useQuery({
    queryKey: [QueryKeys.useFetchUploadToken],
    queryFn: async () => {
      try {
        const tokens = await salesforceServiceSingleton.getUploadToken(
          data!.accessToken, //accessToken is not null because of the enabled flag
          treatmentEventId,
          opportunityId,
          locale
        );
        const expiresAtInSeconds = tokens.expires_at * 1000;
        uploadTokenExpiresAtRef.current = expiresAtInSeconds;
        return { uploadToken: tokens.token };
      } catch {
        uploadTokenExpiresAtRef.current = null;
      }
    },
    enabled: !!data?.ensureAccessTokenIsValid?.() && !!treatmentEventId,
    staleTime:
      !uploadTokenExpiresAtRef.current ||
      uploadTokenExpiresAtRef.current < Date.now()
        ? undefined
        : uploadTokenExpiresAtRef.current,
  });

  return {
    ...result,
    data: result.data?.uploadToken,
  };
};
