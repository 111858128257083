export const createArray = (length: number) => Array.from(Array(length).keys());

export const hasCommonElements = <T>(arr1: T[], arr2: T[]) => {
  return arr1.some((element) => {
    return arr2.includes(element);
  });
};

export const insertArrayAtIndex = <T>(
  originalArray: T[],
  insertArray: T[],
  position: number
) => {
  if (position > originalArray.length) {
    return originalArray;
  }
  if (position < 0) {
    return [...originalArray, ...insertArray];
  }

  const newArray = [
    ...originalArray.slice(0, position),
    ...insertArray,
    ...originalArray.slice(position),
  ];

  return newArray;
};

export const removeDuplicates = <T>(arr: T[]) =>
  arr.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

export const splitArrayHalf = <T>(arr: T[]) => {
  const half = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, half);
  const secondHalf = arr.slice(half);
  return { firstHalf, secondHalf };
};
