import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchExampleBySlug } from "~/api/storyblok/useFetchExampleBySlug";
import Grid from "~/components/organisms/Example/Grid";
import { ExampleCategory, ExampleGender } from "~/modules/example/example";

const HairExample = () => {
  const params = useParams<{ example: string }>();

  const { data: example, isLoading } = useFetchExampleBySlug(
    ExampleCategory.hair,
    params.example ?? ""
  );

  const [activeTab, setActiveTab] = useState(ExampleGender.all);

  if (!example) {
    // TODO redirect to 404 when built
    return null;
  }

  return (
    <Grid
      example={example}
      isLoading={isLoading}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default HairExample;
