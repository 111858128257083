import { useMemo } from "react";
import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { useFetchUploadedPhotos } from "~/api/salesForce/useFetchUploadedPhotos";
import PhotosScreen from "~/components/organisms/Photos/PhotosScreen";
import useTreatmentType from "~/hooks/useTreatmentType";

type isHairProductItemCategoryKeySelectedArgs = {
  productItemCategoryKey?: ProductItemCategoryKey;
  htSelected?: boolean;
  beardSelected?: boolean;
  eyebrowSelected?: boolean;
  prpSelected?: boolean;
};

const isHairProductItemCategoryKeySelected = ({
  productItemCategoryKey,
  htSelected,
  beardSelected,
  eyebrowSelected,
  prpSelected,
}: isHairProductItemCategoryKeySelectedArgs) => {
  switch (productItemCategoryKey) {
    case ProductItemCategoryKey.ht:
      return htSelected || prpSelected;
    case ProductItemCategoryKey.beard:
      return beardSelected;
    case ProductItemCategoryKey.eyebrow:
      return eyebrowSelected;
    default:
      return false;
  }
};

const POSSIBLE_HAIR_CATEGORIES = [
  ProductItemCategoryKey.ht,
  ProductItemCategoryKey.beard,
  ProductItemCategoryKey.eyebrow,
];

const Photos = () => {
  const { htSelected, prpSelected, beardSelected, eyebrowSelected } =
    useTreatmentType();

  const selectedHairCategories = useMemo(() => {
    return POSSIBLE_HAIR_CATEGORIES.filter((category) =>
      isHairProductItemCategoryKeySelected({
        productItemCategoryKey: category,
        htSelected: !!htSelected,
        beardSelected: !!beardSelected,
        eyebrowSelected: !!eyebrowSelected,
        prpSelected: !!prpSelected,
      })
    );
  }, [htSelected, beardSelected, eyebrowSelected, prpSelected]);

  const { uploadToken, photos, isLoading } = useFetchUploadedPhotos("hair");

  return (
    <PhotosScreen
      uploadToken={uploadToken}
      selectedCategories={selectedHairCategories}
      isLoading={isLoading}
      photos={photos}
      productCategory="hair"
      isProductItemCategoryKeySelected={(productCategoryKey) =>
        isHairProductItemCategoryKeySelected({
          productItemCategoryKey: productCategoryKey,
          htSelected: !!htSelected,
          beardSelected: !!beardSelected,
          eyebrowSelected: !!eyebrowSelected,
          prpSelected: !!prpSelected,
        }) ?? false
      }
    />
  );
};
export default Photos;
