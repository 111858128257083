import { useIntl } from "react-intl";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import NeedlingProductCard from "~/components/organisms/Pricing/NeedlingProductCard/NeedlingProductCard";
import useDefaultProduct from "~/hooks/useDefaultProduct";

const NeedlingCard = () => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();
  const defaultProduct = useDefaultProduct(allProducts.skin.needling);

  return (
    <NeedlingProductCard
      skinProductFamilyKey={SkinProductKey.needling}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.needling",
      })}
      modalTitle={intl.formatMessage({ id: "pricing.needling" })}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.needling.none",
      })}
      isLoading={isLoading}
      options={allProducts.skin.needling}
      optionSubtitleFormatter={(option) =>
        intl.formatMessage(
          {
            id: "pricing.card.sessions",
          },
          {
            sessions: option?.sessions,
          }
        )
      }
    />
  );
};

export default NeedlingCard;
