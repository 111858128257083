import { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { AccordionRoot } from "~/components/atoms/Accordion/Accordion";
import { ConfigurationAccordions } from "~/components/atoms/Accordion/Accordions.type";
import HeadConfiguration from "~/components/atoms/HeadConfiguration/HeadConfiguration";
import BeardGrafts from "~/components/molecules/BeardGrafts/BeardGrafts";
import EyebrowGrafts from "~/components/molecules/EyebrowGrafts/EyebrowGrafts";
import HeadGrafts from "~/components/molecules/HeadGrafts/HeadGrafts";
import TreatmentFocusCard from "~/components/molecules/TreatmentFocusCard/TreatmentFocusCard";
import { Header } from "~/components/organisms/layout/Header/Header";
import useTreatmentType from "~/hooks/useTreatmentType";

const HairConfiguration = () => {
  const { opportunityId } = useParams();
  const { data: opportunity } = useFetchOpportunity({
    id: opportunityId as string,
  });
  const intl = useIntl();
  const { htSelected, prpSelected, beardSelected, eyebrowSelected } =
    useTreatmentType();

  const [openedAccordion, setOpenedAccordion] = useState<
    ConfigurationAccordions | undefined
  >(undefined);

  return (
    <div className="gap-header p-body flex flex-col h-full">
      <Header title={intl.formatMessage({ id: "configuration.title" })} />
      <div className="grid grid-cols-2 h-full items-center justify-center gap-20">
        <div className="flex items-center justify-center w-full h-full">
          <div className="w-configuration max-w-2/3">
            <HeadConfiguration />
          </div>
        </div>
        <section className="flex flex-col gap-3 w-full">
          <AccordionRoot
            type="single"
            value={openedAccordion}
            onValueChange={(value) =>
              setOpenedAccordion(value as ConfigurationAccordions)
            }
            collapsible
            className="flex flex-col gap-3"
          >
            {htSelected && (
              <HeadGrafts
                openedAccordion={openedAccordion}
                collapsible={!!beardSelected || !!eyebrowSelected}
                isEditable={opportunity?.isValidForSales}
              />
            )}
            {prpSelected && !htSelected && (
              <TreatmentFocusCard
                type="hair"
                openedAccordion={openedAccordion}
                collapsible={
                  !!prpSelected && (!!beardSelected || !!eyebrowSelected)
                }
              />
            )}
            {beardSelected && (
              <BeardGrafts
                openedAccordion={openedAccordion}
                collapsible={!!prpSelected || !!htSelected || !!eyebrowSelected}
                isEditable={opportunity?.isValidForSales}
              />
            )}
            {eyebrowSelected && (
              <EyebrowGrafts
                openedAccordion={openedAccordion}
                collapsible={!!prpSelected || !!htSelected || !!beardSelected}
                isEditable={opportunity?.isValidForSales}
              />
            )}
          </AccordionRoot>
        </section>
      </div>
    </div>
  );
};

export default HairConfiguration;
