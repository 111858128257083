import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  OtherSpecialConditionValue,
  SpecialCondition,
} from "~/api/models/SpecialCondition";
import Input from "~/components/atoms/Input/Input";
import Radio from "~/components/atoms/Radio/Radio";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import PricingModal from "~/components/molecules/PricingModal/PricingModal";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type SpecialConditionsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  conditions?: SpecialCondition[];
  loadingConditions?: boolean;
};

const SpecialConditionsModal = ({
  open,
  setOpen,
  conditions,
  loadingConditions,
}: SpecialConditionsModalProps) => {
  const intl = useIntl();

  const [
    setSpecialConditionsValues,
    specialConditionsValues,
    otherSpecialConditions,
    setOtherSpecialConditions,
  ] = useCheckoutStore((state) => [
    state.setSpecialConditionsValues,
    state.specialConditionsValues,
    state.otherSpecialConditions,
    state.setOtherSpecialConditions,
  ]);
  const [selectedSpecialConditions, setSelectedSpecialConditions] = useState(
    specialConditionsValues
  );
  const [additionalCondition, setAdditionalCondition] = useState<string | null>(
    ""
  );
  const toggleCondition = (condition: SpecialCondition) => {
    if (selectedSpecialConditions?.find((c) => c === condition.value)) {
      setSelectedSpecialConditions(
        selectedSpecialConditions?.filter((c) => c !== condition.value)
      );
      if (condition.value.toLowerCase() === OtherSpecialConditionValue) {
        setAdditionalCondition("");
      }
    } else {
      setSelectedSpecialConditions([
        ...(selectedSpecialConditions || []),
        condition.value,
      ]);
    }
  };
  const hasOtherFieldSelected = useMemo(
    () =>
      selectedSpecialConditions?.find(
        (c) => c.toLowerCase() === OtherSpecialConditionValue
      ),
    [selectedSpecialConditions]
  );

  const onInputChange = (value: string) => {
    setAdditionalCondition(value);
  };

  const onSave = async () => {
    setSpecialConditionsValues(selectedSpecialConditions ?? []);
    setOtherSpecialConditions(additionalCondition ?? "");
    setOpen(false);
  };

  const onReset = () => {
    setSelectedSpecialConditions(specialConditionsValues);
    setAdditionalCondition(otherSpecialConditions);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onReset();
    }
    setOpen(open);
  };

  useEffect(() => {
    if (open && specialConditionsValues) {
      setSelectedSpecialConditions(specialConditionsValues);
      setAdditionalCondition(otherSpecialConditions);
    }
  }, [open, specialConditionsValues, otherSpecialConditions]);

  return (
    <PricingModal
      open={open}
      setOpen={onOpenChange}
      title={intl.formatMessage({ id: "pricing.specialConditions.title" })}
      buttonText={intl.formatMessage({ id: "actions.save" })}
      buttonAction={onSave}
    >
      {loadingConditions ? (
        <div className="flex items-center justify-center h-full">
          <SpinnerIcon className="text-3xl" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {conditions?.map((condition, index) => (
            <Radio
              key={index}
              withCheck
              option={{ name: condition.label, id: condition.value }}
              checked={selectedSpecialConditions?.includes(condition.value)}
              onChange={() => toggleCondition(condition)}
            />
          ))}
          <Input
            value={additionalCondition ?? ""}
            onChange={onInputChange}
            placeholder={intl.formatMessage({
              id: "pricing.specialConditions.placeholder",
            })}
            disabled={!hasOtherFieldSelected}
          />
        </div>
      )}
    </PricingModal>
  );
};

export default SpecialConditionsModal;
