import { useState } from "react";
import { useIntl } from "react-intl";
import EditableOption from "~/components/molecules/EditableOption/EditableOption";
import TransplantationModal from "~/components/molecules/TransplantationModal/TransplantationModal";
import useHairProducts from "~/hooks/useHairProducts";

type EditableTransplantationProps = { editable?: boolean };

const EditableTransplantation = ({
  editable,
}: EditableTransplantationProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { transplantationProduct } = useHairProducts();
  const showModal = () => setOpen(true);

  return (
    <>
      <TransplantationModal open={open} setOpen={setOpen} />
      <EditableOption
        className="text-black text-sm"
        onClick={showModal}
        title={
          transplantationProduct?.name ??
          intl.formatMessage({ id: "treatment.pricing.ht.none" })
        }
        editable={editable}
      />
    </>
  );
};

export default EditableTransplantation;
