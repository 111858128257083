import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import Headline from "~/components/atoms/Headline/Headline";

const PricingHeader = () => {
  const intl = useIntl();
  //const validityDate = new Date();
  const { opportunityId } = useParams();

  const { data: opportunity, isLoading } = useFetchOpportunity({
    id: opportunityId as string,
  });

  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-2">
        {!isLoading && (
          <Headline level={3} className="animate-fade-in">
            {intl.formatMessage(
              {
                id: "pricing.header.title",
              },
              { username: opportunity?.accountFirstName }
            )}
          </Headline>
        )}
        {/* <Subtitle className="text-grey-400">
          {intl.formatMessage(
            { id: "pricing.header.validity" },
            { validityDate: validityDate.toLocaleDateString() }
          )}
        </Subtitle>*/}
      </div>
      {/* <PricingDropDown /> */}
    </div>
  );
};

export default PricingHeader;
