import { MutableRefObject, PropsWithChildren } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type BlurCardProps = PropsWithClassName<
  PropsWithChildren<{
    ref?: MutableRefObject<HTMLDivElement | null>;
  }>
>;

const BlurCard = ({ children, className, ref }: BlurCardProps) => {
  return (
    <div className={cn("group/blurCard w-full h-full", className)} ref={ref}>
      <AnimatePresence>
        <motion.div
          key="intro blur card"
          initial={{ visibility: "hidden" }}
          animate={{ visibility: "visible" }}
          transition={{ duration: 0.5, delay: 1 }}
          className="group-hover/blurCard:opacity-100 opacity-0 absolute top-0 left-0 h-full w-full bg-black/30 backdrop-blur-sm z-30 pointer-events-none transition-opacity duration-300 ease-in-out"
        ></motion.div>
      </AnimatePresence>
      <div className="w-full h-full relative group-hover/blurCard:z-50">
        {children}
      </div>
    </div>
  );
};

export default BlurCard;
