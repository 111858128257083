import check from "~/assets/svg/check.svg";
import { PhotoPosition } from "~/modules/upload-photo/upload-photo";
import { cn } from "~/utils";

type UploadTakePhotoItem = {
  item: PhotoPosition;
  isSelectedPosition: boolean;
  onClick: () => void;
  photoTaken?: string;
};

const UploadTakePhotoItem = ({
  item,
  isSelectedPosition,
  onClick,
  photoTaken,
}: UploadTakePhotoItem) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "relative rounded-lg bg-beige-100 overflow-hidden flex items-center justify-center text-sm w-full min-w-[4.25rem] h-full",
        {
          "border-2": isSelectedPosition,
          "px-2": !photoTaken,
        }
      )}
    >
      {photoTaken ? (
        <>
          <img src={photoTaken} className="w-full h-full object-cover" />
          <img
            src={check}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </>
      ) : (
        item.title
      )}
    </button>
  );
};

export default UploadTakePhotoItem;
