import { generatePath, useNavigate, useParams } from "react-router-dom";
import { TreatmentPlanProductCategory } from "~/api/models/TreatmentPlan";
import Plan from "~/components/organisms/Plan/Plan";
import useSkinProducts from "~/hooks/useSkinProducts";
import { Routes } from "~/routes/routes";

const SkinPlan = () => {
  const { getProductItemCategoryKeySelected } = useSkinProducts();
  const navigate = useNavigate();
  const { opportunityId } = useParams();

  const goToSkinConfiguration = () => {
    navigate(
      generatePath(Routes.SkinConfiguration, {
        opportunityId: opportunityId as string,
      })
    );
  };

  return (
    <Plan
      planCategory={TreatmentPlanProductCategory.skin}
      getProductItemCategoryKeySelected={getProductItemCategoryKeySelected}
      onNoProductsCta={goToSkinConfiguration}
    />
  );
};

export default SkinPlan;
