import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { cn } from "~/utils";

type SideBarItemProps = {
  title: string;
  to: string;
  isActive: boolean;
  order: number;
} & PropsWithChildren;

export function SideBarItem({
  to,
  title,
  isActive,
  order,
  children,
}: SideBarItemProps) {
  return (
    <NavLink
      to={to}
      className={cn("flex flex-col gap-4", {
        "text-red-900": isActive,
      })}
    >
      <div className="flex gap-4 items-center">
        <div
          className={cn(
            "flex h-7 w-7 items-center justify-center rounded-full bg-beige-100 text-grey-400 outline-1 outline-grey-400 transition-colors duration-500",
            { "bg-black text-white": isActive }
          )}
        >
          <p className="text-xs font-bold">{order}</p>
        </div>
        <h2
          className={cn(
            "text-base text-grey-400 transition-colors duration-500",
            {
              "text-black": isActive,
            }
          )}
        >
          {title}
        </h2>
      </div>
      {children}
    </NavLink>
  );
}
