import { StoryblokAsset } from "~/services/storyblok";

const IMAGE_COMPONENT = "image";

type ContentBlockDto = {
  component: string;
  image?: StoryblokAsset;
  title?: string;
  icon?: StoryblokAsset;
};

export type LoadingContentDto = {
  blocks: ContentBlockDto[];
};

export class ContentBlock {
  public isImage: boolean;
  public image?: StoryblokAsset;
  public pill?: {
    title?: string;
    icon?: StoryblokAsset;
  };
  constructor(dto: ContentBlockDto) {
    this.isImage = dto.component.toLowerCase() === IMAGE_COMPONENT;
    if (this.isImage) {
      this.image = dto.image;
    } else {
      this.pill = {
        title: dto.title,
        icon: dto.icon,
      };
    }
  }
}
