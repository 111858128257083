import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent } from "~/components/atoms/Dialog/Dialog";
import { StoryblokAsset } from "~/services/storyblok";

type VideoScreenProps = {
  video?: StoryblokAsset;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const VideoScreen = ({ open, setOpen, video }: VideoScreenProps) => {
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-[80%] h-[90%] max-w-[90%] p-0 rounded-3xl">
        <div
          onClick={closeModal}
          className="z-10 absolute top-5 right-5 cursor-pointer text-grey-400 bg-white-30 rounded-full py-3 px-4 flex items-center hover:text-black"
        >
          <FontAwesomeIcon icon={faTimes} className="text-lg" />
        </div>
        <div className="max-h-full max-w-full overflow-hidden">
          <video
            controls
            playsInline
            className="h-full w-full object-cover rounded-2xl"
            src={video?.filename}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VideoScreen;
