import { useIntl } from "react-intl";
import Button from "~/components/atoms/Button/Button";
import { ButtonVariant } from "~/components/atoms/Button/Buttons.types";
import { Dialog, DialogContent } from "~/components/atoms/Dialog/Dialog";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type ConfirmModalProps = PropsWithClassName<{
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description?: string;
  buttonText?: string;
  buttonAction?: () => void;
  onCancel?: () => void;
}>;

const ConfirmModal = ({
  title,
  description,
  buttonText,
  buttonAction,
  open,
  setOpen,
  onCancel,
  className,
}: ConfirmModalProps) => {
  const intl = useIntl();
  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className={cn(
          "min-w-pricing-modal flex flex-col justify-between",
          className
        )}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <Headline level={5}>{title}</Headline>
            <Subtitle className="text-grey-400">{description}</Subtitle>
          </div>
          <div className="flex gap-3">
            <Button
              variant={ButtonVariant.secondaryOutline}
              onClick={handleCancel}
              text={intl.formatMessage({
                id: "actions.cancel",
              })}
              className="w-full"
            />
            <Button
              variant={ButtonVariant.primary}
              className="w-full bg-red-500 hover:bg-red-900"
              onClick={buttonAction}
              text={buttonText}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
