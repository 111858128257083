import { PropsWithChildren, ReactNode } from "react";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { PricingTextKeys } from "~/stores/checkout/paymentMethod";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";
import { PricingText } from "./PricingText";

type PricingModalOptionProps = PropsWithChildren<
  PropsWithClassName<{
    title?: string;
    subtitle?: string;
    selected?: boolean;
    priceAmount?: number;
    badge?: ReactNode;
    onSelected?: () => void;
    variant?: "1row" | "2rows";
    instalmentMonths?: number;
    pricingText?: PricingTextKeys;
    minimum?: number;
  }>
>;

const PricingModalOption = ({
  title,
  subtitle,
  selected,
  priceAmount,
  badge,
  onSelected,
  variant = "1row",
  className,
  children,
  instalmentMonths = 1,
  pricingText = "pricing.amount",
  minimum = 0,
}: PricingModalOptionProps) => {
  return (
    <button
      onClick={onSelected}
      className={cn(
        "border-2 px-6 py-5 border-grey-50 rounded-2xl cursor-pointer outline-black h-full",
        {
          "border-black": selected,
        },
        className
      )}
    >
      <div
        className={cn({
          "flex justify-between items-center gap-4": variant === "1row",
        })}
      >
        <div
          className={cn({
            "flex w-full flex-col gap-1": variant === "1row",
          })}
        >
          <div className="flex items-center gap-2">
            <Subtitle className="text-lg text-left">{title}</Subtitle>
            <div>{badge}</div>
          </div>
          {subtitle && (
            <Subtitle className="text-grey-400 text-left" level={3}>
              {subtitle}
            </Subtitle>
          )}
          {children}
        </div>
        {priceAmount && (
          <div
            className={cn("shrink-0", {
              "text-left text-grey-400 mt-2": variant === "2rows",
            })}
          >
            <PricingText
              amount={priceAmount}
              instalmentMonths={instalmentMonths}
              pricingText={pricingText}
              minimum={minimum}
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default PricingModalOption;
