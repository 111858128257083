import { RouteObject } from "react-router-dom";
import { ErrorElement } from "~/components/organisms/ErrorElement/ErrorElement";
import SideBarLayout from "~/components/organisms/layout/SideBarLayout";
import Agenda from "~/components/pages/agenda/agenda";
import DownloadLink from "~/components/pages/downloadLink/DownloadLink";
import FollicleScan from "~/components/pages/hair/FollicleScan";
import HairConfiguration from "~/components/pages/hair/HairConfiguration";
import HairExample from "~/components/pages/hair/HairExample";
import HairExamples from "~/components/pages/hair/HairExamples";
import HairPhotos from "~/components/pages/hair/Photos";
import { Login } from "~/components/pages/login";
import { OAuth } from "~/components/pages/oauth";
import HairPlan from "~/components/pages/plan/HairPlan";
import HairPlanCategory from "~/components/pages/plan/HairPlanCategory";
import SkinPhotos from "~/components/pages/skin/Photos";
import SkinConfiguration from "~/components/pages/skin/SkinConfiguration";
import SkinExample from "~/components/pages/skin/SkinExample";
import SkinExamples from "~/components/pages/skin/SkinExamples";
import SkinPlan from "~/components/pages/skin/SkinPlan";
import SkinPlanCategory from "~/components/pages/skin/SkinPlanCategory";
import SkinScan from "~/components/pages/skin/SkinScan";
import Summary from "~/components/pages/summary/Summary";
import Pricing from "~/components/pages/summary/pricing/Pricing";
import SummaryContract from "~/components/pages/summary/pricing/SummaryContract";
import UploadOverview from "~/components/pages/upload/Overview";
import Welcome from "~/components/pages/welcome/Welcome";
import WhyHS from "~/components/pages/whyHS/WhyHS";
import LoggedInOnly from "~/routes/LoggedInOnly";
import LoggedOutOnly from "~/routes/LoggedOutOnly";
import { Routes } from "~/routes/routes";

type SidebarLayoutPath = RouteObject & {
  excludeFromNavigationWithArrows?: boolean;
};

export const sidebarIntroPaths: SidebarLayoutPath[] = [
  {
    element: <WhyHS />,
    path: Routes.Introduction,
  },
];

export const sidebarHairPaths: SidebarLayoutPath[] = [
  {
    element: <HairPhotos />,
    path: Routes.HairPhotos,
  },
  {
    element: <FollicleScan />,
    path: Routes.HairFollicleScan,
  },
  {
    element: <HairPlan />,
    path: Routes.HairTreatmentPlan,
  },
  {
    element: <HairPlanCategory />,
    path: Routes.HairTreatmentPlanCategory,
    excludeFromNavigationWithArrows: true,
  },
  {
    element: <HairConfiguration />,
    path: Routes.HairConfiguration,
  },
  {
    element: <HairExamples />,
    path: Routes.HairExamples,
  },
  {
    element: <HairExample />,
    path: Routes.HairExample,
    excludeFromNavigationWithArrows: true,
  },
];

export const sidebarSkinPaths: SidebarLayoutPath[] = [
  {
    element: <SkinConfiguration />,
    path: Routes.SkinConfiguration,
  },
  {
    element: <SkinScan />,
    path: Routes.SkinScan,
  },
  {
    element: <SkinPhotos />,
    path: Routes.SkinPhotos,
  },
  {
    element: <SkinPlan />,
    path: Routes.SkinTreatmentPlan,
  },
  {
    element: <SkinPlanCategory />,
    path: Routes.SkinTreatmentPlanCategory,
    excludeFromNavigationWithArrows: true,
  },
  {
    element: <SkinExamples />,
    path: Routes.SkinExamples,
  },
  {
    element: <SkinExample />,
    path: Routes.SkinExample,
    excludeFromNavigationWithArrows: true,
  },
];

const sidebarSummaryPaths: SidebarLayoutPath[] = [
  {
    element: <Pricing />,
    path: Routes.SummaryPricing,
  },
  {
    element: <SummaryContract />,
    path: Routes.SummaryContract,
    excludeFromNavigationWithArrows: true,
  },
];

const generateSidebarLayoutPaths = (planType: string): SidebarLayoutPath[] => {
  if (planType === "hs") {
    return [
      ...sidebarIntroPaths,
      ...sidebarHairPaths,
      ...sidebarSkinPaths,
      ...sidebarSummaryPaths,
    ].filter((path) => !path.excludeFromNavigationWithArrows);
  }

  if (planType === "skin") {
    return [
      ...sidebarIntroPaths,
      ...sidebarSkinPaths,
      ...sidebarSummaryPaths,
    ].filter((path) => !path.excludeFromNavigationWithArrows);
  }

  return [
    ...sidebarIntroPaths,
    ...sidebarHairPaths,
    ...sidebarSummaryPaths,
  ].filter((path) => !path.excludeFromNavigationWithArrows);
};

const generatePaths = (): RouteObject[] => {
  return [
    {
      path: "/",
      errorElement: <ErrorElement />,
      children: [
        {
          path: Routes.DownloadLink,
          element: <DownloadLink />,
        },
        {
          element: <LoggedOutOnly />,
          children: [
            {
              element: <Login />,
              path: Routes.Login,
            },
            {
              element: <OAuth />,
              path: Routes.OAuth,
            },
          ],
        },
        {
          element: <LoggedInOnly />,
          children: [
            {
              element: <Welcome />,
              path: Routes.Welcome,
            },
            {
              element: <Agenda />,
              path: Routes.Agenda,
            },
            {
              element: <SideBarLayout />,
              children: [
                ...sidebarIntroPaths,
                ...sidebarHairPaths,
                ...sidebarSkinPaths,
                ...sidebarSummaryPaths,
              ],
            },

            {
              element: <Summary />,
              path: Routes.SummaryOverview,
            },
          ],
        },
        {
          element: <UploadOverview />,
          path: Routes.UploadOverview,
        },
      ],
    },
  ];
};

export { generatePaths, generateSidebarLayoutPaths };
