type OptimizeImageProps = {
  src?: string;
  isBackground?: boolean;
  level?: number;
};
export const optimizeImage = ({
  src = "",
  isBackground = false,
  level = 60,
}: OptimizeImageProps) =>
  `${src}/m/${!isBackground ? `/filters:quality(${level})` : ""}`;
