import { productCardBuilder } from "~/components/organisms/ProductCards/ProductCards";
import { ProductCardContext } from "~/hooks/useProductCardContext";
import useTreatmentRelatedSkinProductKeys from "~/hooks/useTreatmentRelatedSkinProductKeys";

const TreatmentIndividualProducts = () => {
  const treatmentRelatedSkinProductKeys = useTreatmentRelatedSkinProductKeys();

  return (
    <div className="flex flex-col gap-4">
      <ProductCardContext.Provider
        value={{ isSuggested: true, isSummaryCard: false }}
      >
        {treatmentRelatedSkinProductKeys.map((productKey) => {
          return (
            <div key={productKey}>{productCardBuilder({ productKey })}</div>
          );
        })}
      </ProductCardContext.Provider>
    </div>
  );
};

export default TreatmentIndividualProducts;
