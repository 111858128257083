import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { isStringInList } from "~/utils/string.utils";
import { PdfExtension } from "./PDFDoc";
import { DocumentPictureExtension } from "./documentPicture";

export type FileType = DocumentPictureExtension | PdfExtension;

export const photosCategories = ["hair", "skin"] as const;

export type PhotosCategory = (typeof photosCategories)[number];

export type ConsultationDocumentDTO = {
  id: string;
  latest_published_version_id?: string;
  file_extension: FileType;
  title: string;
  description: string;
  url: string;
};

export type ConsultationDocument = {
  id: string;
  latestPublishedVersionId?: string;
  fileExtension: FileType;
  title: string;
  description: string;
  url: string;
  category: PhotosCategory | null;
  subCategory: ProductItemCategoryKey | null;
};

export const consultationDocumentMapper = (
  documents: ConsultationDocumentDTO[]
): ConsultationDocument[] =>
  documents?.map((document) => {
    const splittedTitle = document.title.split(",");
    const category: PhotosCategory | null =
      splittedTitle.length > 1 &&
      isStringInList(splittedTitle[0], photosCategories)
        ? (splittedTitle[0] as PhotosCategory)
        : null;
    const subCategory: ProductItemCategoryKey | null =
      splittedTitle.length > 2
        ? (splittedTitle[1] as ProductItemCategoryKey)
        : null;

    const titleWithoutCategory =
      splittedTitle.length > 2 ? splittedTitle[2] : document.title;

    return {
      id: document.id,
      latestPublishedVersionId: document.latest_published_version_id,
      fileExtension: document.file_extension,
      description: document.description,
      url: document.url,
      title: titleWithoutCategory,
      category,
      subCategory,
    };
  });
