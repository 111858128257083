import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UploadScanBodyDto } from "~/api/models/ScanFile";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { notifyOnce } from "~/utils/toast.utils";

const UPLOAD_SCAN_ERROR_TOAST_ID = "uploadScanError";
const UPLOAD_SCAN_SUCCESS_TOAST_ID = "uploadScanSuccess";

export const useUploadScan = (opportunityId: string) => {
  const queryClient = useQueryClient();
  const { locale } = useLocaleStore();
  const { data } = useFetchAccessToken();

  return useMutation({
    mutationKey: [QueryKeys.useUploadScan],
    mutationFn: (body: UploadScanBodyDto) =>
      salesforceServiceSingleton.uploadScan(
        data?.accessToken ?? "",
        opportunityId,
        body,
        locale
      ),
    onError: () => {
      notifyOnce(
        {
          toastType: "error",
          descriptionId: "scan.upload.error",
        },
        UPLOAD_SCAN_ERROR_TOAST_ID
      );
    },
    onSuccess: () => {
      notifyOnce(
        {
          toastType: "success",
          descriptionId: "scan.upload.success",
        },
        UPLOAD_SCAN_SUCCESS_TOAST_ID
      );
      queryClient.invalidateQueries([
        QueryKeys.useFetchRecentScans,
        QueryKeys.useUploadScan,
      ]);
    },
  });
};
