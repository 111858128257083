import Radio from "~/components/atoms/Radio/Radio";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";
import { RadioGroupOption } from "./RadioGroup.types";

type RadioGroupProps<T> = PropsWithClassName<{
  options: T[];
  onChange?: (value: T) => void;
  value?: T;
  disabled?: boolean;
}>;

const RadioGroup = <T extends RadioGroupOption>({
  options,
  onChange,
  value,
  className,
  disabled,
}: RadioGroupProps<T>) => {
  return (
    <div className={cn("flex flex-col gap-3", className)}>
      {options.map((option, index) => (
        <Radio
          key={index}
          checked={option.id == value?.id}
          option={option}
          onChange={onChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
