import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AnimatePresence, motion } from "framer-motion";
import { TreatmentPlanPhase } from "~/api/models/TreatmentPlan";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import PlanPhaseDetails from "~/components/molecules/PlanPhaseDetails/PlanPhaseDetails";
import PlanCategoryDetail from "./PlanCategoryDetail";

type PLanCategoryPhasesProps = {
  isLoading: boolean;
  phases: TreatmentPlanPhase[];
};

const PLanCategoryPhases = ({ phases, isLoading }: PLanCategoryPhasesProps) => {
  const [hasModalOpen, setHasModalOpen] = useState(false);
  const intl = useIntl();
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
  const [sectionIsHovered, setSectionIsHovered] = useState(false);
  const nextPhase = useCallback(() => {
    if (currentPhaseIndex === phases.length - 1) {
      setCurrentPhaseIndex(0);
    } else {
      setCurrentPhaseIndex(currentPhaseIndex + 1);
    }
  }, [currentPhaseIndex, phases.length]);

  const prevPhase = useCallback(() => {
    if (currentPhaseIndex === 0) {
      setCurrentPhaseIndex(phases.length - 1);
    } else {
      setCurrentPhaseIndex(currentPhaseIndex - 1);
    }
  }, [currentPhaseIndex, phases.length]);

  useEffect(() => {
    const handleKeyboardArrows = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") nextPhase();

      if (event.key === "ArrowLeft") prevPhase();
    };

    window.addEventListener("keydown", handleKeyboardArrows);

    return () => {
      window.removeEventListener("keydown", handleKeyboardArrows);
    };
  }, [nextPhase, prevPhase]);

  return (
    <div
      className="grid grid-cols-2 gap-20 overflow-x-hidden h-full py-10 no-scrollbar"
      onMouseEnter={() => setSectionIsHovered(true)}
      onMouseLeave={() => setSectionIsHovered(false)}
    >
      <AnimatePresence>
        {!isLoading && (
          <>
            <div className="flex grow pt-10 flex-col gap-10 justify-between">
              <motion.div
                className="relative z-10 "
                transition={{ duration: 0.7 }}
                initial={{ opacity: 0, x: "-100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "-100%" }}
              >
                <PlanPhaseDetails
                  currentPhaseIndex={currentPhaseIndex}
                  setCurrentPhaseIndex={setCurrentPhaseIndex}
                  stepCount={phases.length}
                  currentPhase={
                    phases.length > currentPhaseIndex
                      ? phases[currentPhaseIndex]
                      : undefined
                  }
                  stopTimer={hasModalOpen || sectionIsHovered}
                />
              </motion.div>
              <div className="flex flex-col gap-8">
                {phases.length > currentPhaseIndex &&
                  phases[currentPhaseIndex]?.details?.map((detail) => (
                    <PlanCategoryDetail
                      key={detail.title}
                      detail={detail}
                      open={hasModalOpen}
                      setOpen={setHasModalOpen}
                    />
                  ))}
                <div className="flex w-full justify-between">
                  {currentPhaseIndex !== 0 ? (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.7 }}
                      exit={{ opacity: 0 }}
                    >
                      <Button
                        variant={ButtonVariant.secondaryOutline}
                        size={ButtonSize.sm}
                        text={intl.formatMessage({
                          id: "actions.back",
                        })}
                        contentType={ButtonContentType.iconText}
                        onClick={() => prevPhase()}
                      />
                    </motion.div>
                  ) : (
                    <div></div>
                  )}
                  {currentPhaseIndex !== phases.length - 1 && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.7 }}
                      exit={{ opacity: 0 }}
                    >
                      <Button
                        variant={ButtonVariant.secondaryOutline}
                        size={ButtonSize.sm}
                        text={intl.formatMessage({
                          id: "actions.next",
                        })}
                        contentType={ButtonContentType.textIcon}
                        onClick={() => nextPhase()}
                      />
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
            <motion.div
              className="relative"
              transition={{ duration: 0.7 }}
              initial={{ opacity: 0, x: "100%", scale: 0.5 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: "100%", scale: 0.5 }}
            >
              {phases.map((phase) => {
                const phaseIndex = phases.indexOf(phase);
                return (
                  <motion.div
                    animate={{
                      x: phaseIndex > (currentPhaseIndex ?? 0) ? "100%" : 0,
                    }}
                    key={phase?.image.filename}
                    transition={{ duration: 0.4 }}
                    className="rounded-2xl absolute top-0 left-0 right-0 w-full h-full"
                  >
                    <StoryblokOptimizedImage
                      src={phase?.image.filename}
                      alt={phase?.image.alt}
                      className="rounded-2xl bg-beige-100 object-cover object-center w-full h-full"
                    />
                  </motion.div>
                );
              })}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PLanCategoryPhases;
