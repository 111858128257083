import { ScanDocumentType } from "~/api/salesForce/useFetchRecentScans";

export type RecentScansDto = {
  hair_scan: ScanFileDto;
  skin_scan: ScanFileDto;
};

export type ScanFileDto = {
  id: string;
  title: string;
  file_url: string;
  file_extension: string;
  content_size: number;
  created_at: string;
  latest_published_version_id: string;
};

export class ScanFile {
  id: string;
  title: string;
  fileUrl: string;
  fileExtension: string;
  contentSize: number;
  latestPublishedVersionId: string;
  constructor(scan: ScanFileDto) {
    this.id = scan.id;
    this.title = scan.title;
    this.fileUrl = scan.file_url;
    this.fileExtension = scan.file_extension;
    this.contentSize = scan.content_size;
    this.latestPublishedVersionId = scan.latest_published_version_id;
  }
}

export type UploadScanBodyDto = {
  document: File;
  title: string;
  document_type: ScanDocumentType;
};
