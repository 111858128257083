import { useQuery } from "@tanstack/react-query";
import { TreatmentFocusList } from "~/api/models/TreatmentFocus";
import { QueryKeys } from "~/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchTreatmentFocus = (type: "skin" | "hair") => {
  const { locale } = useLocaleStore();
  return useQuery<
    StoryblokMultiStoryResult<TreatmentFocusList>,
    TreatmentFocusList | undefined,
    TreatmentFocusList | undefined
  >({
    queryKey: [QueryKeys.useFetchHairTreatmentFocus, locale, type],
    queryFn: () =>
      storyblokServiceSingleton.get(
        type === "skin"
          ? storyblokRoutes.skinTreatmentFocus
          : storyblokRoutes.hairTreatmentFocus,
        {
          locale,
        }
      ),
    select: (data) =>
      data.data.stories.length ? data.data.stories[0].content : undefined,
  });
};
