// import { useIntl } from "react-intl";
// import { ReactComponent as Logout } from "~/assets/svg/logout.svg";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Dropdown from "~/components/atoms/Dropdown/Dropdown";
import { getInitials } from "~/utils/string.utils";

type ProfileDropdownProps = {
  displayName?: string;
};

const ProfileDropdown = ({ displayName }: ProfileDropdownProps) => {
  // const intl = useIntl();

  const handleLogout = () => {
    window.location.href = import.meta.env.VITE_SALESFORCE_DASHBOARD_URL;
  };

  return (
    <Dropdown
      hasArrow={false}
      align="end"
      onChange={handleLogout}
      customTrigger={
        <Button
          variant={ButtonVariant.secondaryOutline}
          customIcon={
            displayName ? (
              <span className="flex w-10 h-full cursor-pointer items-center justify-center rounded-full bg-beige-500 text-center text-black">
                {getInitials(displayName)}
              </span>
            ) : null
          }
          contentType={ButtonContentType.icon}
          size={ButtonSize.sm}
          className="p-0"
        />
      }
      options={[]}
      // options={[
      //   {
      //     id: "logout",
      //     title: intl.formatMessage({ id: "actions.logout" }),
      //     icon: <Logout className="w-[1.125rem]" />,
      //   },
      // ]}
    />
  );
};

export default ProfileDropdown;
