import { PropsWithChildren } from "react";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

const Pill = ({
  children,
  className,
}: PropsWithChildren<PropsWithClassName>) => {
  return (
    <div
      className={cn(
        "flex items-center w-fit rounded-full border border-grey-200 px-3 py-2",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Pill;
