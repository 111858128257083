import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import { useUpdateOpportunity } from "~/api/salesForce/useUpdateOpportunity";
import BotoxWrinklesAreas from "~/components/molecules/BotoxWrinklesRadioGroup/BotoxWrinklesAreas";
import PricingModalOption from "~/components/molecules/PricingModalOption/PricingModalOption";
import BotoxProductCard from "~/components/organisms/Pricing/BotoxProductCard/BotoxProductCard";
import useSkinProducts from "~/hooks/useSkinProducts";
import { Product } from "~/stores/checkout/product";
import useConfigurationSectionStore, {
  getToggleSkinSectionAndHandleSkinSectionLinks,
} from "~/stores/headConfiguration";
import {
  BotoxSkinSection,
  SkinSection,
} from "~/stores/headConfiguration/ConfigurationSection.types";
import { notifyOnce } from "~/utils/toast.utils";

const BotoxWrinklesCard = () => {
  const intl = useIntl();
  const { opportunityId } = useParams();
  const {
    mutateAsync,
    isError,
    isLoading: updating,
  } = useUpdateOpportunity(opportunityId as string);
  const { getProductByProductCardKey, setSkinProductByProductCardKey } =
    useSkinProducts();
  const botoxWrinklesProduct = getProductByProductCardKey(
    SkinProductKey.botoxWrinkles
  );
  const setBotoxWrinklesProduct = (product?: Product) => {
    setSkinProductByProductCardKey(SkinProductKey.botoxWrinkles, product);
  };
  const { data: allProducts, isLoading } = useFetchProducts();
  const [skinSections, setSkinSections] = useConfigurationSectionStore(
    (state) => [state.skinSections, state.setSkinSections]
  );

  const botoxSkinSections = useMemo(
    () =>
      skinSections.filter((section) =>
        section.includes("botox")
      ) as BotoxSkinSection[],
    [skinSections]
  );
  const botoxWrinklesProductSessions = useMemo(
    () =>
      botoxWrinklesProduct
        ? botoxWrinklesProduct.sessions === 1
          ? 1
          : 2
        : undefined,
    [botoxWrinklesProduct]
  );

  const [selectedSessions, setSelectedSessions] = useState<
    1 | 2 | "none" | undefined
  >(botoxWrinklesProductSessions);

  const changeSessions = (session?: 1 | 2) => {
    setSelectedSessions(session);
  };

  const defaultProduct = useMemo(() => {
    let products = allProducts.skin.botox.wrinkles.x2;
    if (selectedSessions === 1) {
      products = allProducts.skin.botox.wrinkles.x1;
    }
    return products.find(
      (product) => product.areas === botoxSkinSections.length
    );
  }, [allProducts, botoxSkinSections, selectedSessions]);

  const [forceShowModal, setForceShowModal] = useState(false);

  const triggerForceShowModal = () => {
    setForceShowModal(true);
  };

  const [selectedBotoxAreas, setSelectedBotoxAreas] =
    useState<SkinSection[]>(botoxSkinSections);

  const toggleBotoxArea = (section: SkinSection) => {
    const newSelectedBotoxAreas = getToggleSkinSectionAndHandleSkinSectionLinks(
      section,
      selectedBotoxAreas
    );
    setSelectedBotoxAreas(newSelectedBotoxAreas);
  };
  const reset = () => {
    setSelectedBotoxAreas(botoxSkinSections);
    setSelectedSessions(botoxWrinklesProductSessions);
  };
  const selectedBotoxWrinklesProduct = useMemo(() => {
    return [
      ...allProducts.skin.botox.wrinkles.x1,
      ...allProducts.skin.botox.wrinkles.x2,
    ].find(
      (product) =>
        product.areas === selectedBotoxAreas.length &&
        product.sessions === selectedSessions
    );
  }, [allProducts, selectedBotoxAreas, selectedSessions]);

  const save = async () => {
    const newSkinSections = skinSections
      .filter((section) => !section.includes("botox"))
      .concat(selectedBotoxAreas);

    try {
      await mutateAsync({
        skin_configuration: newSkinSections,
      });
      if (!isError) {
        if (botoxWrinklesProduct?.id !== selectedBotoxWrinklesProduct?.id) {
          setBotoxWrinklesProduct(selectedBotoxWrinklesProduct);
        }

        setSkinSections(newSkinSections);
      }
    } catch (e) {
      notifyOnce(
        {
          toastType: "error",
          descriptionId: "opportunity.update.error",
        },
        "useUpdateOpportunityError"
      );
    }
  };
  const setNoneOption = () => {
    setSelectedSessions("none");
  };
  useEffect(() => {
    if (botoxWrinklesProductSessions && !selectedSessions) {
      setSelectedSessions(botoxWrinklesProductSessions);
    }
  }, [botoxWrinklesProductSessions, selectedSessions]);

  return (
    <BotoxProductCard
      saveIsLoading={updating}
      hideNoneOption
      onSave={save}
      onReset={reset}
      skinProductFamilyKey={SkinProductKey.botoxWrinkles}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.botoxWrinkles",
      })}
      options={[
        ...allProducts.skin.botox.wrinkles.x1,
        ...allProducts.skin.botox.wrinkles.x2,
      ]}
      optionSubtitleFormatter={(option) =>
        intl.formatMessage(
          {
            id: "pricing.card.areas",
          },
          {
            areas: option?.areas,
          }
        )
      }
      modalTitle={intl.formatMessage({ id: "pricing.botoxWrinkles" })}
      isLoading={isLoading}
      customContent={() => (
        <>
          <PricingModalOption
            className="animate-fade-in"
            title={intl.formatMessage({
              id: "treatment.pricing.botoxWrinkles.none",
            })}
            selected={!selectedBotoxWrinklesProduct}
            onSelected={setNoneOption}
          />
          <PricingModalOption
            className="animate-fade-in"
            title={intl.formatMessage({
              id: "pricing.botoxWrinkles.modal.x1",
            })}
            selected={selectedBotoxWrinklesProduct?.type === "botox x1"}
            onSelected={() => changeSessions(1)}
          >
            <BotoxWrinklesAreas
              open={selectedSessions === 1}
              options={allProducts.skin.botox.wrinkles.x1}
              selected={selectedBotoxWrinklesProduct}
              toggleBotoxArea={toggleBotoxArea}
              selectedBotoxAreas={selectedBotoxAreas}
            />
          </PricingModalOption>
          <PricingModalOption
            className="animate-fade-in"
            title={intl.formatMessage({
              id: "pricing.botoxWrinkles.modal.x2",
            })}
            selected={selectedBotoxWrinklesProduct?.type === "botox x2"}
            onSelected={() => changeSessions(2)}
          >
            <BotoxWrinklesAreas
              open={selectedSessions === 2}
              options={allProducts.skin.botox.wrinkles.x2}
              selected={selectedBotoxWrinklesProduct}
              selectedBotoxAreas={selectedBotoxAreas}
              toggleBotoxArea={toggleBotoxArea}
            />
          </PricingModalOption>
        </>
      )}
      forceShowModal={forceShowModal}
      stopForceShowModal={() => setForceShowModal(false)}
      customAddButtonAction={
        defaultProduct
          ? () => {
              setBotoxWrinklesProduct(
                botoxWrinklesProduct ? undefined : defaultProduct
              );
            }
          : triggerForceShowModal
      }
    />
  );
};

export default BotoxWrinklesCard;
