import {
  SkinTreatmentFocusItem,
  TreatmentFocusItem,
} from "~/api/models/TreatmentFocus";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import useTreatmentFocusStore from "~/stores/treatmentFocus/useTreatmentFocusStore";
import { cn } from "~/utils";

type TreatmentFocusOptionProps = {
  focus: TreatmentFocusItem;
  type: "hair" | "skin";
};

const TreatmentFocusOption = ({ focus, type }: TreatmentFocusOptionProps) => {
  const [
    selectedHairTreatmentFocus,
    selectedSkinTreatmentFocus,
    toggleSelectedHairTreatmentFocus,
    toggleSelectedSkinTreatmentFocus,
  ] = useTreatmentFocusStore((state) => [
    state.selectedHairTreatmentFocus,
    state.selectedSkinTreatmentFocus,
    state.toggleSelectedHairTreatmentFocus,
    state.toggleSelectedSkinTreatmentFocus,
  ]);

  return (
    <Button
      onClick={() =>
        type === "hair"
          ? toggleSelectedHairTreatmentFocus(focus)
          : toggleSelectedSkinTreatmentFocus(focus as SkinTreatmentFocusItem)
      }
      variant={ButtonVariant.darkSecondaryOutline}
      size={ButtonSize.sm}
      className={cn(
        "text-base font-normal border-2 border-grey-50 text-grey-700 normal-case transition-all max-w-none",
        {
          "border-grey-900": (type === "hair"
            ? selectedHairTreatmentFocus
            : selectedSkinTreatmentFocus
          ).some((selectedFocus) => selectedFocus._uid === focus._uid),
        }
      )}
    >
      {focus.value}
    </Button>
  );
};

export default TreatmentFocusOption;
