import { useIntl } from "react-intl";
import contractSignedImage from "~/assets/images/contract.png";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import { Dialog, DialogContent } from "~/components/atoms/Dialog/Dialog";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";

type ContractSignedModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  email?: string;
};

const ContractSignedModal = ({
  open,
  setOpen,
  email,
}: ContractSignedModalProps) => {
  const intl = useIntl();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex flex-col items-center justify-between gap-20">
        <div className="flex flex-col items-center justify-between gap-6">
          <img
            src={contractSignedImage}
            alt={intl.formatMessage({ id: "pricing.contract.modal.title" })}
            className="h-64 w-64 py-7 px-5"
          />
          <div className="flex flex-col gap-2 items-center">
            <Headline level={4}>
              <Copy id="pricing.contract.modal.title" />
            </Headline>
            {email && (
              <div className="flex flex-col items-center">
                <Subtitle className="text-grey-400">
                  <Copy id="pricing.contract.modal.description" />
                </Subtitle>
                <Subtitle>{email}</Subtitle>
              </div>
            )}
          </div>
        </div>
        <Button
          text={intl.formatMessage({ id: "pricing.contract.modal.cta" })}
          className="w-full max-w-none"
          onClick={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ContractSignedModal;
