import { toast } from "react-toastify";
import {
  faCircleCheck,
  faCircleInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomToast, {
  AUTO_CLOSE,
} from "~/components/atoms/CustomToast/CustomToast";
import { CopyKeys } from "./types";

type ToastType = "success" | "error" | "info";

type NotifyProps = {
  toastType: ToastType;
  title?: string;
  titleId?: CopyKeys;
  description?: string;
  descriptionId?: CopyKeys;
  toastId?: string;
  delay?: number;
  autoClose?: number | false;
  closeButton?: boolean;
};

export const notify = ({
  toastType,
  description,
  descriptionId,
  toastId,
  delay,
  title,
  titleId,
  autoClose = AUTO_CLOSE,
  closeButton = true,
}: NotifyProps) => {
  if (toastType === "success") {
    toast.success(
      <CustomToast
        description={description}
        descriptionId={descriptionId}
        title={"Success" ?? title}
        titleId={titleId}
      />,
      {
        icon: (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-lg text-green-900"
          />
        ),
        toastId,
        delay,
        autoClose,
        closeButton,
      }
    );
  }
  if (toastType === "error") {
    toast.error(
      <CustomToast
        description={description}
        descriptionId={descriptionId}
        title={title ?? "Error"}
        titleId={titleId}
      />,
      {
        icon: (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-lg text-red-900"
          />
        ),
        toastId,
        delay,
        autoClose,
        closeButton,
      }
    );
  }
  if (toastType === "info") {
    toast.info(
      <CustomToast
        description={description}
        descriptionId={descriptionId}
        title={title ?? "Informative"}
        titleId={titleId}
      />,
      {
        icon: (
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="text-lg text-yellow-900"
          />
        ),
        toastId,
        delay,
        autoClose,
        closeButton,
      }
    );
  }
};

export const notifyOnce = (props: NotifyProps, toastId: string) =>
  notify({ ...props, toastId });

export const dismiss = (toastId: React.MutableRefObject<null>) => {
  const currentToastId = toastId.current;

  if (currentToastId !== null) {
    toast.dismiss(currentToastId);
  }
};

export const dismissAll = () => toast.dismiss();

export const clearWaitingQueue = () => {
  toast.clearWaitingQueue();
};
