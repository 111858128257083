import { PropsWithChildren, ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import { motion } from "framer-motion";
import { Benefit } from "~/api/models/Benefit";
import { ProductImage } from "~/api/models/ProductDetails";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Pill from "~/components/atoms/Pill/Pill";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import ProductDetailCard from "~/components/molecules/ProductDetailCard/ProductDetailCard";
import TitleDropdown from "~/components/molecules/TitleDropdown/TitleDropdown";
import useProductCardContext from "~/hooks/useProductCardContext";
import { StoryblokAsset } from "~/services/storyblok";
import { cn, getImageBasedOnGender } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

// value based on a 1080px width to collapse instead of break line
const maximumFocusesCharacters = 60;

type OfferCardProps = PropsWithClassName<
  PropsWithChildren<{
    title?: string;
    price?: string;
    image?: ProductImage;
    buttonText?: string;
    buttonAction?: () => void;
    buttonVariant?: ButtonVariant;
    loadingDetails?: boolean;
    focuses?: string[];
    benefits?: Benefit[];
    treatments?: ReactNode;
    customAddButtonAction?: () => void;
    customGetImageBasedOnGender?: (
      image?: ProductImage,
      gender?: "male" | "female"
    ) => StoryblokAsset | undefined;
    opportunityGender?: "male" | "female";
  }>
>;

const OfferCard = ({
  title,
  price,
  image,
  buttonAction,
  buttonText,
  loadingDetails,
  children,
  buttonVariant = ButtonVariant.primary,
  className,
  focuses = [],
  benefits = [],
  treatments,
  customAddButtonAction,
  customGetImageBasedOnGender,
  opportunityGender,
}: OfferCardProps) => {
  const intl = useIntl();
  const { isSuggested, isSummaryCard } = useProductCardContext();

  const formattedFocuses = useMemo(() => {
    if (!focuses?.length) return "";

    const focusesToShow: string[] = [];
    for (const focus of focuses) {
      const totalLength =
        focusesToShow.join(", ").length + focus.length + ", ".length;

      if (totalLength < maximumFocusesCharacters) {
        focusesToShow.push(focus);
        continue;
      }
      break;
    }

    const joinedFocuses = focusesToShow.join(", ");
    const focusesToCollapse = Math.abs(focuses.length - focusesToShow.length);
    const restOfFocuses =
      focusesToCollapse > 0 ? `, +${focusesToCollapse}` : "";

    return `${joinedFocuses}${restOfFocuses}`;
  }, [focuses]);

  const imageByGender = useMemo(() => {
    if (customGetImageBasedOnGender) {
      return customGetImageBasedOnGender(image, opportunityGender);
    } else {
      return getImageBasedOnGender({ image, opportunityGender });
    }
  }, [opportunityGender, image, customGetImageBasedOnGender]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut", stiffness: 10 }}
    >
      <div
        className={cn(
          "col-span-auto p-1 border rounded-[1.25rem] border-grey-200 grid grid-cols-[auto_1fr] w-full pr-6 transition-all duration-300 gap-x-4 items-center",
          {
            "border-none p-0": isSummaryCard,
          },
          className
        )}
      >
        <div
          className={cn(
            "bg-pink-500 rounded-2xl w-offer-image h-offer-image shrink-0 flex items-end justify-center",
            {
              "h-summary-offer-image w-summary-offer-image": isSummaryCard,
            }
          )}
        >
          {loadingDetails ? (
            <SpinnerIcon className="text-white" />
          ) : (
            <StoryblokOptimizedImage
              src={imageByGender?.filename}
              alt={imageByGender?.alt}
              className="rounded-2xl max-w-full object-cover object-center w-full h-full"
            />
          )}
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex gap-1 flex-col">
            <div className="flex gap-2 items-center">
              <Subtitle>{title}</Subtitle>
              {focuses?.length > 0 && (
                <Pill className="text-green-700 flex gap-1 items-center px-2 py-1 uppercase">
                  <Subtitle level={4}>
                    {intl.formatMessage({
                      id: "configuration.skin.card.focus",
                    })}
                  </Subtitle>
                  <Subtitle level={4}>{formattedFocuses}</Subtitle>
                </Pill>
              )}
            </div>
            {children}
          </div>
          <div className="flex gap-6 items-center">
            {!isSuggested && price && (
              <Subtitle level={2} className="min-w-max">
                {price}
              </Subtitle>
            )}
            {buttonText && (
              <Button
                variant={buttonVariant}
                text={buttonText}
                onClick={customAddButtonAction ?? buttonAction}
                size={ButtonSize.sm}
              />
            )}
          </div>
        </div>

        <div></div>
        {!isSummaryCard && (benefits.length > 0 || treatments) && (
          <div className="border-t border-grey-50 py-4 flex flex-col gap-3">
            {treatments && (
              <TitleDropdown
                title={intl.formatMessage({
                  id: "pricing.card.treatments",
                })}
              >
                {treatments}
              </TitleDropdown>
            )}
            {benefits.length > 0 && (
              <TitleDropdown
                title={intl.formatMessage({
                  id: "pricing.card.benefits",
                })}
              >
                {benefits.map((benefit) => (
                  <ProductDetailCard
                    title={benefit.title}
                    image={benefit.image}
                    imageClassName="p-3"
                    key={benefit.title}
                  />
                ))}
              </TitleDropdown>
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default OfferCard;
