import { PropsWithChildren } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchProductCategories } from "~/api/storyblok/useFetchProductCategories";
import { ErrorElement } from "~/components/organisms/ErrorElement/ErrorElement";
import ScreenLoader from "~/components/organisms/ScreenLoader/ScreenLoader";
import useFlattenedProducts from "~/hooks/useFlattenedProducts";
import useInitializeProducts from "~/hooks/useInitializeProducts";
import useOpportunityProducts from "~/hooks/useOpportunityProducts";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import useSelectedProductCategoriesStore from "~/stores/selectedProductCategories/useSelectedProductCategoriesStore";

type InitializedProps = PropsWithChildren;

const Initialized = ({ children }: InitializedProps) => {
  const intl = useIntl();
  const [
    specialConditionsValues,
    otherSpecialConditions,
    setPaymentMethod,
    setSpecialConditionsValues,
    setOtherSpecialConditions,
    setDiscountCode,
    setReferralCode,
  ] = useCheckoutStore((state) => [
    state.specialConditionsValues,
    state.otherSpecialConditions,
    state.setPaymentMethod,
    state.setSpecialConditionsValues,
    state.setOtherSpecialConditions,
    state.setDiscountCode,
    state.setReferralCode,
  ]);
  const { data: HSDropdownOptions, isLoading: loadingHSDropdownOptions } =
    useFetchProductCategories();
  const [setSelectedProductCategories, selectedProductCategories] =
    useSelectedProductCategoriesStore((state) => [
      state.setSelectedProductCategories,
      state.selectedProductCategories,
    ]);
  const { opportunityId } = useParams();
  const {
    data: opportunity,
    isLoading: loadingOpportunity,
    isError: opportunityError,
  } = useFetchOpportunity({
    id: opportunityId as string,
  });
  const { getCategoriesByOpportunityProducts } =
    useOpportunityProducts(opportunity);
  const { isLoading: loadingProducts } = useFlattenedProducts();

  useEffect(() => {
    if (
      !loadingHSDropdownOptions &&
      HSDropdownOptions &&
      !loadingOpportunity &&
      !loadingProducts
    ) {
      const data = HSDropdownOptions.map((group) =>
        group.options?.length > 0 ? group.options : group
      ).flat();
      if (!selectedProductCategories.length) {
        setSelectedProductCategories(
          getCategoriesByOpportunityProducts(data) ?? data
        );
      }
    }
  }, [
    HSDropdownOptions,
    loadingHSDropdownOptions,
    setSelectedProductCategories,
    loadingOpportunity,
    loadingProducts,
  ]);

  useEffect(() => {
    if (opportunity?.paymentMeans) {
      setPaymentMethod(opportunity.paymentMeans);
    }
  }, [opportunity?.paymentMeans, setPaymentMethod]);

  useEffect(() => {
    if (opportunity?.specialConditions && specialConditionsValues === null) {
      setSpecialConditionsValues(opportunity.specialConditions);
    }
  }, [
    opportunity?.specialConditions,
    setSpecialConditionsValues,
    specialConditionsValues,
  ]);

  useEffect(() => {
    if (
      opportunity?.otherSpecialConditions &&
      otherSpecialConditions === null
    ) {
      setOtherSpecialConditions(opportunity.otherSpecialConditions);
    }
  }, [
    opportunity?.otherSpecialConditions,
    setOtherSpecialConditions,
    otherSpecialConditions,
  ]);

  useEffect(() => {
    if (opportunity?.discountCode) {
      setDiscountCode(opportunity.discountCode);
    }
  }, [opportunity?.discountCode, setDiscountCode]);

  useEffect(() => {
    if (opportunity?.referralCode) {
      setReferralCode(opportunity.referralCode);
    }
  }, [opportunity?.referralCode, setReferralCode]);

  useInitializeProducts({ opportunity, loadingOpportunity });

  return (
    <>
      {loadingOpportunity ? (
        <ScreenLoader />
      ) : !opportunity || opportunityError ? (
        <ErrorElement
          hideButtons
          customTitle={intl.formatMessage({
            id: "opportunity.error.title",
          })}
          customDescription={intl.formatMessage({
            id: "opportunity.error.description",
          })}
        />
      ) : (
        children
      )}
    </>
  );
};

export default Initialized;
