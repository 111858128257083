import { useQuery } from "@tanstack/react-query";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchSpecialConditions = () => {
  const { locale } = useLocaleStore();
  const { data } = useFetchAccessToken();

  return useQuery({
    queryKey: [QueryKeys.useFetchSpecialConditions, locale],
    queryFn: () =>
      salesforceServiceSingleton.getSpecialConditions(
        data?.accessToken ?? "",
        locale
      ),
    enabled: !!data?.ensureAccessTokenIsValid?.(),
  });
};
