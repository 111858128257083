import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import useTreatmentType from "~/hooks/useTreatmentType";
import useConfigurationSectionStore from "~/stores/headConfiguration";
import { HairGraftSection } from "~/stores/headConfiguration/ConfigurationSection.types";
import { cn } from "~/utils";
import { hasCommonElements } from "~/utils/array.utils";

type HeadSections = {
  eyebrowSection: HairGraftSection;
  beardSection: HairGraftSection;
  headSections: HairGraftSection[];
};

const HEAD_CONFIGURATION_SECTIONS: HeadSections = {
  eyebrowSection: 8,
  beardSection: 7,
  headSections: [1, 2, 3, 4, 5, 6],
};

const HeadConfiguration = () => {
  const [headGraftSections, toggleHeadGraftSection] =
    useConfigurationSectionStore((state) => [
      state.hairGraftSections,
      state.toggleHairGraftSection,
    ]);

  const { opportunityId } = useParams();
  const { data: opportunity } = useFetchOpportunity({
    id: opportunityId as string,
  });

  const { beardSelected, eyebrowSelected, hairPlanType } = useTreatmentType();

  useEffect(() => {
    if (
      !eyebrowSelected &&
      headGraftSections.includes(HEAD_CONFIGURATION_SECTIONS.eyebrowSection)
    ) {
      toggleHeadGraftSection(HEAD_CONFIGURATION_SECTIONS.eyebrowSection);
    }
  }, [eyebrowSelected, headGraftSections, toggleHeadGraftSection]);

  useEffect(() => {
    if (
      !beardSelected &&
      headGraftSections.includes(HEAD_CONFIGURATION_SECTIONS.beardSection)
    ) {
      toggleHeadGraftSection(HEAD_CONFIGURATION_SECTIONS.beardSection);
    }
  }, [beardSelected, headGraftSections, toggleHeadGraftSection]);

  useEffect(() => {
    if (
      hairPlanType === "none" &&
      hasCommonElements(
        headGraftSections,
        HEAD_CONFIGURATION_SECTIONS.headSections
      )
    ) {
      toggleHeadGraftSection(HEAD_CONFIGURATION_SECTIONS.headSections);
    }
  }, [beardSelected, headGraftSections, toggleHeadGraftSection, hairPlanType]);

  const toggleHeadSection = (section: HairGraftSection) => {
    if (hairPlanType !== "none") {
      toggleHeadGraftSection(section);
    }
  };

  return (
    <>
      {opportunity?.gender === "female" ||
      (eyebrowSelected && !beardSelected && hairPlanType === "none") ? (
        <svg
          className="w-full h-full"
          viewBox="0 0 354 480"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M210.207 364.609C210.207 364.609 209.372 370.865 199.989 371.49C197.279 371.699 194.568 372.324 192.483 373.993C180.806 383.376 172.257 377.329 167.669 373.993C165.584 372.533 162.873 371.699 160.163 371.49C150.571 370.865 149.945 364.609 149.945 364.609"
            stroke="black"
            strokeWidth="1.04258"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M35.4688 286C36.7198 306.852 40.4731 327.495 46.3116 347.721C60.2822 396.097 91.1426 438.008 133.68 465.115C135.14 465.95 136.599 466.784 138.059 467.618C149.736 473.873 163.081 477.001 176.426 477.001H183.307C196.652 477.001 209.997 473.873 221.674 467.618C223.133 466.784 224.593 465.95 226.053 465.115C268.59 438.008 299.242 396.097 313.421 347.721C319.259 327.703 322.804 307.06 324.264 286.208"
            stroke="black"
            strokeWidth="1.04258"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M29.2133 217.397C29.2133 217.397 7.94465 210.516 11.2809 236.789C13.7831 256.807 21.2897 282.663 35.6773 291.212"
            stroke="black"
            strokeWidth="1.04258"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M330.102 217.397C330.102 217.397 351.371 210.516 348.035 236.789C345.532 256.598 338.234 282.037 324.264 290.795"
            stroke="black"
            strokeWidth="1.04258"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M178.762 3C32.1755 3 0.272532 139.37 9.44725 201.716C12.992 226.321 21.9582 244.253 24.8775 257.39C25.92 262.394 27.3796 268.024 28.2137 274.28C28.6307 278.033 35.3033 291.587 35.3033 289.71C35.3033 289.71 22.3752 224.444 73.4617 185.66C110.786 157.302 146.025 175.443 178.554 175.026C211.082 175.443 246.53 156.259 285.314 182.533C329.311 212.559 322.013 289.084 322.013 289.084C322.013 291.169 328.269 276.156 328.894 274.071C330.771 268.233 331.188 262.186 332.23 257.181C335.15 244.045 343.907 226.112 347.661 201.507C357.252 139.37 345.158 3 178.762 3Z"
            fill="#F8F8F8"
          />
          <g
            filter="url(#filter0_b_8802_167239)"
            onClick={() => toggleHeadSection(3)}
            id="section3Female"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(3) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M137.5 124.5C144 149 143.5 171 143.5 171C143.5 171 167.638 175.378 184.5 176C203 176 225.5 169 225.5 169C225.5 169 225.5 144 231 124.5C231 124.5 209 130 184.5 130C168 130 137.5 124.5 137.5 124.5Z" />
            <path
              d="M137.5 124.5C144 149 143.5 171 143.5 171C143.5 171 167.638 175.378 184.5 176C203 176 225.5 169 225.5 169C225.5 169 225.5 144 231 124.5C231 124.5 209 130 184.5 130C168 130 137.5 124.5 137.5 124.5Z"
              stroke="white"
            />
          </g>
          <path
            d="M185.614 150.968C185.614 150.072 184.926 149.4 183.934 149.4C182.478 149.4 182.03 150.76 182.03 150.76L180.366 149.976C180.366 149.976 181.23 147.608 183.934 147.608C185.982 147.608 187.55 148.904 187.55 150.968C187.55 152.568 186.174 153.32 186.174 153.32C186.174 153.32 187.822 153.736 187.822 155.88C187.822 157.912 186.126 159.192 183.95 159.192C180.83 159.192 180.03 156.6 180.03 156.6L181.598 155.688C181.598 155.688 182.094 157.4 183.95 157.4C185.166 157.4 185.902 156.744 185.902 155.88C185.902 154.696 184.878 154.296 183.87 154.296H183.07V152.536H183.678C184.846 152.536 185.614 152.104 185.614 150.968Z"
            id="section3TextFemale"
            onClick={() => toggleHeadSection(3)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(3) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <path
            d="M242.5 76.5C242.5 76.5 249.804 78.5176 259.5 83.5C273.452 90.6693 291.822 101.941 296 105C310.082 115.313 315.5 128.5 315.5 128.5C315.5 128.5 276.5 142 231.5 124"
            fill="#EFECE4"
          />
          <g
            onClick={() => toggleHeadSection(4)}
            id="section4Female"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path
              d="M242.5 76.5C242.5 76.5 239.191 92.2178 238.5 95.5C236.5 105 231.5 124 231.5 124C276.5 142 315.5 128.5 315.5 128.5C315.5 128.5 310.082 115.313 296 105C291.822 101.941 273.452 90.6693 259.5 83.5C249.804 78.5176 242.5 76.5 242.5 76.5Z"
              stroke="white"
              strokeWidth="1.45525"
              strokeMiterlimit="10"
            />
            <path d="M242.5 76.5C242.5 76.5 249.804 78.5176 259.5 83.5C273.452 90.6693 291.822 101.941 296 105C310.082 115.313 315.5 128.5 315.5 128.5C315.5 128.5 276.5 142 231.5 124C231.5 124 236.5 105 238.5 95.5C239.191 92.2178 242.5 76.5 242.5 76.5Z" />
          </g>
          <path
            d="M264.194 116.568L269.89 107.8H272.034V114.776H273.266V116.568H272.034V119H270.114V116.568H264.194ZM267.33 114.776H270.146V110.44L267.33 114.776Z"
            onClick={() => toggleHeadSection(4)}
            id="section4TextFemale"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            filter="url(#filter1_b_8802_167239)"
            onClick={() => toggleHeadSection(2)}
            id="section2LeftFemale"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M225.5 169C226.5 147.5 227.5 139 231.5 124.5C254 133 287.5 137 314.5 129C316.5 144 303.5 182 308 208C301.026 196.542 297.526 191.04 280.191 179.625C280.066 179.544 279.931 179.468 279.796 179.405C266.91 173.427 258.327 166.513 225.5 169Z" />
            <path
              d="M225.5 169C226.5 147.5 227.5 139 231.5 124.5C254 133 287.5 137 314.5 129C316.5 144 303.5 182 308 208C301.026 196.542 297.526 191.04 280.191 179.625C280.066 179.544 279.931 179.468 279.796 179.405C266.91 173.427 258.327 166.513 225.5 169Z"
              stroke="white"
            />
          </g>
          <path
            d="M265.703 159L269.815 153.784C270.231 153.24 271.111 152.392 271.111 151.128C271.111 150.232 270.503 149.4 269.447 149.4C267.799 149.4 267.447 151.032 267.447 151.032L265.735 150.408C265.735 150.408 266.455 147.608 269.447 147.608C271.863 147.608 273.031 149.24 273.031 151.096C273.031 152.488 272.327 153.48 272.055 153.848L269.479 157.176H273.079V159H265.703Z"
            onClick={() => toggleHeadSection(2)}
            id="section2LeftTextFemale"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            filter="url(#filter2_b_8802_167239)"
            id="section2RightFemale"
            onClick={() => toggleHeadSection(2)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M143.5 171C142.5 149.5 141 139 137 124.5C114.5 133 81 138.5 54 130.5C50 144 55 187.5 47.5 215C54.5 203.5 67.5 188.5 85.5 177.5C98.5 171.5 110.5 168.5 143.5 171Z" />
            <path
              d="M143.5 171C142.5 149.5 141 139 137 124.5C114.5 133 81 138.5 54 130.5C50 144 55 187.5 47.5 215C54.5 203.5 67.5 188.5 85.5 177.5C98.5 171.5 110.5 168.5 143.5 171Z"
              stroke="white"
            />
          </g>
          <path
            d="M94.7025 160L98.8145 154.784C99.2305 154.24 100.111 153.392 100.111 152.128C100.111 151.232 99.5025 150.4 98.4465 150.4C96.7985 150.4 96.4465 152.032 96.4465 152.032L94.7345 151.408C94.7345 151.408 95.4545 148.608 98.4465 148.608C100.863 148.608 102.031 150.24 102.031 152.096C102.031 153.488 101.327 154.48 101.055 154.848L98.4785 158.176H102.079V160H94.7025Z"
            id="section2RightTextFemale"
            onClick={() => toggleHeadSection(2)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <path
            d="M242.5 11.5C245.5 13 251 36 217 33.5C194 33.5 194 33.5 179.5 33.5C165 33.5 166 33.5 144 33.5C124.501 32.6263 124.5 14.5 127.5 9.5"
            fill="#EFECE4"
          />
          <g
            id="section6Female"
            onClick={() => toggleHeadSection(6)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(6) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M242.5 11.5C245.5 13 251 36 217 33.5C194 33.5 194 33.5 179.5 33.5C165 33.5 166 33.5 144 33.5C124.501 32.6263 124.5 14.5 127.5 9.5C127.5 9.5 149.291 1.99975 184 2C220.791 2.00026 242.5 11.5 242.5 11.5Z" />
            <path
              d="M242.5 11.5C245.5 13 251 36 217 33.5C194 33.5 194 33.5 179.5 33.5C165 33.5 166 33.5 144 33.5C124.501 32.6263 124.5 14.5 127.5 9.5C127.5 9.5 149.291 1.99975 184 2C220.791 2.00026 242.5 11.5 242.5 11.5Z"
              stroke="white"
              strokeWidth="1.45525"
              strokeMiterlimit="10"
            />
          </g>
          <path
            d="M185.852 11.28L183.772 14.656C183.772 14.656 183.98 14.608 184.156 14.608C186.46 14.608 187.948 16.496 187.948 18.4C187.948 20.304 186.428 22.192 184.028 22.192C181.628 22.192 180.108 20.304 180.108 18.432C180.108 17.472 180.444 16.64 180.732 16.112C180.908 15.792 184.252 10.288 184.252 10.288L185.852 11.28ZM184.028 16.4C182.924 16.4 182.044 17.184 182.044 18.4C182.044 19.616 182.924 20.4 184.028 20.4C185.132 20.4 186.012 19.616 186.012 18.4C186.012 17.184 185.132 16.4 184.028 16.4Z"
            id="section6TextFemale"
            onClick={() => toggleHeadSection(6)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(6) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <path
            d="M339.5 103.5C331.5 121.5 327 122.5 315.5 128C313.5 155 300.999 199 310 212C319 225 340.499 230.5 340.499 230.5"
            fill="#EFECE4"
          />
          <path
            d="M339.5 103.5C331.5 121.5 327 122.5 315.5 128C313.5 155 300.999 199 310 212C319 225 340.499 230.5 340.499 230.5C340.499 230.5 352.5 191.5 350 157C347.391 121 339.5 103.5 339.5 103.5Z"
            fill="#EFECE4"
          />
          <path
            d="M339.5 103.5C331.5 121.5 327 122.5 315.5 128C313.5 155 300.999 199 310 212C319 225 340.499 230.5 340.499 230.5C340.499 230.5 352.5 191.5 350 157C347.391 121 339.5 103.5 339.5 103.5Z"
            stroke="white"
            strokeWidth="1.45525"
            strokeMiterlimit="10"
          />
          <path
            d="M27.4991 94.4996C29.999 117.999 40.9988 125.499 52.9988 131.499C53.1337 158.573 52.9987 206.499 45.3659 219.5C37.7331 232.5 17.999 235.499 17.999 235.499"
            fill="#EFECE4"
          />
          <path
            d="M27.4991 94.4996C29.999 117.999 40.9988 125.499 52.9988 131.499C53.1337 158.573 52.9987 206.499 45.3659 219.5C37.7331 232.5 17.999 235.499 17.999 235.499C17.999 235.499 4.28482 192.128 9.49902 157.999C14.999 121.999 27.4991 94.4996 27.4991 94.4996Z"
            fill="#EFECE4"
          />
          <path
            d="M27.4991 94.4996C29.999 117.999 40.9988 125.499 52.9988 131.499C53.1337 158.573 52.9987 206.499 45.3659 219.5C37.7331 232.5 17.999 235.499 17.999 235.499C17.999 235.499 4.28482 192.128 9.49902 157.999C14.999 121.999 27.4991 94.4996 27.4991 94.4996Z"
            stroke="white"
            strokeWidth="1.45525"
            strokeMiterlimit="10"
          />
          <g
            filter="url(#filter3_b_8802_167239)"
            id="section5LeftFemale"
            onClick={() => toggleHeadSection(5)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M312.5 53C292.5 29.5217 258 14.5 243 11.5C250 29 229.5 34 226 33.5C243 48 242.5 61.5 242.5 76.5C252 78.5 272.697 91.1453 285.5 98.5C309 112 315.5 128 315.5 128C324 126 336.5 116 339 102C337 95.5 325.704 68.5 312.5 53Z" />
            <path
              d="M312.5 53C292.5 29.5217 258 14.5 243 11.5C250 29 229.5 34 226 33.5C243 48 242.5 61.5 242.5 76.5C252 78.5 272.697 91.1453 285.5 98.5C309 112 315.5 128 315.5 128C324 126 336.5 116 339 102C337 95.5 325.704 68.5 312.5 53Z"
              stroke="white"
            />
          </g>
          <path
            d="M288.992 66.32C288.992 68.944 286.944 70.192 285.008 70.192C282.048 70.192 281.088 67.6 281.088 67.6L282.688 66.688C282.688 66.688 283.264 68.4 285.008 68.4C286.304 68.4 287.056 67.488 287.056 66.32C287.056 65.12 286.336 64.24 284.672 64.24C283.12 64.24 281.664 65.28 281.664 65.28V58.8H288.864V60.624H283.584V62.736C283.584 62.736 284.208 62.48 285.168 62.48C287.152 62.48 288.992 63.696 288.992 66.32Z"
            id="section5LeftTextFemale"
            onClick={() => toggleHeadSection(5)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section1Female"
            onClick={() => toggleHeadSection(1)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(1) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
            filter="url(#filter4_b_8802_167239)"
          >
            <path d="M226.5 33.5008C200.5 33.5008 206.5 33.4998 180.5 33.5C154.5 33.5003 156 33.5008 142.999 33.5008C137.499 38.5008 126.5 47.001 125.999 73.501C125.876 80.0278 129.499 98.501 137.5 124.001C144 125.501 160.777 130.501 179.999 130.501C198.703 130.501 221.5 126.001 231.5 124.001C237 99.501 242.5 82.0911 242.5 76.501C242.5 55.501 241 47.5006 226.5 33.5008Z" />
            <path
              d="M226.5 33.5008C200.5 33.5008 206.5 33.4998 180.5 33.5C154.5 33.5003 156 33.5008 142.999 33.5008C137.499 38.5008 126.5 47.001 125.999 73.501C125.876 80.0278 129.499 98.501 137.5 124.001C144 125.501 160.777 130.501 179.999 130.501C198.703 130.501 221.5 126.001 231.5 124.001C237 99.501 242.5 82.0911 242.5 76.501C242.5 55.501 241 47.5006 226.5 33.5008Z"
              stroke="white"
            />
          </g>
          <path
            d="M180.701 78.52L184.109 75.8H185.693V87H183.773V78.36L181.805 79.928L180.701 78.52Z"
            id="section1TextFemale"
            onClick={() => toggleHeadSection(1)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(1) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <path
            d="M209.062 266.785V266.994C209.688 271.164 214.9 273.875 219.905 272.832L260.774 264.909C264.944 264.074 269.115 263.657 273.285 263.866C276.621 264.074 280.375 264.492 283.711 265.534C292.469 268.453 306.856 276.168 306.856 276.168L298.098 267.619C286.839 256.776 269.74 251.355 252.433 253.44L218.654 257.402C212.607 257.819 208.436 261.989 209.062 266.785Z"
            id="EyebrowLeftFemale"
            onClick={() => {
              if (eyebrowSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                );
              }
            }}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(
                    HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                  ) && eyebrowSelected,
                "cursor-pointer": eyebrowSelected,
              }
            )}
          />
          <path
            d="M147.586 266.008V266.217C147.169 270.387 141.956 273.098 136.951 272.264L95.8736 264.966C91.7033 264.132 87.5329 263.923 83.3626 264.132C80.0263 264.34 76.273 264.757 72.9368 266.008C64.1791 268.928 50 277.06 50 277.06L58.5491 268.302C69.392 257.251 86.4904 251.621 103.797 253.289L137.785 256.625C143.832 257.042 148.211 261.212 147.586 266.008Z"
            id="EyebrowRightFemale"
            onClick={() => {
              if (eyebrowSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                );
              }
            }}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(
                    HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                  ) && eyebrowSelected,
                "cursor-pointer": eyebrowSelected,
              }
            )}
          />
          <path
            d="M128.774 260.968V259.6H134.774L130.274 268.288L129.05 267.652L132.506 260.968H128.774Z"
            id="EyebrowRightTextFemale"
            onClick={() => {
              if (eyebrowSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                );
              }
            }}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(8) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <path
            d="M222.774 261.968V260.6H228.774L224.274 269.288L223.05 268.652L226.506 261.968H222.774Z"
            id="EyebrowLeftTextFemale"
            onClick={() => {
              if (eyebrowSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                );
              }
            }}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(8) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            filter="url(#filter5_b_8802_167239)"
            id="Section5RightFemale"
            onClick={() => toggleHeadSection(5)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M55.5 53C75.5 29.5217 112 12.5 127 9.5C120 27 138.5 34 142 33.5C125 48 126 64.5 126 79.5C116.5 82.5 96.8027 93.6453 84 101C60.5 114.5 53.5 131 53.5 131C35 124 29 108.5 27.5 94C29.5 87.5 42.2963 68.5 55.5 53Z" />
            <path
              d="M55.5 53C75.5 29.5217 112 12.5 127 9.5C120 27 138.5 34 142 33.5C125 48 126 64.5 126 79.5C116.5 82.5 96.8027 93.6453 84 101C60.5 114.5 53.5 131 53.5 131C35 124 29 108.5 27.5 94C29.5 87.5 42.2963 68.5 55.5 53Z"
              stroke="white"
            />
          </g>
          <path
            d="M86.9923 66.32C86.9923 68.944 84.9443 70.192 83.0083 70.192C80.0483 70.192 79.0883 67.6 79.0883 67.6L80.6883 66.688C80.6883 66.688 81.2643 68.4 83.0083 68.4C84.3043 68.4 85.0563 67.488 85.0563 66.32C85.0563 65.12 84.3363 64.24 82.6723 64.24C81.1203 64.24 79.6643 65.28 79.6643 65.28V58.8H86.8643V60.624H81.5843V62.736C81.5843 62.736 82.2083 62.48 83.1683 62.48C85.1523 62.48 86.9923 63.696 86.9923 66.32Z"
            id="Section5RightTextFemale"
            onClick={() => toggleHeadSection(5)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <path
            d="M126 80C126 80 118.696 82.0176 109 87C95.0479 94.1693 77.6776 104.941 73.5 108C59.4175 118.312 54 130.5 54 130.5C54 130.5 92 141.5 137.5 124.5"
            fill="#EFECE4"
          />
          <g
            id="Section4RightFemale"
            onClick={() => toggleHeadSection(4)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M126 80C126 80 118.696 82.0176 109 87C95.0479 94.1693 77.6776 104.941 73.5 108C59.4175 118.312 54 130.5 54 130.5C54 130.5 92 141.5 137.5 124.5C137.5 124.5 131.5 106 129.5 96.4997C128.809 93.2175 126 80 126 80Z" />
            <path
              d="M126 80C126 80 128.809 93.2175 129.5 96.4997C131.5 106 137.5 124.5 137.5 124.5C92 141.5 54 130.5 54 130.5C54 130.5 59.4175 118.312 73.5 108C77.6776 104.941 95.0479 94.1693 109 87C118.696 82.0176 126 80 126 80Z"
              stroke="white"
              strokeWidth="1.45525"
              strokeMiterlimit="10"
            />
          </g>
          <path
            d="M94.1938 117.568L99.8898 108.8H102.034V115.776H103.266V117.568H102.034V120H100.114V117.568H94.1938ZM97.3298 115.776H100.146V111.44L97.3298 115.776Z"
            id="Section4RightTextFemale"
            onClick={() => toggleHeadSection(4)}
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <defs>
            <filter
              id="filter0_b_8802_167239"
              x="134.737"
              y="121.728"
              width="99.0565"
              height="56.8496"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_8802_167239"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_8802_167239"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_b_8802_167239"
              x="222.896"
              y="121.76"
              width="94.3927"
              height="88.5782"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_8802_167239"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_8802_167239"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_b_8802_167239"
              x="44.9395"
              y="121.76"
              width="101.164"
              height="95.5782"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_8802_167239"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_8802_167239"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_b_8802_167239"
              x="222.285"
              y="8.74998"
              width="119.307"
              height="121.912"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_8802_167239"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_8802_167239"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_b_8802_167239"
              x="123.418"
              y="30.9219"
              width="121.66"
              height="102.156"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_8802_167239"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_8802_167239"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_b_8802_167239"
              x="24.914"
              y="6.74998"
              width="120.801"
              height="126.967"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_8802_167239"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_8802_167239"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : (
        <svg
          className="w-full h-full"
          viewBox="0 0 313 480"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M296.58 218.758C296.58 218.758 299.011 213.32 304.243 213.284C314.013 213.218 311.906 226.968 311.906 236.821C311.906 250.437 301.433 258.622 298.222 271.853C295.934 281.284 297.956 290.313 292.201 298.127C290.408 300.561 289.5 300 289.5 300"
            stroke="black"
            strokeWidth="1.09474"
          />
          <path
            d="M23.9891 229.158C23.9891 229.158 16.8063 213.339 8.85415 213.284C-0.915672 213.218 1.191 226.968 1.191 236.821C1.191 250.437 11.6646 258.622 14.8752 271.853C17.1636 281.284 15.141 290.313 20.8963 298.127C22.6891 300.561 26.4999 301.5 26.4999 301.5"
            stroke="black"
            strokeWidth="1.09474"
          />
          <path
            d="M52.4545 388.442C52.4545 388.442 22.8966 338.632 26.7281 301.958C30.3543 267.251 15.7808 213.832 15.7808 213.832C15.7808 213.832 3.5782 184.86 2.64402 162.379C1.94277 145.503 5.90034 133.51 9.21247 116.947C11.9275 103.371 20.7071 84.1052 20.7071 84.1052C51.9071 24.4421 111.547 4.18945 154.265 4.18945C196.618 4.18945 246.223 17.8737 292.202 80.2737C292.202 80.2737 301.184 98.0613 303.697 109.832C307.623 128.228 305.339 158 305.339 158L294.939 232.442L288.918 301.958C288.918 301.958 288.32 349.548 261.549 387.895C241.297 416.905 230.434 443.226 199.149 461.789C182.796 471.493 174.06 477.448 154.812 476.568C137.992 475.8 127.085 471.133 112.118 461.789C96.9879 452.344 91.7877 443.54 79.8229 429.495C67.3278 414.827 52.4545 388.442 52.4545 388.442Z"
            stroke="black"
            strokeWidth="1.09474"
          />
          <path
            id="sideBeard"
            d="M296.406 229.705L288.5 304C288.5 304 286.5 262 289.245 206.716C289.485 201.875 287 191.5 284.5 189C269.5 184.5 244.485 178.597 236.5 177C225.128 174.726 200.5 171 190 175C186 180 182 207.5 161.5 207.5C137 207.5 132 177 127 175C122 173 116.217 172.305 98.5 175C68 179.639 29.5 190 27.5 193.5C24.3846 198.952 27.773 213.032 27 223C31.5 257.5 27.5 297 27.5 297C27.5 297 26.5637 274.619 23.4434 254.337C19.0645 225.874 16.5 217.5 16.5 217.5C16.5 217.5 3.58373 185.955 2.64355 163.474C1.93781 146.598 4.78372 131.867 8.11709 115.305C10.8496 101.729 20.7066 79.7263 20.7066 79.7263C50.8118 25.5368 111.288 2 154.279 2C196.904 2 258.445 25.5368 294.938 80.821C294.938 80.821 301.715 96.4192 304.243 108.189C308.195 126.586 305.338 154.168 305.338 154.168L301.506 190.842L296.406 229.705Z"
            className="fill-beige-100"
          />
          <path
            id="eyebrowRight"
            onClick={() => {
              if (eyebrowSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                );
              }
            }}
            d="M238.01 257.169C219.399 265.379 175.062 285.632 175.062 285.632C175.062 285.632 177.812 289.609 186.01 288.369C203.445 285.73 227.063 275.232 250.052 271.4C258.254 270.033 265.926 252.242 265.926 252.242C265.926 252.242 243.484 254.754 238.01 257.169Z"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(
                    HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                  ) && eyebrowSelected,
                "cursor-pointer": eyebrowSelected,
              }
            )}
          />
          <path
            id="eyebrowLeft"
            onClick={() => {
              if (eyebrowSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                );
              }
            }}
            d="M80.369 257.716C98.9796 265.927 143.316 286.18 143.316 286.18C143.316 286.18 140.567 290.157 132.369 288.916C114.934 286.277 91.3164 275.78 68.3269 271.948C60.1253 270.581 52.4532 252.79 52.4532 252.79C52.4532 252.79 74.8953 255.301 80.369 257.716Z"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(
                    HEAD_CONFIGURATION_SECTIONS.eyebrowSection
                  ) && eyebrowSelected,
                "cursor-pointer": eyebrowSelected,
              }
            )}
          />
          <path
            id="beard"
            onClick={() => {
              if (beardSelected) {
                toggleHeadGraftSection(
                  HEAD_CONFIGURATION_SECTIONS.beardSection
                );
              }
            }}
            d="M53 389C18.0001 328 27.5 291.5 27.5 291.5C27.5 291.5 41.8792 313.305 52.4533 335.348C52.4533 335.348 89.6742 389.538 114.306 417.453C123.965 428.401 140.468 432.232 158.095 432.232C175.722 432.232 189.55 430.046 201.885 417.453C227.611 391.191 261.001 335.348 261.001 335.348L289.5 291C289.5 291 289.113 351.181 261.001 388.443C247 407 229.946 444.148 198 462.5C181.816 471.798 174.059 477.448 154.811 476.569C137.99 475.801 127.073 471.215 112.5 461.5C86.2264 443.984 71.4932 421.231 53 389Z"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(
                    HEAD_CONFIGURATION_SECTIONS.beardSection
                  ) && beardSelected,
                "cursor-pointer": beardSelected,
              }
            )}
          />
          <g filter="url(#filter0_b_4812_146044)">
            <path
              d="M2 159.42C2 134.482 13.9493 87.7238 25.3791 73.1768C15.5079 93.4387 26.9377 115.259 35.2503 133.962C32.1331 160.459 27 193 27 193L15.5 213.5C12.5 204 2 184.357 2 159.42Z"
              className="fill-beige-100"
            />
            <path
              d="M2 159.42C2 134.482 13.9493 87.7238 25.3791 73.1768C15.5079 93.4387 26.9377 115.259 35.2503 133.962C32.1331 160.459 27 193 27 193L15.5 213.5C12.5 204 2 184.357 2 159.42Z"
              stroke="white"
              strokeWidth="1.14222"
            />
          </g>
          <g filter="url(#filter1_b_4812_146044)">
            <path
              d="M306.771 133.963C306.771 109.026 300.212 85.6466 287.224 71.6191C297.615 91.3615 303.33 107.467 291.9 133.963C295.017 160.46 284.877 188.653 284.877 188.653L298.014 214.38C299.572 205.721 306.771 158.901 306.771 133.963Z"
              className="fill-beige-100"
            />
            <path
              d="M306.771 133.963C306.771 109.026 300.212 85.6466 287.224 71.6191C297.615 91.3615 303.33 107.467 291.9 133.963C295.017 160.46 284.877 188.653 284.877 188.653L298.014 214.38C299.572 205.721 306.771 158.901 306.771 133.963Z"
              stroke="white"
              strokeWidth="1.14222"
            />
          </g>
          <g
            id="section1"
            filter="url(#filter2_b_4812_146044)"
            onClick={() => toggleHeadSection(1)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(1) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M217.221 47.5899C203.713 46.5508 189.166 40.3164 155.916 40.3164C122.665 40.3164 104.818 48.629 88.1932 47.5899C81.3649 58.7429 79.5438 76.1644 92.5059 93.3091C105.256 93.3091 110.533 117.208 115.209 133.833H155.916H201.115C207.347 114.618 213.863 93.3274 224.481 93.3091C235.405 62.6565 227.612 59.0197 217.221 47.5899Z" />
            <path
              d="M224.494 93.3091C213.869 93.3091 207.35 114.61 201.115 133.833C191.764 133.833 174.619 133.833 155.916 133.833C136.693 133.833 125.08 133.833 115.209 133.833C110.533 117.208 105.256 93.3091 92.5059 93.3091C79.5438 76.1644 81.3649 58.7429 88.1932 47.5899C104.818 48.629 122.665 40.3164 155.916 40.3164C189.166 40.3164 203.713 46.5508 217.221 47.5899C227.612 59.0197 235.405 62.6565 224.481 93.3091"
              stroke="white"
            />
          </g>
          <path
            id="section1Text"
            onClick={() => toggleHeadSection(1)}
            d="M152.662 84.8677L156.07 82.1477H157.654V93.3477H155.734V84.7077L153.766 86.2757L152.662 84.8677Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(1) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section3"
            filter="url(#filter3_b_4812_146044)"
            onClick={() => toggleHeadSection(3)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(3) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M115.26 133.963C123.453 166.469 136.208 206.698 160.979 206.698C185.75 206.698 191.116 166.469 200.995 133.963H115.26Z" />
            <path
              d="M115.26 133.963C123.453 166.469 136.208 206.698 160.979 206.698C185.75 206.698 191.116 166.469 200.995 133.963H115.26Z"
              stroke="white"
            />
          </g>
          <path
            id="section3Text"
            onClick={() => toggleHeadSection(3)}
            d="M160.614 165.968C160.614 165.072 159.926 164.4 158.934 164.4C157.478 164.4 157.03 165.76 157.03 165.76L155.366 164.976C155.366 164.976 156.23 162.608 158.934 162.608C160.982 162.608 162.55 163.904 162.55 165.968C162.55 167.568 161.174 168.32 161.174 168.32C161.174 168.32 162.822 168.736 162.822 170.88C162.822 172.912 161.126 174.192 158.95 174.192C155.83 174.192 155.03 171.6 155.03 171.6L156.598 170.688C156.598 170.688 157.094 172.4 158.95 172.4C160.166 172.4 160.902 171.744 160.902 170.88C160.902 169.696 159.878 169.296 158.87 169.296H158.07V167.536H158.678C159.846 167.536 160.614 167.104 160.614 165.968Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(3) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section4Left"
            filter="url(#filter4_b_4812_146044)"
            onClick={() => toggleHeadSection(4)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M92.3989 93.4385C67.9807 93.4385 43.0429 123.572 35.2499 133.962C42.0038 141.236 95.5161 152.146 115.258 133.962C109.544 114.739 105.387 93.958 92.3989 93.4385Z" />
            <path
              d="M92.3989 93.4385C67.9807 93.4385 43.0429 123.572 35.2499 133.962C42.0038 141.236 95.5161 152.146 115.258 133.962C109.544 114.739 105.387 93.958 92.3989 93.4385Z"
              stroke="white"
            />
          </g>
          <path
            id="section4LeftText"
            onClick={() => toggleHeadSection(4)}
            d="M74.1528 124.936L79.8488 116.168H81.9928V123.144H83.2248V124.936H81.9928V127.368H80.0728V124.936H74.1528ZM77.2888 123.144H80.1048V118.808L77.2888 123.144Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section4Right"
            filter="url(#filter5_b_4812_146044)"
            onClick={() => toggleHeadSection(4)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M291.897 133.963C286.702 139.159 212.408 153.186 200.459 133.963C208.252 109.026 214.486 93.4395 223.838 93.4395C244.1 93.4395 277.87 120.455 291.897 133.963Z" />
            <path
              d="M291.897 133.963C286.702 139.159 212.408 153.186 200.459 133.963C208.252 109.026 214.486 93.4395 223.838 93.4395C244.1 93.4395 277.87 120.455 291.897 133.963Z"
              stroke="white"
            />
          </g>
          <path
            onClick={() => toggleHeadSection(4)}
            id="section4RightText"
            d="M239.774 124.937L245.47 116.169H247.614V123.145H248.846V124.937H247.614V127.369H245.694V124.937H239.774ZM242.91 123.145H245.726V118.809L242.91 123.145Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(4) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section5Right"
            filter="url(#filter6_b_4812_146044)"
            onClick={() => toggleHeadSection(5)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M283.935 66.4226C268.141 43.563 234.949 24.5613 223 18.5C235.469 35.6447 219.686 48.7583 217.607 47.7193C232.674 61.7467 233.021 68.5007 224.708 93.4384C251.204 93.958 291.901 134.482 291.901 134.482C301.772 112.142 299.729 89.2822 283.935 66.4226Z" />
            <path
              d="M283.935 66.4226C268.141 43.563 234.949 24.5613 223 18.5C235.469 35.6447 219.686 48.7583 217.607 47.7193C232.674 61.7467 233.021 68.5007 224.708 93.4384C251.204 93.958 291.901 134.482 291.901 134.482C301.772 112.142 299.729 89.2822 283.935 66.4226Z"
              stroke="white"
            />
          </g>
          <path
            id="section5RightText"
            onClick={() => toggleHeadSection(5)}
            d="M259.996 73.4313C259.996 76.0553 257.948 77.3033 256.012 77.3033C253.052 77.3033 252.092 74.7113 252.092 74.7113L253.692 73.7993C253.692 73.7993 254.268 75.5113 256.012 75.5113C257.308 75.5113 258.06 74.5993 258.06 73.4313C258.06 72.2313 257.34 71.3513 255.676 71.3513C254.124 71.3513 252.668 72.3913 252.668 72.3913V65.9113H259.868V67.7353H254.588V69.8473C254.588 69.8473 255.212 69.5913 256.172 69.5913C258.156 69.5913 259.996 70.8073 259.996 73.4313Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section6"
            filter="url(#filter7_b_4812_146044)"
            onClick={() => toggleHeadSection(6)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(6) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M155.78 2.06712C184.956 2.95701 206.471 10.1093 219.415 15.9744C228.153 19.9334 231.523 30.279 226.91 38.6893C216.438 57.7803 211.375 40.4418 155.78 40.4418C118.55 40.4418 90.78 54.3377 79.3816 44.4437C69.8506 36.1706 79.4562 21.1903 90.8215 15.7026C105.022 8.84584 127.445 1.20288 155.78 2.06712Z" />
            <path
              d="M155.78 2.06712C184.956 2.95701 206.471 10.1093 219.415 15.9744C228.153 19.9334 231.523 30.279 226.91 38.6893C216.438 57.7803 211.375 40.4418 155.78 40.4418C118.55 40.4418 90.78 54.3377 79.3816 44.4437C69.8506 36.1706 79.4562 21.1903 90.8215 15.7026C105.022 8.84584 127.445 1.20288 155.78 2.06712Z"
              stroke="white"
            />
          </g>
          <path
            id="section6Text"
            onClick={() => toggleHeadSection(6)}
            d="M154.852 14.9909L152.772 18.3669C152.772 18.3669 152.98 18.3189 153.156 18.3189C155.46 18.3189 156.948 20.2069 156.948 22.1109C156.948 24.0149 155.428 25.9029 153.028 25.9029C150.628 25.9029 149.108 24.0149 149.108 22.1429C149.108 21.1829 149.444 20.3509 149.732 19.8229C149.908 19.5029 153.252 13.9989 153.252 13.9989L154.852 14.9909ZM153.028 20.1109C151.924 20.1109 151.044 20.8949 151.044 22.1109C151.044 23.3269 151.924 24.1109 153.028 24.1109C154.132 24.1109 155.012 23.3269 155.012 22.1109C155.012 20.8949 154.132 20.1109 153.028 20.1109Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(6) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section5Left"
            filter="url(#filter8_b_4812_146044)"
            onClick={() => toggleHeadSection(5)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M29.4324 66.4233C45.2263 43.5637 73.0734 25.7263 85.0228 19.665C62.3912 40.4465 87.2037 49.2786 88.2427 47.72C79.9171 61.1691 81.614 80.4508 92.5243 93.4392C63.9498 93.9587 35.3751 133.963 35.3751 133.963C25.5041 111.623 13.6385 89.2829 29.4324 66.4233Z" />
            <path
              d="M29.4324 66.4233C45.2263 43.5637 73.0734 25.7263 85.0228 19.665C62.3912 40.4465 87.2037 49.2786 88.2427 47.72C79.9171 61.1691 81.614 80.4508 92.5243 93.4392C63.9498 93.9587 35.3751 133.963 35.3751 133.963C25.5041 111.623 13.6385 89.2829 29.4324 66.4233Z"
              stroke="white"
            />
          </g>
          <path
            onClick={() => toggleHeadSection(5)}
            id="section5LeftText"
            d="M60.1077 73.4304C60.1077 76.0544 58.0597 77.3024 56.1237 77.3024C53.1637 77.3024 52.2037 74.7104 52.2037 74.7104L53.8037 73.7984C53.8037 73.7984 54.3797 75.5104 56.1237 75.5104C57.4197 75.5104 58.1717 74.5984 58.1717 73.4304C58.1717 72.2304 57.4517 71.3504 55.7877 71.3504C54.2357 71.3504 52.7797 72.3904 52.7797 72.3904V65.9104H59.9797V67.7344H54.6997V69.8464C54.6997 69.8464 55.3237 69.5904 56.2837 69.5904C58.2677 69.5904 60.1077 70.8064 60.1077 73.4304Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(5) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            filter="url(#filter9_b_4812_146044)"
            onClick={() => toggleHeadSection(2)}
            id="section2Left"
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M35.2506 133.963C39.4069 140.717 97.595 151.627 115.259 133.963C119.415 150.069 121.766 157.875 128 174.5C109.816 168.265 51.0368 183.242 27.5 192C29.5 174 33.1725 150.068 35.2506 133.963Z" />
            <path
              d="M35.2506 133.963C39.4069 140.717 97.595 151.627 115.259 133.963C119.415 150.069 121.766 157.875 128 174.5C109.816 168.265 51.0368 183.242 27.5 192C29.5 174 33.1725 150.068 35.2506 133.963Z"
              stroke="white"
              strokeWidth="1.14222"
            />
          </g>
          <path
            id="section2LeftText"
            onClick={() => toggleHeadSection(2)}
            d="M68.181 166.694L72.293 161.478C72.709 160.934 73.589 160.086 73.589 158.822C73.589 157.926 72.981 157.094 71.925 157.094C70.277 157.094 69.925 158.726 69.925 158.726L68.213 158.102C68.213 158.102 68.933 155.302 71.925 155.302C74.341 155.302 75.509 156.934 75.509 158.79C75.509 160.182 74.805 161.174 74.533 161.542L71.957 164.87H75.557V166.694H68.181Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          <g
            id="section2Right"
            filter="url(#filter10_b_4812_146044)"
            onClick={() => toggleHeadSection(2)}
            className={cn(
              "fill-beige-100 transition-all duration-300 ease-in-out",
              {
                "fill-beige-900":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          >
            <path d="M189.5 174.5C194.735 158.394 196.5 149 200.5 134.5C211.494 152.684 282.053 140.754 292 134C293.571 148.027 291.068 170.437 284.5 188.5C269.842 184.344 209.393 167.226 189.5 174.5Z" />
            <path
              d="M189.5 174.5C194.735 158.394 196.5 149 200.5 134.5C211.494 152.684 282.053 140.754 292 134C293.571 148.027 291.068 170.437 284.5 188.5C269.842 184.344 209.393 167.226 189.5 174.5Z"
              stroke="white"
            />
          </g>
          <path
            id="section2RightText"
            onClick={() => toggleHeadSection(2)}
            d="M245.622 165.695L249.734 160.479C250.15 159.935 251.03 159.087 251.03 157.823C251.03 156.927 250.422 156.095 249.366 156.095C247.718 156.095 247.366 157.727 247.366 157.727L245.654 157.103C245.654 157.103 246.374 154.303 249.366 154.303C251.782 154.303 252.95 155.935 252.95 157.791C252.95 159.183 252.246 160.175 251.974 160.543L249.398 163.871H252.998V165.695H245.622Z"
            className={cn(
              "fill-grey-400 transition-all duration-300 ease-in-out",
              {
                "fill-white":
                  headGraftSections.includes(2) && hairPlanType !== "none",
                "cursor-pointer": hairPlanType !== "none",
              }
            )}
          />
          {beardSelected && (
            <path
              onClick={() => toggleHeadSection(7)}
              d="M153.865 450.624V448.8H161.865L155.865 460.384L154.233 459.536L158.841 450.624H153.865Z"
              className={cn(
                "fill-grey-400 transition-all duration-300 ease-in-out",
                {
                  "fill-white":
                    headGraftSections.includes(7) && hairPlanType !== "none",
                  "cursor-pointer": hairPlanType !== "none",
                }
              )}
            />
          )}
          {eyebrowSelected && (
            <>
              <path
                onClick={() => toggleHeadSection(8)}
                d="M74.499 259.456C76.251 259.456 77.139 260.632 77.139 261.88C77.139 262.996 76.203 263.536 76.203 263.536C76.203 263.536 77.379 264.004 77.379 265.528C77.379 266.932 76.323 268.144 74.499 268.144C72.675 268.144 71.619 266.932 71.619 265.528C71.619 264.004 72.795 263.536 72.795 263.536C72.795 263.536 71.859 262.996 71.859 261.88C71.859 260.632 72.747 259.456 74.499 259.456ZM74.499 266.824C75.447 266.824 75.927 266.188 75.927 265.504C75.927 264.82 75.447 264.208 74.499 264.208C73.551 264.208 73.071 264.82 73.071 265.504C73.071 266.188 73.551 266.824 74.499 266.824ZM75.711 261.892C75.711 261.208 75.171 260.776 74.499 260.776C73.827 260.776 73.287 261.208 73.287 261.892C73.287 262.576 73.827 263.008 74.499 263.008C75.171 263.008 75.711 262.576 75.711 261.892Z"
                className={cn(
                  "fill-grey-400 transition-all duration-300 ease-in-out",
                  {
                    "fill-white":
                      headGraftSections.includes(8) && hairPlanType !== "none",
                    "cursor-pointer": hairPlanType !== "none",
                  }
                )}
              />
              <path
                onClick={() => toggleHeadSection(8)}
                d="M244.499 259.456C246.251 259.456 247.139 260.632 247.139 261.88C247.139 262.996 246.203 263.536 246.203 263.536C246.203 263.536 247.379 264.004 247.379 265.528C247.379 266.932 246.323 268.144 244.499 268.144C242.675 268.144 241.619 266.932 241.619 265.528C241.619 264.004 242.795 263.536 242.795 263.536C242.795 263.536 241.859 262.996 241.859 261.88C241.859 260.632 242.747 259.456 244.499 259.456ZM244.499 266.824C245.447 266.824 245.927 266.188 245.927 265.504C245.927 264.82 245.447 264.208 244.499 264.208C243.551 264.208 243.071 264.82 243.071 265.504C243.071 266.188 243.551 266.824 244.499 266.824ZM245.711 261.892C245.711 261.208 245.171 260.776 244.499 260.776C243.827 260.776 243.287 261.208 243.287 261.892C243.287 262.576 243.827 263.008 244.499 263.008C245.171 263.008 245.711 262.576 245.711 261.892Z"
                className={cn(
                  "fill-grey-400 transition-all duration-300 ease-in-out",
                  {
                    "fill-white":
                      headGraftSections.includes(8) && hairPlanType !== "none",
                    "cursor-pointer": hairPlanType !== "none",
                  }
                )}
              />
            </>
          )}
          <defs>
            <filter
              id="filter0_b_4812_146044"
              x="-0.649251"
              y="70.7461"
              width="38.5632"
              height="146.262"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_b_4812_146044"
              x="282.177"
              y="69.1533"
              width="27.2433"
              height="149.073"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_b_4812_146044"
              x="80.3203"
              y="37.7383"
              width="152.024"
              height="98.6729"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_b_4812_146044"
              x="112.54"
              y="131.385"
              width="91.2081"
              height="77.8916"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_b_4812_146044"
              x="32.5211"
              y="90.8603"
              width="85.3836"
              height="55.6309"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_b_4812_146044"
              x="197.835"
              y="90.8613"
              width="96.854"
              height="55.4991"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter6_b_4812_146044"
              x="215.188"
              y="15.9756"
              width="85.2998"
              height="121.452"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter7_b_4812_146044"
              x="72.9152"
              y="-0.578145"
              width="158.64"
              height="51.1553"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter8_b_4812_146044"
              x="19.164"
              y="17.1406"
              width="76.4953"
              height="119.904"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter9_b_4812_146044"
              x="24.7518"
              y="130.21"
              width="106.286"
              height="64.7266"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
            <filter
              id="filter10_b_4812_146044"
              x="186.629"
              y="131.043"
              width="108.404"
              height="60.1426"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.03907" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_4812_146044"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_4812_146044"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )}
    </>
  );
};

export default HeadConfiguration;
