import { MessageDescriptor } from "react-intl";

export type HairGraftSection = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type SkinSectionLink = {
  section: SkinSection;
  onlyAvailableWith: SkinSection;
};

export const BOTOX_SKIN_SECTIONS = [
  "botox-forehead",
  "botox-outer-eye",
  "botox-nasojugal-fold",
  "botox-glabella",
] as const;

export const FILLER_SKIN_SECTIONS = [
  "filler-jawline",
  "filler-cheeks",
  "filler-chin",
  "filler-lips",
] as const;

export const SKIN_SECTION_LINKS: SkinSectionLink[] = [
  { section: "botox-forehead", onlyAvailableWith: "botox-glabella" },
];

export const SKIN_SECTIONS = [
  ...BOTOX_SKIN_SECTIONS,
  ...FILLER_SKIN_SECTIONS,
] as const;

export type BotoxSkinSection = (typeof BOTOX_SKIN_SECTIONS)[number];

export type SkinSection = (typeof SKIN_SECTIONS)[number];

export const BOTOX_SKIN_SECTIONS_NAMES: {
  key: BotoxSkinSection;
  value: MessageDescriptor["id"];
}[] = [
  { key: "botox-forehead", value: "skin.botox.area.forehead" },
  { key: "botox-outer-eye", value: "skin.botox.area.outerEye" },
  { key: "botox-nasojugal-fold", value: "skin.botox.area.nasojugalFold" },
  { key: "botox-glabella", value: "skin.botox.area.glabella" },
];
