import { ProductItemCategoryKey } from "~/api/models/ProductCategory";

export class DocumentPicture {
  constructor(
    readonly id: string,
    readonly fileExtension: DocumentPictureExtension,
    readonly title: string,
    readonly url: string,
    readonly subCategory: ProductItemCategoryKey | null,
    readonly latestPublishedVersionId?: string
  ) {}
}

export type DocumentPictureExtension = "png" | "jpeg" | "jpg";

export type UploadedPhoto = {
  id: string;
  title: string;
  src: string;
  subCategory: ProductItemCategoryKey | null;
};
