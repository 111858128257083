import {
  ProductCategoryGroupKey,
  ProductCategoryKey,
  ProductItemCategoryKey,
} from "~/api/models//ProductCategory";

export type InterestValue =
  | "Hair"
  | "Skin"
  | "Hair and Skin"
  | "Beard"
  | "Eyebrows"
  | "Botox"
  | "Filler";

export class Interest {
  public static hair = new Interest("Hair", [
    ProductItemCategoryKey.ht,
    ProductItemCategoryKey.prp,
  ]);
  public static skin = new Interest("Skin", [ProductCategoryGroupKey.skin]);
  public static hs = new Interest("Hair and Skin", [
    ProductItemCategoryKey.ht,
    ProductItemCategoryKey.prp,
    ProductCategoryGroupKey.skin,
  ]);
  public static beard = new Interest("Beard", [ProductItemCategoryKey.beard]);
  public static eyebrows = new Interest("Eyebrows", [
    ProductItemCategoryKey.eyebrow,
  ]);
  public static botox = new Interest("Botox", [
    ProductItemCategoryKey.botox,
    ProductCategoryGroupKey.skin,
  ]);
  public static filler = new Interest("Filler", [
    ProductItemCategoryKey.filler,
    ProductCategoryGroupKey.skin,
  ]);
  public static values = [
    this.hair,
    this.skin,
    this.hs,
    this.beard,
    this.eyebrows,
    this.filler,
    this.botox,
  ];

  value: InterestValue;
  categories: ProductCategoryKey[];

  private constructor(value: InterestValue, categories: ProductCategoryKey[]) {
    this.value = value;
    this.categories = categories;
  }
}
