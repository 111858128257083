import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PhotosCategory } from "~/modules/consultation-document/consultation-document";
import { UploadedPhoto } from "~/modules/consultation-document/documentPicture";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { useFetchAccessToken } from "./useFetchAccessToken";
import { useFetchConsultationDocuments } from "./useFetchConsultationDocuments";
import { useFetchUploadToken } from "./useFetchUploadToken";

export const useFetchUploadedPhotos = (category: PhotosCategory) => {
  const { data: tokens } = useFetchAccessToken();
  const { opportunityId } = useParams();
  const { locale } = useLocaleStore();

  const { data: documents } = useFetchConsultationDocuments(
    opportunityId as string,
    true,
    category
  );
  const { data: uploadToken } = useFetchUploadToken(
    documents?.treatmentEventId || "",
    opportunityId as string
  );

  const result = useQuery({
    keepPreviousData: true,
    queryKey: [
      QueryKeys.useFetchUploadedPhotos,
      documents?.pictures?.map((picture) => picture.latestPublishedVersionId),
    ],
    queryFn: async () => {
      if (!documents?.pictures) return [];
      const photoPromises: Promise<UploadedPhoto | null>[] =
        documents?.pictures?.map(async (picture) => {
          try {
            const blob = await salesforceServiceSingleton.getFile(
              tokens!.accessToken,
              picture.id,
              picture.latestPublishedVersionId ?? "",
              locale
            );

            return {
              id: picture.id,
              title: picture.title,
              src: URL.createObjectURL(blob),
              subCategory: picture.subCategory,
            };
          } catch (error) {
            return null;
          }
        });

      const photoBlobs = await Promise.all(photoPromises);

      return photoBlobs?.reduce(
        (acc: UploadedPhoto[], blob: UploadedPhoto | null) => {
          if (blob) {
            acc.push(blob);
          }
          return acc;
        },
        []
      );
    },
    enabled:
      tokens?.ensureAccessTokenIsValid?.() &&
      documents?.pictures &&
      documents?.pictures?.length > 0,
  });

  return {
    uploadToken,
    ...result,
    photos: result.data ?? [],
    isLoading: result.isLoading && result.fetchStatus !== "idle",
  };
};
