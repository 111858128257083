import { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useIntersectionObserver } from "usehooks-ts";
import { TreatmentPlanRecommendation } from "~/api/models/TreatmentPlan";
import Copy from "~/components/atom/Copy";
import Headline from "~/components/atoms/Headline/Headline";
import TreatmentPlanRecommendationCard from "~/components/molecules/TreatmentPlanRecommendationCard/TreatmentPlanRecommendationCard";

type PLanCategoryRecommendationsProps = {
  recommendations?: TreatmentPlanRecommendation[];
};
const PLanCategoryRecommendations = ({
  recommendations,
}: PLanCategoryRecommendationsProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  const [zoomedCard, setZoomedCard] = useState(0);

  return (
    <AnimatePresence>
      <motion.div
        animate={{
          y: isVisible ? 0 : "10%",
          opacity: isVisible ? 1 : 0,
          scale: isVisible ? 1 : 0.9,
        }}
        transition={{ duration: 0.7 }}
        className="h-full flex flex-col gap-10 py-10"
      >
        <div className="flex flex-col gap-3 text-center items-center">
          <Headline level={2}>
            <Copy id="treatment.plan.recommendations.title" />
          </Headline>
          <div ref={ref} className="max-w-1/3">
            <Headline level={6} className="text-grey-400">
              <Copy id="treatment.plan.recommendations.description" />
            </Headline>
          </div>
        </div>
        <div className="flex gap-6 h-full">
          {recommendations?.map((recommendation) => {
            const planCategoryIndex = recommendations?.findIndex(
              (c) => c.title === recommendation.title
            );
            return (
              <TreatmentPlanRecommendationCard
                key={recommendation.title}
                recommendation={recommendation}
                order={recommendations.indexOf(recommendation) + 1}
                hasZoom={zoomedCard === planCategoryIndex}
                onHover={() => setZoomedCard(planCategoryIndex)}
              />
            );
          })}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PLanCategoryRecommendations;
