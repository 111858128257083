import { ReactNode } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

type PopoverProps = {
  content: ReactNode;
  item: ReactNode;
  align?: PopoverPrimitive.PopoverContentProps["align"];
};

type PopoverTriggerProps = {
  children: ReactNode;
};

export const PopoverTrigger = ({ children }: PopoverTriggerProps) => {
  return (
    <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
  );
};

const Popover = ({ content, item, align = "center" }: PopoverProps) => {
  return (
    <PopoverPrimitive.Root>
      {item}

      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content sideOffset={5} align={align} asChild>
          {content}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};

export default Popover;
