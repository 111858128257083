import { useQuery } from "@tanstack/react-query";
import {
  ProductCategory,
  ProductCategoryDropdownOption,
} from "~/api/models/ProductCategory";
import { DropdownOptionGroup } from "~/components/atoms/Dropdown/Dropdown.types";
import { QueryKeys } from "~/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { AppLocale } from "~/utils/types";

export const useFetchProductCategories = () => {
  const { locale } = useLocaleStore();
  return useQuery<
    StoryblokMultiStoryResult<ProductCategory>,
    unknown,
    DropdownOptionGroup<ProductCategoryDropdownOption>,
    (QueryKeys | AppLocale)[]
  >({
    queryKey: [QueryKeys.useFetchProductCategories, locale],
    queryFn: () =>
      storyblokServiceSingleton.get(storyblokRoutes.productCategories, {
        locale,
      }),
    select: (data) =>
      data.data.stories?.map((story) => ({
        id: story.content.key,
        key: story.content.key,
        groupKey: story.content.key,
        title: story.content.name,
        options: story.content.subCategories?.map((option) => ({
          id: option.key,
          key: option.key,
          groupKey: story.content.key,
          title: option.name,
        })),
      })),
  });
};
