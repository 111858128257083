import { ProductFamily } from "./product";

export const SKIN_FAMILIES: ProductFamily[] = [
  "botox",
  "filler",
  "skin booster",
  "needling",
];

export const HAIR_FAMILIES: ProductFamily[] = [
  "prp hair",
  "hair transplant",
  "beard transplant",
  "eyebrow transplant",
];
