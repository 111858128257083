import Headline from "~/components/atoms/Headline/Headline";
import { cn } from "~/utils";
import { toDoubleDigit } from "~/utils/number.utils";
import { PropsWithClassName } from "~/utils/types";

type StepCountProps = PropsWithClassName<{
  number: number;
}>;

const StepCount = ({ number, className }: StepCountProps) => {
  return (
    <Headline
      level={1}
      className={cn("min-w-step-count text-beige-700", className)}
    >
      {toDoubleDigit(number)}
    </Headline>
  );
};

export default StepCount;
