import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import {
  PaymentMethod,
  PaymentMethodDTO,
} from "~/stores/checkout/paymentMethod";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchPaymentMethods = () => {
  const { locale } = useLocaleStore();
  const result = useQuery({
    queryKey: [QueryKeys.useFetchPaymentMethods, locale],
    queryFn: () =>
      storyblokServiceSingleton.get<PaymentMethodDTO>(
        storyblokRoutes.paymentMethods,
        {
          locale,
        },
        {
          resolve_relations: "paymentMethod.paymentConfiguration",
        }
      ),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    ...result,
    data:
      result.data?.data?.stories
        .map(
          ({ content }) =>
            new PaymentMethod(
              content._uid,
              content.title,
              content.salesforceValue,
              content.exclusives,
              content.feeTagText,
              content.feeTooltipText,
              content.paymentConfiguration,
              content.position
            )
        )
        ?.sort((a, b) => {
          // Sort by position ascending
          if (a.position < b.position) return -1;
          if (a.position > b.position) return 1;
          return 0;
        }) ?? [],
  };
};
