import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContractRequestBody } from "~/api/models/Contract";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { notifyOnce } from "~/utils/toast.utils";

export const useRequestContract = (opportunityId: string) => {
  const queryClient = useQueryClient();
  const { locale } = useLocaleStore();
  const { data } = useFetchAccessToken();

  return useMutation({
    mutationKey: [QueryKeys.useRequestContract],
    mutationFn: (body: ContractRequestBody) =>
      salesforceServiceSingleton.requestContract(
        data?.accessToken ?? "",
        opportunityId,
        body,
        locale
      ),
    onError: (error: Error) => {
      Sentry.captureException(error);
      if (error.cause !== 401) {
        notifyOnce(
          {
            toastType: "error",
            descriptionId: "contract.create.error",
          },
          "useCreateContractError"
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryKeys.useGetRequestedContract,
        QueryKeys.useFetchContract,
      ]);
    },
  });
};
