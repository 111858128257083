import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { PhotosTaken } from "~/api/models/UploadPhotos";
import check from "~/assets/svg/check.svg";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/atoms/Dialog/Dialog";
import UploadTakePhotoCamera from "~/components/molecules/UploadTakePhotoCamera/UploadTakePhotoCamera";
import UploadTakePhotoItem from "~/components/molecules/UploadTakePhotoItem/UploadTakePhotoItem";
import { PhotoPosition } from "~/modules/upload-photo/upload-photo";
import { cn } from "~/utils";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

type TakePhotosModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  doneButtonAction?: () => void;
  positions?: PhotoPosition[];
  selectedPosition: string;
  setSelectedPosition: (title: string) => void;
  photosTaken?: PhotosTaken;
  handleTakePhoto: () => void;
  waitingApprovalPhoto: PhotosTaken | null;
  setWaitingApprovalPhoto: (photoToReview: PhotosTaken | null) => void;
  clearWaitingPhotos?: () => void;
  isUploadLoading: boolean;
};

const TakePhotosModal = ({
  positions,
  doneButtonAction,
  open,
  setOpen,
  selectedPosition,
  setSelectedPosition,
  photosTaken,
  handleTakePhoto,
  waitingApprovalPhoto,
  setWaitingApprovalPhoto,
  clearWaitingPhotos = () => {},
  isUploadLoading,
}: TakePhotosModalProps) => {
  const intl = useIntl();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const title = useMemo(() => {
    const selectedPositionData = positions?.find(
      (position) => position.id === selectedPosition
    );

    return selectedPositionData?.title || "";
  }, [selectedPosition, positions]);

  const handleSelectPosition = (positionId: string) => {
    setSelectedPosition(positionId);
  };

  const handleToggleModalVisibility = (open: boolean) => {
    if (!open) {
      setConfirmModalOpen(true);
    } else {
      setOpen(true);
    }
  };
  const quitModal = () => {
    setOpen(false);
    clearWaitingPhotos();
    setSelectedPosition("");
    setConfirmModalOpen(false);
  };

  return (
    <>
      <ConfirmModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        title={intl.formatMessage({ id: "photos.upload.cancel.title" })}
        description={intl.formatMessage({
          id: "photos.upload.cancel.description",
        })}
        buttonText={intl.formatMessage({ id: "photos.upload.cancel.cta" })}
        buttonAction={quitModal}
        className="min-w-[90%] text-center items-center justify-center"
      />

      <Dialog open={open} onOpenChange={handleToggleModalVisibility}>
        <DialogContent className="flex flex-col h-svh gap-0 p-0 overflow-y-scroll w-full max-w-full">
          <DialogHeader className="space-y-0 flex flex-row px-6 py-3 shrink-0">
            <DialogClose className="h-8" />
            <DialogTitle className="font-normal text-lg tracking-normal">
              {title}
            </DialogTitle>
            <Button
              onClick={doneButtonAction}
              size={ButtonSize.sm}
              loading={isUploadLoading}
              disabled={isUploadLoading}
              className="h-7 px-3 py-2"
            >
              <Copy id="actions.save" />
            </Button>
          </DialogHeader>
          <UploadTakePhotoCamera
            className="grow"
            selectedPosition={selectedPosition}
            waitingApprovalPhoto={waitingApprovalPhoto}
            setWaitingApprovalPhoto={setWaitingApprovalPhoto}
          />
          <DialogFooter
            className={cn(
              "overflow-x-auto overflow-y-hidden px-6 py-4 h-28 shrink-0",
              {
                "flex flex-row items-center space-x-4": waitingApprovalPhoto,
              }
            )}
          >
            {!waitingApprovalPhoto ? (
              positions?.map((position) => (
                <UploadTakePhotoItem
                  key={position.id}
                  item={position}
                  isSelectedPosition={selectedPosition === position.id}
                  onClick={() => handleSelectPosition(position.id)}
                  photoTaken={photosTaken?.[position.id]?.src}
                />
              ))
            ) : (
              <>
                <Button
                  className="px-6"
                  variant={ButtonVariant.secondaryOutline}
                  onClick={() => setWaitingApprovalPhoto(null)}
                >
                  <Copy id="photos.upload.take-photo-modal.retake" />
                </Button>
                <Button
                  onClick={handleTakePhoto}
                  className="flex-1 justify-between max-w-none"
                  contentType={ButtonContentType.icon}
                  customIcon={<img src={check} />}
                >
                  <Copy id="photos.upload.take-photo-modal.save" />
                </Button>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TakePhotosModal;
