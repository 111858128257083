import { ProductItemCategoryKey } from "~/api/models/ProductCategory";

export class Example {
  public readonly treatments: ExampleTreatment[];
  public readonly exclusives: string[];
  public readonly parentId: number;

  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly imageUrl: string,
    public readonly slug: string,
    public readonly categoryKey: ProductItemCategoryKey,
    parentId: number,
    treatments: TreatmentDTO[],
    exclusives?: string[]
  ) {
    this.treatments = treatments.map(
      (cat) =>
        new ExampleTreatment(
          cat._uid,
          cat.title,
          cat.gender,
          cat.beforeImages.map((img) => ({
            title: img.title,
            imageUrl: img.image.filename,
          })),
          cat.afterImages.map((img) => ({
            title: img.title,
            imageUrl: img.image.filename,
          }))
        )
    );
    this.exclusives = exclusives ?? [];
    this.parentId = parentId;
  }
}

export class ExampleTreatment {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly gender: ExampleGender,
    public readonly beforeImages: TreatmentImage[],
    public readonly afterImages: TreatmentImage[]
  ) {}
}

export enum ExampleType {
  TreatmentExample = "treatmentExample",
  TreatmentExampleWrapper = "treatmentExampleWrapper",
}

export type ExampleDTO = {
  _uid: string;
  image: ImageDTO;
  title: string;
  treatments: TreatmentDTO[];
  parent_id: number;
  slug: string;
  categoryKey: ProductItemCategoryKey;
  exclusives?: string[];
};

type TreatmentDTO = {
  _uid: string;
  gender: ExampleGender;
  title: string;
  afterImages: TreatmentImageDTO[];
  beforeImages: TreatmentImageDTO[];
};

export type ImageDTO = {
  filename: string;
};

export type TreatmentImageDTO = {
  title: string;
  image: ImageDTO;
};

export type TreatmentImage = {
  imageUrl: string;
  title: string;
};

export enum ExampleGender {
  all = "all",
  men = "men",
  women = "women",
}

export enum ExampleCategory {
  hair = "hair",
  skin = "skin",
}
