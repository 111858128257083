import { PropsWithChildren } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type CardProps = PropsWithChildren<
  PropsWithClassName<{
    buttonAction?: () => void;
  }>
>;

const Card = ({ children, className, buttonAction }: CardProps) => {
  return (
    <div className="flex gap-4 items-center rounded-2xl border border-grey-50 py-4 px-6">
      <div className={cn("grow", className)}>{children}</div>
      {buttonAction && (
        <button onClick={buttonAction} className="shrink-0">
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-grey-400 hover:text-grey-900 transition-all duration-350 ease-in"
          />
        </button>
      )}
    </div>
  );
};

export default Card;
