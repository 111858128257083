import de from "~/assets/icons/flags/de.png";
import fr from "~/assets/icons/flags/fr.png";
import gb from "~/assets/icons/flags/gb.png";
import it from "~/assets/icons/flags/it.png";
import { DropdownOption } from "~/components/atoms/Dropdown/Dropdown.types";
import { AppLocale, CopyKeys } from "~/utils/types";

export type LanguageDropdownOption = DropdownOption & {
  id: AppLocale;
};

export const LANGUAGE_OPTIONS: (LanguageDropdownOption & {
  title: CopyKeys;
})[] = [
  {
    id: "en",
    title: "languages.en",
    icon: (
      <img
        src={gb}
        alt="en"
        className="rounded-full h-6 w-6 border border-grey-50"
      />
    ),
  },
  {
    id: "de-CH",
    title: "languages.de",
    icon: (
      <img
        src={de}
        alt="de"
        className="rounded-full h-6 w-6 border border-grey-50"
      />
    ),
  },
  {
    id: "fr-CH",
    title: "languages.fr",
    icon: (
      <img
        src={fr}
        alt="fr"
        className="rounded-full h-6 w-6 border border-grey-50"
      />
    ),
  },
  {
    id: "it-CH",
    title: "languages.it",
    icon: (
      <img
        src={it}
        alt="it"
        className="rounded-full h-6 w-6 border border-grey-50"
      />
    ),
  },
];
