import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { createArray } from "~/utils/array.utils";

type StarRatingProps = {
  starCount?: number;
  rating: number;
};

const StarRating = ({ starCount = 5, rating }: StarRatingProps) => {
  const ANIMATION_DURATION = 0.1;
  const calculateFillWidth = (value: number): string => {
    const decimalPart = rating - Math.floor(value);
    const fillPercentage = decimalPart > 0 ? decimalPart * 100 : 0;
    return `${Math.min(fillPercentage, 100)}%`;
  };

  return (
    <div className="flex gap-1 text-xs ml-1">
      {createArray(starCount).map((v) => {
        return (
          <div className="relative flex" key={v}>
            <FontAwesomeIcon icon={faStar} className=" text-grey-200" />
            <AnimatePresence>
              <motion.div
                transition={{
                  duration: ANIMATION_DURATION,
                  ease: "linear",
                  delay: v * ANIMATION_DURATION,
                }}
                initial={{ width: 0 }}
                animate={{ width: calculateFillWidth(v) }}
                className="text-yellow-900 overflow-hidden flex items-center absolute top-1/2 transform left-0 -translate-y-1/2"
              >
                <FontAwesomeIcon icon={faStar} />
              </motion.div>
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;
