import { useQuery } from "@tanstack/react-query";
import { WhyCard, WhyCardsList } from "~/api/models/WhyHS";
import { QueryKeys } from "~/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchIntroCards = () => {
  const { locale } = useLocaleStore();
  return useQuery<
    StoryblokMultiStoryResult<WhyCardsList>,
    WhyCard[],
    WhyCard[]
  >({
    queryKey: [QueryKeys.useFetchIntroCards, locale],
    queryFn: () =>
      storyblokServiceSingleton.get(storyblokRoutes.whyCards, {
        locale,
      }),
    select: (data) =>
      data.data.stories.length ? data.data.stories[0].content.cards : [],
  });
};
