import { generatePath, useNavigate, useParams } from "react-router-dom";
import { TreatmentPlanProductCategory } from "~/api/models/TreatmentPlan";
import Plan from "~/components/organisms/Plan/Plan";
import useTreatmentType from "~/hooks/useTreatmentType";
import { Routes } from "~/routes/routes";

const HairPlan = () => {
  const { getProductItemCategoryKeySelected } = useTreatmentType();
  const navigate = useNavigate();
  const { opportunityId } = useParams();

  const goToHairConfiguration = () => {
    navigate(
      generatePath(Routes.HairConfiguration, {
        opportunityId: opportunityId as string,
      })
    );
  };

  return (
    <Plan
      planCategory={TreatmentPlanProductCategory.hair}
      getProductItemCategoryKeySelected={getProductItemCategoryKeySelected}
      onNoProductsCta={goToHairConfiguration}
    />
  );
};

export default HairPlan;
