import Logo from "~/components/atoms/Logo/Logo";
import { cn } from "~/utils";

type ScreenLoaderProps = {
  done?: boolean;
};

const ScreenLoader = ({ done }: ScreenLoaderProps) => {
  return (
    <div className="h-screen w-full flex flex-col gap-10 items-center justify-center">
      <Logo />
      <div>
        <div className="relative w-80 bg-beige-100 h-2 rounded-full">
          <div
            className={cn(
              "absolute animate-home-loader w-80 bg-black h-2 rounded-full",
              {
                "animate-none": done,
              }
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ScreenLoader;
