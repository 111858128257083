import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppDownloadBadgeType } from "~/components/atoms/AppDownloadBadge/AppDownloadBadge.types";

type AppDownloadBadgeProps = {
  type: AppDownloadBadgeType;
};

const AppDownloadBadge = ({ type }: AppDownloadBadgeProps) => {
  return (
    <div className="h-10 bg-black rounded-xl py-1 px-4 flex gap-2 items-center">
      <FontAwesomeIcon icon={type.icon} className="text-white text-2xl" />
      <div className="flex flex-col text-2xs text-white items-start">
        <p>{type.text}</p>
        <p className="text-sm font-bold leading-none">{type.name}</p>
      </div>
    </div>
  );
};

export default AppDownloadBadge;
