import { MessageDescriptor, useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import noProductsImage from "~/assets/placeholder.png";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import { ButtonSize } from "~/components/atoms/Button/Buttons.types";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { Routes } from "~/routes/routes";

type EmptyStateScreenProps = {
  hideButton?: boolean;
  customTitleId?: MessageDescriptor["id"];
  customDescriptionId?: MessageDescriptor["id"];
  customCtaId?: MessageDescriptor["id"];
  customImageUrl?: string;
  onClick?: () => void;
};

const EmptyStateScreen = ({
  hideButton,
  customDescriptionId,
  customTitleId,
  customCtaId,
  onClick,
  customImageUrl,
}: EmptyStateScreenProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const goToIntro = () => {
    navigate(
      generatePath(Routes.Introduction, {
        opportunityId: opportunityId as string,
      })
    );
  };

  return (
    <div className="p-4 flex flex-col gap-6 items-center justify-center h-full">
      <div className="flex flex-col gap-4 items-center justify-center w-1/2 text-center">
        <img
          className="max-w-1/2 p-4"
          src={customImageUrl ?? noProductsImage}
          alt={intl.formatMessage({ id: customTitleId ?? "empty.title" })}
        />
        <div className="flex flex-col gap-2 items-center justify-center w-full text-center">
          <Headline level={6}>
            <Copy id={customTitleId ?? "empty.title"} />
          </Headline>
          <Subtitle className="text-grey-400" level={2}>
            <Copy id={customDescriptionId || "empty.description"} />
          </Subtitle>
        </div>
      </div>
      {!hideButton && (
        <Button
          className="w-1/3 max-w-full"
          size={ButtonSize.sm}
          text={intl.formatMessage({ id: customCtaId ?? "empty.cta" })}
          onClick={onClick ?? goToIntro}
        />
      )}
    </div>
  );
};

export default EmptyStateScreen;
