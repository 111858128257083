import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  generatePath,
  useNavigate,
  useParams,
  useRouteError,
} from "react-router-dom";
import errorIcon from "~/assets/error.png";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { Routes } from "~/routes/routes";
import { CustomError } from "~/services/CustomError";
import { NO_TOKEN_ERROR_STATUS_CODE } from "~/services/errors.const";
import { notifyOnce } from "~/utils/toast.utils";

type ErrorElementProps = {
  customTitle?: string;
  customDescription?: string;
  hideButtons?: boolean;
};

export function ErrorElement({
  customTitle,
  customDescription,
  hideButtons,
}: ErrorElementProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const error = useRouteError();
  const { opportunityId } = useParams();

  const handleLoginError = (error: unknown, opportunityId?: string) => {
    if (
      error instanceof CustomError &&
      error.cause === NO_TOKEN_ERROR_STATUS_CODE
    ) {
      navigate(
        generatePath(Routes.Login, { opportunityId: opportunityId as string })
      );
      notifyOnce(
        {
          toastType: "error",
          descriptionId: "error.unauthorized",
        },
        "unauthorizedError"
      );
    }
  };

  useEffect(() => {
    if (error) {
      handleLoginError(error, opportunityId);
    }
  }, [error, opportunityId]);

  const backHome = () => {
    navigate(
      generatePath(Routes.Welcome, { opportunityId: opportunityId as string })
    );
  };
  const refresh = () => {
    navigate(0);
  };
  const clear = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    navigate(-1);
  };

  return (
    <div className="flex w-full h-full min-h-screen items-center justify-center">
      <div className="flex flex-col gap-6 w-full h-full items-center justify-center">
        <div className="py-12 pt-0 px-9">
          <img src={errorIcon} alt="Error image" />
        </div>

        <div className="flex flex-col gap-2">
          <Headline className="text-center" level={4}>
            {customTitle ?? <Copy id="error.title" />}
          </Headline>
          <Subtitle className="text-grey-400 text-center">
            {customDescription ?? <Copy id="error.subtitle" />}
          </Subtitle>
        </div>
        {!hideButtons && (
          <div className="flex items-center gap-4 flex-row">
            <Button
              size={ButtonSize.md}
              onClick={refresh}
              text={intl.formatMessage({ id: "error.refresh" })}
            />
            <Button
              size={ButtonSize.md}
              onClick={backHome}
              variant={ButtonVariant.secondary}
              text={intl.formatMessage({ id: "error.button" })}
            />
          </div>
        )}
        {!hideButtons && (
          <button className="mt-1" onClick={clear}>
            <p className="underline">
              <Copy id="error.clear" />
            </p>
          </button>
        )}
      </div>
    </div>
  );
}
