import { useState } from "react";
import { cn } from "~/utils";

type SwitchProps = {
  value: boolean;
  onChanged: (value: boolean) => void;
  label?: string;
  labelStyle?: string;
  disabled?: boolean;
};

const Switch = ({
  value,
  onChanged,
  label,
  labelStyle,
  disabled,
}: SwitchProps) => {
  const [checked, setChecked] = useState(value);
  const toggleChecked = () => {
    if (disabled) return;
    setChecked(!checked);
    onChanged(!checked);
  };

  return (
    <div className="flex items-center gap-3">
      <button
        onClick={toggleChecked}
        className={cn(
          "flex bg-grey-200 w-9 h-5 p-0.5 rounded-full shadow-inner items-center transition-all duration-300",
          { "bg-black": checked }
        )}
      >
        <div
          className={cn(
            "w-full max-w-1/2 h-full bg-white rounded-full hover:shadow-inner transform transition-all duration-300",
            { "translate-x-full": checked, "cursor-pointer": !disabled }
          )}
        ></div>
      </button>
      {label && (
        <button onClick={toggleChecked} className={labelStyle}>
          {label}
        </button>
      )}
    </div>
  );
};

export default Switch;
