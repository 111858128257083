import { motion } from "framer-motion";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";
import Subtitle from "../Subtitle/Subtitle";

type TabProps = PropsWithClassName<{
  text: string;
  isActive: boolean;
  onClick: () => void;
  counter?: number;
}>;

export function Tab({ text, isActive, onClick, counter, className }: TabProps) {
  return (
    <button
      onClick={onClick}
      className={cn(
        "outline-sky-400 relative z-20 rounded-full px-8 py-3 font-sans font-medium text-grey-400 transition focus-visible:outline-2 flex items-center justify-center gap-1",
        { "text-black": isActive },
        className
      )}
    >
      {isActive && (
        <motion.span
          layoutId="bubble"
          className="absolute rounded-full inset-0 -z-10 m-1 bg-white shadow-sm"
          transition={{ duration: 0.3 }}
        />
      )}
      <Subtitle level={1} className="truncate">
        {text}
      </Subtitle>
      {counter !== undefined && (
        <span
          className={cn(
            "font-bold rounded-full w-5 h-5 shrink-0 p-1 flex items-center justify-center",
            isActive ? "bg-black text-white" : "bg-beige-500 text-grey-400"
          )}
        >
          <Subtitle level={1}>{counter}</Subtitle>
        </span>
      )}
    </button>
  );
}
