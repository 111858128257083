import { useQuery } from "@tanstack/react-query";
import {
  SkincareProduct,
  SkincareProductDTO,
} from "~/modules/skincare-products/skincare-products";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchSkincareProducts = () => {
  const { locale } = useLocaleStore();
  return useQuery({
    queryKey: [QueryKeys.useFetchSkincareProducts, locale],
    queryFn: () =>
      storyblokServiceSingleton.get<SkincareProductDTO>(
        storyblokRoutes.skincareProducts,
        {
          locale,
        }
      ),
    select: (data) =>
      data.data.stories?.map(
        (story) =>
          new SkincareProduct(
            story.uuid,
            story.content.title,
            story.content.description,
            story.content.image,
            story.content.benefits,
            story.content.subtitle
          )
      ),
  });
};
