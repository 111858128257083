import { useState } from "react";
import { useIntl } from "react-intl";
import { OtherSpecialConditionValue } from "~/api/models/SpecialCondition";
import { useFetchSpecialConditions } from "~/api/salesForce/useFetchSpecialConditions";
import EditableOption from "~/components/molecules/EditableOption/EditableOption";
import SpecialConditionsModal from "~/components/molecules/SpecialConditionsModal/SpecialConditionsModal";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { cn } from "~/utils";

type EditableSpecialConditionsProps = {
  editable?: boolean;
};

const EditableSpecialConditions = ({
  editable,
}: EditableSpecialConditionsProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen(true);
  const { data: conditions, isLoading } = useFetchSpecialConditions();
  const [specialConditions, otherSpecialConditions] = useCheckoutStore(
    (state) => [
      state.specialConditionsValues ?? [],
      state.otherSpecialConditions ?? "",
    ]
  );

  const specialConditionsText = specialConditions
    .map((c) => {
      if (
        c.toLowerCase() === OtherSpecialConditionValue &&
        otherSpecialConditions
      ) {
        return otherSpecialConditions;
      }
      return conditions?.find((cond) => cond.value === c)?.label;
    })
    .join(", ");

  return (
    <div>
      <SpecialConditionsModal
        open={open}
        setOpen={setOpen}
        conditions={conditions}
        loadingConditions={isLoading}
      />
      <EditableOption
        onClick={showModal}
        title={
          specialConditionsText || intl.formatMessage({ id: "field.empty" })
        }
        editable={editable}
        className={cn({ "text-black": specialConditions.length })}
      />
    </div>
  );
};

export default EditableSpecialConditions;
