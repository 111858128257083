import { PropsWithChildren } from "react";
import Button from "~/components/atoms/Button/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/atoms/Dialog/Dialog";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type PricingModalProps = PropsWithClassName<
  PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    buttonText?: string;
    buttonAction?: () => void;
    hasOnlySaveButton?: boolean;
    saveIsLoading?: boolean;
  }>
>;

const PricingModal = ({
  title,
  children,
  buttonText,
  buttonAction,
  open,
  setOpen,
  className,
  hasOnlySaveButton,
  saveIsLoading,
}: PricingModalProps) => {
  const onOpenChange = (open: boolean) => {
    if (hasOnlySaveButton && !open) {
      return;
    }
    setOpen(open);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(
          "max-w-pricing-modal min-h-pricing-modal flex flex-col justify-between max-h-[90%] overflow-auto",
          className
        )}
      >
        <div className="flex flex-col gap-6 grow">
          <DialogHeader>
            <DialogTitle className="font-normal">{title}</DialogTitle>
            {!hasOnlySaveButton && <DialogClose />}
          </DialogHeader>
          {children}
        </div>
        <Button
          loading={saveIsLoading}
          text={buttonText}
          className="max-w-full"
          onClick={buttonAction}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PricingModal;
