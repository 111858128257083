import { PropsWithChildren } from "react";
import { AnimatePresence, MotionProps, motion } from "framer-motion";
import { PropsWithClassName } from "~/utils/types";

type FadeInSectionProps = PropsWithChildren<PropsWithClassName> &
  MotionProps & {
    duration?: number;
  };

const FadeInSection = ({
  children,
  className,
  duration = 0.5,
  ...rest
}: FadeInSectionProps) => {
  return (
    <AnimatePresence>
      <motion.div
        className={className}
        transition={{ duration: duration, ease: "easeInOut", stiffness: 10 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        {...rest}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default FadeInSection;
