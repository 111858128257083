import { useNavigate } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TreatmentPlanCategory } from "~/api/models/TreatmentPlan";
import Headline from "~/components/atoms/Headline/Headline";
import ZoomCard from "~/components/molecules/ZoomCard/ZoomCard";
import { cn } from "~/utils";

type TreatmentPlanCategoryCardProps = {
  treatmentPlanCategory: TreatmentPlanCategory;
  hasZoom?: boolean;
  onHover?: () => void;
};

const TreatmentPlanCategoryCard = ({
  treatmentPlanCategory,
  hasZoom,
  onHover,
}: TreatmentPlanCategoryCardProps) => {
  const navigate = useNavigate();
  const { title, image, key } = treatmentPlanCategory;
  const goToCategory = () => {
    navigate(key);
  };
  return (
    <ZoomCard
      hasZoom={hasZoom}
      backgroundImage={{
        src: image.filename,
        alt: image.alt,
      }}
      onClick={goToCategory}
      onHover={onHover}
    >
      {(isHovered) => (
        <div className="flex w-full justify-between gap-2">
          <Headline level={4} className="text-white">
            {title}
          </Headline>

          <div
            className={cn(
              "text-white text-base hover:text-black opacity-0 flex items-center",
              {
                "opacity-100": isHovered && hasZoom,
              }
            )}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      )}
    </ZoomCard>
  );
};

export default TreatmentPlanCategoryCard;
