import { useQuery } from "@tanstack/react-query";
import {
  PhotoPosition,
  PhotoPositionCategoryDTO,
} from "~/modules/upload-photo/upload-photo";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchUploadPhotosPositions = (type: "hair" | "skin") => {
  const { locale } = useLocaleStore();

  const result = useQuery({
    queryKey: [QueryKeys.useFetchPhotosPositions, locale],
    queryFn: () =>
      storyblokServiceSingleton.get<PhotoPositionCategoryDTO>(
        type === "hair"
          ? storyblokRoutes.hairPhotosPositions
          : storyblokRoutes.skinPhotosPositions,
        {
          locale,
        }
      ),
  });

  return {
    ...result,
    data: result.data?.data.stories.map((story) => {
      return {
        key: story.content.key,
        positions: story.content.positions.map(
          (position) =>
            new PhotoPosition(
              position.title,
              position.title,
              position.placeholderImage.filename,
              position.placeholderImage.alt
            )
        ),
      };
    }),
  };
};
