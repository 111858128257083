import { generatePath, useNavigate, useParams } from "react-router-dom";
import logo from "~/assets/images/logo.png";
import { Routes } from "~/routes/routes";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

const Logo = ({ className }: PropsWithClassName) => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();

  return (
    <img
      onClick={() =>
        navigate(
          generatePath(Routes.Welcome, {
            opportunityId: opportunityId as string,
          })
        )
      }
      src={logo}
      alt="logo"
      className={cn("max-w-logo cursor-pointer", className)}
    />
  );
};

export default Logo;
