import { motion } from "framer-motion";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

const animationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: [0.8, 1, 0.8], y: [0, -1, 0] },
  exit: { opacity: 0 },
};

const LoadingDots = ({ className }: PropsWithClassName) => {
  return (
    <div className={cn("flex gap-1", className)}>
      <motion.span
        className="text-inherit"
        {...animationVariants}
        transition={{ delay: 0, repeat: Infinity }}
      >
        .
      </motion.span>
      <motion.span
        className="text-inherit"
        {...animationVariants}
        transition={{ delay: 0.2, repeat: Infinity }}
      >
        .
      </motion.span>
      <motion.span
        className="text-inherit"
        {...animationVariants}
        transition={{ delay: 0.4, repeat: Infinity }}
      >
        .
      </motion.span>
    </div>
  );
};

export default LoadingDots;
