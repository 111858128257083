import {
  SELECTED_RADIUS,
  UNSELECTED_RADIUS,
} from "~/components/molecules/SkinHeadConfiguration//SkinHeadConfiguration";
import { SkinSection } from "~/stores/headConfiguration/ConfigurationSection.types";
import { cn } from "~/utils";

type MaleSkinConfigurationProps = {
  toggleSkinSectionAndUpdateProduct: (section: SkinSection) => void;
  isSelected: (section: SkinSection) => boolean;
};

const MaleSkinConfiguration = ({
  toggleSkinSectionAndUpdateProduct,
  isSelected,
}: MaleSkinConfigurationProps) => {
  return (
    <svg
      className="h-full w-full"
      viewBox="0 0 480 444"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.178 330.428C168.178 330.428 168.624 396.251 163.572 412.001"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M312.156 330.428C312.156 330.428 308.738 393.428 316.762 412.001"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M141.136 262.525C129.843 259.257 123.008 236.078 119.442 219.585C114.836 198.04 130.289 201.606 130.289 201.606"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M346.776 201.457C346.776 201.457 368.024 198.188 364.458 219.881C361.189 239.197 349.599 256.879 338.901 262.525"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M312.007 49.9015L308.293 47.6728C283.777 31.6258 239.053 32.0715 239.053 32.0715C239.053 32.0715 197.45 29.9914 167.584 48.2671C121.226 73.8235 109.637 117.656 118.998 168.322C122.118 184.964 128.507 186.598 130.587 195.513C131.33 198.931 132.519 202.794 133.113 207.103C133.262 208.44 135.49 208.44 135.49 206.954L135.639 145.292C133.559 126.571 145 121.222 160.452 109.781C165.356 106.215 180.808 128.948 203.987 134.891C208.742 136.08 217.954 138.754 224.64 140.835C234.001 143.806 247.374 142.618 252.574 140.835C259.26 138.457 268.473 136.228 273.227 134.891C296.406 128.948 311.859 103.986 316.762 109.781C326.866 122.113 341.427 127.462 341.576 145.292L342.467 176.346C342.467 177.98 345.439 202.794 345.587 204.428C346.182 208.44 349.005 209.777 349.599 207.103C351.679 198.188 355.542 184.072 358.514 167.431C367.578 120.033 352.274 71.4461 312.007 49.9015Z"
        fill="white"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M271.445 305.464C271.445 305.464 267.284 307.544 257.626 306.95C251.535 306.504 247.077 307.099 244.403 307.544C242.174 307.99 239.796 307.842 237.716 307.396C234.893 306.802 230.287 306.059 224.195 306.504C214.389 307.099 210.377 305.019 210.377 305.019"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M262.679 313.489C262.679 313.489 257.627 318.244 245.443 318.69C244.551 318.69 243.66 318.69 242.768 318.69H239.202C238.311 318.69 237.419 318.69 236.528 318.69C224.344 318.244 218.995 313.489 218.995 313.489"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M248.71 338.6C245.887 337.708 243.212 337.411 240.835 337.411H240.686C238.309 337.411 235.635 337.708 232.812 338.6"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M224.491 272.182C225.086 271.588 226.126 270.994 227.463 270.845C230.137 270.548 232.812 272.479 234.446 274.114C237.567 277.234 242.321 278.423 246.333 276.491C246.63 276.343 246.927 276.194 247.224 276.045L248.859 274.857C249.453 274.411 250.048 273.965 250.493 273.519C252.128 272.034 254.505 271.291 257.031 271.439C257.774 271.588 258.368 271.588 258.814 271.885"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M218.698 260.892C218.698 260.892 213.349 272.035 222.71 275.007"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M264.907 260.892C264.907 260.892 270.108 272.035 260.896 275.007"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M261.34 378.271C260.003 380.649 254.951 390.01 256.734 395.656"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M220.183 378.271C221.52 380.649 226.572 390.01 224.789 395.656"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M262.975 196.406C262.53 196.257 261.638 189.868 266.69 184.222C272.188 178.278 283.777 174.712 290.76 173.078C294.772 172.186 298.933 171.741 303.093 171.741C306.362 171.889 310.225 172.186 313.048 173.078C319.14 175.01 325.38 183.33 327.015 192.245C327.015 192.245 319.883 182.884 306.956 182.884C294.029 182.884 273.079 192.691 273.079 192.691C273.079 192.691 265.65 197.743 262.975 196.406Z"
        fill="#F8F8F8"
      />
      <path
        d="M222.264 195.364C222.71 195.215 223.601 188.826 218.401 183.18C212.903 177.237 201.314 173.671 194.331 172.036C190.319 171.145 186.158 170.699 181.998 170.699C178.729 170.848 174.866 171.145 172.043 172.036C165.951 173.968 159.711 182.289 158.076 191.204C158.076 191.204 165.208 181.843 178.135 181.843C191.062 181.843 212.012 191.649 212.012 191.649C212.012 191.649 219.59 196.701 222.264 195.364Z"
        fill="#F8F8F8"
      />
      <path
        d="M346.479 215.573C343.805 227.311 341.725 244.101 340.388 251.381C337.119 268.766 337.416 272.48 335.484 287.636C333.553 304.574 324.935 315.421 324.935 315.421C307.699 335.48 305.916 339.788 277.685 361.333C273.971 364.156 269.662 365.939 264.907 366.682C259.112 367.574 254.804 368.316 245.146 368.316H240.54C230.882 368.316 222.115 367.574 216.172 366.682C211.566 365.939 207.108 364.008 203.394 361.333C175.163 339.788 173.38 335.48 156.144 315.421C156.144 315.421 147.526 304.426 145.595 287.636C143.812 272.48 143.96 268.766 140.692 251.381C139.354 244.101 137.274 227.311 134.6 215.573"
        stroke="black"
        strokeWidth="0.742917"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <g
        filter="url(#filter0_b_11134_240062)"
        id="botox-forehead"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("botox-forehead"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("botox-forehead");
        }}
      >
        <circle
          cx="240.998"
          cy="163"
          r={isSelected("botox-forehead") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.501059"
        />
      </g>
      <g
        filter="url(#filter1_b_11134_240062)"
        id="botox-glabella"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("botox-glabella"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("botox-glabella");
        }}
      >
        <circle
          cx="240.966"
          cy="195.966"
          r={isSelected("botox-glabella") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <g
        filter="url(#filter2_b_11134_240062)"
        id="botox-outer-eye"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("botox-outer-eye"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("botox-outer-eye");
        }}
      >
        <circle
          cx="324.966"
          cy="211.966"
          r={
            isSelected("botox-outer-eye") ? SELECTED_RADIUS : UNSELECTED_RADIUS
          }
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <g
        filter="url(#filter3_b_11134_240062)"
        id="filler-cheeks"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("filler-cheeks"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("filler-cheeks");
        }}
      >
        <circle
          cx="308.966"
          cy="262.966"
          r={isSelected("filler-cheeks") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <g
        filter="url(#filter4_b_11134_240062)"
        id="botox-nasojugal-fold"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("botox-nasojugal-fold"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("botox-nasojugal-fold");
        }}
      >
        <circle
          cx="216.95"
          cy="229.966"
          r={
            isSelected("botox-nasojugal-fold")
              ? SELECTED_RADIUS
              : UNSELECTED_RADIUS
          }
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <g
        filter="url(#filter5_b_11134_240062)"
        id="filler-lips"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("filler-lips"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("filler-lips");
        }}
      >
        <circle
          cx="240.966"
          cy="309.966"
          r={isSelected("filler-lips") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <g
        filter="url(#filter6_b_11134_240062)"
        id="filler-chin"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("filler-chin"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("filler-chin");
        }}
      >
        <circle
          cx="240.983"
          cy="349.966"
          r={isSelected("filler-chin") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <g
        filter="url(#filter7_b_11134_240062)"
        id="filler-jawline"
        className={cn("cursor-pointer fill-white transition-all duration-500", {
          "fill-[#E0DACC]": isSelected("filler-jawline"),
        })}
        onClick={() => {
          toggleSkinSectionAndUpdateProduct("filler-jawline");
        }}
      >
        <circle
          cx="168.136"
          cy="323.966"
          r={isSelected("filler-jawline") ? SELECTED_RADIUS : UNSELECTED_RADIUS}
          fillOpacity="0.8"
          stroke="#D3CDBE"
          strokeWidth="0.502121"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_11134_240062"
          x="228.497"
          y="150.499"
          width="25.002"
          height="25.002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_11134_240062"
          x="202.464"
          y="157.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_11134_240062"
          x="286.464"
          y="173.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_b_11134_240062"
          x="270.464"
          y="224.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_b_11134_240062"
          x="178.448"
          y="191.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_b_11134_240062"
          x="202.464"
          y="271.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_b_11134_240062"
          x="202.481"
          y="311.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_b_11134_240062"
          x="129.634"
          y="285.464"
          width="77.0039"
          height="77.0039"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_11134_240062"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_11134_240062"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MaleSkinConfiguration;
