import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import { ConfigurationAccordions } from "~/components/atoms/Accordion/Accordions.type";
import ConfigurationAccordion from "~/components/molecules/ConfigurationAccordion/ConfigurationAccordion";
import useHairProducts from "~/hooks/useHairProducts";
import { ProductRadioGroupOption } from "~/stores/checkout/product";

type HeadGraftsProps = {
  collapsible?: boolean;
  openedAccordion?: ConfigurationAccordions;
  isEditable?: boolean;
};

const HeadGrafts = ({
  collapsible,
  openedAccordion,
  isEditable = true,
}: HeadGraftsProps) => {
  const intl = useIntl();
  const { data: allProducts } = useFetchProducts();
  const { transplantationProduct, setTransplantationProduct } =
    useHairProducts();

  const products: ProductRadioGroupOption[] = useMemo(
    () =>
      allProducts.hair?.head.transplantation?.short?.map((option) => ({
        ...option,
        name: option.name,
        text: intl.formatMessage(
          { id: "configuration.grafts.value" },
          { from: option.grafts.from, to: option.grafts.to }
        ),
      })) ?? [],
    [allProducts.hair?.head.transplantation?.short]
  );
  const [selectedOption, setSelectedOption] = useState<
    ProductRadioGroupOption | undefined
  >(
    products.find((product) => product.id === transplantationProduct?.id) ||
      products[0]
  );

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(
        products.find((product) => product.id === transplantationProduct?.id) ||
          products[0]
      );
    }
  }, [products, transplantationProduct]);

  const isOpen = useMemo(
    () => openedAccordion == ConfigurationAccordions.HEAD_GRAFTS,
    [openedAccordion]
  );

  if (!selectedOption) return null;

  return (
    <ConfigurationAccordion
      itemValue={ConfigurationAccordions.HEAD_GRAFTS}
      collapsible={collapsible}
      isOpen={isOpen}
      products={products}
      setSelectedOption={setSelectedOption}
      title={intl.formatMessage({
        id: "configuration.head.grafts.title",
      })}
      closedAccordionDescription={intl.formatMessage(
        { id: "configuration.grafts.value" },
        {
          from: selectedOption.grafts.from,
          to: selectedOption.grafts.to,
        }
      )}
      product={transplantationProduct}
      setProduct={setTransplantationProduct}
      isEditable={isEditable}
    />
  );
};

export default HeadGrafts;
