import { HairProductKey } from "~/api/models/HairProductKey";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import BeardCard from "~/components/organisms/Pricing/BeardCard/BeardCard";
import BotoxSweatCard from "~/components/organisms/Pricing/BotoxSweatCard/BotoxSweatCard";
import BotoxWrinklesCard from "~/components/organisms/Pricing/BotoxWrinklesCard/BotoxWrinklesCard";
import CheeksFillerCard from "~/components/organisms/Pricing/CheeksFillerCard/CheeksFillerCard";
import ChinFillerCard from "~/components/organisms/Pricing/ChinFillerCard/ChinFillerCard";
import EyebrowsCard from "~/components/organisms/Pricing/EyebrowsCard/EyebrowsCard";
import HyaluronidaseFillerCard from "~/components/organisms/Pricing/HyaluronidaseFillerCard/HyaluronidaseFillerCard";
import JawlineFillerCard from "~/components/organisms/Pricing/JawlineFillerCard/JawlineFillerCard";
import LipsFillerCard from "~/components/organisms/Pricing/LipsFillerCard/LipsFillerCard";
import NeedlingCard from "~/components/organisms/Pricing/NeedlingCard/NeedlingCard";
import SkinBoosterFillerCard from "~/components/organisms/Pricing/SkinBoosterFillerCard/SkinBoosterFillerCard";
import SkinPackageCard from "~/components/organisms/Pricing/SkinBundleCard/SkinBundleCard";
import TransplantBundleOfferCard from "~/components/organisms/Pricing/TransplantBundleOfferCard/TransplantBundleOfferCard";

type productCardBuilderArgs = {
  productKey?: SkinProductKey | HairProductKey;
};

export const productCardBuilder = ({ productKey }: productCardBuilderArgs) => {
  const cards = {
    hair: <TransplantBundleOfferCard key="hair" />,
    beard: <BeardCard key="beard" />,
    eyebrows: <EyebrowsCard key="eyebrows" />,
    needling: <NeedlingCard key="needling" />,
    "lips filler": <LipsFillerCard key="lips filler" />,
    "cheeks filler": <CheeksFillerCard key="cheeks filler" />,
    "chin filler": <ChinFillerCard key="chin filler" />,
    "jawline filler": <JawlineFillerCard key="jawline filler" />,
    hyaluronidase: <HyaluronidaseFillerCard key="hyaluronidase" />,
    "skin booster": <SkinBoosterFillerCard key="skin booster" />,
    "botox wrinkles": <BotoxWrinklesCard key="botox wrinkles" />,
    "botox sweat": <BotoxSweatCard key="botox sweat" />,
    "skin bundle": <SkinPackageCard key="skin bundle" />,
    "botox after treatment": null,
    ht: null,
    prp: null,
    "skin care": null,
    bundle: null,
    "botox bundle": null,
    "general filler": null,
    freePrp: null,
  };
  if (!productKey) return null;
  return cards[productKey];
};
