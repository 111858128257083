import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface AuthStore {
  refreshToken: string | null;
  expiresAt: number | null;
  setRefreshToken: (arg: string | null) => void;
  setExpiresAt: (arg: number | null) => void;
  isLoggedIn: () => boolean;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      refreshToken: null,
      expiresAt: null,
      setRefreshToken: (arg: string | null) =>
        set({
          refreshToken: arg,
        }),
      setExpiresAt: (arg: number | null) =>
        set({
          expiresAt: arg,
        }),
      isLoggedIn: () => get().refreshToken !== null,
    }),
    {
      storage: createJSONStorage(() => localStorage),
      name: "auth-store",
    }
  )
);
