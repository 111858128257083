import { useQuery } from "@tanstack/react-query";
import { HairProductKey } from "~/api/models/HairProductKey";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { Product, ProductFamily, ProductType } from "~/stores/checkout/product";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { SKIN_CARE_PRODUCT_CODE } from "../constants/skincare.const";

export type ProductsDTO = {
  id: string;
  name: string;
  family: ProductFamily;
  type: ProductType;
  suggested: boolean;
  ht_max_grafts: number;
  ht_min_grafts: number;
  pricebook_entry: {
    unit_price: number;
  } | null;
  number_of_sessions: number;
  is_long_hair: boolean;
  product_code: string;
  number_of_areas: number;
  hidden_from_direct_sales: boolean;
};

export type AllProductsList = {
  hair: {
    head: {
      transplantation?: { long?: Product[]; short?: Product[] };
      prp?: Product[];
      freePrp?: Product[];
      bundle?: Product[];
    };
    beard: {
      transplantation: Product[];
    };
    eyebrow: {
      transplantation: Product[];
    };
  };
  skin: {
    botox: {
      wrinkles: {
        x1: Product[];
        x2: Product[];
      };
      sweat: {
        x1: Product[];
        x2: Product[];
      };
      afterTreatment: Product[];
      bundle: Product[];
    };
    needling: Product[];
    filler: {
      lips: Product[];
      cheeks: Product[];
      jawline: Product[];
      chin: Product[];
      hyaluronidase: Product[];
      skinBooster: Product[];
      general: Product[];
    };
    care: Product[];
  };
};

const ProductsDTOToProducts = (result: ProductsDTO[]): Product[] => {
  return result.map(
    ({
      id,
      name,
      family,
      type,
      suggested,
      ht_min_grafts,
      ht_max_grafts,
      pricebook_entry,
      number_of_sessions,
      is_long_hair,
      product_code,
      number_of_areas,
      hidden_from_direct_sales,
    }: ProductsDTO) =>
      new Product(
        id,
        name,
        family.toLowerCase() as ProductFamily,
        type.toLowerCase() as ProductType,
        suggested,
        { from: ht_min_grafts, to: ht_max_grafts },
        pricebook_entry?.unit_price ?? 0,
        number_of_sessions,
        is_long_hair,
        product_code,
        number_of_areas,
        hidden_from_direct_sales
      )
  );
};

export const useFetchProducts = () => {
  const [selectedProducts, setSelectedProducts] = useCheckoutStore((state) => [
    state.selectedProducts,
    state.setSelectedProducts,
  ]);
  const { data } = useFetchAccessToken();
  const { locale } = useLocaleStore();

  const updateStoredProducts = (data: Product[]) => {
    let changedLocale = false;
    //change products in store so that their names are translated
    const newSelectedProducts = selectedProducts.map((product) => {
      const newProduct = data.find((p) => p.id === product.id);
      if (!changedLocale && newProduct && newProduct.name !== product.name) {
        changedLocale = true;
      }
      return newProduct || product;
    });
    if (changedLocale) {
      setSelectedProducts(newSelectedProducts);
    }
  };

  const result = useQuery({
    queryKey: [QueryKeys.useFetchProducts, locale],
    queryFn: () =>
      salesforceServiceSingleton.getProducts(data!.accessToken, locale), // accessToken is not null because of the enabled flag
    enabled: !!data?.ensureAccessTokenIsValid?.(),
    onSuccess: (data) => {
      updateStoredProducts(ProductsDTOToProducts(data));
    },
  });
  const products = Array.isArray(result?.data)
    ? ProductsDTOToProducts(result.data)
    : [];

  return {
    ...result,
    data: {
      hair: {
        head: {
          transplantation: {
            long: products
              .filter(
                (option) =>
                  option.family === "hair transplant" && option.isLongHair
              )
              .sort((a, b) => a.grafts.to - b.grafts.to),
            short: products
              .filter(
                (option) =>
                  option.family === "hair transplant" && !option.isLongHair
              )
              .sort((a, b) => a.grafts.to - b.grafts.to),
          },
          prp: products
            .filter(
              (option) =>
                option.family === "prp hair" && option.type === "prp hair"
            )
            .sort((a, b) => a.sessions - b.sessions),
          freePrp: products.filter(
            (option) => option.productCardKey === HairProductKey.freePrp
          ),
          bundle: products
            .filter(
              (option) =>
                option.family === "prp hair" && option.type === "bundle"
            )
            .sort((a, b) => a.sessions - b.sessions),
        },
        beard: {
          transplantation: products
            .filter((option) => option.productCardKey === HairProductKey.beard)
            .sort((a, b) => a.grafts.to - b.grafts.to),
        },
        eyebrow: {
          transplantation: products
            .filter(
              (option) => option.productCardKey === HairProductKey.eyebrows
            )
            .sort((a, b) => a.grafts.to - b.grafts.to),
        },
      },
      skin: {
        botox: {
          wrinkles: {
            x1: products
              .filter(
                (option) =>
                  option.productCardKey === SkinProductKey.botoxWrinkles &&
                  option.type === "botox x1"
              )
              .sort((a, b) => a.areas - b.areas),

            x2: products
              .filter(
                (option) =>
                  option.productCardKey === SkinProductKey.botoxWrinkles &&
                  option.type === "botox x2"
              )
              .sort((a, b) => a.areas - b.areas),
          },
          afterTreatment: products.filter(
            (option) =>
              option.productCardKey === SkinProductKey.botoxAfterTreatment
          ),
          sweat: {
            x1: products
              .filter(
                (option) =>
                  option.productCardKey === SkinProductKey.botoxSweat &&
                  option.type === "sweat botox x1"
              )
              .sort((a, b) => a.areas - b.areas),

            x2: products
              .filter(
                (option) =>
                  option.productCardKey === SkinProductKey.botoxSweat &&
                  option.type === "sweat botox x2"
              )
              .sort((a, b) => a.areas - b.areas),
          },
          bundle: products.filter(
            (option) => option.productCardKey === SkinProductKey.botoxBundle
          ),
        },
        needling: products
          .filter((option) => option.productCardKey === SkinProductKey.needling)
          .sort((a, b) => a.sessions - b.sessions),
        filler: {
          lips: products.filter(
            (option) => option.productCardKey === SkinProductKey.lipsFiller
          ),
          cheeks: products.filter(
            (option) => option.productCardKey === SkinProductKey.cheeksFiller
          ),
          jawline: products.filter(
            (option) => option.productCardKey === SkinProductKey.jawlineFiller
          ),
          chin: products.filter(
            (option) => option.productCardKey === SkinProductKey.chinFiller
          ),
          hyaluronidase: products.filter(
            (option) => option.productCardKey === SkinProductKey.hyaluronidase
          ),
          skinBooster: products.filter(
            (option) => option.productCardKey === SkinProductKey.skinBooster
          ),
          general: products.filter(
            (option) => option.productCardKey === SkinProductKey.generalFiller
          ),
        },
        care: products.filter(
          (option) => option.productCode === SKIN_CARE_PRODUCT_CODE
        ),
      },
    },
  };
};
