export class CustomError extends Error {
  hasErrorBoundary: boolean;

  constructor({
    message,
    options,
    hasErrorBoundary,
  }: {
    message: string;
    options?: ErrorOptions;
    hasErrorBoundary?: boolean;
  }) {
    super(message, options);
    this.name = this.constructor.name;
    this.hasErrorBoundary = hasErrorBoundary ?? true;
    // Ensure stack trace is captured for this error
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
