import { notifyOnce } from "./toast.utils";

export const verifyUploadOnePdf = (files: File[] | FileList): boolean => {
  if (files instanceof FileList) {
    files = Array.from(files);
  }

  //only allow one file
  if (files.length !== 1) {
    return false;
  }

  //only allow pdf
  const allowedTypes = ["application/pdf"];
  if (files.some((file) => !allowedTypes.includes(file.type))) {
    return false;
  }

  return true;
};

export const notifyFileUploadError = () => {
  notifyOnce(
    {
      descriptionId: "file.drop.fail",
      toastType: "error",
    },
    "file.drop.fail"
  );
};

export const showPdfInNewWindow = (pdfBlob: Blob) => {
  if (pdfBlob) {
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");
  }
};
