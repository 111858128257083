import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import { cn } from "~/utils";

type PlanCategoryInformationSwiperButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  icon?: IconDefinition;
};

const PlanCategoryInformationSwiperButton = ({
  disabled,
  onClick,
  icon,
}: PlanCategoryInformationSwiperButtonProps) => {
  return (
    <Button
      contentType={ButtonContentType.icon}
      variant={ButtonVariant.secondaryOutline}
      size={ButtonSize.sm}
      onClick={onClick}
      disabled={disabled}
      className={cn("w-10 h-10", {
        "bg-transparent": disabled,
      })}
      icon={icon}
    />
  );
};

export default PlanCategoryInformationSwiperButton;
