import { StoryblokAsset, storyblokRoutes } from "~/services/storyblok";
import { Benefit } from "./Benefit";
import { HairProductKey } from "./HairProductKey";
import { SkinProductKey } from "./SkinProductKey";

export type ProductImage = {
  male?: StoryblokAsset;
  female?: StoryblokAsset;
};

export type ProductDetailsDTO = {
  benefits: Benefit[];
  image: ProductImage[];
  hideBenefits: boolean;
};

export type ProductDetails = {
  benefits: Benefit[];
  image?: ProductImage;
  hideBenefits: boolean;
};

export class ProductDetailsType {
  public static prp = new ProductDetailsType(
    HairProductKey.prp,
    storyblokRoutes.productDetailsPrp
  );
  public static hairBundle = new ProductDetailsType(
    HairProductKey.bundle,
    storyblokRoutes.productDetailsHairBundle
  );

  public static ht = new ProductDetailsType(
    HairProductKey.ht,
    storyblokRoutes.productDetailsHt
  );

  public static beard = new ProductDetailsType(
    HairProductKey.beard,
    storyblokRoutes.productDetailsBeard
  );
  public static eyebrow = new ProductDetailsType(
    HairProductKey.eyebrows,
    storyblokRoutes.productDetailsEyebrow
  );
  public static needling = new ProductDetailsType(
    SkinProductKey.needling,
    storyblokRoutes.productDetailsNeedling
  );
  public static lipsFiller = new ProductDetailsType(
    SkinProductKey.lipsFiller,
    storyblokRoutes.productDetailsLipsFiller
  );
  public static cheeksFiller = new ProductDetailsType(
    SkinProductKey.cheeksFiller,
    storyblokRoutes.productDetailsCheeksFiller
  );
  public static jawlineFiller = new ProductDetailsType(
    SkinProductKey.jawlineFiller,
    storyblokRoutes.productDetailsJawlineFiller
  );
  public static chinFiller = new ProductDetailsType(
    SkinProductKey.chinFiller,
    storyblokRoutes.productDetailsChinFiller
  );
  public static skinBooster = new ProductDetailsType(
    SkinProductKey.skinBooster,
    storyblokRoutes.productDetailsSkinBooster
  );
  public static hyaluronidase = new ProductDetailsType(
    SkinProductKey.hyaluronidase,
    storyblokRoutes.productDetailsHyaluronidase
  );
  public static botoxWrinkles = new ProductDetailsType(
    SkinProductKey.botoxWrinkles,
    storyblokRoutes.productDetailsBotoxWrinkles
  );
  public static botoxSweat = new ProductDetailsType(
    SkinProductKey.botoxSweat,
    storyblokRoutes.productDetailsBotoxSweat
  );
  public static botoxAfterTreatment = new ProductDetailsType(
    SkinProductKey.botoxAfterTreatment,
    storyblokRoutes.productDetailsBotoxAfterTreatment
  );

  public static skinCare = new ProductDetailsType(
    SkinProductKey.skinCare,
    storyblokRoutes.productDetailsSkinCare
  );

  public static all: ProductDetailsType[] = [
    ProductDetailsType.prp,
    ProductDetailsType.ht,
    ProductDetailsType.hairBundle,
    ProductDetailsType.beard,
    ProductDetailsType.eyebrow,
    ProductDetailsType.needling,
    ProductDetailsType.lipsFiller,
    ProductDetailsType.cheeksFiller,
    ProductDetailsType.jawlineFiller,
    ProductDetailsType.chinFiller,
    ProductDetailsType.skinBooster,
    ProductDetailsType.hyaluronidase,
    ProductDetailsType.botoxWrinkles,
    ProductDetailsType.botoxSweat,
    ProductDetailsType.botoxAfterTreatment,
    ProductDetailsType.skinCare,
  ];

  productCardKey: SkinProductKey | HairProductKey;
  route: string;

  private constructor(name: SkinProductKey | HairProductKey, route: string) {
    this.productCardKey = name;
    this.route = route;
  }
  static fromProductKey = (productKey?: SkinProductKey | HairProductKey) => {
    return (
      ProductDetailsType.all.find(
        (productDetailsType) => productDetailsType.productCardKey === productKey
      ) ?? ProductDetailsType.ht
    );
  };
}
