import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { render } from "storyblok-rich-text-react-renderer";
import { useFetchPaymentMethods } from "~/api/storyblok/useFetchPaymentMethods";
import { ReactComponent as Info } from "~/assets/svg/info.svg";
import Tag from "~/components/atoms/Tag/Tag";
import { Tooltip } from "~/components/atoms/Tooltip/Tooltip";
import PricingModal from "~/components/molecules/PricingModal/PricingModal";
import PricingModalOption from "~/components/molecules/PricingModalOption/PricingModalOption";
import { useStoryblokExclusives } from "~/hooks/useStoryblokExclusives";
import { PaymentMethod } from "~/stores/checkout/paymentMethod";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type PaymentMethodModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  amount?: number;
};

const PaymentMethodModal = ({
  open,
  setOpen,
  amount,
}: PaymentMethodModalProps) => {
  const intl = useIntl();
  const { data: paymentMethods, isLoading, isError } = useFetchPaymentMethods();
  const [paymentMethod, setPaymentMethod] = useCheckoutStore((state) => [
    state.paymentMethod,
    state.setPaymentMethod,
  ]);
  const isAvailable = useStoryblokExclusives();

  const [selected, setSelected] = useState<string | undefined>(undefined);

  const filteredPaymentMethods = useMemo(
    () =>
      paymentMethods
        ? paymentMethods.filter((method) => isAvailable(method.exclusives))
        : [],
    [paymentMethods, isAvailable]
  );

  const selectOption = (option: PaymentMethod) => {
    setSelected(option.salesforceValue);
  };
  const saveOption = () => {
    if (!selected) return;
    setPaymentMethod(selected);
    setOpen(false);
  };

  useEffect(() => {
    /*
    when the modal opens it assigns the previously saved paymentMethod or the first one in the list
    */
    if (open) {
      if (paymentMethod) {
        setSelected(paymentMethod);
      } else if (paymentMethods.length) {
        setSelected(paymentMethods[0].salesforceValue);
      }
    }
  }, [open]);

  return (
    <PricingModal
      open={open}
      setOpen={setOpen}
      title={intl.formatMessage({ id: "pricing.modal.paymentMethods.title" })}
      buttonText={intl.formatMessage({ id: "actions.save" })}
      buttonAction={saveOption}
    >
      <div className="grid gap-4">
        {!isLoading &&
          !isError &&
          filteredPaymentMethods.map((method) => {
            return (
              <PricingModalOption
                key={method?.id}
                variant="2rows"
                title={method.title}
                selected={method?.salesforceValue === selected}
                onSelected={() => {
                  selectOption(method);
                }}
                priceAmount={amount}
                instalmentMonths={method.paymentConfiguration.instalmentMonths}
                pricingText={method.paymentConfiguration.pricingText}
                badge={
                  <Tooltip
                    trigger={
                      <Tag
                        variant={method.paymentConfiguration.tagVariant}
                        icon={<Info className="h-3 w-3" />}
                        copy={method.feeTagText}
                      />
                    }
                  >
                    <div className="max-w-pricing-tooltip">
                      {render(method.feeTooltipText)}
                    </div>
                  </Tooltip>
                }
              />
            );
          })}
      </div>
    </PricingModal>
  );
};

export default PaymentMethodModal;
