import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { generatePath } from "@remix-run/router";
import { generateSidebarLayoutPaths } from "~/routes/paths";
import useTreatmentType from "./useTreatmentType";

function useKeyboardArrows() {
  const { opportunityId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { planType } = useTreatmentType();

  const sidebarLayoutPaths = useMemo(() => {
    return generateSidebarLayoutPaths(planType);
  }, [planType]);

  const flattenedPaths = useMemo(
    () =>
      sidebarLayoutPaths.reduce<string[]>((accumulator, path) => {
        if (!path.path) return accumulator;

        return [...accumulator, path.path];
      }, []),
    [sidebarLayoutPaths]
  );

  const generatedPath = useCallback(
    (path: string) =>
      generatePath(path, {
        opportunityId: opportunityId as string,
      }),
    [opportunityId]
  );

  const activeRouteIndex = useMemo(
    () => flattenedPaths.findIndex((path) => generatedPath(path) === pathname),
    [pathname, flattenedPaths, generatedPath]
  );

  const navigateTo = useCallback(
    (path: string) => {
      navigate(generatedPath(path));
    },
    [navigate, generatedPath]
  );

  const previousRoute = useCallback(() => {
    if (activeRouteIndex > 0) navigateTo(flattenedPaths[activeRouteIndex - 1]);
  }, [activeRouteIndex, flattenedPaths, navigateTo]);

  const nextRoute = useCallback(() => {
    if (activeRouteIndex >= 0 && activeRouteIndex < flattenedPaths.length - 1)
      navigateTo(flattenedPaths[activeRouteIndex + 1]);
  }, [activeRouteIndex, flattenedPaths, navigateTo]);

  const handleKeyboardArrows = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") previousRoute();

      if (event.key === "ArrowRight") nextRoute();
    },
    [nextRoute, previousRoute]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardArrows);

    return () => {
      window.removeEventListener("keydown", handleKeyboardArrows);
    };
  }, [pathname, activeRouteIndex, handleKeyboardArrows]);
}

export default useKeyboardArrows;
