import { PropsWithChildren, useState } from "react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import Headline from "~/components/atoms/Headline/Headline";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import VideoScreen from "~/components/molecules/VideoScreen/VideoScreen";
import { StoryblokAsset } from "~/services/storyblok";
import { cn } from "~/utils";

type IntroCardProps = PropsWithChildren<{
  backgroundImage?: StoryblokAsset;
  title?: string;
  className?: string;
  centered?: boolean;
  contained?: boolean;
  hasGradient?: boolean;
  initiallyHideContent?: boolean;
  video?: StoryblokAsset;
  minTextWidthFit?: boolean;
}> &
  MotionProps;

const IntroCard = ({
  backgroundImage,
  title,
  className,
  centered,
  contained,
  initiallyHideContent = false,
  children,
  video,
  minTextWidthFit,
  ...rest
}: IntroCardProps) => {
  const [videoShown, setVideoShown] = useState(false);
  const showVideo = () => {
    if (video?.filename) {
      setVideoShown(true);
    }
  };

  return (
    <AnimatePresence>
      <VideoScreen open={videoShown} setOpen={setVideoShown} video={video} />
      <motion.div
        key={title}
        className={cn("h-full w-full rounded-2xl bg-beige-100 ", className)}
        transition={{
          type: "tween",
          duration: 0.6,
          delay: 0.1,
          ease: "easeOut",
        }}
        {...rest}
      >
        <div className="flex h-full w-full flex-col gap-4 rounded-2xl">
          <StoryblokOptimizedImage
            src={backgroundImage?.filename}
            alt={backgroundImage?.alt}
            className={cn(
              "absolute top-0 left-0 w-full h-full object-contain object-right-bottom rounded-2xl",
              {
                "object-cover object-center": centered,
                "object-contain object-center": contained,
              }
            )}
          />
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: initiallyHideContent ? 0.4 : 0,
                delay: initiallyHideContent ? 0.2 : 0,
                ease: "easeInOut",
                stiffness: 10,
              }}
              className={cn(
                "relative flex flex-col gap-4 rounded-2xl p-6 justify-between h-full "
              )}
              onClick={showVideo}
            >
              <div className="bg-intro-overlay rounded-2xl absolute top-0 left-0 h-full w-full cursor-pointer"></div>
              {children}
              <div
                className={cn("relative z-10 max-w-1/2 min-w-[8rem] mt-auto", {
                  "min-w-fit": minTextWidthFit,
                })}
              >
                {title && (
                  <Headline level={4} className="font-normal text-white">
                    {title}
                  </Headline>
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default IntroCard;
