import { PropsWithChildren, ReactNode } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";

export const Tooltip = ({
  trigger,
  children,
}: PropsWithChildren<{
  trigger: ReactNode;
}>) => (
  <RadixTooltip.Provider delayDuration={0}>
    <RadixTooltip.Root>
      <RadixTooltip.Trigger>{trigger}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content className="custom-tooltip">
          {children}
          <RadixTooltip.Arrow />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  </RadixTooltip.Provider>
);
