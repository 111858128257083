import SkinProductCard, {
  SkinProductCardProps,
} from "~/components/organisms/Pricing//SkinProductCard/SkinProductCard";

type NeedlingProductCardProps = SkinProductCardProps;

const NeedlingProductCard = (props: NeedlingProductCardProps) => {
  return <SkinProductCard {...props}>{props.children}</SkinProductCard>;
};

export default NeedlingProductCard;
