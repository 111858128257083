import { useQuery } from "@tanstack/react-query";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

type UseGetRequestedContractArgs = {
  requestId: string;
  disabled?: boolean;
};

export const useGetRequestedContract = ({
  requestId,
  disabled,
}: UseGetRequestedContractArgs) => {
  const { locale } = useLocaleStore();
  const { data } = useFetchAccessToken();

  return useQuery({
    queryKey: [QueryKeys.useGetRequestedContract, requestId],
    queryFn: () =>
      salesforceServiceSingleton.getContractByRequest(
        data?.accessToken ?? "",
        requestId,
        locale
      ),
    enabled:
      !!data?.ensureAccessTokenIsValid?.() && !disabled && requestId !== "",
  });
};
