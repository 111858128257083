import { useState } from "react";
import { useIntl } from "react-intl";
import DiscountModal from "~/components/molecules/DiscountModal/DiscountModal";
import EditableOption from "~/components/molecules/EditableOption/EditableOption";

type EditableDiscountProps = {
  showDiscountedAmount?: boolean;
  discountPercent?: string;
  discountTotal?: number;
  editable?: boolean;
};

const EditableDiscount = ({
  discountPercent,
  discountTotal,
  showDiscountedAmount,
  editable = true,
}: EditableDiscountProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen(true);

  return (
    <div>
      <DiscountModal open={open} setOpen={setOpen} />
      <EditableOption
        editable={editable}
        onClick={showModal}
        title={
          showDiscountedAmount
            ? intl.formatMessage(
                {
                  id: "pricing.card.discountValue",
                },
                {
                  percent: discountPercent,
                  amount: discountTotal,
                }
              )
            : intl.formatMessage({ id: "field.empty" })
        }
        className={showDiscountedAmount ? "text-black" : "text-grey-400"}
      />
    </div>
  );
};

export default EditableDiscount;
