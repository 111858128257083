import React from "react";
import { Children, PropsWithChildren, ReactNode, Ref } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type PaginationSwiperProps = PropsWithChildren<
  PropsWithClassName<{
    slidesPerView?: number;
    spaceBetween?: number;
  }>
>;

const PaginationSwiper = React.forwardRef(
  (
    {
      children,
      className,
      slidesPerView = 3,
      spaceBetween = 24,
    }: PaginationSwiperProps,
    ref: Ref<SwiperRef> | undefined
  ) => {
    return (
      <div className="relative h-full">
        <Swiper
          ref={ref}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          grabCursor
          slidesPerView={slidesPerView}
          className={cn("h-full", className)}
          spaceBetween={spaceBetween}
          slideToClickedSlide
          centeredSlides
        >
          {Children.map(children, (child: ReactNode, index) => (
            <SwiperSlide key={index} className="w-full h-full rounded-2xl">
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
);

PaginationSwiper.displayName = "PaginationSwiper";

export default PaginationSwiper;
