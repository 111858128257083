import { Children, PropsWithChildren, ReactNode, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { cn } from "~/utils";
import { createArray } from "~/utils/array.utils";
import { PropsWithClassName } from "~/utils/types";
import CustomSwiperButton from "./CustomSwiperButton";

type CustomSwiperProps = PropsWithClassName<
  PropsWithChildren<{
    slidesPerView?: number;
    buttonPositionClassName?: string;
    buttonClassName?: string;
    isChevron?: boolean;
  }>
>;

const CustomSwiper = ({
  slidesPerView = 3,
  children,
  className,
  buttonPositionClassName,
  buttonClassName,
  isChevron,
}: CustomSwiperProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = Children.map(children, (child: ReactNode, index) => (
    <SwiperSlide className="!h-auto" key={index}>
      {child}
    </SwiperSlide>
  ));
  if (slides && slides.length < slidesPerView) {
    slides?.push(
      ...createArray(slidesPerView - slides.length).map((_, index) => (
        <SwiperSlide key={index + slides.length}></SwiperSlide>
      ))
    );
  }
  return (
    <Swiper
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      grabCursor={true}
      slidesPerView={slidesPerView}
      modules={[Pagination]}
      spaceBetween={24}
      className={cn("relative", className)}
    >
      <div
        className={cn(
          "absolute top-1/2 -translate-y-1/2 right-4 z-10 h-fit",
          buttonPositionClassName
        )}
      >
        <CustomSwiperButton
          isChevron={isChevron}
          className={buttonClassName}
          activeIndex={activeIndex}
          slidesPerView={Math.round(slidesPerView)}
        />
      </div>
      {slides}
      <div
        className={cn(
          "absolute top-1/2 -translate-y-1/2 left-4 z-10 h-fit",
          buttonPositionClassName
        )}
      >
        <CustomSwiperButton
          isChevron={isChevron}
          className={buttonClassName}
          type="prev"
          activeIndex={activeIndex}
          slidesPerView={Math.round(slidesPerView)}
        />
      </div>
    </Swiper>
  );
};
export default CustomSwiper;
