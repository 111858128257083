import { useMemo } from "react";
import { ProductItemCategoryKey } from "~/api/models/ProductCategory";
import { useFetchUploadedPhotos } from "~/api/salesForce/useFetchUploadedPhotos";
import PhotosScreen from "~/components/organisms/Photos/PhotosScreen";
import useSkinProducts from "~/hooks/useSkinProducts";

const POSSIBLE_SKIN_CATEGORIES = [
  ProductItemCategoryKey.filler,
  ProductItemCategoryKey.botox,
  ProductItemCategoryKey.needling,
  ProductItemCategoryKey.skinBooster,
];

const Photos = () => {
  const { uploadToken, photos, isLoading } = useFetchUploadedPhotos("skin");
  const { getProductItemCategoryKeySelected } = useSkinProducts();
  const selectedSkinCategories = useMemo(() => {
    return POSSIBLE_SKIN_CATEGORIES.filter((category) =>
      getProductItemCategoryKeySelected(category)
    );
  }, [getProductItemCategoryKeySelected]);

  return (
    <PhotosScreen
      uploadToken={uploadToken}
      selectedCategories={selectedSkinCategories}
      isLoading={isLoading}
      photos={photos}
      productCategory="skin"
      isProductItemCategoryKeySelected={(category) =>
        !!getProductItemCategoryKeySelected(category)
      }
    />
  );
};
export default Photos;
