import {
  AllProductsList,
  useFetchProducts,
} from "~/api/salesForce/useFetchProducts";
import { Product } from "~/stores/checkout/product";

const useFlattenedProducts = (): {
  data: Product[];
  isLoading: boolean;
  originalData: AllProductsList;
} => {
  const { data: allProducts, isLoading: loadingProducts } = useFetchProducts();
  const flattenedProducts: Product[] = [
    ...allProducts.hair.head.transplantation.long,
    ...allProducts.hair.head.transplantation.short,
    ...allProducts.hair.head.prp,
    ...allProducts.hair.head.freePrp,
    ...allProducts.hair.head.bundle,
    ...allProducts.hair.beard.transplantation,
    ...allProducts.hair.eyebrow.transplantation,
    ...allProducts.skin.botox.wrinkles.x1,
    ...allProducts.skin.botox.wrinkles.x2,
    ...allProducts.skin.botox.sweat.x1,
    ...allProducts.skin.botox.sweat.x2,
    ...allProducts.skin.botox.afterTreatment,
    ...allProducts.skin.needling,
    ...allProducts.skin.filler.hyaluronidase,
    ...allProducts.skin.filler.skinBooster,
    ...allProducts.skin.filler.lips,
    ...allProducts.skin.filler.cheeks,
    ...allProducts.skin.filler.jawline,
    ...allProducts.skin.filler.chin,
    ...allProducts.skin.care,
    ...allProducts.skin.filler.general,
    ...allProducts.skin.botox.bundle,
  ];
  return {
    data: flattenedProducts ?? [],
    isLoading: loadingProducts,
    originalData: allProducts,
  };
};

export default useFlattenedProducts;
