import { useState } from "react";
import { useIntl } from "react-intl";
import downloadImage from "~/assets/images/download.png";
import Card from "~/components/atoms/Card/Card";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import DownloadModal from "~/components/organisms/DownloadModal";

const DownloadAppCard = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <Card className="flex gap-4" buttonAction={toggleOpen}>
      <DownloadModal open={open} toggleOpen={toggleOpen} />
      <img
        src={downloadImage}
        alt={intl.formatMessage({ id: "summary.download.title" })}
        className="w-[6.25rem] h-[6.25rem] rounded-xl"
      />
      <div className="flex flex-col gap-1 justify-center">
        <Headline level={6}>
          {intl.formatMessage({ id: "summary.download.title" })}
        </Headline>
        <Subtitle className="text-grey-400" level={2}>
          {intl.formatMessage({ id: "summary.download.subtitle" })}
        </Subtitle>
      </div>
    </Card>
  );
};

export default DownloadAppCard;
