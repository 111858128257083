import { useCallback, useMemo, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useIntersectionObserver } from "usehooks-ts";
import { Benefit } from "~/api/models/Benefit";
import Copy from "~/components/atom/Copy";
import Headline from "~/components/atoms/Headline/Headline";
import Pill from "~/components/atoms/Pill/Pill";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { cn } from "~/utils";
import { hasCommonElements } from "~/utils/array.utils";
import { PropsWithClassName } from "~/utils/types";

type PlanCategoryBenefitsProps = PropsWithClassName<{
  benefits?: Benefit[];
}>;
const CARD_DELAY = 0.3;

const PlanCategoryBenefits = ({
  benefits,
  className,
}: PlanCategoryBenefitsProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const [selectedProducts, selectedBundle] = useCheckoutStore((state) => [
    state.selectedProducts,
    state.selectedBundle,
  ]);

  const isAvailable = useCallback(
    (benefit: Benefit) =>
      !benefit.exclusives?.length ||
      hasCommonElements(
        benefit.exclusives,
        selectedProducts.map((product) => product.productCode)
      ) ||
      (selectedBundle &&
        benefit.exclusives.includes(selectedBundle?.productCode)),
    [selectedProducts, selectedBundle]
  );

  const filteredBenefits = useMemo(() => {
    return benefits?.map((benefit) => ({
      ...benefit,
      isAvailable: isAvailable(benefit),
    }));
  }, [benefits, isAvailable]);

  return (
    <AnimatePresence>
      <motion.div
        animate={{
          y: isVisible ? 0 : "25%",
          opacity: isVisible ? 1 : 0,
          scale: isVisible ? 1 : 0.9,
        }}
        transition={{ duration: 0.7 }}
        className={className}
      >
        <div
          ref={ref}
          className="flex my-10 flex-col gap-10 justify-center py-10"
        >
          <Headline level={3}>
            <Copy id="treatment.plan.details.benefits" />
          </Headline>
          <div className="grid grid-cols-3 gap-6">
            {filteredBenefits?.map((benefit) => {
              //used to avoid using index
              const order = filteredBenefits.indexOf(benefit) + 1;
              return (
                <motion.div
                  animate={{ opacity: isVisible ? 1 : 0 }}
                  transition={{
                    duration: isVisible ? 0.7 : 0,
                    delay: isVisible ? order * CARD_DELAY : 0,
                  }}
                  className={cn(
                    "border rounded-2xl  py-5 px-6 flex flex-col gap-4 relative transition-all duration-500",
                    benefit.isAvailable
                      ? "border-grey-50 hover:border-black cursor-pointer"
                      : "bg-grey-100 border-transparent"
                  )}
                  key={benefit.title}
                >
                  {benefit.exclusives &&
                    benefit.exclusives?.length > 0 &&
                    benefit.exclusiveTagTitle && (
                      <Pill className="absolute top-5 right-6 bg-black text-white px-2 py-1 uppercase">
                        <Subtitle level={4}>
                          {benefit.exclusiveTagTitle}
                        </Subtitle>
                      </Pill>
                    )}
                  {benefit.image.filename ? (
                    <StoryblokOptimizedImage
                      src={
                        !benefit.isAvailable && benefit.disabledImage?.filename
                          ? benefit.disabledImage.filename
                          : benefit.image.filename
                      }
                      alt={
                        !benefit.isAvailable && benefit.disabledImage?.filename
                          ? benefit.disabledImage.alt
                          : benefit.image.alt
                      }
                      className="w-8 h-8 p-1"
                    />
                  ) : (
                    <svg
                      className="p-1"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 6L9 17L4 12"
                        className="stroke-grey-400"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  <div className="flex flex-col gap-1">
                    <Headline
                      level={6}
                      className={cn(
                        benefit.isAvailable
                          ? "text-grey-700"
                          : "text-grey-400 line-through"
                      )}
                    >
                      {benefit.title}
                    </Headline>
                    <Subtitle level={2} className="text-grey-400">
                      {benefit.description}
                    </Subtitle>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PlanCategoryBenefits;
