import { render } from "storyblok-rich-text-react-renderer";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { BenefitDTO } from "~/modules/skincare-products/skincare-products";

type SkincareProductsBenefitsProps = {
  benefits: BenefitDTO[];
};

export const SkincareProductsBenefits = ({
  benefits,
}: SkincareProductsBenefitsProps) => {
  return (
    <div className="mt-6 flex flex-col gap-6">
      {benefits.map((benefit) => {
        if (benefit.component === "SkincareProductSingleBenefit") {
          return (
            <div key={benefit._uid} className="space-y-2">
              <Subtitle
                level={2}
                className="font-bold text-grey-700 leading-normal"
              >
                {benefit.title}
              </Subtitle>
              <p className="text-grey-400 text-base">
                {render(benefit.description)}
              </p>
            </div>
          );
        }

        return (
          <div key={benefit._uid} className="flex justify-between">
            <div className="w-full max-w-[17.625rem] space-y-2">
              <Subtitle
                level={2}
                className="font-bold text-grey-700 leading-normal"
              >
                {benefit.firstBenefit[0].title}
              </Subtitle>
              <div className="text-grey-400 text-base markdown">
                {render(benefit.firstBenefit[0].description)}
              </div>
            </div>
            <div className="w-full max-w-[17.625rem] space-y-2">
              <Subtitle
                level={2}
                className="font-bold text-grey-700 leading-normal"
              >
                {benefit.secondBenefit[0].title}
              </Subtitle>
              <div className="text-grey-400 text-base markdown">
                {render(benefit.secondBenefit[0].description)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
