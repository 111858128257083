import { useParams } from "react-router-dom";
import { useElementSize } from "usehooks-ts";
import { TreatmentPlanProductCategory } from "~/api/models/TreatmentPlan";
import { useFetchTreatmentPlan } from "~/api/storyblok/useFetchTreatmentPlan";
import ScreenSizedCard from "~/components/atoms/ScreenSizedCard/ScreenSizedCard";
import PLanCategoryInformation from "~/components/organisms/PlanCategory/PLanCategoryInformation";
import PLanCategoryRecommendations from "~/components/organisms/PlanCategory/PLanCategoryRecommendations";
import PlanCategoryBenefits from "~/components/organisms/PlanCategory/PlanCategoryBenefits";
import PLanCategoryPhases from "~/components/organisms/PlanCategory/PlanCategoryPhases";
import { Header } from "~/components/organisms/layout/Header/Header";

type PlanCategoryProps = {
  category: TreatmentPlanProductCategory;
};

const PlanCategory = ({ category }: PlanCategoryProps) => {
  const [headerRef, { height = 0 }] = useElementSize();
  const params = useParams<{ categoryKey: string }>();
  const { data: planCategories, isLoading } = useFetchTreatmentPlan(category);
  const planCategory = planCategories?.find(
    (plan) => plan.key === params.categoryKey
  );

  if (!planCategory) return null;

  return (
    <div className="pl-body py-0 w-full overflow-x-hidden">
      <div className="contents">
        <div
          ref={headerRef}
          className="sticky pt-body pr-body pb-8 z-20 bg-white top-0 w-full right-0 left-0"
        >
          <Header title={planCategory.title} hasBackButton />
        </div>
        <div className="flex flex-col gap-4">
          <ScreenSizedCard headerHeight={height} className="pr-body">
            <PLanCategoryPhases
              isLoading={isLoading}
              phases={planCategory.phases}
            />
          </ScreenSizedCard>
          <PlanCategoryBenefits
            benefits={planCategory.benefits}
            className="pr-body"
          />
          {planCategory.information && planCategory.information?.length > 0 && (
            <ScreenSizedCard headerHeight={height}>
              <div className="flex flex-col gap-4 h-full">
                {planCategory.information?.map((informationSection) => (
                  <PLanCategoryInformation
                    key={informationSection.title}
                    informationSection={informationSection}
                  />
                ))}
              </div>
            </ScreenSizedCard>
          )}
          <ScreenSizedCard headerHeight={height} className="pr-body">
            <PLanCategoryRecommendations
              recommendations={planCategory.recommendations}
            />
          </ScreenSizedCard>
        </div>
      </div>
    </div>
  );
};

export default PlanCategory;
