import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { cn } from "~/utils";

type NestedSidebarItemProps = { path: string; title: string };

export function NestedSidebarItem({ path, title }: NestedSidebarItemProps) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        cn(
          "outline-sky-400 relative border-l-2 border-l-beige-100 px-8 py-2 font-sans text-base font-medium text-grey-400 transition focus-visible:outline-2 ",
          { "text-black/80 hover:text-black": isActive }
        )
      }
    >
      {({ isActive }) => (
        <>
          {isActive && (
            <motion.span
              layoutId="sidebar-animation"
              className="absolute inset-0 -left-[2px] z-10 h-full w-[2px] bg-black shadow-sm"
              style={{ borderRadius: 9999 }}
              transition={{
                type: "spring",
                bounce: 0.3,
                duration: 0.6,
              }}
            />
          )}
          {title}
        </>
      )}
    </NavLink>
  );
}
