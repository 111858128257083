import { PropsWithChildren } from "react";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type Level = 1 | 2 | 3 | 4 | 5 | 6;

type HeadlineProps = PropsWithClassName<
  PropsWithChildren<{ level?: Level; visualLevel?: Level }>
>;

const getStyles = (level: Level) => {
  switch (level) {
    case 1:
      return "text-5xl";

    case 2:
      return "text-4xl";

    case 3:
      return "text-3xl";

    case 4:
      return "text-2xl";

    case 5:
      return "text-xl";

    case 6:
      return "text-lg";
  }
};

const Headline = ({
  level = 6,
  visualLevel,
  children,
  className,
}: HeadlineProps) => {
  const Element = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <Element className={cn(getStyles(visualLevel || level), className)}>
      {children}
    </Element>
  );
};

export default Headline;
