import { useMemo } from "react";
import { motion } from "framer-motion";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import useProductCardContext from "~/hooks/useProductCardContext";
import { Product } from "~/stores/checkout/product";
import {
  BOTOX_SKIN_SECTIONS,
  BOTOX_SKIN_SECTIONS_NAMES,
  SkinSection,
} from "~/stores/headConfiguration/ConfigurationSection.types";
import { cn } from "~/utils";

type BotoxWrinklesAreasProps = {
  options: Product[];
  selected?: Product;
  open?: boolean;
  selectedBotoxAreas: SkinSection[];
  toggleBotoxArea: (area: SkinSection) => void;
};

const BotoxWrinklesAreas = ({
  options,
  selected,
  open,
  toggleBotoxArea,
  selectedBotoxAreas,
}: BotoxWrinklesAreasProps) => {
  const { isSuggested } = useProductCardContext();
  const optionIsSelected = useMemo(() => {
    return open || options.find((option) => option.id === selected?.id);
  }, [options, selected, open]);

  return (
    <motion.div
      initial={{ height: 0, marginTop: 0 }}
      exit={{ height: 0, marginTop: 0 }}
      animate={{
        height: optionIsSelected ? "auto" : 0,
        marginTop: optionIsSelected ? 8 : 0,
      }}
      className="flex flex-col gap-3 overflow-hidden"
    >
      <Subtitle level={2} className="text-grey-400 text-left">
        <Copy
          id={
            !isSuggested || selectedBotoxAreas.length === 0
              ? "pricing.botoxWrinkles.modal.areas.select"
              : "pricing.card.areas"
          }
          values={{ areas: selectedBotoxAreas.length }}
        />
      </Subtitle>
      <div className="flex flex-col gap-6">
        <div className="flex gap-2 flex-wrap">
          {BOTOX_SKIN_SECTIONS.map((section) => {
            const sectionName = BOTOX_SKIN_SECTIONS_NAMES.find(
              (s) => s.key === section
            )?.value;
            return (
              <Button
                onClick={() => toggleBotoxArea(section)}
                key={section}
                variant={ButtonVariant.darkSecondaryOutline}
                size={ButtonSize.sm}
                className={cn(
                  "text-sm font-normal border-2 border-grey-50 text-grey-700 normal-case capitalize transition-all max-w-none px-4 py-2",
                  {
                    "border-grey-900": selectedBotoxAreas.includes(section),
                  }
                )}
              >
                {sectionName && <Copy id={sectionName} />}
              </Button>
            );
          })}
        </div>
        {!isSuggested && (
          <div className="flex flex-col p-2 gap-1 bg-beige-100 rounded-xl text-grey-400">
            {options.map((option) => (
              <div
                key={option.id}
                className={cn(
                  "flex gap-4 justify-between items-center rounded-xl px-3 py-2 transition-all duration-500",
                  {
                    "text-black bg-beige-100": selected?.id === option.id,
                  }
                )}
              >
                <Subtitle level={2}>
                  <Copy
                    id="pricing.card.areas"
                    values={{ areas: option.areas }}
                  />
                </Subtitle>
                <Subtitle level={2}>
                  <Copy id="pricing.amount" values={{ amount: option.price }} />
                </Subtitle>
              </div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default BotoxWrinklesAreas;
