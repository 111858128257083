import { PropsWithChildren } from "react";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";

type PricingCardItemProps = PropsWithChildren<{
  title: string;
}>;

const PricingCardItem = ({ title, children }: PricingCardItemProps) => {
  return (
    <div className="grid grid-cols-2 gap-3 items-center">
      <Subtitle
        level={2}
        className="text-grey-400 overflow-hidden w-full text-ellipsis"
      >
        {title}
      </Subtitle>
      {children}
    </div>
  );
};

export default PricingCardItem;
