import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useFetchPaymentMethods } from "~/api/storyblok/useFetchPaymentMethods";
import EditableOption from "~/components/molecules/EditableOption/EditableOption";
import PaymentMethodModal from "~/components/molecules/PaymentMethodModal/PaymentMethodModal";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type EditablePaymentMethodProps = { amount?: number; editable?: boolean };

const EditablePaymentMethod = ({
  amount,
  editable,
}: EditablePaymentMethodProps) => {
  const { data: paymentMethods, isLoading, isError } = useFetchPaymentMethods();
  const [paymentMethod, setPaymentMethod] = useCheckoutStore((state) => [
    state.paymentMethod,
    state.setPaymentMethod,
  ]);
  const [open, setOpen] = useState(false);

  const showModal = () => setOpen(true);

  useEffect(() => {
    if (!isLoading && !isError && paymentMethods.length > 0) {
      if (paymentMethod) {
        setPaymentMethod(paymentMethod);
      } else {
        setPaymentMethod(paymentMethods[0].salesforceValue);
      }
    }
  }, [paymentMethod, paymentMethods.length]);

  return (
    <AnimatePresence>
      <PaymentMethodModal
        amount={amount}
        key="modal"
        open={open}
        setOpen={setOpen}
      />
      <EditableOption
        key="editableOption"
        onClick={showModal}
        title={
          paymentMethods.find((pm) => pm.salesforceValue === paymentMethod)
            ?.title
        }
        level={2}
        className="text-black"
        editable={editable}
      />
    </AnimatePresence>
  );
};

export default EditablePaymentMethod;
