import { PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import { Header } from "~/components/organisms/layout/Header/Header";

type ExamplesListProps = PropsWithChildren<{
  isLoading: boolean;
  isError: boolean;
  isSkinExampleList?: boolean;
}>;

const ExamplesList = ({ children, isLoading, isError }: ExamplesListProps) => {
  const intl = useIntl();
  return (
    <div className="h-full gap-header p-body flex flex-col">
      <Header title={intl.formatMessage({ id: "examples.title" })} />
      {!isError &&
        (isLoading ? (
          <div className="flex items-center justify-center h-full">
            <SpinnerIcon className="text-3xl" />
          </div>
        ) : (
          <div className="h-full">{children}</div>
        ))}
    </div>
  );
};
export default ExamplesList;
