import type { FC, SVGProps } from "react";

export const Edit: FC<SVGProps<SVGSVGElement>> = (svgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...svgProps}
    >
      <path
        d="M11.4649 4.84804L15.1891 8.57226M7.85155 15.791L2.9165 17.0832L4.20391 12.133L12.6135 3.69624C13.6146 2.6928 15.2593 2.62101 16.3322 3.69624C17.3332 4.70128 17.3332 6.35001 16.3322 7.42604L7.85155 15.791Z"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
