import { FC, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Logo from "~/components/atoms/Logo/Logo";
import GoogleRatingPill from "~/components/molecules/GoogleRatingPill/GoogleRatingPill";
import { NestedSidebarItem } from "~/components/organisms/layout/SideBar/NestedSidebarItem/NestedSidebarItem";
import { SideBarItem } from "~/components/organisms/layout/SideBar/SideBarItem/SideBarItem";
import useTreatmentType from "~/hooks/useTreatmentType";
import { Routes } from "~/routes/routes";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

export type Path = {
  customTitle: string;
  path: string;
  children?: { path: string; customTitle: string }[];
};

type Paths = {
  [key: string]: Path;
};

const Child: FC<{ route: Path; index: number }> = ({ route, index }) => {
  const location = useLocation();

  const firstNestedRoute = route.children?.length
    ? route.children[0]?.path
    : route.path;
  const redirectPath = firstNestedRoute ?? route.path;
  const order = index + 1;

  const isActive = location.pathname.startsWith(route.path);
  const hasNestedRoutes =
    isActive && route.children && route.children.length > 0;

  return (
    <SideBarItem
      to={redirectPath}
      order={order}
      title={route.customTitle}
      isActive={isActive}
    >
      <>
        {hasNestedRoutes && (
          <div className="ml-3 mt-1 flex flex-col">
            {route.children?.map((children, childrenIndex) => (
              <NestedSidebarItem
                key={childrenIndex}
                path={children.path}
                title={children.customTitle}
              />
            ))}
          </div>
        )}
      </>
    </SideBarItem>
  );
};

const SideBar = () => {
  const intl = useIntl();
  const { opportunityId } = useParams();
  const { planType } = useTreatmentType();
  const hasSelectedProducts = useCheckoutStore(
    (state) => state.selectedProducts.length > 0 || state.selectedBundle
  );
  const navigate = useNavigate();

  const sideBarRoutes = useMemo(() => {
    const introductionRoutes: Paths = {
      [generatePath(Routes.Introduction, {
        opportunityId: opportunityId as string,
      })]: {
        customTitle: intl.formatMessage({ id: "sidebar.introduction.title" }),
        path: generatePath(Routes.Introduction, {
          opportunityId: opportunityId as string,
        }),
      },
    };
    const hairRoutes: Paths = {
      [generatePath(Routes.Hair, {
        opportunityId: opportunityId as string,
      })]: {
        customTitle: intl.formatMessage({ id: "sidebar.hair.title" }),
        path: generatePath(Routes.Hair, {
          opportunityId: opportunityId as string,
        }),
        children: [
          {
            customTitle: intl.formatMessage({
              id: "sidebar.hair.photos",
            }),
            path: generatePath(Routes.HairPhotos, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.hair.follicle.scan",
            }),
            path: generatePath(Routes.HairFollicleScan, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.hair.plan",
            }),
            path: generatePath(Routes.HairTreatmentPlan, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.hair.configuration",
            }),
            path: generatePath(Routes.HairConfiguration, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.hair.examples",
            }),
            path: generatePath(Routes.HairExamples, {
              opportunityId: opportunityId as string,
            }),
          },
        ],
      },
    };

    const skinRoutes = {
      [generatePath(Routes.Skin, {
        opportunityId: opportunityId as string,
      })]: {
        customTitle: intl.formatMessage({ id: "sidebar.skin.title" }),
        path: generatePath(Routes.Skin, {
          opportunityId: opportunityId as string,
        }),
        children: [
          {
            customTitle: intl.formatMessage({
              id: "sidebar.skin.configuration",
            }),
            path: generatePath(Routes.SkinConfiguration, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.skin.photos",
            }),
            path: generatePath(Routes.SkinPhotos, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.skin.scan",
            }),
            path: generatePath(Routes.SkinScan, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.skin.plan",
            }),
            path: generatePath(Routes.SkinTreatmentPlan, {
              opportunityId: opportunityId as string,
            }),
          },
          {
            customTitle: intl.formatMessage({
              id: "sidebar.skin.examples",
            }),
            path: generatePath(Routes.SkinExamples, {
              opportunityId: opportunityId as string,
            }),
          },
        ],
      },
    };
    const summaryRoutes: Paths = {
      [generatePath(Routes.Summary, {
        opportunityId: opportunityId as string,
      })]: {
        customTitle: intl.formatMessage({ id: "sidebar.summary.title" }),
        path: generatePath(Routes.Summary, {
          opportunityId: opportunityId as string,
        }),
        children: [
          {
            customTitle: intl.formatMessage({
              id: "sidebar.summary.pricing",
            }),
            path: generatePath(Routes.SummaryPricing, {
              opportunityId: opportunityId as string,
            }),
          },
        ],
      },
    };

    if (planType === "hs") {
      return {
        ...introductionRoutes,
        ...hairRoutes,
        ...skinRoutes,
        ...summaryRoutes,
      };
    }

    if (planType === "skin") {
      return {
        ...introductionRoutes,
        ...skinRoutes,
        ...summaryRoutes,
      };
    }

    return {
      ...introductionRoutes,
      ...hairRoutes,
      ...summaryRoutes,
    };
  }, [opportunityId, intl, planType]);

  useEffect(() => {
    if (planType === "hair" && location.pathname.includes("/skin")) {
      navigate(
        generatePath(Routes.Introduction, {
          opportunityId: opportunityId as string,
        })
      );
    } else if (planType === "skin" && location.pathname.includes("/hair")) {
      navigate(
        generatePath(Routes.Introduction, {
          opportunityId: opportunityId as string,
        })
      );
    }
  }, [planType, navigate, opportunityId]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: "-150%" }}
        animate={{ x: 0 }}
        exit={{ x: "-150%" }}
        transition={{ duration: 0.7 }}
        key="sidebar"
        className="flex flex-col justify-between border-r-4 border-grey-200 px-6 py-8 overflow-auto min-w-fit"
      >
        <div className="flex flex-col gap-10">
          <Logo />
          <div className="flex flex-col gap-7">
            {Object.keys(sideBarRoutes).map((key, index) => {
              const route = sideBarRoutes[key];

              return <Child route={route} index={index} key={index} />;
            })}
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            initial={{ x: "-50%" }}
            animate={{ x: 0 }}
            exit={{ x: "-50%" }}
            transition={{ duration: 0.7, delay: 0.3 }}
            className="flex flex-col gap-4"
          >
            <GoogleRatingPill />
            <AnimatePresence>
              {hasSelectedProducts && (
                <motion.div
                  className="w-full"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Button
                    text={intl.formatMessage({
                      id: "sidebar.cta",
                    })}
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.sm}
                    className="max-w-none w-full"
                    onClick={() => {
                      navigate(
                        generatePath(Routes.SummaryOverview, {
                          opportunityId: opportunityId as string,
                        })
                      );
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};

export default SideBar;
