import { generatePath, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useFetchContract } from "~/api/salesForce/useFetchContract";
import Copy from "~/components/atom/Copy";
import Card from "~/components/atoms/Card/Card";
import Pill from "~/components/atoms/Pill/Pill";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import Routes from "~/routes/routes";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { cn } from "~/utils";

type ContractCardProps = {
  contractExpirationDate?: Date;
  contractId?: string | null;
};

const ContractCard = ({ contractId }: ContractCardProps) => {
  const navigate = useNavigate();
  const { locale } = useLocaleStore();
  const { opportunityId } = useParams();
  const { data: contract, isLoading: loadingContract } = useFetchContract({
    contractId: contractId ?? "",
    hasRefetch: false,
    disabled: !contractId,
  });
  const onClick = () => {
    if (!contractId) {
      navigate(
        generatePath(Routes.SummaryPricing, {
          opportunityId: opportunityId as string,
        })
      );
    } else {
      window.open(contract?.url, "_blank");
    }
  };
  const formattedExpirationDate =
    contract?.discountExpiration.toLocaleDateString(locale);

  return (
    <Card className="flex justify-between items-center" buttonAction={onClick}>
      <div className="flex flex-col">
        <div className="flex gap-2 items-center">
          <Subtitle level={1}>
            <Copy id="summary.cards.contract.title" />
          </Subtitle>
          <Pill
            className={cn(
              "bg-red-800 text-white text-2xs px-2 py-1 transition-all duration-350 uppercase",
              {
                "bg-green-900": contract?.signed,
                "bg-grey-50 text-grey-50": loadingContract && contractId,
              }
            )}
          >
            <Copy
              id={
                contract?.signed || (loadingContract && contractId)
                  ? "summary.cards.contract.signed"
                  : contractId
                    ? "summary.cards.contract.notSigned"
                    : "summary.cards.contract.notCreated"
              }
            />
          </Pill>
        </div>
        <motion.div
          transition={{ duration: 0.5 }}
          initial={{ height: 0, marginTop: 0 }}
          animate={{
            height: contract && !contract.signed ? "auto" : 0,
            marginTop: contract && !contract.signed ? "0.5rem" : 0,
          }}
          className="overflow-hidden"
        >
          {formattedExpirationDate && (
            <Subtitle level={2} className="text-grey-400">
              <Copy
                id="summary.cards.contract.offerValidity"
                values={{
                  date: formattedExpirationDate,
                }}
              />
            </Subtitle>
          )}
        </motion.div>
      </div>
    </Card>
  );
};

export default ContractCard;
