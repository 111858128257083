const FirstLevelRoutes = {
  DownloadLink: "/download-link",
  Opportunity: "/opportunity/:opportunityId",
} as const;

export const Routes = {
  Login: `${FirstLevelRoutes.Opportunity}/login`,
  OAuth: `${FirstLevelRoutes.Opportunity}/oauth`,
  Welcome: `${FirstLevelRoutes.Opportunity}/welcome`,
  Agenda: `${FirstLevelRoutes.Opportunity}/agenda`,
  Introduction: `${FirstLevelRoutes.Opportunity}/intro`,
  Hair: `${FirstLevelRoutes.Opportunity}/hair`,
  HairPhotos: `${FirstLevelRoutes.Opportunity}/hair/photos`,
  HairFollicleScan: `${FirstLevelRoutes.Opportunity}/hair/follicle-scan`,
  HairTreatmentPlan: `${FirstLevelRoutes.Opportunity}/hair/plan`,
  HairTreatmentPlanCategory: `${FirstLevelRoutes.Opportunity}/hair/plan/:categoryKey`,
  HairConfiguration: `${FirstLevelRoutes.Opportunity}/hair/configuration`,
  HairExamples: `${FirstLevelRoutes.Opportunity}/hair/examples`,
  HairExample: `${FirstLevelRoutes.Opportunity}/hair/examples/:example`,
  Skin: `${FirstLevelRoutes.Opportunity}/skin`,
  SkinTreatmentPlan: `${FirstLevelRoutes.Opportunity}/skin/plan`,
  SkinTreatmentPlanCategory: `${FirstLevelRoutes.Opportunity}/skin/plan/:categoryKey`,
  SkinConfiguration: `${FirstLevelRoutes.Opportunity}/skin/configuration`,
  SkinExamples: `${FirstLevelRoutes.Opportunity}/skin/examples`,
  SkinExample: `${FirstLevelRoutes.Opportunity}/skin/examples/:example`,
  SkinScan: `${FirstLevelRoutes.Opportunity}/skin/scan`,
  SkinPhotos: `${FirstLevelRoutes.Opportunity}/skin/photos`,
  Summary: `${FirstLevelRoutes.Opportunity}/summary`,
  SummaryPricing: `${FirstLevelRoutes.Opportunity}/summary/pricing`,
  SummaryContract: `${FirstLevelRoutes.Opportunity}/summary/pricing/contract/:contractId`,
  SummaryOverview: `${FirstLevelRoutes.Opportunity}/summary/overview`,
  UploadOverview: `${FirstLevelRoutes.Opportunity}/upload/upload-overview`,
  ...FirstLevelRoutes,
} as const;

export default Routes;
