import { useIntl } from "react-intl";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import ProductCard from "~/components/organisms/Pricing/ProductCard/ProductCard";
import useDefaultProduct from "~/hooks/useDefaultProduct";

const SkinBoosterFillerCard = () => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();

  const defaultProduct = useDefaultProduct(allProducts.skin.filler.skinBooster);

  return (
    <ProductCard
      skinProductFamilyKey={SkinProductKey.skinBooster}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.skinBoosterFiller",
      })}
      options={allProducts.skin.filler.skinBooster}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.skinBoosterFiller.none",
      })}
      modalTitle={intl.formatMessage({
        id: "pricing.skinBoosterFiller",
      })}
      isLoading={isLoading}
    />
  );
};

export default SkinBoosterFillerCard;
