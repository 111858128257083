export class NumberFormatterService {
  static formatToCH(number: number) {
    return this.formatter("de-CH").format(number);
  }

  private static formatter(locale: "de-CH" | "en-GB") {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
    });
  }
}
