import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import {
  SkinTreatmentFocusItem,
  TreatmentFocusItem,
} from "~/api/models/TreatmentFocus";

export interface TreatmentFocus {
  toggleSelectedHairTreatmentFocus: (item: TreatmentFocusItem) => void;
  toggleSelectedSkinTreatmentFocus: (item: SkinTreatmentFocusItem) => void;
  setSelectedSkinTreatmentFocus: (items: SkinTreatmentFocusItem[]) => void;
  getTreatmentRelatedSkinProductKeys: () => SkinProductKey[];
  selectedHairTreatmentFocus: TreatmentFocusItem[];
  selectedSkinTreatmentFocus: SkinTreatmentFocusItem[];
}

const useTreatmentFocusStore = create<TreatmentFocus>()(
  persist(
    (set, get) => ({
      toggleSelectedHairTreatmentFocus: (
        treatmentFocus: TreatmentFocusItem
      ) => {
        const selectedTreatmentFocus = get().selectedHairTreatmentFocus;

        if (
          selectedTreatmentFocus.some(
            (selectedFocus) => selectedFocus._uid === treatmentFocus._uid
          )
        ) {
          set({
            selectedHairTreatmentFocus: selectedTreatmentFocus.filter(
              (selectedFocus) => selectedFocus._uid !== treatmentFocus._uid
            ),
          });
        } else {
          set({
            selectedHairTreatmentFocus: [
              ...selectedTreatmentFocus,
              treatmentFocus,
            ],
          });
        }
      },
      toggleSelectedSkinTreatmentFocus: (
        treatmentFocus: SkinTreatmentFocusItem
      ) => {
        const selectedTreatmentFocus = get().selectedSkinTreatmentFocus;

        if (
          selectedTreatmentFocus.some(
            (selectedFocus) => selectedFocus._uid === treatmentFocus._uid
          )
        ) {
          set({
            selectedSkinTreatmentFocus: selectedTreatmentFocus.filter(
              (selectedFocus) => selectedFocus._uid !== treatmentFocus._uid
            ),
          });
        } else {
          set({
            selectedSkinTreatmentFocus: [
              ...selectedTreatmentFocus,
              treatmentFocus,
            ],
          });
        }
      },
      setSelectedSkinTreatmentFocus: (items: SkinTreatmentFocusItem[]) => {
        set({
          selectedSkinTreatmentFocus: items,
        });
      },
      getTreatmentRelatedSkinProductKeys: () => {
        const selectedSkinTreatmentFocus = get().selectedSkinTreatmentFocus;
        if (!selectedSkinTreatmentFocus.length) return [];
        const skinProductKeys: SkinProductKey[] = [];
        selectedSkinTreatmentFocus.map((focus) => {
          focus.products.map((product) => {
            if (!skinProductKeys.includes(product)) {
              skinProductKeys.push(product);
            }
          });
        });
        return skinProductKeys;
      },
      selectedHairTreatmentFocus: [],
      selectedSkinTreatmentFocus: [],
    }),
    {
      storage: createJSONStorage(() => sessionStorage),
      name: "treatmentFocuses",
    }
  )
);

export default useTreatmentFocusStore;
