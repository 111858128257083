import type { ISbRichtext } from "@storyblok/react";
import { TagVariants } from "~/components/atoms/Tag/Tag";

export type PricingTextKeys =
  | "pricing.monthly"
  | "pricing.own.instalment"
  | "pricing.amount";

export class PaymentMethod {
  public readonly position: number;
  public readonly paymentConfiguration: {
    instalmentMonths: number;
    pricingText: PricingTextKeys;
    tagVariant: TagVariants;
  };

  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly salesforceValue: string,
    public readonly exclusives: string[],
    public readonly feeTagText: string,
    public readonly feeTooltipText: ISbRichtext,
    paymentConfiguration: {
      content: {
        instalmentMonths: number;
        pricingText: PricingTextKeys;
        tagVariant: TagVariants;
        minimum: number;
      };
    },
    position: string
  ) {
    this.position = parseFloat(position) || 1;
    this.paymentConfiguration = paymentConfiguration.content;
  }
}

export interface PaymentMethodDTO {
  _uid: string;
  title: string;
  salesforceValue: string;
  exclusives: string[];
  feeTagText: string;
  feeTooltipText: ISbRichtext;
  position: string;
  paymentConfiguration: {
    content: {
      instalmentMonths: number;
      pricingText: PricingTextKeys;
      tagVariant: TagVariants;
      minimum: number;
    };
  };
}
