import { useIntl } from "react-intl";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import FillerProductCard from "~/components/organisms/Pricing/FillerProductCard/FillerProductCard";
import useDefaultProduct from "~/hooks/useDefaultProduct";

const JawlineFillerCard = () => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();
  const defaultProduct = useDefaultProduct(allProducts.skin.filler.jawline);

  return (
    <FillerProductCard
      skinProductFamilyKey={SkinProductKey.jawlineFiller}
      defaultProduct={defaultProduct}
      cardTitle={intl.formatMessage({
        id: "pricing.jawlineFiller",
      })}
      options={allProducts.skin.filler.jawline}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.jawlineFiller.none",
      })}
      modalTitle={intl.formatMessage({
        id: "pricing.jawlineFiller",
      })}
      isLoading={isLoading}
    />
  );
};

export default JawlineFillerCard;
