import { useMemo } from "react";
import { HairProductKey } from "~/api/models/HairProductKey";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import { Product, ProductFamily, ProductType } from "~/stores/checkout/product";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import useTreatmentType from "./useTreatmentType";

const useHairProducts = () => {
  const { data: allProducts } = useFetchProducts();
  const [
    selectedProducts,
    transplantationProduct,
    prpProduct,
    beardProduct,
    eyebrowProduct,
    freePrpProduct,
    setSelectedProducts,
  ] = useCheckoutStore((state) => [
    state.selectedProducts,
    state.selectedProducts.find(
      (product) => product.family === "hair transplant"
    ),
    state.selectedProducts.find(
      (product) =>
        product.family === "prp hair" &&
        product.productCardKey !== HairProductKey.freePrp
    ),
    state.selectedProducts.find(
      (product) => product.family === "beard transplant"
    ),
    state.selectedProducts.find(
      (product) => product.family === "eyebrow transplant"
    ),
    state.selectedProducts.find(
      (product) => product.productCardKey === HairProductKey.freePrp
    ),
    state.setSelectedProducts,
  ]);
  const {
    htCategory,
    htSelected,
    prpCategory,
    prpSelected,
    eyebrowCategory,
    eyebrowSelected,
    beardCategory,
    beardSelected,
    toggleProductCategory,
  } = useTreatmentType();

  const setProductByFamilyAndTypes = (
    family: ProductFamily,
    product?: Product,
    types?: ProductType[]
  ) => {
    const list = selectedProducts;
    // if we have type check it too
    const index = list.findIndex(
      (selected) =>
        selected.family === family && (!types || types.includes(selected.type))
    );
    if (!product && index > -1) {
      list.splice(index, 1);
    } else if (product && index > -1) {
      list[index] = product;
    } else if (product && index < 0) {
      list.push(product);
    }
    setSelectedProducts(list);
  };

  const setTransplantationProduct = (product?: Product) => {
    setProductByFamilyAndTypes("hair transplant", product);
    //update selected categories
    if (!product && htSelected) {
      toggleProductCategory(htSelected);
    } else if (product && !htSelected && htCategory) {
      toggleProductCategory(htCategory);
    }
    //update prp product
    if (
      !product &&
      allProducts.hair?.head?.prp?.length &&
      prpProduct?.type === "bundle"
    ) {
      setPrpProduct(
        allProducts.hair?.head.prp?.find(
          (v) => v.sessions === prpProduct?.sessions
        ) ?? allProducts.hair?.head.prp[0]
      );
    } else {
      if (
        product &&
        allProducts.hair?.head.bundle?.length &&
        prpProduct?.type === "prp hair"
      ) {
        setPrpProduct(
          allProducts.hair?.head.bundle?.find(
            (v) => v.sessions === prpProduct?.sessions
          ) ?? allProducts.hair?.head.bundle[0]
        );
      }
    }
  };
  const setPrpProduct = (product?: Product) => {
    setProductByFamilyAndTypes("prp hair", product, ["prp hair", "bundle"]);
    //update selected categories
    if (!product && prpSelected && !freePrpProduct) {
      toggleProductCategory(prpSelected);
    } else if (product && !prpSelected && prpCategory) {
      toggleProductCategory(prpCategory);
    }
  };

  const setFreePrpProduct = (product?: Product) => {
    setProductByFamilyAndTypes("prp hair", product, ["prp hair free"]);
    //update selected categories
    if (!product && prpSelected && !prpProduct) {
      toggleProductCategory(prpSelected);
    } else if (product && !prpSelected && prpCategory) {
      toggleProductCategory(prpCategory);
    }
  };

  const setBeardProduct = (product?: Product) => {
    setProductByFamilyAndTypes("beard transplant", product);
    //update selected categories
    if (!product && beardSelected) {
      toggleProductCategory(beardSelected);
    } else if (product && !beardSelected && beardCategory) {
      toggleProductCategory(beardCategory);
    }
  };
  const setEyebrowProduct = (product?: Product) => {
    setProductByFamilyAndTypes("eyebrow transplant", product);
    //update selected categories
    if (!product && eyebrowSelected) {
      toggleProductCategory(eyebrowSelected);
    } else if (product && !eyebrowSelected && eyebrowCategory) {
      toggleProductCategory(eyebrowCategory);
    }
  };
  const hairTotal = useMemo(() => {
    return (prpProduct?.price ?? 0) + (transplantationProduct?.price ?? 0);
  }, [prpProduct, transplantationProduct]);

  return {
    setTransplantationProduct,
    setPrpProduct,
    setBeardProduct,
    setEyebrowProduct,
    transplantationProduct,
    prpProduct,
    eyebrowProduct,
    beardProduct,
    freePrpProduct,
    hairTotal,
    setFreePrpProduct,
  };
};

export default useHairProducts;
