import { useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useFetchSkincareProducts } from "~/api/storyblok/useFetchSkincareProducts";
import Copy from "~/components/atom/Copy";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/atoms/Dialog/Dialog";
import Headline from "~/components/atoms/Headline/Headline";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { SkincareProductsBenefits } from "~/components/molecules/SkincareProductsBenefits/SkincareProductsBenefits";
import { SkincareProduct } from "~/modules/skincare-products/skincare-products";
import { cn } from "~/utils";

type SkincareProductsModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const SkincareProductsModal = ({
  open,
  setOpen,
}: SkincareProductsModalProps) => {
  const { data: skincareProducts, isLoading: loadingProducts } =
    useFetchSkincareProducts();
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const handleToggleModalVisibility = () => setOpen((prev) => !prev);
  const activeProduct = useMemo(
    () => skincareProducts?.[activeProductIndex],
    [activeProductIndex, skincareProducts]
  );
  const setActiveProduct = (product: SkincareProduct) => {
    setActiveProductIndex(skincareProducts?.indexOf(product) ?? 0);
  };

  if (!skincareProducts) return null;

  return (
    <Dialog open={open} onOpenChange={handleToggleModalVisibility}>
      <DialogContent
        slideUpAnimation
        className="overflow-scroll w-screen max-w-none h-slide-up-modal flex flex-col translate-x-0 translate-y-0 top-auto bottom-0 left-0 right-0 gap-0 px-20 pt-0"
      >
        <DialogHeader className="space-y-0 flex flex-row py-8">
          <DialogTitle className="font-normal text-2xl tracking-tight">
            <Copy id="configuration.skin.tabs.packages.products.skinCare" />
          </DialogTitle>
          <DialogClose className="h-8" />
        </DialogHeader>
        {loadingProducts ? (
          <SpinnerIcon className="self-start" />
        ) : (
          <section className="flex gap-[4.5rem]">
            <div className="w-96">
              <div className="w-full h-96 flex items-center justify-center bg-pink-500 rounded-3xl overflow-hidden">
                <AnimatePresence>
                  <StoryblokOptimizedImage
                    src={activeProduct?.image.filename}
                    alt={activeProduct?.image.alt}
                    className="w-full h-full object-contain"
                  />
                </AnimatePresence>
              </div>

              <div className="mt-4 grid grid-cols-3 gap-6 w-full overflow-y-auto">
                {skincareProducts.map((product) => (
                  <div
                    key={product.id}
                    onMouseEnter={() => setActiveProduct(product)}
                    className={cn(
                      "border rounded-2xl overflow-hidden max-w-[7.25rem] w-full cursor-pointer",
                      activeProduct?.id === product.id
                        ? "border-black"
                        : "border-grey-50"
                    )}
                  >
                    <StoryblokOptimizedImage
                      src={product.image.filename}
                      alt={product.image.alt}
                      className="w-full h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1">
              <Headline level={3}>{activeProduct?.title}</Headline>
              {activeProduct?.subtitle && (
                <Subtitle
                  level={2}
                  className="mt-1 text-grey-400 tracking-wider"
                >
                  {activeProduct.subtitle}
                </Subtitle>
              )}
              <p className="text-grey-500 text-base mt-4">
                {activeProduct?.description}
              </p>
              <SkincareProductsBenefits
                benefits={activeProduct?.benefits ?? []}
              />
            </div>
          </section>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default SkincareProductsModal;
