import { useIntl } from "react-intl";
import FileUploadModal from "~/components/molecules/FileUploadModal/FileUploadModal";
import SuccessWindow from "~/components/molecules/SuccessWindow/SuccessWindow";
import { showPdfInNewWindow } from "~/utils/files.utils";

type ScanResultProps = {
  pdfBlob: Blob;
  onFileDrop: (file: File | null) => void;
  loadingUpload: boolean;
  uploadModalOpen: boolean;
  setUploadModalOpen: (open: boolean) => void;
  disabled: boolean;
};

const ScanResult = ({
  pdfBlob,
  onFileDrop,
  loadingUpload,
  uploadModalOpen,
  setUploadModalOpen,
  disabled,
}: ScanResultProps) => {
  const intl = useIntl();
  const openUploadModal = () => setUploadModalOpen(true);

  return (
    <SuccessWindow
      title={intl.formatMessage({
        id: "scan.results.title",
      })}
      description={intl.formatMessage({
        id: "scan.results.description",
      })}
      primaryAction={() => showPdfInNewWindow(pdfBlob)}
      secondaryAction={openUploadModal}
      primaryCta={intl.formatMessage({
        id: "scan.results.view",
      })}
      secondaryCta={intl.formatMessage({
        id: "scan.results.new",
      })}
      disabled={disabled || loadingUpload}
    >
      <FileUploadModal
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        onFileDrop={onFileDrop}
        loadingUpload={loadingUpload}
        disabled={disabled}
      />
    </SuccessWindow>
  );
};

export default ScanResult;
