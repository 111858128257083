import { TreatmentPlanRecommendation } from "~/api/models/TreatmentPlan";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/atoms/Dialog/Dialog";
import TreatmentPlanRecommendationCard from "~/components/molecules/TreatmentPlanRecommendationCard/TreatmentPlanRecommendationCard";

type PlanCategoryRecommendationModalProps = {
  title?: string;
  recommendations?: TreatmentPlanRecommendation[];
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PlanCategoryRecommendationModal = ({
  title,
  recommendations,
  open,
  setOpen,
}: PlanCategoryRecommendationModalProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        slideUpAnimation
        className="max-w-full flex flex-col gap-24 py-10 px-20 translate-x-0 translate-y-0 top-16 bottom-0 left-0 right-0"
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <div className="grow flex gap-6 grid-rows-1 overflow-x-scroll no-scrollbar">
          {recommendations?.map((recommendation) => (
            <TreatmentPlanRecommendationCard
              className="row-span-1"
              disableHover
              key={recommendation.title}
              recommendation={recommendation}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PlanCategoryRecommendationModal;
