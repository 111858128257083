export enum SkinProductKey {
  needling = "needling",
  lipsFiller = "lips filler",
  cheeksFiller = "cheeks filler",
  chinFiller = "chin filler",
  jawlineFiller = "jawline filler",
  generalFiller = "general filler",
  hyaluronidase = "hyaluronidase",
  skinBooster = "skin booster",
  botoxWrinkles = "botox wrinkles",
  botoxSweat = "botox sweat",
  botoxBundle = "botox bundle",
  botoxAfterTreatment = "botox after treatment",
  skinBundle = "skin bundle",
  skinCare = "skin care",
}
