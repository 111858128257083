import { PropsWithChildren, useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HairProductKey } from "~/api/models/HairProductKey";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchProductDetails } from "~/api/storyblok/useFetchProductDetails";
import { ButtonVariant } from "~/components/atoms/Button/Buttons.types";
import EditableProduct, {
  EditableProductProps,
} from "~/components/molecules/EditableProduct/EditableProduct";
import OfferCard from "~/components/molecules/OfferCard/OfferCard";
import useProductCardContext from "~/hooks/useProductCardContext";
import useSkinProducts from "~/hooks/useSkinProducts";
import { Product } from "~/stores/checkout/product";
import useTreatmentFocusStore from "~/stores/treatmentFocus/useTreatmentFocusStore";

export type ProductCardProps = PropsWithChildren<{
  defaultProduct?: Product;
  cardTitle?: string;
  skinProductFamilyKey?: SkinProductKey;
  hairProductFamilyKey?: HairProductKey;
  focuses?: string[];
  customAddButtonAction?: () => void;
}> &
  Omit<EditableProductProps, "editable">;

const ProductCard = ({
  defaultProduct,
  cardTitle,
  children,
  skinProductFamilyKey,
  hairProductFamilyKey,
  product,
  setProduct,
  customAddButtonAction,
  forceShowModal,
  ...rest
}: ProductCardProps) => {
  const { isSuggested, isSummaryCard } = useProductCardContext();

  const intl = useIntl();
  const { getProductByProductCardKey, setSkinProductByProductCardKey } =
    useSkinProducts();
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const { data: productDetails, isLoading: loadingProductDetails } =
    useFetchProductDetails(hairProductFamilyKey || skinProductFamilyKey);

  const cardProduct = useMemo(() => {
    if (product) {
      return product;
    }
    if (!skinProductFamilyKey) {
      return undefined;
    }
    return getProductByProductCardKey(skinProductFamilyKey);
  }, [skinProductFamilyKey, getProductByProductCardKey, product]);

  const setCardProduct = (selectedProduct?: Product | Product[]) => {
    if (setProduct) {
      return setProduct(selectedProduct);
    }
    if (!skinProductFamilyKey || Array.isArray(selectedProduct)) {
      return;
    }
    setSkinProductByProductCardKey(skinProductFamilyKey, selectedProduct);
  };

  const selectedSkinTreatmentFocus = useTreatmentFocusStore(
    (state) => state.selectedSkinTreatmentFocus
  );
  const focuses = useMemo(() => {
    if (!isSuggested || !skinProductFamilyKey) {
      return [];
    }
    return selectedSkinTreatmentFocus
      .filter((focus) => focus.products.includes(skinProductFamilyKey))
      .map((focus) => focus.value);
  }, [isSuggested, selectedSkinTreatmentFocus, skinProductFamilyKey]);
  const hasProduct = useMemo(() => {
    return Array.isArray(cardProduct) ? cardProduct.length > 0 : cardProduct;
  }, [cardProduct]);

  const buttonText = useMemo(() => {
    if (!hasProduct) {
      return intl.formatMessage({ id: "actions.add" });
    } else {
      return isSuggested
        ? intl.formatMessage({ id: "actions.added" })
        : undefined;
    }
  }, [intl, hasProduct, isSuggested]);

  if (rest.options.length === 0) return null;
  return (
    <AnimatePresence>
      {!loadingOpportunity && (isSuggested || hasProduct) && (
        <OfferCard
          opportunityGender={opportunity?.gender}
          benefits={
            !isSuggested && !productDetails?.hideBenefits
              ? productDetails?.benefits
              : []
          }
          focuses={focuses}
          image={productDetails?.image}
          loadingDetails={loadingProductDetails}
          title={cardTitle}
          price={
            hasProduct
              ? intl.formatMessage(
                  { id: "pricing.amount" },
                  {
                    amount: Array.isArray(cardProduct)
                      ? cardProduct.reduce((acc, curr) => acc + curr.price, 0)
                      : cardProduct?.price,
                  }
                )
              : undefined
          }
          buttonText={opportunity?.isValidForSales ? buttonText : undefined}
          buttonAction={
            customAddButtonAction ??
            (() => {
              setCardProduct(
                hasProduct
                  ? undefined
                  : Array.isArray(cardProduct) && defaultProduct
                    ? [defaultProduct]
                    : defaultProduct
              );
            })
          }
          buttonVariant={
            hasProduct ? ButtonVariant.secondaryOutline : ButtonVariant.primary
          }
        >
          {(hasProduct || forceShowModal) &&
            (children ?? (
              <EditableProduct
                hideNoneOption={isSuggested}
                product={cardProduct}
                setProduct={setCardProduct}
                forceShowModal={forceShowModal}
                editable={!isSummaryCard && opportunity?.isValidForSales}
                {...rest}
              />
            ))}
        </OfferCard>
      )}
    </AnimatePresence>
  );
};

export default ProductCard;
