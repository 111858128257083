import { SkinProductKey } from "~/api/models/SkinProductKey";
import useConfigurationSectionStore from "~/stores/headConfiguration";
import useTreatmentFocusStore from "~/stores/treatmentFocus/useTreatmentFocusStore";

const useTreatmentRelatedSkinProductKeys = () => {
  const getTreatmentRelatedSkinProductKeys = useTreatmentFocusStore(
    (state) => state.getTreatmentRelatedSkinProductKeys
  );
  const hasBotoxAreasSelected = useConfigurationSectionStore((state) =>
    state.skinSections.find((section) => section.includes("botox"))
  );

  const focusRelatedSkinProductKeys = getTreatmentRelatedSkinProductKeys();
  if (!hasBotoxAreasSelected) return focusRelatedSkinProductKeys;

  const treatmentRelatedSkinProductKeys = focusRelatedSkinProductKeys.includes(
    SkinProductKey.botoxWrinkles
  )
    ? focusRelatedSkinProductKeys
    : focusRelatedSkinProductKeys.concat(SkinProductKey.botoxWrinkles);
  return treatmentRelatedSkinProductKeys;
};

export default useTreatmentRelatedSkinProductKeys;
