import googleIcon from "~/assets/svg/google.svg";
import Pill from "~/components/atoms/Pill/Pill";
import StarRating from "~/components/atoms/StarRating/StarRating";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type GoogleRatingPillProps = PropsWithClassName<{
  hasStars?: boolean;
}>;

const GoogleRatingPill = ({
  hasStars = false,
  className,
}: GoogleRatingPillProps) => {
  const rating = 4.7;
  return (
    <Pill className={cn("bg-white", className)}>
      <div className="flex items-center justify-center gap-1">
        <img src={googleIcon} className="h-4 w-4" />
        <Subtitle level={2}>{rating}</Subtitle>
        {hasStars && <StarRating rating={rating} />}
      </div>
    </Pill>
  );
};

export default GoogleRatingPill;
