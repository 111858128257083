import { PropsWithChildren } from "react";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type ScreenSizedCardProps = PropsWithChildren<
  PropsWithClassName<{
    headerHeight?: number;
  }>
>;

const ScreenSizedCard = ({
  headerHeight,
  children,
  className,
}: ScreenSizedCardProps) => {
  return (
    <div
      className={cn(
        "w-full max-h-full overflow-scroll no-scrollbar",
        className
      )}
      style={{
        height: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      {children}
    </div>
  );
};

export default ScreenSizedCard;
