import { AnimatePresence, motion } from "framer-motion";
import { Image, PropsWithClassName } from "~/utils/types";

type FadeInImageProps = PropsWithClassName<{
  image: Image;
}>;

const FadeInImage = ({ image, className }: FadeInImageProps) => {
  return (
    <AnimatePresence>
      <motion.img
        transition={{ duration: 0.7, ease: "easeInOut", stiffness: 10 }}
        key={image.alt}
        className={className}
        src={image.src}
        alt={image.alt}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      />
    </AnimatePresence>
  );
};

export default FadeInImage;
