import { AnimatePresence, motion } from "framer-motion";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import { ButtonSize } from "~/components/atoms/Button/Buttons.types";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";

type FormHeaderProps = {
  opened: boolean;
  title?: string;
  openAccordionDescription?: string;
  closedAccordionDescription?: string;
  buttonText?: string;
  buttonAction?: () => void;
};

export const FormHeader = ({
  opened,
  title,
  openAccordionDescription,
  closedAccordionDescription,
  buttonAction,
  buttonText,
}: FormHeaderProps) => (
  <>
    <div className="flex gap-3 justify-between items-center">
      <Headline level={4} className="text-grey-700 text-left">
        {title}
      </Headline>
      {
        //placeholder button added to stop layout jump
      }
      <Button size={ButtonSize.sm} className="px-0" />
      <AnimatePresence>
        {buttonText && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: buttonText ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Button
              size={ButtonSize.sm}
              text={buttonText}
              onClick={buttonAction}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    <Subtitle level={2} className="text-grey-400 text-left">
      {opened ? (
        <>
          {openAccordionDescription || (
            <Copy id="configuration.grafts.description" />
          )}
        </>
      ) : (
        closedAccordionDescription
      )}
    </Subtitle>
  </>
);
