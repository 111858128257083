import { useState, useEffect, PropsWithChildren } from "react";

type StepProgressProps = PropsWithChildren<{
  onTimeout?: () => void;
  progress?: number;
  counterInSeconds?: number;
  sizeInRem: number;
  stopTimer?: boolean;
  onClick?: () => void;
}>;

const StepProgress = ({
  progress,
  counterInSeconds,
  sizeInRem,
  onTimeout,
  children,
  onClick,
  stopTimer,
}: StepProgressProps) => {
  const [currentProgress, setCurrentProgress] = useState(progress || 0);
  const scaledCircleSize = sizeInRem / 0.9;
  const radius = sizeInRem / 2;
  const dashArray = sizeInRem * Math.PI;
  const toRem = (sizeInRem: number) => `${sizeInRem}rem`;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (progress !== undefined) setCurrentProgress(progress);
    if (counterInSeconds && !progress) {
      const startTime = Date.now();

      intervalId = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = Math.max(
          0,
          100 - (elapsedTime / (counterInSeconds * 1000)) * 100
        );
        setCurrentProgress(newProgress);

        if (newProgress <= 0) {
          clearInterval(intervalId);
          setCurrentProgress(0);
          onTimeout?.();
        }
        if (stopTimer) {
          clearInterval(intervalId);
        }
      }, 100);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [counterInSeconds, onTimeout, progress, stopTimer]);

  return (
    <button className="relative w-fit" onClick={onClick}>
      <svg
        className="rotate-[-90deg]"
        width={toRem(scaledCircleSize)}
        height={toRem(scaledCircleSize)}
        viewBox={`0 0 ${toRem(scaledCircleSize)} ${toRem(scaledCircleSize)}`}
      >
        <circle
          className="fill-none stroke-current stroke-2 stroke-grey-50"
          cx={toRem(scaledCircleSize / 2)}
          cy={toRem(scaledCircleSize / 2)}
          r={toRem(radius)}
        />
        <circle
          className="fill-none stroke stroke-grey-200 stroke-grey-900 transition-stroke-dashoffset duration-800 ease-in-out"
          cx={toRem(scaledCircleSize / 2)}
          cy={toRem(scaledCircleSize / 2)}
          r={toRem(radius)}
          strokeDasharray={toRem(dashArray)}
          strokeDashoffset={toRem(
            dashArray - (dashArray * currentProgress) / 100
          )}
        />
      </svg>
      <div className="w-full h-full absolute top-0 left-0 p-2">
        <div className="w-full h-full">{children}</div>
      </div>
    </button>
  );
};

export default StepProgress;
