import { useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import welcomeImg from "~/assets/images/welcome.png";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import Headline from "~/components/atoms/Headline/Headline";
import HomeCard from "~/components/molecules/HomeCard/HomeCard";
import ScreenLoader from "~/components/organisms/ScreenLoader/ScreenLoader";
import { Routes } from "~/routes/routes";

const WelcomeCard = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading } = useFetchOpportunity({
    id: opportunityId as string,
  });

  return isLoading ? (
    <ScreenLoader done />
  ) : (
    <HomeCard image={{ src: welcomeImg, alt: "welcome" }}>
      <div className="flex flex-col justify-center gap-6">
        <Headline level={1}>
          <Copy id="home.welcome.title" />
          <br />
          <Copy
            id="home.welcome.second-title"
            values={{ name: opportunity?.accountFirstName }}
          />
        </Headline>
        <Headline level={4} className="text-grey-400">
          <Copy id="home.welcome.subtitle" />
        </Headline>
        <Button
          onClick={() =>
            navigate(
              generatePath(Routes.Agenda, {
                opportunityId: opportunityId as string,
              })
            )
          }
          className="mt-1"
          text={intl.formatMessage({ id: "home.welcome.button" })}
        />
      </div>
    </HomeCard>
  );
};

export default WelcomeCard;
