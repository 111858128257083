import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import Copy from "~/components/atom/Copy";
import Badge from "~/components/atoms/Badge/Badge";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import Switch from "~/components/atoms/Switch/Switch";
import PricingModal from "~/components/molecules/PricingModal/PricingModal";
import PricingModalOption from "~/components/molecules/PricingModalOption/PricingModalOption";
import useHairProducts from "~/hooks/useHairProducts";
import { ProductRadioGroupOption } from "~/stores/checkout/product";

type TransplantationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const TransplantationModal = ({ open, setOpen }: TransplantationModalProps) => {
  const intl = useIntl();
  const { transplantationProduct, setTransplantationProduct } =
    useHairProducts();
  const [isLongHair, setIsLongHair] = useState(
    transplantationProduct?.isLongHair ?? false
  );
  const { data: allProducts, isLoading } = useFetchProducts();
  const [selected, setSelected] = useState(transplantationProduct);
  const selectOption = (option?: ProductRadioGroupOption) => {
    setSelected(option);
  };
  const saveOption = () => {
    setTransplantationProduct(selected);

    setOpen(false);
  };

  const resetSelected = () => {
    setSelected(transplantationProduct);
  };

  const resetIsLongHair = () => {
    setIsLongHair(transplantationProduct?.isLongHair ?? false);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      resetSelected();
      resetIsLongHair();
    }
    setOpen(open);
  };

  useEffect(() => {
    if (transplantationProduct) {
      setSelected(transplantationProduct);
      setIsLongHair(transplantationProduct.isLongHair);
    }
  }, [transplantationProduct]);

  const options = useMemo(() => {
    if (!isLoading) {
      if (isLongHair) {
        return allProducts.hair.head.transplantation?.long;
      }
      return allProducts.hair.head.transplantation?.short;
    }
  }, [isLongHair, isLoading]);
  return (
    <PricingModal
      className="h-full"
      open={open}
      setOpen={onOpenChange}
      title={intl.formatMessage({ id: "pricing.modal.transplantation.title" })}
      buttonText={intl.formatMessage({ id: "actions.save" })}
      buttonAction={saveOption}
    >
      <div className="flex flex-col justify-between align-center gap-4 h-full">
        <div className="grid gap-4">
          <PricingModalOption
            className="animate-fade-in"
            title={intl.formatMessage({ id: "treatment.pricing.ht.none" })}
            selected={!selected}
            onSelected={() => selectOption(undefined)}
          />
          {options?.map((option, index) => {
            const badge = option.recommended && (
              <Badge className="bg-green-900">
                <Copy id="pricing.modal.recommended" />
              </Badge>
            );

            return (
              <PricingModalOption
                className="animate-fade-in"
                key={index}
                title={option.name.split("(")[0]}
                subtitle={intl.formatMessage(
                  {
                    id: "graftsCount",
                  },
                  {
                    from: option.grafts.from,
                    to: option.grafts.to,
                  }
                )}
                selected={option.id === selected?.id}
                onSelected={() => selectOption(option)}
                priceAmount={option.price}
                badge={badge}
              />
            );
          })}
        </div>
        <div className="flex gap-3 items-center mt-1">
          <Switch value={isLongHair} onChanged={setIsLongHair} />
          <Subtitle level={2} className="text-grey-700">
            <Copy id="pricing.longHair" />
          </Subtitle>
        </div>
      </div>
    </PricingModal>
  );
};

export default TransplantationModal;
