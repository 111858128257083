import { Product } from "~/stores/checkout/product";
import { SalesforceBundle } from "./Bundle";

export type ContractRequestDto = {
  request_id: string;
};

export type ContractRequest = {
  requestId: string;
};

export type ContractResponseDto = {
  id: string;
  contract_id: string;
  opportunity_id: string;
  error_message: string | null;
};

export type ContractResponse = {
  id: string;
  contractId: string;
  opportunityId: string;
  errorMessage: string | null;
};

export type ContractRequestBodyDto = {
  products: string[];
  payment_method: string | null;
  bundles: string[];
  special_conditions: string[];
  other_special_conditions: string | null;
  discount_code: string | null;
  referral_code: string | null;
};

export type ContractRequestBody = {
  products: Product[];
  bundle: SalesforceBundle | null;
  paymentMethod: string | null;
  specialConditions: string[];
  otherSpecialConditions: string | null;
  discountCode: string | null;
  referralCode: string | null;
};

export type ContractDto = {
  id: string;
  name: string;
  url: string;
  email: string;
  signed: boolean;
  discount_expiration: string;
};

export class Contract {
  id: string;
  name: string;
  url: string;
  email: string;
  signed: boolean;
  discountExpiration: Date;

  constructor(dto: ContractDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.url = dto.url;
    this.email = dto.email;
    this.signed = dto.signed;
    this.discountExpiration = new Date(dto.discount_expiration);
  }
}
