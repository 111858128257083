import { useState } from "react";
import { useIntl } from "react-intl";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import discount from "~/assets/svg/discount.svg";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonContentType,
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";
import Dropdown from "~/components/atoms/Dropdown/Dropdown";
import { DropdownOption } from "~/components/atoms/Dropdown/Dropdown.types";
import DiscountModal from "~/components/molecules/DiscountModal/DiscountModal";

enum DropdownOptions {
  discount = "discount",
}

const TIME_TO_DISPLAY_MODAL = 100;

const PricingDropDown = () => {
  const intl = useIntl();
  const [discountModal, setDiscountModal] = useState(false);
  const showDiscountModal = () => setDiscountModal(true);

  const dropdownOptions = [
    {
      id: DropdownOptions.discount,
      title: intl.formatMessage({ id: "pricing.header.dropdown.discount" }),
      icon: (
        <img
          src={discount}
          alt={intl.formatMessage({ id: "pricing.header.dropdown.discount" })}
        />
      ),
    },
  ];
  const handleDropdownChange = (option: DropdownOption) => {
    switch (option.id) {
      case DropdownOptions.discount:
        setTimeout(() => {
          showDiscountModal();
        }, TIME_TO_DISPLAY_MODAL);
        break;
    }
  };

  return (
    <>
      <Dropdown
        customTrigger={
          <Button
            contentType={ButtonContentType.icon}
            icon={faEllipsis}
            size={ButtonSize.sm}
            variant={ButtonVariant.secondary}
            className="w-10 h-10 hover:border hover:border-black"
            iconSize={40}
          />
        }
        hasArrow={false}
        options={dropdownOptions}
        align="end"
        onChange={handleDropdownChange}
      />
      <DiscountModal open={discountModal} setOpen={setDiscountModal} />
    </>
  );
};

export default PricingDropDown;
