import { createContext, useContext } from "react";

export const ProductCardContext = createContext({
  isSuggested: false,
  isSummaryCard: false,
});

const useProductCardContext = () => useContext(ProductCardContext);

export default useProductCardContext;
