import { Outlet } from "react-router-dom";
import SideBar from "~/components/organisms/layout/SideBar/SideBar";

const SideBarLayout = () => {
  return (
    <div className="h-screen w-full flex overflow-hidden">
      <SideBar />
      <div className="flex h-full border-box w-full flex-col gap-10 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default SideBarLayout;
