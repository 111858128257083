import { useIntl } from "react-intl";
import RadioGroup from "~/components/molecules/RadioGroup/RadioGroup";
import { Product, ProductRadioGroupOption } from "~/stores/checkout/product";

type FormContentProps = {
  product?: Product;
  products?: ProductRadioGroupOption[];
  setSelectedOption?: (value: ProductRadioGroupOption) => void;
  setProduct?: (value: ProductRadioGroupOption) => void;
  isEditable?: boolean;
};

export const FormContent = ({
  product,
  products = [],
  setSelectedOption,
  setProduct,
  isEditable = true,
}: FormContentProps) => {
  const intl = useIntl();

  return (
    <div className="min-h-grafts-form-radio-group">
      <RadioGroup
        disabled={!isEditable}
        value={
          product
            ? {
                ...product,
                name: product.name,
                text: intl.formatMessage(
                  { id: "configuration.grafts.value" },
                  {
                    from: product.grafts.from,
                    to: product.grafts.to,
                  }
                ),
              }
            : undefined
        }
        onChange={(value) => {
          if (setProduct) setProduct(value);
          if (setSelectedOption) setSelectedOption(value);
        }}
        options={products}
      />
    </div>
  );
};
