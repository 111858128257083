import { motion } from "framer-motion";
import { ContentBlock } from "~/api/models/LoadingContent";
import Pill from "~/components/atoms/Pill/Pill";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";

export type LoaderListProps = {
  blocks?: ContentBlock[];
};

const LoaderList = ({ blocks }: LoaderListProps) => {
  return (
    <div className="h-screen overflow-y-hidden">
      <motion.div
        className="flex flex-col gap-4"
        animate={{ y: ["0%", "-50%", "0%"] }}
        transition={{ duration: 10, repeat: Infinity }}
      >
        {blocks?.map((block, index) => (
          <div key={index}>
            {block.isImage ? (
              <div className="bg-pink-500 rounded-md w-full h-fit">
                <img
                  src={block.image?.filename}
                  alt={block.image?.alt}
                  className="rounded-md h-80 w-full object-cover"
                />
              </div>
            ) : (
              <Pill className="w-full bg-beige-100 border-none flex items-center justify-center gap-2 py-3 px-4">
                <img
                  src={block.pill?.icon?.filename}
                  alt={block.pill?.icon?.alt}
                />
                <Subtitle level={2} className="text-grey-700">
                  {block.pill?.title}
                </Subtitle>
              </Pill>
            )}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default LoaderList;
