import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { AllProductsList } from "~/api/salesForce/useFetchProducts";
import { Product } from "../checkout/product";
import {
  HairGraftSection,
  SKIN_SECTION_LINKS,
  SkinSection,
} from "./ConfigurationSection.types";

export interface ConfigurationSectionsState {
  toggleHairGraftSection: (
    sections: HairGraftSection | HairGraftSection[]
  ) => void;
  hairGraftSections: HairGraftSection[];
  toggleSkinSection: (sections: SkinSection) => void;
  toggleSkinSectionAndUpdateProduct: ({
    section,
    botoxWrinklesProduct,
    allProducts,
    setSkinProductByProductCardKey,
  }: toggleSkinSectionAndUpdateProductArgs) => void;
  setSkinSections: (sections: SkinSection[]) => void;
  skinSections: SkinSection[];
}

type toggleSkinSectionAndUpdateProductArgs = {
  section: SkinSection;
  botoxWrinklesProduct?: Product;
  allProducts: AllProductsList;
  setSkinProductByProductCardKey: (
    productCardKey: SkinProductKey,
    product?: Product
  ) => void;
};

export const getToggleSkinSectionAndHandleSkinSectionLinks = (
  section: SkinSection,
  currentSections: SkinSection[]
): SkinSection[] => {
  if (currentSections.includes(section)) {
    return currentSections.filter(
      (s) =>
        s !== section &&
        !SKIN_SECTION_LINKS.find(
          (link) => link.onlyAvailableWith === section && link.section === s
        )
    );
  } else {
    const additional = SKIN_SECTION_LINKS.find(
      (link) => link.section === section
    )?.onlyAvailableWith;
    const pushAdditional = additional && !currentSections.includes(additional);
    return [...currentSections, section].concat(
      pushAdditional ? additional : []
    );
  }
};

export const useConfigurationSectionStore =
  create<ConfigurationSectionsState>()(
    persist(
      (set, get) => ({
        toggleHairGraftSection: (
          sections: HairGraftSection | HairGraftSection[]
        ) => {
          const current = get().hairGraftSections;
          if (Array.isArray(sections)) {
            sections.map((section) => {
              if (current.includes(section)) {
                set({
                  hairGraftSections: current.filter((s) => s !== section),
                });
              } else {
                set({ hairGraftSections: [...current, section] });
              }
            });
          } else {
            if (current.includes(sections)) {
              set({ hairGraftSections: current.filter((s) => s !== sections) });
            } else {
              set({ hairGraftSections: [...current, sections] });
            }
          }
        },
        toggleSkinSection: (section: SkinSection) => {
          const current = get().skinSections;
          set({
            skinSections: getToggleSkinSectionAndHandleSkinSectionLinks(
              section,
              current
            ),
          });
        },
        toggleSkinSectionAndUpdateProduct: ({
          section,
          botoxWrinklesProduct,
          allProducts,
          setSkinProductByProductCardKey,
        }: toggleSkinSectionAndUpdateProductArgs) => {
          const current = get().skinSections;
          if (botoxWrinklesProduct && section.includes("botox")) {
            const currentBotoxSections = current.filter((s) =>
              s.includes("botox")
            );
            const hasSection = currentBotoxSections.includes(section);
            const numberOfSections = hasSection
              ? currentBotoxSections.length - 1
              : currentBotoxSections.length + 1;
            if (numberOfSections !== botoxWrinklesProduct.areas) {
              const newProduct =
                botoxWrinklesProduct.type === "botox x1"
                  ? allProducts.skin.botox.wrinkles.x1.find(
                      (product) => product.areas === numberOfSections
                    )
                  : allProducts.skin.botox.wrinkles.x2.find(
                      (product) => product.areas === numberOfSections
                    );
              setSkinProductByProductCardKey(
                SkinProductKey.botoxWrinkles,
                newProduct
              );
            }
          }
          set({
            skinSections: getToggleSkinSectionAndHandleSkinSectionLinks(
              section,
              current
            ),
          });
        },
        setSkinSections: (sections: SkinSection[]) => {
          set({ skinSections: sections });
        },
        hairGraftSections: [],
        skinSections: [],
      }),
      {
        storage: createJSONStorage(() => sessionStorage),
        name: "configurationSection",
      }
    )
  );

export default useConfigurationSectionStore;
