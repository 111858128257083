export class PDFDoc {
  constructor(
    readonly id: string,
    readonly fileExtension: PdfExtension,
    readonly title: string,
    readonly url: string,
    readonly latestPublishedVersionId?: string
  ) {}
}

export type PdfExtension = "pdf";
