import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateOpportunityDTO } from "~/api/models/Opportunity";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const UPDATE_OPPORTUNITY_ERROR_TOAST_ID = "useUpdateOpportunityError";

export const useUpdateOpportunity = (opportunityId: string) => {
  const { data } = useFetchAccessToken();
  const queryClient = useQueryClient();
  const { locale } = useLocaleStore();

  return useMutation({
    mutationKey: [QueryKeys.useUpdateOpportunity],
    mutationFn: (body: UpdateOpportunityDTO) =>
      salesforceServiceSingleton.updateOpportunity(
        data?.accessToken ?? "",
        opportunityId,
        body,
        locale
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.useFetchOpportunity]);
    },
  });
};
