import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/atoms/Dialog/Dialog";
import FadeInImage from "~/components/atoms/FadeInImage/FadeInImage";
import CustomSwiper from "~/components/molecules/CustomSwiper/CustomSwiper";
import { ExampleTreatment } from "~/modules/example/example";
import { optimizeImage } from "~/utils/storyblok.image.utils";

export function TreatmentPreviewCard({
  treatment,
}: {
  treatment: ExampleTreatment;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{treatment.title}</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <div className="grid grid-cols-2 gap-4">
            {treatment.beforeImages.length === 1 ? (
              <div
                className="min-h-72 flex flex-col gap-4 rounded-2xl bg-beige-100 p-0.5 pb-4"
                onClick={() => setIsOpen(true)}
                key={treatment.title}
              >
                <div className="rounded-2xl h-full">
                  <FadeInImage
                    image={{
                      src: optimizeImage({
                        src: treatment.beforeImages[0].imageUrl,
                      }),
                      alt: treatment.title,
                    }}
                    className="rounded-2xl h-full object-cover object-center"
                  />
                </div>

                <h2 className="px-4 text-center font-sans text-lg font-normal text-grey-700">
                  {treatment.beforeImages[0].title}
                </h2>
              </div>
            ) : (
              <CustomSwiper
                className="w-full rounded-2xl bg-beige-100"
                slidesPerView={1}
                buttonPositionClassName="top-auto bottom-2 -translate-y-0"
                buttonClassName="bg-transparent text-black hover:bg-transparent hover:text-green-500"
                isChevron
              >
                {treatment.beforeImages.map((beforeImage, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-4 p-0.5 pb-4 h-full"
                    >
                      <FadeInImage
                        image={{
                          src: optimizeImage({
                            src: beforeImage.imageUrl,
                            level: 40,
                          }),
                          alt: beforeImage.title,
                        }}
                        className="rounded-2xl h-full object-cover object-center"
                      />
                      <h2 className="px-4 text-center font-sans text-lg font-normal text-grey-700">
                        {beforeImage.title}
                      </h2>
                    </div>
                  );
                })}
              </CustomSwiper>
            )}

            <CustomSwiper
              className="w-full rounded-2xl bg-beige-100"
              slidesPerView={1}
              buttonPositionClassName="top-auto bottom-2 -translate-y-0"
              buttonClassName="bg-transparent text-black hover:bg-transparent hover:text-green-500"
              isChevron
            >
              {treatment.afterImages.map((afterImage, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col gap-4 p-0.5 pb-4 h-full"
                  >
                    <FadeInImage
                      image={{
                        src: optimizeImage({
                          src: afterImage.imageUrl,
                          level: 40,
                        }),
                        alt: afterImage.title,
                      }}
                      className="rounded-2xl h-full object-cover object-center"
                    />
                    <h2 className="px-4 text-center font-sans text-lg font-normal text-grey-700">
                      {afterImage.title}
                    </h2>
                  </div>
                );
              })}
            </CustomSwiper>
          </div>
        </DialogContent>
      </Dialog>
      <div
        className="flex h-full flex-col gap-4 rounded-2xl bg-beige-100 p-0.5 pb-4"
        onClick={() => setIsOpen(true)}
        key={treatment.title}
      >
        <div className="rounded-2xl h-72 ">
          <FadeInImage
            image={{
              src: optimizeImage({
                src: treatment.beforeImages[0].imageUrl,
                level: 40,
              }),
              alt: treatment.title,
            }}
            className="rounded-2xl h-full w-full object-cover"
          />
        </div>

        <h2 className="px-4 font-sans text-base font-normal">
          {treatment.title}
        </h2>
      </div>
    </>
  );
}
