import {
  ButtonHTMLAttributes,
  ComponentProps,
  ForwardedRef,
  PropsWithChildren,
  ReactNode,
  RefAttributes,
  forwardRef,
} from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type AccordionRootProps = PropsWithClassName<
  PropsWithChildren<
    (
      | AccordionPrimitive.AccordionSingleProps
      | AccordionPrimitive.AccordionMultipleProps
    ) &
      RefAttributes<HTMLDivElement>
  >
>;

type AccordionItemProps =
  PropsWithChildren<AccordionPrimitive.AccordionItemProps>;

type AccordionTriggerProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    chevronStyle?: ComponentProps<"svg">["className"];
  }> &
  AccordionPrimitive.AccordionTriggerProps;

type AccordionContentProps = {
  children: ReactNode;
} & ComponentProps<"div">;

export const AccordionItem = ({ value, children }: AccordionItemProps) => (
  <AccordionPrimitive.Item value={value}>{children}</AccordionPrimitive.Item>
);

export const AccordionTrigger = forwardRef(
  (
    { children, className, chevronStyle, ...props }: AccordionTriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement>
  ) => (
    <AccordionPrimitive.Trigger
      {...props}
      className={cn(
        "AccordionTrigger flex justify-between items-center",
        className
      )}
      ref={forwardedRef}
    >
      <div className="">{children}</div>
      <FontAwesomeIcon
        icon={faChevronDown}
        className={cn("AccordionChevron", chevronStyle)}
        aria-hidden
      />
    </AccordionPrimitive.Trigger>
  )
);
AccordionTrigger.displayName = "AccordionTrigger";

AccordionTrigger.displayName = AccordionPrimitive.AccordionTrigger.displayName;

export const AccordionRoot = forwardRef(
  (
    { children, className, ...props }: AccordionRootProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <AccordionPrimitive.Root
      {...props}
      className={cn("flex flex-col gap-3", className)}
      ref={forwardedRef}
    >
      {children}
    </AccordionPrimitive.Root>
  )
);
AccordionRoot.displayName = AccordionPrimitive.Root.displayName;

export const AccordionContent = forwardRef(
  (
    { children, className, ...props }: AccordionContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => (
    <AccordionPrimitive.Content
      {...props}
      ref={forwardedRef}
      className={cn("AccordionContent", className)}
    >
      {children}
    </AccordionPrimitive.Content>
  )
);
AccordionContent.displayName = AccordionPrimitive.AccordionContent.displayName;
