import { FC } from "react";
import { Navigate, Outlet, generatePath, useParams } from "react-router-dom";
import { useAuthStore } from "~/stores/auth/useAuthStore";
import { Routes } from "./routes";

const LoggedOutOnly: FC = () => {
  const { isLoggedIn } = useAuthStore();
  const { opportunityId } = useParams();

  if (isLoggedIn()) {
    return (
      <Navigate
        to={generatePath(Routes.Welcome, {
          opportunityId: opportunityId as string,
        })}
        replace
      />
    );
  }

  return <Outlet />;
};

export default LoggedOutOnly;
