import { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import SkinHeadConfiguration from "~/components/molecules/SkinHeadConfiguration/SkinHeadConfiguration";
import TreatmentFocusCard from "~/components/molecules/TreatmentFocusCard/TreatmentFocusCard";
import SelectTreatmentsDialog from "~/components/organisms/SelectTreatmentsDialog/SelectTreatmentsDialog";
import { Header } from "~/components/organisms/layout/Header/Header";
import useConfigurationSectionStore from "~/stores/headConfiguration";
import useTreatmentFocusStore from "~/stores/treatmentFocus/useTreatmentFocusStore";

const SkinConfiguration = () => {
  const intl = useIntl();
  const { opportunityId } = useParams();
  const { data: opportunity } = useFetchOpportunity({
    id: opportunityId as string,
  });
  const [showSelectTreatment, setShowSelectTreatment] = useState(false);
  const openSelectTreatment = () => setShowSelectTreatment(true);
  const selectedSkinTreatmentFocus = useTreatmentFocusStore(
    (state) => state.selectedSkinTreatmentFocus
  );
  const hasBotoxAreasSelected = useConfigurationSectionStore((state) =>
    state.skinSections.find((section) => section.includes("botox"))
  );

  return (
    <div className="gap-header p-body flex flex-col h-full justify-center relative">
      <Header title={intl.formatMessage({ id: "configuration.title" })} />
      <div className="grid grid-cols-2 h-full items-center justify-center gap-4">
        <div className="flex items-center justify-center w-full h-full">
          <div className="w-configuration">
            <SkinHeadConfiguration isEditable={opportunity?.isValidForSales} />
          </div>
        </div>
        <div className="flex items-center h-full">
          <TreatmentFocusCard
            type="skin"
            collapsible={false}
            showTreatmentsButton={
              selectedSkinTreatmentFocus.length > 0 || !!hasBotoxAreasSelected
            }
            show={openSelectTreatment}
          />
        </div>
      </div>
      <SelectTreatmentsDialog
        showSelectTreatment={showSelectTreatment}
        setShowSelectTreatment={setShowSelectTreatment}
        isEditable={opportunity?.isValidForSales}
      />
    </div>
  );
};
export default SkinConfiguration;
