import { useCallback } from "react";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import { hasCommonElements } from "~/utils/array.utils";

export function useStoryblokExclusives() {
  const { selectedProducts, selectedBundle } = useCheckoutStore();

  return useCallback(
    (exclusives?: string[]) => {
      if (!exclusives?.length) {
        return true;
      }
      if (
        hasCommonElements(
          exclusives,
          selectedProducts.map((product) => product.productCode)
        ) ||
        (selectedBundle && exclusives.includes(selectedBundle.productCode))
      ) {
        return true;
      }
      return false;
    },
    [selectedProducts, selectedBundle]
  );
}
