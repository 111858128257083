import { useFetchIntroMainCard } from "~/api/storyblok/useFetchIntroMainCard";
import GoogleRatingPill from "~/components/molecules/GoogleRatingPill/GoogleRatingPill";
import IntroCard from "~/components/molecules/IntroCard/IntroCard";

type MainIntroCardProps = {
  top: number;
  left: number;
};

const MainIntroCard = ({ top, left }: MainIntroCardProps) => {
  const { data, isLoading } = useFetchIntroMainCard();

  return (
    !isLoading && (
      <IntroCard
        className="absolute z-10"
        initiallyHideContent
        initial={{
          left: `calc(50VW - ${left}px)`,
          top: `-${top}px`,
          width: "50vw",
          height: "100vh",
        }}
        animate={{
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
        transition={{
          type: "tween",
          duration: 0.65,
          delay: 0.05,
          ease: "easeOut",
        }}
        centered
        {...data}
      >
        <GoogleRatingPill hasStars />
      </IntroCard>
    )
  );
};

export default MainIntroCard;
