import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export class AppDownloadBadgeType {
  public static ios = new AppDownloadBadgeType(
    "Download on the",
    "App Store",
    faApple
  );
  public static android = new AppDownloadBadgeType(
    "GET IT ON",
    "Google Play",
    faGooglePlay
  );

  text: string;
  name: string;
  icon: IconDefinition;

  private constructor(text: string, name: string, icon: IconDefinition) {
    this.text = text;
    this.name = name;
    this.icon = icon;
  }
}
