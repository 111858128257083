import { HTMLProps, PropsWithChildren } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useFetchLoggedInUser } from "~/api/salesForce/useFetchLoggedInUser";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import HSDropdown from "~/components/organisms/layout/HSDropdown/HSDropdown";
import LanguageDropdown from "~/components/organisms/layout/LanguageDropdown/LanguageDropdown";
import ProfileDropdown from "~/components/organisms/layout/ProfileDropdown/ProfileDropdown";
import { Routes } from "~/routes/routes";
import { cn } from "~/utils";

type HeaderProps = PropsWithChildren<{
  title?: string;
  description?: string;
  hasBackButton?: boolean;
  hasAnimation?: boolean;
  className?: HTMLProps<HTMLElement>["className"];
  hideButtons?: boolean;
}>;
export function Header({
  title,
  description,
  hasBackButton,
  hasAnimation = false,
  children,
  className,
  hideButtons,
}: HeaderProps) {
  const { data } = useFetchLoggedInUser();
  const { opportunityId } = useParams();
  const { data: opportunity } = useFetchOpportunity({
    id: opportunityId as string,
  });
  const navigate = useNavigate();
  function handleGoBack() {
    const hasPreviousPage = window.history.length > 1;
    if (!hasPreviousPage) {
      return navigate(
        generatePath(Routes.Welcome, { opportunityId: opportunityId as string })
      );
    }
    return navigate(-1);
  }
  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: "-200%" }}
        animate={{ y: 0 }}
        exit={{ y: "-200%" }}
        transition={{ duration: hasAnimation ? 0.7 : 0 }}
        className={cn("flex justify-between", className)}
      >
        <div className="flex items-center gap-6">
          {hasBackButton && (
            <button onClick={handleGoBack} className="flex items-center">
              <FontAwesomeIcon
                className="text-2xl stroke-1 hover:text-green-900"
                icon={faArrowLeftLong}
              />
            </button>
          )}
          <div className="flex flex-col gap-2">
            {title && <Headline level={3}>{title}</Headline>}
            {description && (
              <Subtitle className=" text-grey-400">{description}</Subtitle>
            )}
            {children}
          </div>
        </div>
        {!hideButtons && (
          <div className="flex gap-4">
            <HSDropdown disabled={!opportunity?.isValidForSales} />
            <LanguageDropdown />
            <ProfileDropdown displayName={data?.display_name} />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
