import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type InfoButtonProps = PropsWithClassName<{
  onClick?: () => void;
}>;

const InfoButton = ({ onClick, className }: InfoButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "text-grey-400 border rounded-full border-grey-400 p-1 h-2 w-2 shrink-0 flex items-center justify-center",
        className
      )}
    >
      <FontAwesomeIcon icon={faInfo} className="text-3xs" />
    </button>
  );
};

export default InfoButton;
