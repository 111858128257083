import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFetchUploadPhotosPositions } from "~/api/storyblok/useFetchUploadPhotosPositions";
import PhotosOverview, { FamiliesState } from "./PhotosOverview";
import { DEFAULT_HAIR_FAMILIES } from "./photos.const";

const SkinOverview = () => {
  const [searchParams] = useSearchParams();
  const selectedHairCategories = searchParams.get("selected_categories");
  const { data, isLoading } = useFetchUploadPhotosPositions("hair");
  const [families, setFamilies] = useState<FamiliesState[]>(
    DEFAULT_HAIR_FAMILIES
  );

  return (
    <PhotosOverview
      data={data}
      isLoading={isLoading}
      families={families}
      setFamilies={setFamilies}
      selectedCategories={selectedHairCategories}
    />
  );
};

export default SkinOverview;
