import { FC, ReactNode } from "react";
import { FormattedMessage, PrimitiveType } from "react-intl";
import { CopyKeys } from "~/utils/types";

export type CopyProps = {
  id: CopyKeys;
  values?: Record<
    string,
    string | ReactNode | ((chunks: ReactNode) => ReactNode) | PrimitiveType
  >;
};

const Copy: FC<CopyProps> = ({ id, values }) => (
  <FormattedMessage id={id} values={values} />
);

export default Copy;
