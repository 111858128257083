import { PropsWithChildren } from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "~/components/atoms/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "~/components/atoms/Button/Buttons.types";

type SuccessWindowProps = PropsWithChildren<{
  title: string;
  description: string;
  primaryCta: string;
  primaryAction: () => void;
  secondaryCta?: string;
  secondaryAction?: () => void;
  disabled?: boolean;
}>;

const SuccessWindow = ({
  title,
  description,
  primaryCta,
  primaryAction,
  secondaryCta,
  secondaryAction,
  children,
  disabled,
}: SuccessWindowProps) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-6">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-green-100 bg-green-900 rounded-full text-[11rem] m-8"
        />
        <div>
          <h1 className="mb-2 text-center text-2xl font-normal">{title}</h1>
          <p className="text-center text-base text-grey-400">{description}</p>
        </div>
        <div className="flex items-center justify-center gap-2">
          <Button
            type="button"
            className="px-16 py-3"
            size={ButtonSize.sm}
            onClick={primaryAction}
            variant={ButtonVariant.primary}
            text={primaryCta}
            disabled={disabled}
          />
          {secondaryCta && (
            <Button
              type="button"
              text={secondaryCta}
              variant={ButtonVariant.secondaryOutline}
              className="px-16 py-3"
              size={ButtonSize.sm}
              onClick={secondaryAction}
              disabled={disabled}
            />
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default SuccessWindow;
