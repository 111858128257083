import { PropsWithChildren, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { cn } from "~/utils";

type TitleDropdownProps = PropsWithChildren<{
  title: string;
}>;

const TitleDropdown = ({ title, children }: TitleDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((prev) => !prev);

  return (
    <div className="flex flex-col">
      <button
        onClick={toggleOpen}
        className={cn(
          "flex gap-1 items-center items-center text-grey-400 group"
        )}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          className={cn(
            "text-xs group-hover:text-black transition-all duration-500 ease-in-out",
            {
              "rotate-180": isOpen,
            }
          )}
        />
        <Subtitle level={2}>{title}</Subtitle>
      </button>
      <motion.div
        className="overflow-hidden"
        initial={{
          height: 0,
          marginTop: 0,
          marginBottom: 0,
        }}
        animate={{
          height: isOpen ? "auto" : 0,
          marginTop: isOpen ? "1rem" : 0,
          marginBottom: isOpen ? "1rem" : 0,
        }}
      >
        <div className="grid grid-cols-2 gap-6">{children}</div>
      </motion.div>
    </div>
  );
};

export default TitleDropdown;
