import { cn } from "~/utils";
import { PropsWithClassName } from "~/utils/types";

type DropProps = PropsWithClassName;

const Drop = ({ className }: DropProps) => {
  return (
    <svg
      className={cn("w-full h-full", className)}
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8643 8.5L32.8643 44.2049"
        stroke="black"
        strokeWidth="2.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3634 35.7046L32.8646 44.2058L41.3657 35.7046"
        stroke="black"
        strokeWidth="2.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4451 14.2939C17.6388 16.6336 13.7598 20.5229 11.433 25.3356C9.10631 30.1482 8.46726 35.604 9.61883 40.8241C10.7704 46.0442 13.6456 50.7247 17.7812 54.1117C21.9168 57.4987 27.0721 59.3951 32.4168 59.4954C37.7614 59.5956 42.9842 57.8939 47.244 54.6644C51.5037 51.4349 54.5524 46.8655 55.8989 41.6923C57.2455 36.519 56.8115 31.0432 54.6669 26.1466C52.5223 21.2501 48.7919 17.218 44.0767 14.6997"
        stroke="black"
        strokeWidth="2.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Drop;
