import { BundleProduct } from "~/api/models/Bundle";
import { ProductImage } from "~/api/models/ProductDetails";
import { useFetchProductDetails } from "~/api/storyblok/useFetchProductDetails";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import ProductDetailCard from "~/components/molecules/ProductDetailCard/ProductDetailCard";
import { StoryblokAsset } from "~/services/storyblok";

type SkinBundleCardProductProps = {
  bundleProduct: BundleProduct;
  customGetImageBasedOnGender: (
    image?: ProductImage
  ) => StoryblokAsset | undefined;
};

const SkinBundleCardProduct = ({
  bundleProduct,
  customGetImageBasedOnGender,
}: SkinBundleCardProductProps) => {
  const { data: productDetails } = useFetchProductDetails(
    bundleProduct.productCardKey
  );

  return (
    <ProductDetailCard
      image={customGetImageBasedOnGender(productDetails?.image)}
    >
      <div className="flex gap-2 items-center">
        <Subtitle level={2}>{bundleProduct?.name}</Subtitle>
      </div>
    </ProductDetailCard>
  );
};

export default SkinBundleCardProduct;
