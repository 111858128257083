import { useQuery } from "@tanstack/react-query";
import { HairProductKey } from "~/api/models/HairProductKey";
import {
  ProductDetailsType,
  ProductDetails,
  ProductDetailsDTO,
} from "~/api/models/ProductDetails";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { QueryKeys } from "~/queryKeys";
import {
  StoryblokMultiStoryResult,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";
import { AppLocale } from "~/utils/types";

export const useFetchProductDetails = (
  cardKey?: SkinProductKey | HairProductKey
) => {
  const { locale } = useLocaleStore();
  const type = ProductDetailsType.fromProductKey(cardKey);

  return useQuery<
    StoryblokMultiStoryResult<ProductDetailsDTO>,
    unknown,
    ProductDetails | undefined,
    (QueryKeys | ProductDetailsType | AppLocale)[]
  >({
    queryKey: [QueryKeys.useFetchProductDetails, type, locale],
    queryFn: () => storyblokServiceSingleton.get(type.route, { locale }),
    select: (data) =>
      data.data.stories.length > 0
        ? {
            benefits: data.data.stories[0].content.benefits,
            image:
              data.data.stories[0].content.image.length > 0
                ? {
                    male: data.data.stories[0].content.image[0].male,
                    female: data.data.stories[0].content.image[0].female,
                  }
                : undefined,
            hideBenefits: data.data.stories[0].content.hideBenefits,
          }
        : undefined,
  });
};
