import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFetchContract } from "~/api/salesForce/useFetchContract";
import Copy from "~/components/atom/Copy";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import ContractSignedModal from "~/components/organisms/Pricing/Contract/ContractSignedModal";
import { Header } from "~/components/organisms/layout/Header/Header";

const SummaryContract = () => {
  const intl = useIntl();
  const { contractId } = useParams();
  const {
    data: contract,
    isLoading: loadingContract,
    isRefetching,
  } = useFetchContract({ contractId: contractId as string, hasRefetch: true });
  const [showContractSignedModal, setShowContractSignedModal] = useState(false);
  const [contractWasRefetch, setContractWasRefetch] = useState(false);

  useEffect(() => {
    if (isRefetching && !contractWasRefetch) {
      setContractWasRefetch(true);
    }
  }, [isRefetching, contractWasRefetch]);

  useEffect(() => {
    if (contractWasRefetch && contract?.signed) {
      setShowContractSignedModal(true);
    }
  }, [contractWasRefetch, contract?.signed]);

  return (
    <div className="flex flex-col p-body gap-header bg-grey-150 h-full">
      <ContractSignedModal
        open={showContractSignedModal}
        setOpen={setShowContractSignedModal}
      />
      <Header
        title={intl.formatMessage({ id: "pricing.contract.title" })}
        hasBackButton
      />
      {loadingContract ? (
        <div className="flex items-center justify-center h-full">
          <SpinnerIcon className="text-3xl" />
        </div>
      ) : (
        <div className="grid grid-cols-5 h-full gap-16">
          <div className="col-span-4 bg-white h-full rounded-2xl">
            <iframe
              src={contract?.url}
              title={intl.formatMessage({ id: "pricing.contract.title" })}
              className="w-full h-full rounded-2xl"
            ></iframe>
          </div>
          <div className="flex flex-col gap-6">
            {contract?.signed && (
              <div className="border border-grey-50 rounded-2xl px-4 py-5 flex flex-col gap-2 items-center text-center">
                <div className="bg-green-100 rounded-full h-20 w-20 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-green-900 rounded-full text-2xl"
                  />
                </div>
                <Subtitle level={2}>
                  <Copy id="pricing.contract.signed" />
                </Subtitle>
              </div>
            )}
            <div className="border border-grey-50 rounded-2xl px-4 py-5 flex flex-col gap-1 items-center text-center">
              <QRCode
                value={contract?.url ?? ""}
                className="p-3 w-full h-full"
                bgColor="#F7F5F0"
              />
              <Subtitle level={2} className="text-grey-400">
                {contract?.signed
                  ? intl.formatMessage({ id: "pricing.contract.qr.view" })
                  : intl.formatMessage({ id: "pricing.contract.qr.sign" })}
              </Subtitle>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryContract;
