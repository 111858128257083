import { useMemo, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import Copy from "~/components/atom/Copy";
import Radio from "~/components/atoms/Radio/Radio";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { Product } from "~/stores/checkout/product";

type FreeProductsChevronProps = {
  selectedFreePrp?: Product;
  setSelectedFreePrp: (product?: Product) => void;
};

const FreePrpProductsChevron = ({
  selectedFreePrp,
  setSelectedFreePrp,
}: FreeProductsChevronProps) => {
  const { data: allProducts, isLoading } = useFetchProducts();
  const [showOptions, setShowOptions] = useState(false);
  const freePrpOptions = useMemo(
    () => (isLoading ? [] : allProducts.hair.head.freePrp),
    [allProducts, isLoading]
  );
  const onPrpRadioChange = (option?: Product) => {
    if (selectedFreePrp?.id === option?.id) {
      setSelectedFreePrp(undefined);
    } else {
      setSelectedFreePrp(option);
    }
  };
  const toggleOptions = () => setShowOptions((prev) => !prev);

  return (
    <div>
      <button className="flex gap-1 items-center py-3" onClick={toggleOptions}>
        <motion.div
          animate={{ rotate: showOptions ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className="w-3 h-3 p-1 text-xs"
          />
        </motion.div>
        <Subtitle level={3} className="uppercase font-bold">
          <Copy id="pricing.modal.discount.other" />
        </Subtitle>
      </button>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: showOptions ? "auto" : 0 }}
        className="overflow-hidden"
        exit={{ height: 0 }}
        transition={{ duration: 0.3 }}
      >
        {freePrpOptions.map((option, index) => (
          <Radio
            key={index}
            option={option}
            checked={!!selectedFreePrp}
            onChange={onPrpRadioChange}
            withCheck
            className="py-2"
          />
        ))}
      </motion.div>
    </div>
  );
};

export default FreePrpProductsChevron;
