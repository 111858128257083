import { PropsWithChildren } from "react";
import StoryblokOptimizedImage from "~/components/atoms/StoryblokOptimizedImage/StoryblokOptimizedImage";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import { StoryblokAsset } from "~/services/storyblok";
import { cn } from "~/utils";

type ProductDetailCardProps = PropsWithChildren<{
  title?: string;
  image?: StoryblokAsset;
  imageClassName?: string;
}>;

const ProductDetailCard = ({
  title,
  image,
  imageClassName,
  children,
}: ProductDetailCardProps) => {
  return (
    <div className="flex gap-3 items-center">
      <div className="shrink-0 rounded-xl bg-beige-100 w-product-detail-card h-product-detail-card">
        <StoryblokOptimizedImage
          src={image?.filename}
          alt={image?.alt}
          className={cn(
            "rounded-xl max-w-full object-cover object-center",
            imageClassName
          )}
        />
      </div>
      {title && <Subtitle level={2}>{title}</Subtitle>}
      {children}
    </div>
  );
};

export default ProductDetailCard;
