import { ReactNode, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Copy from "~/components/atom/Copy";
import Badge from "~/components/atoms/Badge/Badge";
import Headline from "~/components/atoms/Headline/Headline";
import SpinnerIcon from "~/components/atoms/SpinnerIcon/SpinnerIcon";
import PricingModal from "~/components/molecules/PricingModal/PricingModal";
import PricingModalOption from "~/components/molecules/PricingModalOption/PricingModalOption";
import useProductCardContext from "~/hooks/useProductCardContext";
import { Product, ProductRadioGroupOption } from "~/stores/checkout/product";

export type ProductModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  product?: Product | Product[];
  setProduct?: (product?: Product | Product[]) => void;
  modalTitle: string;
  blankOptionTitle?: string;
  optionSubtitleFormatter?: (
    option?: ProductRadioGroupOption | Product
  ) => string;
  optionTitleFormatter?: (option: ProductRadioGroupOption) => string;
  options: Product[];
  isLoading: boolean;
  hasSubtitle?: boolean;
  hideNoneOption?: boolean;
  customContent?: (
    selectOption: (option?: ProductRadioGroupOption) => void,
    selected?: Product
  ) => ReactNode;
  isFillerProduct?: boolean;
  hasOnlySaveButton?: boolean;
  onReset?: () => void;
  onSave?: () => void;
  saveIsLoading?: boolean;
};

type SelectedOption = {
  mainOption?: Product;
  extras?: Product[];
};

const ProductModal = ({
  open,
  setOpen,
  product,
  setProduct,
  modalTitle,
  blankOptionTitle,
  optionSubtitleFormatter,
  optionTitleFormatter,
  options,
  isLoading,
  hasSubtitle = true,
  hideNoneOption = false,
  customContent,
  isFillerProduct,
  hasOnlySaveButton,
  onReset,
  onSave,
  saveIsLoading,
}: ProductModalProps) => {
  const intl = useIntl();
  const { isSuggested } = useProductCardContext();
  const [storeMainOption, storeExtras] = useMemo(() => {
    if (!Array.isArray(product)) {
      return [product, []];
    }
    let mainOption: Product | undefined = undefined;
    const extras: Product[] = [];
    product?.map((product) => {
      if (product.type.includes("addition")) {
        extras.push(product);
      } else {
        mainOption = product;
      }
    });

    return [mainOption, extras];
  }, [product]);

  const [selected, setSelected] = useState<SelectedOption>({
    mainOption: storeMainOption,
    extras: storeExtras,
  });

  const selectExtra = (option?: ProductRadioGroupOption) => {
    if (option && selected.mainOption) {
      const extras = selected.extras || [];
      const index = extras.findIndex((extra) => extra.id === option.id);
      if (index >= 0) {
        extras.splice(index, 1);
      } else {
        extras.push(option);
      }
      setSelected({ ...selected, extras });
    }
  };

  const selectMainOption = (option?: ProductRadioGroupOption) => {
    const extras = option ? selected.extras : [];
    setSelected({ mainOption: option, extras: extras });
  };

  const saveOption = () => {
    if (onSave) {
      onSave();
      return setOpen(false);
    }
    const products: Product[] = [];
    if (selected.mainOption) {
      products.push(selected.mainOption);
    }
    if (selected.extras) {
      products.push(...selected.extras);
    }
    if (setProduct) {
      setProduct(isFillerProduct ? products : selected.mainOption);
    }
    setOpen(false);
  };

  const resetSelected = () => {
    if (onReset) {
      return onReset();
    }
    setSelected({
      mainOption: storeMainOption,
      extras: storeExtras,
    });
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      resetSelected();
    }
    setOpen(open);
  };

  useEffect(() => {
    setSelected({
      mainOption: storeMainOption,
      extras: storeExtras,
    });
  }, [storeMainOption, storeExtras]);

  const [mainOptions, extras] = useMemo(() => {
    const mainOptions: Product[] = [];
    const extras: Product[] = [];
    options
      .filter((option) => !option.isHiddenFromSales)
      .map((option) => {
        if (option.type.includes("addition")) {
          extras.push(option);
        } else {
          mainOptions.push(option);
        }
      });

    return [mainOptions, extras];
  }, [options]);

  return (
    <PricingModal
      hasOnlySaveButton={hasOnlySaveButton}
      open={open}
      setOpen={onOpenChange}
      title={modalTitle}
      buttonText={intl.formatMessage({ id: "actions.save" })}
      buttonAction={saveOption}
      saveIsLoading={saveIsLoading}
    >
      <div className="flex flex-col justify-between align-center gap-4 h-full">
        <div className="grid gap-4">
          {!hideNoneOption && (
            <PricingModalOption
              className="animate-fade-in"
              title={blankOptionTitle}
              selected={!selected.mainOption}
              onSelected={() => selectMainOption(undefined)}
            />
          )}
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <SpinnerIcon className="text-3xl" />
            </div>
          ) : customContent ? (
            customContent(selectMainOption, selected.mainOption)
          ) : (
            <>
              {mainOptions.map((option, index) => {
                const badge = option.recommended && (
                  <Badge className="bg-green-900">
                    <Copy id="pricing.modal.recommended" />
                  </Badge>
                );

                return (
                  <PricingModalOption
                    className="animate-fade-in"
                    key={index}
                    title={
                      optionTitleFormatter
                        ? optionTitleFormatter(option)
                        : option.name
                    }
                    subtitle={
                      hasSubtitle
                        ? optionSubtitleFormatter
                          ? optionSubtitleFormatter(option)
                          : option.name
                        : undefined
                    }
                    selected={option.id === selected?.mainOption?.id}
                    onSelected={() => selectMainOption(option)}
                    priceAmount={!isSuggested ? option.price : undefined}
                    badge={badge}
                  />
                );
              })}
              {extras.length > 0 && (
                <Headline level={6}>
                  <Copy id="pricing.filler.extras" />
                </Headline>
              )}
              {extras.map((option, index) => {
                const badge = option.recommended && (
                  <Badge className="bg-green-900">
                    <Copy id="pricing.modal.recommended" />
                  </Badge>
                );

                return (
                  <PricingModalOption
                    className="animate-fade-in"
                    key={index}
                    title={
                      optionTitleFormatter
                        ? optionTitleFormatter(option)
                        : option.name
                    }
                    subtitle={
                      hasSubtitle
                        ? optionSubtitleFormatter
                          ? optionSubtitleFormatter(option)
                          : option.name
                        : undefined
                    }
                    selected={
                      !!selected?.extras?.find(
                        (extra) => extra.id === option.id
                      )
                    }
                    onSelected={() => selectExtra(option)}
                    priceAmount={!isSuggested ? option.price : undefined}
                    badge={badge}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </PricingModal>
  );
};

export default ProductModal;
