import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useFetchIntroMainCard } from "~/api/storyblok/useFetchIntroMainCard";
import Copy from "~/components/atom/Copy";
import Button from "~/components/atoms/Button/Button";
import Headline from "~/components/atoms/Headline/Headline";
import StepCount from "~/components/atoms/StepCount/StepCount";
import HomeCard from "~/components/molecules/HomeCard/HomeCard";
import useTreatmentType from "~/hooks/useTreatmentType";
import { Routes } from "~/routes/routes";
import { optimizeImage } from "~/utils/storyblok.image.utils";

const AgendaCard = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { data } = useFetchIntroMainCard();
  const { opportunityId } = useParams();
  const { planType } = useTreatmentType();

  const sideBarRoutes = useMemo(() => {
    if (planType === "hs") {
      return [
        {
          customTitle: intl.formatMessage({ id: "sidebar.introduction.title" }),
        },
        {
          customTitle: intl.formatMessage({ id: "sidebar.hair.title" }),
        },
        { customTitle: intl.formatMessage({ id: "sidebar.skin.title" }) },
        {
          customTitle: intl.formatMessage({ id: "sidebar.summary.title" }),
        },
      ];
    }
    if (planType === "skin") {
      return [
        {
          customTitle: intl.formatMessage({ id: "sidebar.introduction.title" }),
        },
        { customTitle: intl.formatMessage({ id: "sidebar.skin.title" }) },
        {
          customTitle: intl.formatMessage({ id: "sidebar.summary.title" }),
        },
      ];
    }

    return [
      {
        customTitle: intl.formatMessage({ id: "sidebar.introduction.title" }),
      },
      {
        customTitle: intl.formatMessage({ id: "sidebar.hair.title" }),
      },
      {
        customTitle: intl.formatMessage({ id: "sidebar.summary.title" }),
      },
    ];
  }, [planType, intl]);

  return (
    <HomeCard
      image={{
        src: optimizeImage({ src: data?.backgroundImage?.filename }),
        alt: data?.backgroundImage?.alt ?? "",
      }}
    >
      <div className="flex flex-col justify-center gap-8">
        <Headline level={1}>
          <Copy id="home.agenda.title" />
        </Headline>
        <div className="flex flex-col gap-4">
          {sideBarRoutes.map((step, index) => (
            <div key={index} className="flex items-center gap-6">
              <StepCount number={index + 1} />
              <Headline level={4}>{step.customTitle}</Headline>
            </div>
          ))}
        </div>
        <Button
          className="mt-1"
          text={intl.formatMessage({ id: "home.agenda.button" })}
          onClick={() =>
            navigate(
              generatePath(Routes.Introduction, {
                opportunityId: opportunityId as string,
              })
            )
          }
        />
      </div>
    </HomeCard>
  );
};

export default AgendaCard;
