export const getInitials = (name: string) =>
  name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .substring(0, 2);

export const isStringInList = <T extends string>(
  input: T,
  list: readonly T[]
): boolean => list.includes(input);

export const separateLettersAndNonLetters = (inputString: string) => {
  const result: { value: string; isLetter: boolean }[] = [];

  // Updated regular expression to include letters and accented letters as well
  const regex = /([\p{L}\s]+|[\d,-]+)/gu;
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    const value = match[0];

    // Check if the value is a letter or includes hyphens, commas, or numbers
    const isLetter = !/[\d,-]+/.test(value);

    result.push({ value, isLetter });
  }

  return result;
};
