import { useQuery } from "@tanstack/react-query";
import { Opportunity } from "~/api/models/Opportunity";
import { useFetchAccessToken } from "~/api/salesForce/useFetchAccessToken";
import { QueryKeys } from "~/queryKeys";
import { salesforceServiceSingleton } from "~/services/salesForce/salesForce";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

type UseFetchOpportunityArgs = {
  id: string;
  hasRefetch?: boolean;
};

const REFETCH_INTERVAL = 1000 * 3;
const DEFAULT_LOCALE = "en";

export const useFetchOpportunity = ({
  id,
  hasRefetch,
}: UseFetchOpportunityArgs) => {
  const { data } = useFetchAccessToken();
  const [locale, setLocale, isLocaleManuallySelected] = useLocaleStore(
    (state) => [state.locale, state.setLocale, state.isLocaleManuallySelected]
  );

  return useQuery({
    queryKey: [
      QueryKeys.useFetchOpportunity,
      locale,
      hasRefetch,
      isLocaleManuallySelected,
    ],
    queryFn: async () => {
      const opportunity = await salesforceServiceSingleton.getOpportunity(
        data!.accessToken, //accessToken is not null because of the enabled flag
        id,
        locale
      );

      if (!isLocaleManuallySelected) {
        setLocale(
          opportunity?.language ? opportunity.language : DEFAULT_LOCALE
        );
      }
      return opportunity;
    },
    enabled: !!data?.ensureAccessTokenIsValid?.(),
    select: (data) => (data ? new Opportunity(data) : undefined),
    refetchInterval: hasRefetch ? REFETCH_INTERVAL : false,
    cacheTime: hasRefetch ? 0 : 1000 * 60 * 5,
    staleTime: hasRefetch ? 0 : 1000 * 60 * 5,
  });
};
