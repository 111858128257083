export function toDoubleDigit(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}
export function toInterval(from?: number, to?: number): string {
  if (from && to) {
    return `${from}-${to}`;
  } else if (from) {
    return `${from}<`;
  } else if (to) {
    return `>${to}`;
  } else {
    return "";
  }
}
export function formatSalesForcePercent(percent?: number): string {
  return percent?.toFixed(2).replace(/\.00$/, "") ?? "";
}
