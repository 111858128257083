import { formatSalesForcePercent } from "~/utils/number.utils";
import { AppLocale } from "~/utils/types";
import { Interest, InterestValue } from "./Interest";

export type OpportunityDTO = {
  id: string;
  amount: number;
  discount_total: number;
  contract_expiration_date: string;
  last_document_url: string;
  last_document_title: string;
  account: {
    id: string;
    first_name: string;
    last_name: string;
    salutation?: string;
  };
  language: AppLocale | null;
  line_items?: { product_id: string }[];
  interested_in?: InterestValue;
  bundle_items: {
    id: string;
    name: string;
    bundle_id: string;
    sales_price: number;
  }[];
  excluded_bundle_products_ids: string[];
  price_breakdown: {
    total_discount: number;
    discounted_amount: number;
    discount_percent: number;
  };
  last_modified_date_time: string;
  skin_configuration: string[] | null;
  is_valid_for_sales: boolean;
  last_contract_id: string | null;
  payment_means: string;
  location: {
    id: string;
    name: string;
    google_review_url: string;
  };
  special_conditions: string[];
  other_special_conditions: string | null;
  public_discount: {
    name: string;
    discount_code: string;
  } | null;
  referrer: {
    name: string;
    referral_code: string;
  } | null;
};

export class Opportunity {
  id: string;
  products: string[];
  bundles: string[];
  excludedBundleProductIds: string[];
  amount: number;
  discountTotal: number;
  contractExpirationDate: Date;
  lastDocumentUrl: string;
  lastDocumentTitle: string;
  accountFirstName: string;
  accountLastName: string;
  language: AppLocale;
  interestedIn?: Interest;
  gender?: "male" | "female";
  discountedAmount: number;
  lastModifiedDateTime: Date;
  skinConfiguration: string[] = [];
  discountPercent: string;
  isValidForSales: boolean;
  lastContractId: string | null;
  paymentMeans: string;
  location: {
    id: string;
    name: string;
    googleReviewUrl: string;
  };
  specialConditions: string[];
  otherSpecialConditions: string | null;
  discountCode: string | null;
  referralCode: string | null;

  getBundleId() {
    return this.bundles.length > 0 ? this.bundles[0] : undefined;
  }

  hasSkinCare(): boolean | undefined {
    // @TODO: Revise when we have other bundle products
    return !this.getBundleId()
      ? undefined
      : this.excludedBundleProductIds.length === 0;
  }

  public constructor(opportunityDto: OpportunityDTO) {
    this.bundles = opportunityDto.bundle_items.map((b) => b.bundle_id);
    this.excludedBundleProductIds = opportunityDto.excluded_bundle_products_ids;
    this.id = opportunityDto.id;
    this.accountFirstName = opportunityDto.account.first_name;
    this.accountLastName = opportunityDto.account.last_name;
    this.products = opportunityDto.line_items
      ? opportunityDto.line_items.map((item) => item.product_id)
      : [];
    this.amount = opportunityDto.amount;
    this.discountTotal = opportunityDto.discount_total;
    this.contractExpirationDate = new Date(
      opportunityDto.contract_expiration_date
    );
    this.lastDocumentTitle = opportunityDto.last_document_title;
    this.lastDocumentUrl = opportunityDto.last_document_url;
    this.language = opportunityDto.language ? opportunityDto.language : "en";
    this.discountedAmount = opportunityDto.price_breakdown.discounted_amount;
    this.discountPercent = formatSalesForcePercent(
      opportunityDto.price_breakdown.discount_percent
    );

    this.lastModifiedDateTime = new Date(
      opportunityDto.last_modified_date_time
    );
    this.interestedIn = Interest.values.find(
      (interest) => interest.value === opportunityDto.interested_in
    );
    if (
      opportunityDto.account.salutation === "Herr" ||
      opportunityDto.account.salutation === "Frau"
    ) {
      this.gender =
        opportunityDto.account.salutation === "Herr" ? "male" : "female";
    }
    this.skinConfiguration = opportunityDto.skin_configuration ?? [];
    this.isValidForSales = opportunityDto.is_valid_for_sales;
    this.lastContractId = opportunityDto.last_contract_id;
    this.paymentMeans = opportunityDto.payment_means;
    this.location = {
      id: opportunityDto.location.id,
      name: opportunityDto.location.name,
      googleReviewUrl: opportunityDto.location.google_review_url,
    };
    this.specialConditions = opportunityDto.special_conditions;
    this.otherSpecialConditions = opportunityDto.other_special_conditions;
    this.discountCode = opportunityDto.public_discount?.discount_code ?? null;
    this.referralCode = opportunityDto.referrer?.referral_code ?? null;
  }
}

export type UpdateOpportunityDTO = {
  skin_configuration: string[];
};
