import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { SkinProductKey } from "~/api/models/SkinProductKey";
import { useFetchOpportunity } from "~/api/salesForce/useFetchOpportunity";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import {
  UPDATE_OPPORTUNITY_ERROR_TOAST_ID,
  useUpdateOpportunity,
} from "~/api/salesForce/useUpdateOpportunity";
import FemaleSkinConfiguration from "~/components/molecules/FemaleSkinConfiguration/FemaleSkinConfiguration";
import MaleSkinConfiguration from "~/components/molecules/MaleSkinConfiguration/MaleSkinConfiguration";
import useSkinProducts from "~/hooks/useSkinProducts";
import useConfigurationSectionStore from "~/stores/headConfiguration";
import { SkinSection } from "~/stores/headConfiguration/ConfigurationSection.types";
import { cn } from "~/utils";
import { notifyOnce } from "~/utils/toast.utils";

export const SELECTED_RADIUS = 9.25106;
export const UNSELECTED_RADIUS = 7.25106;

type SkinHeadConfigurationProps = {
  isEditable?: boolean;
};

const SkinHeadConfiguration = ({
  isEditable = true,
}: SkinHeadConfigurationProps) => {
  const { opportunityId } = useParams();
  const { data: opportunity, isLoading: loadingOpportunity } =
    useFetchOpportunity({
      id: opportunityId as string,
    });
  const [skinSections, toggleSkinSectionAndUpdateProduct] =
    useConfigurationSectionStore((state) => [
      state.skinSections,
      state.toggleSkinSectionAndUpdateProduct,
    ]);
  const { getProductByProductCardKey, setSkinProductByProductCardKey } =
    useSkinProducts();
  const { data: allProducts } = useFetchProducts();
  const botoxWrinklesProduct = useMemo(
    () => getProductByProductCardKey(SkinProductKey.botoxWrinkles),
    [getProductByProductCardKey]
  );
  const { mutateAsync, isError, isLoading } = useUpdateOpportunity(
    opportunityId as string
  );

  const isSelected = (section: SkinSection) => {
    return skinSections.includes(section);
  };
  const toggleSkinSectionAndUpdateProductValue = async (
    section: SkinSection
  ) => {
    if (!isLoading && isEditable) {
      const newSkinSections = skinSections.includes(section)
        ? skinSections.filter((s) => s !== section)
        : [...skinSections, section];

      try {
        await mutateAsync({
          skin_configuration: newSkinSections,
        });
        if (!isError) {
          toggleSkinSectionAndUpdateProduct({
            section,
            botoxWrinklesProduct,
            allProducts,
            setSkinProductByProductCardKey,
          });
        }
      } catch (e) {
        notifyOnce(
          {
            toastType: "error",
            descriptionId: "opportunity.update.error",
          },
          UPDATE_OPPORTUNITY_ERROR_TOAST_ID
        );
      }
    }
  };

  return (
    !loadingOpportunity && (
      <div
        className={cn("cursor-pointer w-full h-full", {
          "pointer-events-none cursor-not-allowed": isLoading || !isEditable,
        })}
      >
        {opportunity?.gender === "female" ? (
          <FemaleSkinConfiguration
            toggleSkinSectionAndUpdateProduct={
              toggleSkinSectionAndUpdateProductValue
            }
            isSelected={isSelected}
          />
        ) : (
          <MaleSkinConfiguration
            toggleSkinSectionAndUpdateProduct={
              toggleSkinSectionAndUpdateProductValue
            }
            isSelected={isSelected}
          />
        )}
      </div>
    )
  );
};

export default SkinHeadConfiguration;
