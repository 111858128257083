import SkinProductCard, {
  SkinProductCardProps,
} from "~/components/organisms/Pricing//SkinProductCard/SkinProductCard";
import useSkinProducts from "~/hooks/useSkinProducts";
import { Product } from "~/stores/checkout/product";

type FillerProductCardProps = SkinProductCardProps;

const FillerProductCard = (props: FillerProductCardProps) => {
  const { getFillerProductByProductCardKey, setFillerProductByProductCardKey } =
    useSkinProducts();
  const fillerProducts = getFillerProductByProductCardKey(
    props.skinProductFamilyKey
  );

  const setFillerProducts = (products?: Product | Product[]) => {
    setFillerProductByProductCardKey(
      props.skinProductFamilyKey,
      products as Product[]
    );
  };
  return (
    <SkinProductCard
      {...props}
      hasSubtitle={false}
      product={fillerProducts}
      setProduct={setFillerProducts}
      isFillerProduct
    >
      {props.children}
    </SkinProductCard>
  );
};

export default FillerProductCard;
