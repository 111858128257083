import { useSearchParams } from "react-router-dom";
import HairOverview from "~/components/organisms/Photos/HairOverview";
import SkinOverview from "~/components/organisms/Photos/SkinOverview";

const UploadSkinPhotos = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");

  return (
    <div className="h-full">
      {category === "skin" ? <SkinOverview /> : <HairOverview />}
    </div>
  );
};

export default UploadSkinPhotos;
