import { useState } from "react";
import { useIntl } from "react-intl";
import { AnimatePresence, motion } from "framer-motion";
import Button from "~/components/atoms/Button/Button";
import { ButtonVariant } from "~/components/atoms/Button/Buttons.types";
import Headline from "~/components/atoms/Headline/Headline";
import Subtitle from "~/components/atoms/Subtitle/Subtitle";
import SkinCareSwitch from "~/components/molecules/SkinCareSwitch/SkinCareSwitch";
import TabsToggle from "~/components/molecules/TabsToggle/TabsToggle";
import TreatmentIndividualProducts from "~/components/organisms/TreatmentIndividualProducts/TreatmentIndividualProducts";
import TreatmentPackageProducts from "~/components/organisms/TreatmentPackageProducts/TreatmentPackageProducts";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";

type SelectTreatmentsDialogProps = {
  showSelectTreatment: boolean;
  setShowSelectTreatment: (show: boolean) => void;
  isEditable?: boolean;
};
const SelectTreatmentsDialog = ({
  showSelectTreatment,
  setShowSelectTreatment,
  isEditable = true,
}: SelectTreatmentsDialogProps) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(0);

  const hasSkinCare = useCheckoutStore((state) => state.hasSkinCare);

  return (
    <AnimatePresence>
      {showSelectTreatment && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.7, ease: "easeIn" }}
          className="bg-white absolute top-0 left-0 min-h-full w-full flex flex-col p-body gap-6"
        >
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <Headline level={3}>
                {intl.formatMessage({
                  id: "configuration.skin.treatments.title",
                })}
              </Headline>
              <Subtitle className=" text-grey-400">
                {intl.formatMessage({
                  id: "configuration.skin.treatments.description",
                })}
              </Subtitle>
            </div>
            <Button
              text={intl.formatMessage({
                id: "actions.done",
              })}
              variant={ButtonVariant.darkSecondaryOutline}
              onClick={() => setShowSelectTreatment(false)}
              className="px-10"
            />
          </div>
          <div className="flex justify-between">
            <TabsToggle
              options={[
                {
                  key: 0,
                  value: intl.formatMessage({
                    id: "configuration.skin.tabs.packages",
                  }),
                },
                {
                  key: 1,
                  value: intl.formatMessage({
                    id: "configuration.skin.tabs.individual",
                  }),
                },
              ]}
              activeOption={activeTab}
              setActiveOption={setActiveTab}
            />
            {activeTab === 0 && (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                    stiffness: 10,
                  }}
                >
                  <SkinCareSwitch disabled={!isEditable} />
                </motion.div>
              </AnimatePresence>
            )}
          </div>
          {activeTab === 0 ? (
            <TreatmentPackageProducts
              hasSkinCare={hasSkinCare}
              isEditable={isEditable}
            />
          ) : (
            <TreatmentIndividualProducts />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default SelectTreatmentsDialog;
