import { useQuery } from "@tanstack/react-query";
import { CMSBundle, CMSBundleDTO } from "~/api/models/Bundle";
import { QueryKeys } from "~/queryKeys";
import {
  storyblokRoutes,
  storyblokServiceSingleton,
} from "~/services/storyblok";
import { useLocaleStore } from "~/stores/locale/useLocaleStore";

export const useFetchBundleForCode = (bundle?: string) => {
  const { locale } = useLocaleStore();

  const result = useQuery({
    queryKey: [QueryKeys.useFetchBundles, bundle, locale],
    queryFn: () =>
      storyblokServiceSingleton.get<CMSBundleDTO>(
        `${storyblokRoutes.bundles}/${bundle}`,
        {
          locale,
        }
      ),
  });
  const bundleDTO = result.data?.data?.stories.length
    ? result.data?.data?.stories[0]?.content
    : undefined;

  if (!bundleDTO)
    return {
      ...result,
      data: null,
    };

  return {
    ...result,
    data: new CMSBundle(bundleDTO),
  };
};
