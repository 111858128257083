import { ISbRichtext } from "@storyblok/react";
import { StoryblokAsset } from "~/services/storyblok";

export class SkincareProduct {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly description: string,
    public readonly image: StoryblokAsset,
    public readonly benefits: BenefitDTO[],
    public readonly subtitle?: string
  ) {}
}

export type SingleBenefit = {
  component: "SkincareProductSingleBenefit";
  _uid: string;
  title: string;
  description: ISbRichtext;
};

export type DoubleBenefit = {
  component: "SkincareProductDoubleBenefit";
  _uid: string;
  firstBenefit: SingleBenefit[];
  secondBenefit: SingleBenefit[];
};

export type BenefitDTO = SingleBenefit | DoubleBenefit;

export type SkincareProductDTO = {
  _uid: string;
  title: string;
  subtitle?: string;
  description: string;
  image: StoryblokAsset;
  benefits: BenefitDTO[];
};
