import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFetchProducts } from "~/api/salesForce/useFetchProducts";
import EditableProduct from "~/components/molecules/EditableProduct/EditableProduct";
import useHairProducts from "~/hooks/useHairProducts";
import { Product } from "~/stores/checkout/product";

type EditablePrpProps = { editable?: boolean };

const EditablePrp = ({ editable }: EditablePrpProps) => {
  const intl = useIntl();
  const { data: allProducts, isLoading } = useFetchProducts();
  const { transplantationProduct, prpProduct, setPrpProduct } =
    useHairProducts();

  const options = useMemo(() => {
    const categories =
      prpProduct?.type === "bundle" || (!prpProduct && transplantationProduct)
        ? allProducts.hair?.head.bundle
        : allProducts.hair?.head.prp;
    return isLoading ? [] : categories ?? [];
  }, [
    isLoading,
    prpProduct,
    transplantationProduct,
    allProducts.hair?.head.bundle,
    allProducts.hair?.head.prp,
  ]);

  return (
    <EditableProduct
      product={prpProduct}
      setProduct={
        setPrpProduct as (product?: Product | Product[] | undefined) => void
      }
      modalTitle={intl.formatMessage({ id: "pricing.modal.prp.title" })}
      blankOptionTitle={intl.formatMessage({
        id: "treatment.pricing.prp.none",
      })}
      isLoading={isLoading}
      options={options}
      className="text-black text-sm"
      editable={editable}
    />
  );
};

export default EditablePrp;
