import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Input from "~/components/atoms/Input/Input";
import DiscountCodeDropdown from "~/components/molecules/DiscountCodeDropdown/DiscountCodeDropdown";
import PricingModal from "~/components/molecules/PricingModal/PricingModal";
import useHairProducts from "~/hooks/useHairProducts";
import { useCheckoutStore } from "~/stores/checkout/useCheckoutStore";
import FreePrpProductsChevron from "./FreePrpProductsChevron";

type DiscountModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const DiscountModal = ({ open, setOpen }: DiscountModalProps) => {
  const intl = useIntl();
  const [discountCode, referralCode, setDiscountCode, setReferralCode] =
    useCheckoutStore((state) => [
      state.discountCode,
      state.referralCode,
      state.setDiscountCode,
      state.setReferralCode,
    ]);
  const { freePrpProduct, setFreePrpProduct } = useHairProducts();
  const [selectedFreePrp, setSelectedFreePrp] = useState(freePrpProduct);
  const [selectedDiscountCode, setSelectedDiscountCode] = useState<
    string | null
  >(discountCode ?? "");
  const [selectedReferralCode, setSelectedReferralCode] = useState<string>(
    referralCode ?? ""
  );

  const onSave = () => {
    setFreePrpProduct(selectedFreePrp);
    setDiscountCode(selectedDiscountCode);
    setReferralCode(selectedReferralCode);
    setOpen(false);
  };

  const reset = () => {
    setSelectedFreePrp(freePrpProduct);
    setSelectedDiscountCode(discountCode ?? "");
    setSelectedReferralCode(referralCode ?? "");
  };

  useEffect(() => {
    if (open) {
      setSelectedFreePrp(freePrpProduct);
      setSelectedDiscountCode(discountCode ?? "");
      setSelectedReferralCode(referralCode ?? "");
    }
  }, [freePrpProduct, open, referralCode, discountCode]);

  const onOpenChange = (open: boolean) => {
    if (!open) {
      reset();
    }
    setOpen(open);
  };

  return (
    <PricingModal
      open={open}
      setOpen={onOpenChange}
      title={intl.formatMessage({ id: "pricing.modal.discount.title" })}
      buttonText={intl.formatMessage({ id: "actions.save" })}
      buttonAction={onSave}
      className="!min-h-fit !max-w-discount-modal"
    >
      <div className="flex flex-col gap-4">
        <DiscountCodeDropdown
          selectedDiscountCode={selectedDiscountCode}
          setSelectedDiscountCode={setSelectedDiscountCode}
        />
        <Input
          value={selectedReferralCode}
          onChange={(value) => setSelectedReferralCode(value)}
          placeholder={intl.formatMessage({
            id: "pricing.modal.discount.referralCode.placeholder",
          })}
        />
        <FreePrpProductsChevron
          selectedFreePrp={selectedFreePrp}
          setSelectedFreePrp={setSelectedFreePrp}
        />
      </div>
    </PricingModal>
  );
};

export default DiscountModal;
